import React from 'react'
import { Table } from 'reactstrap'
import { Spinner, TableBody } from '../../components'
import GroupRow from './Row'

const GroupList = ({ loading, groups, permissions }) => {
  return (
    <Table className="table-valign-middle" striped>
      <thead>
        <tr>
          <th>Name</th>
          <th>Number of Users</th>
          <th>Number of Permissions</th>
          <th style={{ textAlign: 'center' }}>Actions</th>
        </tr>
      </thead>
      <TableBody>
        {loading ? (
          <Spinner columns={4} />
        ) : (
          groups && groups.map(group => <GroupRow key={group.id} group={group} permissions={permissions} />)
        )}
      </TableBody>
    </Table>
  )
}

export default GroupList
