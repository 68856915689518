import React from 'react'
import Moment from 'react-moment'
import { Table } from 'reactstrap'

export default function ResultIperf({ result }) {
  return result.result === 'failed' ? (
    <Table responsive size="sm">
      <thead>
        <th>Result</th>
        <th>Failure Reason</th>
      </thead>
      <tbody>
        <tr>
          <td>{result.result}</td>
          <td>{result.reason}</td>
        </tr>
      </tbody>
    </Table>
  ) : (
    <Table bordered style={{ textAlign: 'center' }}>
      <thead>
        <tr>
          <th>Source</th>
          <th>Destination</th>
          <th>Start</th>
          {'transfer' in result.connections.forwards && <th>Transfered</th>}
          {'bandwidth' in result.connections.forwards && <th>Bandwidth</th>}
          {'packets_total' in result.connections.forwards && <th>Packets total</th>}
          {'packets_lost' in result.connections.forwards && <th>Packets lost</th>}
          {'loss_ratio' in result.connections.forwards && <th>Loss ratio</th>}
          {'jitter' in result.connections.forwards && <th>Jitter</th>}
          {'transfer sender' in result.connections.forwards && <th>Transfer sender</th>}
          {'bandwidth sender' in result.connections.forwards && <th>Bandwidth sender</th>}
          {'transfer receiver' in result.connections.forwards && <th>Transfer receiver</th>}
          {'bandwidth receiver' in result.connections.forwards && <th>Bandwidth receiver</th>}
        </tr>
      </thead>
      <tbody>
        {result.connections &&
          Object.keys(result.connections).map(direction => (
            <tr>
              <td>{result.connections[direction].src || result.connections[direction].source}</td>
              <td>{result.connections[direction].dst || result.connections[direction].destination}</td>
              <td>
                <Moment format="DD-MM-YYYY HH:mm:ss">{result.connections[direction].start}</Moment>
              </td>
              {'transfer' in result.connections.forwards && (
                <td>
                  {result.connections[direction].transfer && `${result.connections[direction].transfer.toFixed(2)} MB`}
                </td>
              )}
              {'bandwidth' in result.connections.forwards && (
                <td>
                  {result.connections[direction].bandwidth &&
                    `${result.connections[direction].bandwidth.toFixed(2)} MB/s`}
                </td>
              )}
              {'packets_total' in result.connections.forwards && <td>{result.connections[direction].packets_total}</td>}
              {'packets_lost' in result.connections.forwards && <td>{result.connections[direction].packets_lost}</td>}
              {'loss_ratio' in result.connections.forwards && (
                <td>
                  {result.connections[direction].loss_ratio && result.connections[direction].loss_ratio.toFixed(2)}
                </td>
              )}
              {'jitter' in result.connections.forwards && (
                <td>{result.connections[direction].jitter && result.connections[direction].jitter.toFixed(2)}</td>
              )}
              {'transfer sender' in result.connections.forwards && (
                <td>
                  {result.connections[direction]['transfer sender'] &&
                    `${result.connections[direction]['transfer sender'].toFixed(2)} MB`}
                </td>
              )}
              {'bandwidth sender' in result.connections.forwards && (
                <td>
                  {result.connections[direction]['bandwidth sender'] &&
                    `${result.connections[direction]['bandwidth sender'].toFixed(2)} MB/s`}
                </td>
              )}
              {'transfer receiver' in result.connections.forwards && (
                <td>
                  {result.connections[direction]['transfer receiver'] &&
                    `${result.connections[direction]['transfer receiver'].toFixed(2)} MB`}
                </td>
              )}
              {'bandwidth receiver' in result.connections.forwards && (
                <td>
                  {result.connections[direction]['bandwidth receiver'] &&
                    `${result.connections[direction]['bandwidth receiver'].toFixed(2)} MB/s`}
                </td>
              )}
            </tr>
          ))}
      </tbody>
    </Table>
  )
}
