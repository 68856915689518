import React from 'react'
import { Table, Progress } from 'reactstrap'
import { FaRegCircle } from 'react-icons/fa'

export default function ResultCPUUtilization({ result }) {
  return (
    <div>
      <Table responsive size="sm">
        <thead>
          <tr>
            <th>Processor</th>
            <th>Result</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(result).map(key => (
            <tr key={key}>
              <td>{key}</td>
              <td>
                <Progress multi>
                  <Progress
                    bar
                    value={result[key].user}
                    max={result[key].user + result[key].system + result[key].idle}
                    color="success"
                  />
                  <Progress
                    bar
                    value={result[key].system}
                    max={result[key].user + result[key].system + result[key].idle}
                    color="primary"
                  />
                </Progress>
                <div>
                  <FaRegCircle color="green" /> User (
                  {Math.round((result[key].user / (result[key].user + result[key].system + result[key].idle)) * 1000) /
                    10}
                  %) <FaRegCircle color="blue" /> System (
                  {Math.round(
                    (result[key].system / (result[key].user + result[key].system + result[key].idle)) * 1000
                  ) / 10}
                  %) <FaRegCircle color="gray" /> Idle (
                  {Math.round((result[key].idle / (result[key].user + result[key].system + result[key].idle)) * 1000) /
                    10}
                  %)
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}
