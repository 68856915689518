import React from 'react'
import { Table } from 'reactstrap'

export default function ResultFTPAvailability({ result }) {
  return (
    <div>
      <Table responsive size="sm">
        <thead>
          <tr>
            <th>Hostname</th>
            <th>Time</th>
            <th>Succeeded</th>
          </tr>
        </thead>
        <tbody>
          {result.map((data, index) => (
            <tr key={index}>
              <td>{data.hostname}</td>
              <td>{data.time_ms ? `${Math.round(data.time_ms * 100) / 100} ms` : '-'}</td>
              <td style={{ color: data.succeeded ? 'green' : 'red' }}>{data.succeeded ? 'PASS' : 'FAILED'}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}
