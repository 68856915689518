import React, { useContext, useEffect, useState } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Card, CardBody, CardHeader, CardTitle, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap'
import Select from 'react-select'
import * as actions from '../../store/actions'
import { TestAgentsContext } from '../../context/TestAgentsContext'
import { validateLocations, validateTextInput } from '../../utilities/formValidator'
import { locationOptions } from '../../utilities/locations'
import { createTestSuiteMessage } from '../../utilities/testSuite'
import { Spinner } from '../../components'
import { TestTileCompact } from '../../containers'
import TestSuiteConfigurator from '../../context/TestSuiteConfiguratorContext'

export default function TestSuiteQuickStart() {
  const dispatch = useDispatch()
  const history = useHistory()
  const { testAgents } = useContext(TestAgentsContext)
  const token = useSelector(state => state.auth.token)
  const locations = useSelector(state => state.locations.data)
  const taskTypes = useSelector(state => state.task_types.data)
  const loading = useSelector(
    state =>
      state.locations.loading ||
      state.task_types.loading ||
      state.enabled_notifications.loading ||
      state.notifications_types.loading ||
      state.test_categories.loading
  )
  const createdTest = useSelector(state => state.created_test.data)

  const [testname, setTestname] = useState('')
  const [globalLocations, setGlobalLocations] = useState()
  const [inputValid, setInputValid] = useState({ testname: false, locations: false })
  const [validatorFeedback, setValidatorFeedback] = useState({ testname: '' })
  const [validatorEnabled, setValidatorEnabled] = useState({ testname: false })
  const [stepsValid, setStepsValid] = useState(false)
  const [steps, setSteps] = useState([{ tests: [] }])

  const testTiles = [
    { name: 'network_ping', params: ['address'] },
    { name: 'info_memory', params: [] },
    { name: 'har_test', params: ['url'] }
  ]

  useEffect(() => {
    if (createdTest && createdTest.id) {
      history.push(`/results/details/${createdTest.id}`)
    }
  }, [createdTest])

  function handleFormChange(value, paramName, parentName) {
    let _steps = [...steps]
    if (parentName) {
      _steps[0].tests[0][parentName][paramName] = value
    } else {
      _steps[0].tests[0][paramName] = value
    }
    setSteps(_steps)
  }

  function handleMultipleInputChange(value, index, paramName, parentName) {
    let _steps = [...steps]
    if (parentName) {
      _steps[0].tests[0][parentName][paramName][index] = value
    } else {
      _steps[0].tests[0][paramName][index] = value
    }
    setSteps(_steps)
  }

  function addInputToMultiple(paramName, parentName) {
    let _steps = [...steps]
    _steps[0].tests[0][parentName][paramName].push('')
    setSteps(_steps)
  }

  function removeInputFromMultiple(paramName, parentName, index) {
    let _steps = [...steps]
    _steps[0].tests[0][parentName][paramName].splice(index, 1)
    setSteps(_steps)
  }

  function submitTestSuite(e) {
    e.preventDefault()
    let message = createTestSuiteMessage(testname, null, globalLocations, 0, null, {}, steps)
    dispatch(actions.addData(token, `TEST_SUITES`, message))
  }

  return (
    <Card className="top-line-info" style={{ height: '95%' }}>
      <CardHeader className="no-border">
        <CardTitle>Test Suite: Quick Start</CardTitle>
      </CardHeader>
      <CardBody>
        {loading ? (
          <Spinner />
        ) : (
          <TestSuiteConfigurator.Provider
            value={{
              selectedBlock: steps[0].tests[0],
              steps,
              setSteps,
              globalLocations,
              handleFormChange,
              handleMultipleInputChange,
              addInputToMultiple,
              removeInputFromMultiple
            }}
          >
            <Form id="testform" onSubmit={submitTestSuite}>
              {/* Label's row */}
              <Row>
                <Col xs="3">
                  <Label for="testname_quickstart">
                    Name: <p className="text-danger d-inline">*</p>
                  </Label>
                </Col>
                <Col xs="7">
                  <Label for="locations">
                    Select a location: <p className="text-danger d-inline">*</p>
                  </Label>
                </Col>
              </Row>
              {/* Field's row */}
              <Row className="d-flex align-items-top">
                <Col sm="3" xs="4">
                  <Input
                    id="testname"
                    name="testname"
                    placeholder="Test's name"
                    value={testname}
                    valid={validatorEnabled.testname && inputValid.testname === true}
                    invalid={validatorEnabled.testname && inputValid.testname === false}
                    onChange={e => {
                      setTestname(e.target.value)
                      let validateResult = validateTextInput(e.target.value, 'Test name')
                      setInputValid({ ...inputValid, [e.target.name]: validateResult.isValid })
                      setValidatorFeedback({
                        ...validatorFeedback,
                        [e.target.name]: validateResult.feedback
                      })
                    }}
                    onBlur={e => {
                      setValidatorEnabled({ ...validatorEnabled, [e.target.name]: true })
                      let validateResult = validateTextInput(e.target.value, 'Test name')
                      setInputValid({ ...inputValid, [e.target.name]: validateResult.isValid })
                      setValidatorFeedback({
                        ...validatorFeedback,
                        [e.target.name]: validateResult.feedback
                      })
                    }}
                  />
                  {validatorEnabled.testname && <small className="text-danger">{validatorFeedback.testname}</small>}
                </Col>
                <Col sm="7" xs="8">
                  <FormGroup>
                    <Select
                      id="locations"
                      name="locations"
                      isMulti
                      options={locationOptions(testAgents, locations)}
                      value={globalLocations}
                      placeholder="Select an option from a list..."
                      onChange={e => {
                        setGlobalLocations(e)
                        let validateLoc = validateLocations(e, 'Locations')
                        setInputValid({ ...inputValid, locations: validateLoc.isValid })
                        setValidatorFeedback({
                          ...validatorFeedback,
                          locations: validateLoc.feedback
                        })
                      }}
                      onBlur={e => {
                        setValidatorEnabled({ ...validatorEnabled, locations: true })
                        let validateLoc = validateLocations(globalLocations, 'Locations')
                        setInputValid({ ...inputValid, locations: validateLoc.isValid })
                        setValidatorFeedback({
                          ...validatorFeedback,
                          locations: validateLoc.feedback
                        })
                      }}
                      styles={{
                        menu: provided => ({ ...provided, zIndex: 9999 }),
                        control: base => ({
                          ...base,
                          borderColor: validatorEnabled.locations
                            ? inputValid.locations
                              ? 'green'
                              : '#dc3545'
                            : 'lightgray',
                          '&:hover': {
                            borderColor: validatorEnabled.locations
                              ? inputValid.locations
                                ? 'green'
                                : '#dc3545'
                              : 'lightgray'
                          }
                        })
                      }}
                    />
                    {validatorEnabled.locations && <small className="text-danger">{validatorFeedback.locations}</small>}
                  </FormGroup>
                </Col>
                <Col sm="2" xs="12">
                  <Button
                    color="success"
                    type="submit"
                    disabled={
                      Object.values(inputValid).some(value => value === false) ||
                      steps[0].tests.length === 0 ||
                      !stepsValid
                    }
                    block
                  >
                    Start
                  </Button>
                </Col>
              </Row>
              {taskTypes && (
                <Row>
                  {testTiles.map(testTile => {
                    let taskType = taskTypes.find(tt => tt.name === testTile.name)
                    return (
                      <Col sm="3" xs="12" key={testTile.name}>
                        <TestTileCompact
                          item={taskType}
                          params={
                            taskType.options &&
                            taskType.options.params &&
                            taskType.options.params.filter(param => testTile.params.includes(param.name))
                          }
                          setStepsValid={setStepsValid}
                        />
                      </Col>
                    )
                  })}
                  <Col sm="3" xs="12" className="d-flex align-items-end justify-content-end">
                    <NavLink
                      to={{
                        pathname: '/test-suite',
                        state: {
                          rerunTest: createTestSuiteMessage(testname, null, globalLocations, 0, null, {}, steps)
                        }
                      }}
                    >
                      Advanced Config
                    </NavLink>
                  </Col>
                </Row>
              )}
            </Form>
          </TestSuiteConfigurator.Provider>
        )}
      </CardBody>
    </Card>
  )
}
