import React, { useState } from 'react'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'

export const timerangeValues1h1y = [
  { display_name: 'All results', from: 'now-50y', to: 'now' },
  { display_name: 'Last hour', from: 'now-1h', to: 'now' },
  { display_name: 'Last day', from: 'now-1d', to: 'now' },
  { display_name: 'Last week', from: 'now-7d', to: 'now' },
  { display_name: 'Last month', from: 'now-1M', to: 'now' },
  { display_name: 'Last 6 month', from: 'now-6M', to: 'now' },
  { display_name: 'Last year', from: 'now-1y', to: 'now' }
]

export default function TimerangeDropdown({ selectedTimerange, setSelectedTimerange }) {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const toggle = () => setDropdownOpen(prevState => !prevState)

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle caret color="info">
        {selectedTimerange.display_name}
      </DropdownToggle>
      <DropdownMenu right>
        {timerangeValues1h1y.map(value => (
          <DropdownItem key={value.from} onClick={() => setSelectedTimerange(value)}>
            {value.display_name}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  )
}
