import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CardBody, CardFooter, FormGroup, Button, CustomInput, Label, Input } from 'reactstrap'
import * as actions from '../../../store/actions'

export default function Update({ testAgentApi, submitForm }) {
  const dispatch = useDispatch()
  const token = useSelector(state => state.auth.token)
  const updates = useSelector(state => state.updates.data)
  const [testAgent, setTestAgent] = useState(null)
  const [updatePackage, setUpdatePackage] = useState(0)
  const [reboot, setReboot] = useState(true)

  useEffect(() => {
    if (!testAgent) {
      setTestAgent(testAgentApi)
    }
  }, [testAgentApi])

  function updateTestAgent() {
    dispatch(
      actions.addDataDetailsFunction(
        token,
        'TEST_AGENTS',
        testAgent.id,
        'UPDATE',
        { update_id: updatePackage, reboot: reboot },
        true
      )
    )
    submitForm()
  }

  return (
    <>
      <CardBody>
        <p>
          To update test agent software version on this device, select an update package from the list and click Update
          Test Agent button. If there are no packages in the list, contact the system administrator.
        </p>
        <FormGroup>
          <Label for="updates">Update packages</Label>
          <Input
            type="select"
            id="updates"
            name="updates"
            value={updatePackage}
            onChange={e => setUpdatePackage(parseInt(e.target.value))}
          >
            <option value={0} disabled>
              {' '}
              -- Select update package --{' '}
            </option>
            {updates &&
              updates.map(update => (
                <option key={update.id} value={update.id}>
                  {update.display_name}
                </option>
              ))}
          </Input>
        </FormGroup>
      </CardBody>
      <CardFooter>
        <CustomInput
          type="checkbox"
          id="rebootCheckbox"
          label="Reboot test agent after update"
          className="d-inline-block"
          checked={reboot}
          onChange={() => setReboot(prev => !prev)}
        />
        <Button
          color="info"
          className="float-right d-inline-block"
          disabled={updatePackage < 1}
          onClick={updateTestAgent}
        >
          Update Test Agent
        </Button>
      </CardFooter>
    </>
  )
}
