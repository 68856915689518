import React from 'react'
import { Button } from 'reactstrap'

const ButtonFixed = ({ children, ...props }) => {
  return (
    <div className="fixed-bottom sticky-top d-flex justify-content-center" style={{ bottom: '1rem' }}>
      <Button color="info" size="sm" {...props}>
        {children}
      </Button>
    </div>
  )
}

export default ButtonFixed
