import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, CardBody, CardHeader, CardTitle, Col, Row, Table } from 'reactstrap'
import { CardTools, ContainerFluid, Content, ContentHeader } from '../../components'
import { createTitle, displayDetails } from './utilities'
import { adminURI } from '../../api/endpoints'
import * as actions from '../../store/actions'

export default function LogDetails({ match }) {
  const dispatch = useDispatch()
  const token = useSelector(state => state.auth.token)
  const log = useSelector(state => state.log.data)

  useEffect(() => {
    document.title = 'vUTP | Logs'
  })

  useEffect(() => {
    dispatch(actions.fetchDataDetails(token, 'LOGS', match.params.id, adminURI))
  }, [match.params.id])

  return (
    <>
      <ContentHeader title="Logs" />
      <Content>
        <ContainerFluid>
          <Row>
            <Col xl="9">
              <Card>
                <CardHeader className="no-border">
                  <CardTitle>Log Details</CardTitle>
                  <CardTools />
                </CardHeader>
                {log && log.data && (
                  <CardBody className="p-0 table-responsive">
                    <Table className="table-valign-middle" striped>
                      <thead>
                        <tr>
                          {Object.entries(log.data).map(([key]) => (
                            <th key={key}>{createTitle(key)}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          {Object.entries(log.data).map(([key, value]) => (
                            <td key={key}>{displayDetails(key, value)}</td>
                          ))}
                        </tr>
                      </tbody>
                    </Table>
                  </CardBody>
                )}
              </Card>
            </Col>
          </Row>
        </ContainerFluid>
      </Content>
    </>
  )
}
