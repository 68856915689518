import { updateObject } from '../utilities'

const reducer = () => {
  const initialState = {
    data: [],
    loading: false,
    error: null
  }

  return (state = initialState, { type, payload }) => {
    switch (type) {
      case `FETCH_RESULTS_TO_CHARTS_START`:
        return updateObject(state, { loading: true, error: null })
      case `FETCH_RESULTS_TO_CHARTS_SUCCESS`:
        return updateObject(state, {
          loading: false,
          data: [...state.data, ...payload.data.results || payload.data],
        })
      case `FETCH_RESULTS_TO_CHARTS_FAIL`:
        return updateObject(state, { loading: false, error: payload.error })
      case `CLEAR_RESULTS_TO_CHARTS`:
        return updateObject(state, { data: [] })
      default:
        return state
    }
  }
}

export default reducer
