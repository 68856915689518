import React, { useRef } from 'react'
import { NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'reactstrap'
import { Modal } from '../../components'
import { FaTrashAlt, FaEdit } from 'react-icons/fa'
import * as actions from '../../store/actions'
import { adminURI } from '../../api/endpoints'

const CloudConfigRow = ({ cloudConfig }) => {
  const dispatch = useDispatch()
  const modalRef = useRef()
  const token = useSelector(state => state.auth.token)

  function deleteItem(cloudConfig) {
    dispatch(actions.deleteData(token, 'CLOUD_CONFIGS', cloudConfig.id, false, adminURI))
  }

  return (
    <tr>
      <td>{cloudConfig.name}</td>
      <td style={{ textAlign: 'center' }}>
        <NavLink to={`/cloud_configs/edit/${cloudConfig.id}`}>
          <FaEdit title="Edit Cloud Config" />
        </NavLink>
        <Modal
          ref={modalRef}
          title={`Delete cloud config "${cloudConfig.name}"`}
          content={`Are you sure you want to delete "${cloudConfig.name}" cloud config?`}
          onApply={() => deleteItem(cloudConfig)}
        />
        <Button color="link" onClick={() => modalRef.current.openModal()}>
          <FaTrashAlt title="Delete Cloud Config" />
        </Button>
      </td>
    </tr>
  )
}

export default CloudConfigRow
