import React from 'react'
import { Table } from 'reactstrap'
import { convertDigitalValueToLabel } from '../../../../utilities/stringFormatting'

export default function ResultOTT({ result }) {
  // result.succeeded - this field appears only when error is returned
  return result.succeeded !== false ? (
    <div>
      <Table responsive size="sm">
        <thead>
          <tr>
            <th>Chunks</th>
            <th>Chunk Rate</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{Object.keys(result.details).length}</td>
            <td>{Math.round(result.chunk_rate * 1000000) / 1000000}</td>
          </tr>
        </tbody>
      </Table>

      <Table responsive size="sm">
        <thead>
          <tr>
            <th>Chunk</th>
            <th>Time</th>
            <th>Downloaded ratio</th>
            <th>Size</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(result.details).map((detail, index) => (
            <tr key={index}>
              <td>{result.details[detail].uri}</td>
              <td>{Math.round(result.details[detail].time * 1000) / 1000} ms</td>
              <td>{Math.round(result.details[detail].downloaded_ratio * 100) / 100}</td>
              <td>{convertDigitalValueToLabel(result.details[detail].size, 'B')}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  ) : (
    ''
  )
}
