import React from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody } from 'reactstrap'
import { categoryIcon } from '../../../utilities/testParameters'

export default function TestTile({ item, onClick }) {
  const categories = useSelector(state => state.test_categories.data)
  const category = categories && categories.find(category => category.id === item.subcategories[0].category)
  const categoryName = category && category.name
  return (
    <Card className={`m-0 tile-${categoryName}`} style={{ width: '13.25rem' }} onClick={onClick}>
      <CardBody className={`p-1 tile-${categoryName}`}>
        {categoryIcon(categoryName)} <p className="m-0 d-inline">{item.display_name}</p>
      </CardBody>
    </Card>
  )
}
