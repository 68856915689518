import React from 'react'
import { Table, Progress } from 'reactstrap'
import { FaRegCircle } from 'react-icons/fa'
import { convertDigitalValueToLabel } from '../../../../utilities/stringFormatting'

export default function ResultRAM({ result }) {
  return (
    <div>
      <Table responsive size="sm">
        <thead>
          <tr>
            <th>Memory</th>
            <th>Result</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td></td>
            <td>
              <Progress multi>
                <Progress bar value={result.used} max={result.total} color="success">
                  User
                </Progress>
                <Progress bar value={result.total - result.used - result.free} max={result.total} color="primary">
                  System
                </Progress>
              </Progress>
              <div>
                <FaRegCircle color="green" /> User ({convertDigitalValueToLabel(result.used, 'B')}){' '}
                <FaRegCircle color="blue" /> System (
                {convertDigitalValueToLabel(result.total - result.used - result.free, 'B')}){' '}
                <FaRegCircle color="gray" /> Idle ({convertDigitalValueToLabel(result.free, 'B')})
              </div>
            </td>
            <td>{convertDigitalValueToLabel(result.total, 'B')}</td>
          </tr>
        </tbody>
      </Table>
    </div>
  )
}
