import React from 'react'
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap'
import { GrafanaFrame } from '../../containers'

export default function TestsPerStatus() {
  return (
    <Card className="w-100 top-line-info" style={{ height: '97%' }}>
      <CardHeader className="no-border">
        <CardTitle>Test Suites: Statuses</CardTitle>
      </CardHeader>
      <CardBody className="d-md-flex" style={{ textAlign: 'center', padding: '2px', paddingLeft: '5px' }}>
        <GrafanaFrame
          panel_id={6} // panelId from Grafana, defines the chart
          style={{ width: '100%', height: '250px', marginBottom: '6px' }}
          title={'tests-per-status'}
        />
      </CardBody>
    </Card>
  )
}
