import React, { useContext, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../store/actions'
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap'
import { Spinner } from '../../components'
import { ResultsList, Result } from '../Results'
import { TestSuitesContext } from '../../context/TestSuitesContext'
import { sortBy } from '../../utilities/mathOperations'

export default function ResultsMini() {
  const dispatch = useDispatch()
  const limit = 5
  const token = useSelector(state => state.auth.token)
  const testSuitesApi = useSelector(state => state.test_suites.data)
  const loading = useSelector(state => state.test_suites.loading)
  const { testSuitesWS } = useContext(TestSuitesContext)
  const testSuites = concatTestSuitesApiAndWS()

  useEffect(() => {
    dispatch(actions.fetchDataParams(token, 'TEST_SUITES', { limit: limit }))
    return () => {
      dispatch(actions.clearData('TEST_SUITES'))
    }
  }, [])

  function concatTestSuitesApiAndWS() {
    let _testSuites = testSuitesApi
      ? testSuitesApi.map(testSuite => {
          return testSuitesWS.some(tsWS => tsWS.id === testSuite.id)
            ? testSuitesWS.find(tsWS => tsWS.id === testSuite.id)
            : testSuite
        })
      : []
    testSuitesWS.forEach(testSuiteWS => {
      if (!_testSuites.some(testSuite => testSuite.id === testSuiteWS.id)) {
        _testSuites.push(testSuiteWS)
      }
    })
    _testSuites = sortBy(_testSuites, '-timestamp_created')
    return _testSuites.slice(0, limit)
  }

  return (
    <Card className="top-line-info" style={{ height: '97%' }}>
      <CardHeader className="no-border">
        <CardTitle>Test Suites: Last Created</CardTitle>
      </CardHeader>
      <CardBody>
        {loading ? (
          <Spinner />
        ) : (
          <>
            <ResultsList testSuites={testSuites} sortDisabled mini>
              {testSuites &&
                testSuites.length > 0 &&
                testSuites.map(testSuite => <Result key={testSuite.id} result={testSuite} mini />)}
            </ResultsList>
            {testSuites && testSuites.length >= limit && <NavLink to="/results">See more results...</NavLink>}
          </>
        )}
      </CardBody>
    </Card>
  )
}
