import React, { useState } from 'react'
import { TimeChart, ChartParamSelect } from '../../'

export default function OTTStressChart({ defaultParam }) {
  const chartParams = [
    { param: 'chunk_rate', display_name: 'Chunk Rate', panel_id: 72 },
    { param: 'throughput', display_name: 'Throughput', panel_id: 76 },
    { param: 'response_time', display_name: 'Response Time', panel_id: 78 },
    { param: 'total_load', display_name: 'Total Load', panel_id: 80 }
  ]

  const [selectedParam, setSelectedParam] = useState(chartParams.find(cp => cp.param === defaultParam))

  return (
    <>
      <ChartParamSelect chartParams={chartParams} selectedParam={selectedParam} setSelectedParam={setSelectedParam} />
      {selectedParam && <TimeChart panel_id={selectedParam.panel_id} />}
    </>
  )
}
