import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../utilities'

const initialState = {
  token: null,
  is_superuser: false,
  isAuthenticated: null,
  loading: false,
  error: null,
  session_error: null,
  authRedirectPath: '/user'
}

const authStart = (state, action) => {
  return updateObject(state, { error: null, session_error: null, loading: true })
}

const authSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    session_error: null,
    loading: false,
    token: action.token,
    is_superuser: action.is_superuser === true || action.is_superuser === "true",
    isAuthenticated: true
  })
}

const authFail = (state, action) => {
  return updateObject(state, { error: action.error, session_error: null, loading: false })
}

const authLogout = (state, action) => {
  return updateObject(state, { token: null, isAuthenticated: false, session_error: action.session_error || null })
}

const setAuthRedirectPath = (state, action) => {
  return updateObject(state, { authRedirectPath: action.path })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state, action)
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action)
    case actionTypes.AUTH_FAIL:
      return authFail(state, action)
    case actionTypes.AUTH_LOGOUT:
      return authLogout(state, action)
    case actionTypes.SET_AUTH_REDIRECT_PATH:
      return setAuthRedirectPath(state, action)
    default:
      return state
  }
}

export default reducer
