import React, { useEffect } from 'react'
import { Col, Row } from 'reactstrap'
import { ContainerFluid, Content, ContentHeader } from '../../components'
import NotificationsCard from './NotificationsCard'
import NotificationsConfig from './NotificationsConfig'

export default function Notifications() {
  useEffect(() => {
    document.title = 'vUTP | Notifications'
  }, [])

  return (
    <>
      <ContentHeader title="Notifications" />
      <Content>
        <ContainerFluid>
          <Row>
            <Col xl="6" lg="6" md="6" sm="12" xs="12">
              <NotificationsConfig />
            </Col>
            <Col xl="6" lg="6" md="6" sm="12" xs="12">
              <NotificationsCard />
            </Col>
          </Row>
        </ContainerFluid>
      </Content>
    </>
  )
}
