import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import * as actions from '../store/actions'

export default function WSProvider({ children, ws, storeKeyName, contextName, contextSubname }) {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(actions.setConnectionStatus(`WS_${storeKeyName.toUpperCase()}`, null))

    ws.current.onopen = () => {
      console.log(`connected ${contextName}${contextSubname ? `/${contextSubname}` : ''} WebSocket`)
      dispatch(actions.setConnectionStatus(`WS_${storeKeyName.toUpperCase()}`, true))
    }

    ws.current.onclose = e => {
      console.log(`disconnected ${contextName}${contextSubname ? `/${contextSubname}` : ''} WebSocket`)
      dispatch(actions.setConnectionStatus(`WS_${storeKeyName.toUpperCase()}`, e.wasClean === false ? false : null))
      if (e.code === 4010) {
        dispatch(actions.logout('Your session is expired'))
      }
    }

    ws.current.onerror = e => {
      console.error('WebSocket error observed:', e)
    }

    return () => {
      if (ws.current.readyState === ws.CONNECTING) {
        console.log('can`t close the connection until it`s established')
      } else {
        ws.current.close()
      }
    }
  }, [])

  return children
}
