import { updateObject } from '../utilities'

const reducer = (name = '') => {
  const initialState = {
    data: null,
    loading: false,
    error: null
  }

  return (state = initialState, { type, payload }) => {
    switch (type) {
      case `SET_${name}`:
        return updateObject(state, { data: payload.data })
      case `CLEAR_${name}`:
        return updateObject(state, { data: null })
      default:
        return state
    }
  }
}

export default reducer
