import React from 'react'
import { Table } from 'reactstrap'
import { BooleanIcon } from '../../../../components'

export default function ResultRun5GInfra({ result }) {
  return (
    <div>
      <Table responsive size="sm" style={{ textAlign: 'center' }}>
        <thead>
          <tr>
            <th>UE started</th>
            <th>gNB started</th>
            <th>Succeeded</th>
            {result.connection_time && <th>Connection Time</th>}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{<BooleanIcon value={result.ue_started} />}</td>
            <td>{<BooleanIcon value={result.enb_started} />}</td>
            <td>{<BooleanIcon value={result.succeeded} />}</td>
            {result.connection_time && <td>{Math.round(result.connection_time * 1000) / 1000} s</td>}
          </tr>
        </tbody>
      </Table>
    </div>
  )
}
