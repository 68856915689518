import React, { forwardRef, useState, useEffect, useContext } from 'react'
import { useSelector } from 'react-redux'
import { Form, FormGroup, Label, Input, CustomInput, FormFeedback } from 'reactstrap'
import { SubHeader, Tooltip } from '../../components'
import Select from 'react-select'
import OptionInput from './OptionInput'
import {
  validateTestForm,
  validateTextInput,
  validateNumberInput,
  validateMultiSelect,
  validateLocations,
  validateUniqueParam
} from '../../utilities/formValidator'
import { locationOptions } from '../../utilities/locations'
import { TestAgentsContext } from '../../context/TestAgentsContext'
import TestSuiteConfigurator from '../../context/TestSuiteConfiguratorContext'

export default forwardRef(function TestConfiguration({ activationValidationMode }, ref) {
  const [isValid, setIsValid] = useState({})
  const [feedback, setFeedback] = useState({})
  const [validatorEnabled, setValidatorEnabled] = useState({})
  const { testAgents } = useContext(TestAgentsContext)
  const locations = useSelector(state => state.locations.data)
  const testsDetails = useSelector(state => state.task_types.data)
  const {
    selectedBlock,
    steps,
    options,
    handleFormChange,
    handleOverrideLocations,
    submitTest
  } = useContext(TestSuiteConfigurator)
  const testDetails = testsDetails && selectedBlock && testsDetails.find(test => test.id === selectedBlock.task_type)

  const taskTypes5G = [
    'network_ping',
    'dns_test',
    'traceroute_test',
    'pathping_test',
    'rfc6349_test',
    'twamp_test',
    'iperf_test'
  ]

  useEffect(() => {
    if (selectedBlock) {
      let validationResult = validateTestForm(testDetails, selectedBlock)
      setIsValid(validationResult.isValid)
      setFeedback(validationResult.feedback)
      if (activationValidationMode) {
        enableAllValidators()
      } else {
        setValidatorEnabled({})
      }
    }
  }, [selectedBlock, activationValidationMode])

  useEffect(() => {
    if (selectedBlock && selectedBlock.additional_options.thresholds.enabled) {
      validateInput('name', 'text', selectedBlock.name, 'Task Name', {})
    }
  }, [selectedBlock && selectedBlock.additional_options.thresholds.enabled, selectedBlock && selectedBlock.additional_options.thresholds.mode])

  function handleBlur(paramName, index) {
    let _validatorEnabled = { ...validatorEnabled }
    if (isNaN(index)) {
      _validatorEnabled[paramName] = true
    } else {
      if (!(paramName in _validatorEnabled)) {
        _validatorEnabled[paramName] = []
      }
      _validatorEnabled[paramName][index] = true
    }
    setValidatorEnabled(_validatorEnabled)
  }

  function removeValidationParamsFromMultipleInput(paramName, index) {
    let _validatorEnabled = { ...validatorEnabled }
    _validatorEnabled[paramName].splice(index, 1)
    setValidatorEnabled(_validatorEnabled)
    let _feedback = { ...feedback }
    _feedback[paramName].splice(index, 1)
    setFeedback(_feedback)
    let _isValid = { ...isValid }
    _isValid[paramName].splice(index, 1)
    setIsValid(_isValid)
  }

  function validateInput(paramName, inputType, value, display_name, { validators, attributes, index, collection }) {
    let validateResult = { isValid: false, feedback: '' }
    switch (inputType) {
      case 'text':
        validateResult = validateTextInput(value, display_name, validators)
        break
      case 'text-unique':
        let validateText = validateTextInput(value, display_name, validators)
        let validateUnique = validateUniqueParam(value, paramName, display_name, collection)
        validateResult.isValid = validateText.isValid && validateUnique.isValid
        validateResult.feedback = validateText.feedback !== '' ? validateText.feedback : validateUnique.feedback
        break
      case 'number':
        validateResult = validateNumberInput(value, display_name, attributes)
        break
      case 'multiselect':
        validateResult = validateMultiSelect(value, display_name)
        break
      case 'multipleinput':
        validateResult = validateTextInput(value, display_name, validators)
        break
      case 'locations':
        validateResult = validateLocations(
          value,
          display_name,
          testDetails && testDetails.cloud_available,
          testDetails && testDetails.on_premises_available
        )
        break
      default:
        break
    }
    if (isNaN(index)) {
      setIsValid({ ...isValid, [paramName]: validateResult.isValid })
      setFeedback({ ...feedback, [paramName]: validateResult.feedback })
    } else {
      let isV = { ...isValid }
      let feedb = { ...feedback }
      if (!(paramName in isV)) {
        isV[paramName] = []
      }
      if (!(paramName in feedb)) {
        feedb[paramName] = []
      }
      isV[paramName][index] = validateResult.isValid
      feedb[paramName][index] = validateResult.feedback
      setIsValid(isV)
      setFeedback(feedb)
    }
  }

  function enableAllValidators() {
    let _validatorEnabled = { ...validatorEnabled }
    _validatorEnabled.name = true
    _validatorEnabled.locations = activationValidationMode === 'test_suite' ? true : false
    testDetails.options &&
      testDetails.options.params.forEach(option => {
        if (option.attributes.required) {
          if (option.type === 'multipleinput') {
            if (!(option.name in _validatorEnabled)) {
              _validatorEnabled[option.name] = []
            }
            for (let i = 0; i < selectedBlock.params[option.name].length; i++) {
              _validatorEnabled[option.name][i] = true
            }
          } else {
            _validatorEnabled[option.name] = true
          }
        }
      })
    setValidatorEnabled(_validatorEnabled)
  }

  return (
    <div ref={ref}>
      <SubHeader>Test Parameters</SubHeader>
      {selectedBlock && (
        <Form id="test_configuration_form" onSubmit={submitTest}>
          <FormGroup>
            <Label for="name">
              Task Name: <p className="text-danger d-inline">*</p>
            </Label>
            <Input
              id="name"
              name="name"
              value={selectedBlock.name}
              valid={validatorEnabled.name && isValid.name === true}
              invalid={validatorEnabled.name && isValid.name === false}
              onChange={e => {
                handleFormChange(e.target.value, e.target.name)
                let testsArray = []
                steps.forEach(step => testsArray.push(...step.tests))
                testsArray = testsArray.filter(_test => _test.tile_id !== selectedBlock.tile_id)
                validateInput(e.target.name, 'text-unique', selectedBlock.name, 'Task Name', { collection: testsArray })
              }}
              onBlur={e => {
                handleBlur(e.target.name)
                let testsArray = []
                steps.forEach(step => testsArray.push(...step.tests))
                testsArray = testsArray.filter(_test => _test.tile_id !== selectedBlock.tile_id)
                validateInput(e.target.name, 'text-unique', selectedBlock.name, 'Task Name', { collection: testsArray })
              }}
            />
            <FormFeedback>{feedback.name}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <CustomInput
              type="checkbox"
              id="overrideLocations"
              label="Override Locations"
              inline
              checked={selectedBlock.overrideLocations}
              onChange={e => {
                handleOverrideLocations(e.target.checked)
                validateInput('locations', 'locations', selectedBlock.locations, 'Locations', {})
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label for="locations">
              Select a location: <p className="text-danger d-inline">*</p>
            </Label>
            <Select
              id="locations"
              name="locations"
              isMulti
              options={locationOptions(testAgents, locations)}
              value={selectedBlock.locations || []}
              isDisabled={!selectedBlock.overrideLocations}
              placeholder="Select an option from a list..."
              onChange={e => {
                handleFormChange(e, 'locations')
                validateInput('locations', 'locations', e, 'Locations', {})
              }}
              onBlur={e => {
                handleBlur('locations')
                validateInput('locations', 'locations', selectedBlock.locations, 'Locations', {})
              }}
              styles={{
                menu: provided => ({ ...provided, zIndex: 9999 }),
                //multiValueLabel: (styles, { data }) => ({ ...styles, backgroundColor: 'info'})
                control: base => ({
                  ...base,
                  borderColor: validatorEnabled.locations ? (isValid.locations ? 'green' : '#dc3545') : 'lightgray',
                  '&:hover': {
                    borderColor: validatorEnabled.locations ? (isValid.locations ? 'green' : '#dc3545') : 'lightgray'
                  }
                })
              }}
            />
            {validatorEnabled.locations && <small style={{ color: '#dc3545' }}>{feedback.locations}</small>}
          </FormGroup>
          <FormGroup>
            <Label for="priority">Notifications Priority:</Label>{' '}
            <Tooltip
              name="notification_priority"
              content="Notification priority is a priority value allowing aggregation of notifications coming from a given test suite. In such a way that only one notification with the highest priority (greater than the priority limit) is generated from the entire test suite."
            />
            <Input
              type="number"
              id="priority"
              value={Number.isInteger(selectedBlock.priority) ? selectedBlock.priority : ''}
              name="priority"
              min={0}
              onChange={e => {
                handleFormChange(e.target.value ? parseInt(e.target.value) : '', e.target.name)
              }}
            />
          </FormGroup>
          {taskTypes5G.includes(testDetails.name) && options['5g_config'] && options['5g_config'].enabled && (
            <FormGroup>
              <CustomInput
                type="checkbox"
                id="test_block_5g_enabled"
                label="5G Enabled"
                name="5g_enabled"
                checked={selectedBlock['5g_enabled'] || false}
                onChange={e => {
                  handleFormChange(e.target.checked, e.target.name)
                }}
              />
            </FormGroup>
          )}
          {testDetails.options &&
            testDetails.options.params.map(option => (
              <FormGroup key={option.name}>
                <Label for={option.name}>
                  {option.label}:{' '}
                  {option.attributes && option.attributes.required > 0 && <p className="text-danger d-inline">*</p>}
                </Label>{' '}
                {option.tooltip && (
                  <Tooltip color={option.tooltip.color} name={option.name} content={option.tooltip.value} />
                )}
                <OptionInput
                  option={option}
                  validator={{ isValid: isValid, enabled: validatorEnabled, feedback: feedback }}
                  removeValidationParamsFromMultipleInput={removeValidationParamsFromMultipleInput}
                  handleBlur={handleBlur}
                  validateInput={validateInput}
                />
              </FormGroup>
            ))}
        </Form>
      )}
    </div>
  )
})
