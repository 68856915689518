import React, { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useHistory } from 'react-router-dom'
import { Button, Card, CardBody, CardHeader, CardTitle, Col, Row } from 'reactstrap'
import { CardTools, ContainerFluid, Content, ContentHeader } from '../../components'
import GroupList from './List'
import * as actions from '../../store/actions'
import { adminURI } from '../../api/endpoints'

export default function Groups({ location }) {
  const dispatch = useDispatch()
  const history = useHistory()

  const token = useSelector(state => state.auth.token)
  const groups = useSelector(state => state.groups.data)
  const permissions = useSelector(state => state.permissions.data)
  const loading = useSelector(state => state.permissions.loading || state.groups.loading)

  useEffect(() => {
    document.title = 'vUTP | Groups'
    dispatch(actions.fetchData(token, 'PERMISSIONS', adminURI))
    if (!(location && location.state && location.state.noFetch)) {
      dispatch(actions.fetchData(token, 'GROUPS', adminURI))
    } else if (location && location.state){
      history.replace()
    }
  }, [])

  return (
    <Fragment>
      <ContentHeader title="Groups" />
      <Content>
        <ContainerFluid>
          <Row>
            <Col xl="9">
              <Card>
                <CardHeader className="no-border">
                  <CardTitle>Group List</CardTitle>
                  <CardTools>
                    <NavLink to="/groups/add">
                      <Button color="info">Add Group</Button>
                    </NavLink>
                  </CardTools>
                </CardHeader>
                <CardBody className="p-0">
                  <GroupList loading={loading} groups={groups} permissions={permissions}/>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ContainerFluid>
      </Content>
    </Fragment>
  )
}
