import React from 'react'

const SubHeader = props => (
  <>
    <h5 style={{ color: '#529ecc' }}>{props.children}</h5>
    <hr style={{ borderTopColor: '#529ecc', marginTop: 0 }} />
  </>
)

export default SubHeader
