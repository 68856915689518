import React, { useEffect } from 'react'
import { ContentHeader, Content, ContainerFluid } from '../../components'
import { Col, Row } from 'reactstrap'
import {
  TestAgentsMini,
  ResultsMini,
  TestsPerStatus,
  ThresholdsExceededInTestAgents,
  TestsPerType,
  TestSuiteQuickStart
} from './'
import MapCard from '../../containers/Map/MapCard'
import TestSuitesProvider from '../../context/TestSuitesContext'

export default function Dashboard() {
  useEffect(() => {
    document.title = 'vUTP | Dashboard'
  }, [])

  return (
    <TestSuitesProvider>
      <ContentHeader title="Dashboard" />
      <Content>
        <ContainerFluid>
          <Row>
            <Col sm="12" md="8">
              <TestSuiteQuickStart />
            </Col>
          </Row>
          <Row>
            <Col sm="12" md="4">
              <TestAgentsMini />
            </Col>
            <Col sm="12" md="4">
              <MapCard />
            </Col>
            <Col sm="12" md="4">
              <ThresholdsExceededInTestAgents />
            </Col>
          </Row>
          <Row>
            <Col sm="12" md="4">
              <ResultsMini />
            </Col>
            <Col sm="12" md="4">
              <TestsPerStatus />
            </Col>
            <Col sm="12" md="4">
              <TestsPerType />
            </Col>
          </Row>
        </ContainerFluid>
      </Content>
    </TestSuitesProvider>
  )
}
