import React from 'react'
import { Table } from 'reactstrap'

export default function ResultStorageReWrite({ result }) {
  return (
    <div>
      <Table responsive size="sm">
        <thead>
          <tr>
            <th>Storage</th>
            <th>Average Speed</th>
            <th>Time</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>0</td>
            <td>{Math.round(result.avg_speed_MiB_s * 1000) / 1000} MiB/s</td>
            <td>{Math.round(result.time_s * 1000) / 1000} s</td>
          </tr>
        </tbody>
      </Table>
    </div>
  )
}
