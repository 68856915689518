import React, { useRef } from 'react'
import { NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Badge, Button } from 'reactstrap'
import { FaCheck, FaTimes, FaEdit, FaTrashAlt } from 'react-icons/fa'
import Moment from 'react-moment'
import { testAgentStatuses } from '../../utilities/statuses'
import * as actions from '../../store/actions'
import { Modal } from '../../components'

export default function TestAgent({ worker, mini }) {
  const dispatch = useDispatch()
  const modalRef = useRef()
  const token = useSelector(state => state.auth.token)

  function deleteWorker(worker) {
    dispatch(actions.deleteData(token, `TEST_AGENTS`, worker.id))
  }

  return (
    <tr>
      <td>
        <NavLink to={`/test-agents/${worker.id}`} style={{ padding: '0px', textAlign: 'left' }}>
          {worker.name} (#{worker.id})
        </NavLink>
      </td>
      {!mini && <td>{worker.release}</td>}
      {!mini && <td>{worker.ip_local}</td>}
      <td>
        {testAgentStatuses.some(tAS => tAS.id === worker.status) &&
        testAgentStatuses.find(tAS => tAS.id === worker.status).name === 'ONLINE' ? (
          <Badge color="success">ONLINE</Badge>
        ) : (
          <Badge color="danger">OFFLINE</Badge>
        )}
      </td>
      {!mini && <td>{worker.authorized === true ? <FaCheck /> : <FaTimes color="red" />}</td>}
      <td>{worker.running_monitoring_count}</td>
      {!mini && (
        <td>
          {worker.last_status_change ? <Moment format="DD-MM-YYYY HH:mm:ss">{worker.last_status_change}</Moment> : '-'}
        </td>
      )}
      {!mini && (
        <td style={{ textAlign: 'center' }}>
          <NavLink to={`/test-agents/${worker.id}`}>
            <FaEdit title="Edit Test Agent" />
          </NavLink>
          {!(
            (testAgentStatuses.some(tAS => tAS.id === worker.status) &&
              testAgentStatuses.find(tAS => tAS.id === worker.status).name === 'ONLINE') ||
            worker.running_monitoring_count > 1
          ) && (
            <>
              <Modal
                ref={modalRef}
                title={`Delete test agent "${worker.name} (#${worker.id})"`}
                content={`Are you sure you want to delete "${worker.name} (#${worker.id})" test agent?`}
                onApply={() => deleteWorker(worker)}
              />
              <Button color="link" onClick={() => modalRef.current.openModal()} className="py-0">
                <FaTrashAlt color="info" title="Delete Test Agent" />
              </Button>
            </>
          )}
        </td>
      )}
    </tr>
  )
}
