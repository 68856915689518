import React from 'react'
import { useSelector } from 'react-redux'
import { Spinner } from '../../../../components'
import { OTTStressChart } from '../../'

export default function OTTStress() {
  const resultsToCharts = useSelector(state => state.results_to_charts.data)

  return resultsToCharts.length > 1 ? (
    <>
      <OTTStressChart defaultParam="throughput" />
      <OTTStressChart defaultParam="chunk_rate" />
    </>
  ) : (
    <Spinner />
  )
}
