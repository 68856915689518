import React, { useRef } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Button, CustomInput } from 'reactstrap'
import classNames from 'classnames'
import { FaTrashAlt, FaSyncAlt, FaRecycle, FaLongArrowAltRight } from 'react-icons/fa'
import Moment from 'react-moment'
import { Modal } from '../../components'
import { TestSuiteStatus } from '../../containers'
import { testSuiteStatuses } from '../../utilities/statuses'
import * as actions from '../../store/actions'
import { getTestBlocksString } from '../../utilities/testSuite'

export default function Result({ result, limit, currentPage, checked, rowCheckChange, mini }) {
  const history = useHistory()
  const dispatch = useDispatch()
  const token = useSelector(state => state.auth.token)
  const testSuites = useSelector(state => state.test_suites.data)
  const taskTypes = useSelector(state => state.task_types.data)
  const modalRef = useRef()

  function deleteItem(result) {
    dispatch(actions.deleteData(token, `TEST_SUITES`, result.id, { limit: limit, offset: (currentPage - 1) * limit }))
  }

  function rerunTest(testSuiteId) {
    const testSuite = testSuites && testSuites.find(testS => testS.id === testSuiteId)
    history.push(`/test-suite/`, { rerunTest: testSuite })
  }

  return (
    <tr className={classNames({ 'bg-light': checked })}>
      {rowCheckChange && (
        <td>
          <CustomInput
            type="checkbox"
            id={`selectRow${result.id}`}
            checked={checked}
            // uncomment to disable check running test suites
            /*disabled={
              testSuiteStatuses.some(tSS => tSS.id === result.status) &&
              testSuiteStatuses.find(tSS => tSS.id === result.status).name === 'RUNNING'
            }*/
            onChange={e => rowCheckChange(result.id, e.target.checked)}
          />
        </td>
      )}
      <td>
        <NavLink to={`/results/details/${result.id}`}>
          {result.name} (#{result.id})
        </NavLink>
      </td>
      {!mini && (
        <td>
          {result.repeat_interval > 0 ? <FaRecycle title="Monitoring" /> : <FaLongArrowAltRight title="Single Run" />}
        </td>
      )}
      {!mini && <td>{getTestBlocksString(result.test_blocks, taskTypes)}</td>}
      <td>
        <TestSuiteStatus status={result.status} />
      </td>
      <td>
        <Moment format="DD-MM-YYYY HH:mm:ss">{result.timestamp_created}</Moment>
      </td>
      {!mini && (
        <td>
          <Button color="link" onClick={() => rerunTest(result.id)}>
            <FaSyncAlt title="Rerun test suite" size={12} />
          </Button>
          {(!testSuiteStatuses.some(tSS => tSS.id === result.status) ||
            testSuiteStatuses.find(tSS => tSS.id === result.status).name !== 'RUNNING') && (
            <>
              <Modal
                ref={modalRef}
                title={`Delete test suite "${result.name} (#${result.id})"`}
                content={`Are you sure you want to delete "${result.name} (#${result.id})" test suite?`}
                onApply={() => deleteItem(result)}
              />
              <Button color="link" onClick={() => modalRef.current.openModal()}>
                <FaTrashAlt title="Delete item" />
              </Button>
            </>
          )}
        </td>
      )}
    </tr>
  )
}
