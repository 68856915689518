import React, { useRef } from 'react'
import { NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'reactstrap'
import Moment from 'react-moment'
import { FaTrashAlt, FaEdit } from 'react-icons/fa'
import { Modal } from '../../components'
import * as actions from '../../store/actions'
import { adminURI } from '../../api/endpoints'

const SubscriptionRow = ({ subscription }) => {
  const dispatch = useDispatch()
  const modalRef = useRef()
  const token = useSelector(state => state.auth.token)

  function deleteItem(subscription) {
    dispatch(actions.deleteData(token, 'SUBSCRIPTIONS', subscription.id, false, adminURI))
  }

  return (
    <tr>
      <td>{subscription.subscr_id}</td>
      <td>
        {subscription.last_updated ? <Moment format="DD-MM-YYYY HH:mm:ss">{subscription.last_updated}</Moment> : '-'}
      </td>
      <td>{subscription.created ? <Moment format="DD-MM-YYYY HH:mm:ss">{subscription.created}</Moment> : '-'}</td>
      <td>
        {subscription.valid_until ? <Moment format="DD-MM-YYYY HH:mm:ss">{subscription.valid_until}</Moment> : '-'}
      </td>
      <td>{subscription.period}</td>
      <td>{subscription.active.toString()}</td>
      <td style={{ textAlign: 'center' }}>
        <NavLink to={`/subscriptions/edit/${subscription.id}`}>
          <FaEdit title="Edit subscription" />
        </NavLink>
        <Modal
          ref={modalRef}
          title={`Delete subscription "${subscription.subscr_id}"`}
          content={`Are you sure you want to delete "${subscription.subscr_id}" subscription?`}
          onApply={() => deleteItem(subscription)}
        />
        <Button color="link" onClick={() => modalRef.current.openModal()} className="py-0">
          <FaTrashAlt title="Delete subscription" />
        </Button>
      </td>
    </tr>
  )
}

export default SubscriptionRow
