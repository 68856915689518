import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap'
import * as actions from '../../store/actions'
import { ContainerFluid, Content, ContentHeader, Spinner } from '../../components'
import { usePrevious } from '../../utilities/customHooks'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup'

export default function SMTPServerConfiguration() {
  const dispatch = useDispatch()
  const history = useHistory()
  const token = useSelector(state => state.auth.token)
  const account = useSelector(state => state.account.data)
  const loading = useSelector(state => state.account.loading)
  const [emailConfiguration, setEmailConfiguration] = useState()
  const accountError = useSelector(state => state.account.error)
  const prevAccountError = usePrevious(accountError)
  const [isAfterDispatch, setIsAfterDispatch] = useState(false)

  const authenticationsList = [{ id: 'starttls', display_name: 'STARTTLS' }, { id: 'ssl_tls', display_name: 'SSL/TLS' }]

  useEffect(() => {
    document.title = 'vUTP | SMTP Server Configuration'
  }, [])

  useEffect(() => {
    if (isAfterDispatch && !prevAccountError && !accountError) {
      history.push('/notifications')
    } else if (!isAfterDispatch) {
      let emailConf = account && account.delivery_channels_config && account.delivery_channels_config.email
      if (emailConf && !emailConf.authentication) {
        emailConf.authentication = authenticationsList[0].id
      }
      setEmailConfiguration(emailConf)
    }
  }, [account])

  function onFormSubmit(values) {
    dispatch(
      actions.updateData(token, 'ACCOUNT', account.id, { delivery_channels_config: { email: { ...values } } }, true)
    )
    setIsAfterDispatch(true)
  }

  const validationSchema = Yup.object().shape({
    host: Yup.string()
      .max(150, 'Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only')
      .required('Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only'),
    port: Yup.number()
      .min(0, 'Min value is 0')
      .required('Value cannot be empty and must be a number')
      .integer(),
    login: Yup.string()
      .max(150, 'Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only')
      .required('Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only'),
    password: Yup.string()
      .max(150, 'Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only')
      .required('Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only')
  })

  return (
    <>
      <ContentHeader title="SMTP Server Configuration" breadcrumbs={['Notifications', 'SMTP Server Configuration']} />
      <Content>
        <ContainerFluid>
          {loading ? (
            <Spinner />
          ) : (
            <Col xl="4" md="6" sm="8">
              <Formik
                enableReinitialize={true}
                initialValues={{
                  host: (emailConfiguration && emailConfiguration.host) || '',
                  port: (emailConfiguration && emailConfiguration.port) || 0,
                  login: (emailConfiguration && emailConfiguration.login) || '',
                  password: (emailConfiguration && emailConfiguration.password) || '',
                  authentication: (emailConfiguration && emailConfiguration.authentication) || authenticationsList[0].id
                }}
                onSubmit={onFormSubmit}
                validationSchema={validationSchema}
                validateOnMount={true}
              >
                {({ errors, isValid, touched, values, handleChange, handleBlur }) => (
                  <Card className="top-line-info">
                    <Form>
                      <CardHeader>
                        <CardTitle>Add/Edit SMTP Server Configuration</CardTitle>
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col sm="12">
                            <FormGroup>
                              <Label for="host">Host</Label>
                              <Input name="host" tag={Field} invalid={touched.host && Boolean(errors.host)} />
                              <ErrorMessage name="host" component="div" className="invalid-feedback" />
                            </FormGroup>
                            <FormGroup>
                              <Label for="port">Port</Label>
                              <Input
                                type="number"
                                name="port"
                                tag={Field}
                                invalid={touched.port && Boolean(errors.port)}
                              />
                              <ErrorMessage name="port" component="div" className="invalid-feedback" />
                            </FormGroup>
                            <FormGroup>
                              <Label for="login">Login</Label>
                              <Input name="login" tag={Field} invalid={touched.login && Boolean(errors.login)} />
                              <ErrorMessage name="login" component="div" className="invalid-feedback" />
                            </FormGroup>
                            <FormGroup>
                              <Label for="password">Password</Label>
                              <Input
                                type="password"
                                name="password"
                                tag={Field}
                                invalid={touched.password && Boolean(errors.password)}
                              />
                              <ErrorMessage name="password" component="div" className="invalid-feedback" />
                            </FormGroup>
                            <FormGroup>
                              <Label for="authentication">Authentication</Label>
                              <Input
                                type="select"
                                name="authentication"
                                value={values.authentication}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                invalid={touched.authentication && Boolean(errors.authentication)}
                              >
                                {!values.authentication && <option value={0}>Select authentication</option>}
                                {authenticationsList.map(authItem => (
                                  <option key={authItem.id} value={authItem.id}>
                                    {authItem.display_name}
                                  </option>
                                ))}
                              </Input>
                              <ErrorMessage name="authentication" component="div" className="invalid-feedback" />
                            </FormGroup>
                          </Col>
                        </Row>
                      </CardBody>
                      <CardFooter>
                        <Button color="info" className="float-right" type="submit" disabled={!isValid}>
                          Apply
                        </Button>
                      </CardFooter>
                    </Form>
                  </Card>
                )}
              </Formik>
            </Col>
          )}
        </ContainerFluid>
      </Content>
    </>
  )
}
