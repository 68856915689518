import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, CardBody, CardHeader, CardTitle, Table, Button } from 'reactstrap'
import Moment from 'react-moment'
import * as actions from '../../../store/actions'
import { testSuiteStatuses } from '../../../utilities/statuses'
import { FaPause, FaPlay } from 'react-icons/fa'
import { TestSuiteContext } from '../../../context/TestSuiteContext'

export default function ResultScheduleCard() {
  const token = useSelector(state => state.auth.token)
  const { testSuite } = useContext(TestSuiteContext)
  const dispatch = useDispatch()
  const [nextRunTime, setNextRunTime] = useState(null)

  useEffect(() => {
    if (testSuite) {
      nextRunCalculate()
      const interval = setInterval(() => {
        if (new Date().getSeconds() === 1) {
          nextRunCalculate()
        }
      }, 1000)
      return () => clearInterval(interval)
    }
  }, [testSuite])

  function setMonitoringStatus() {
    dispatch(
      actions.updateData(
        token,
        `TEST_SUITES`,
        testSuite.id,
        {
          paused:
            !testSuiteStatuses.some(tSS => tSS.id === testSuite.status) ||
            testSuiteStatuses.find(tSS => tSS.id === testSuite.status).name !== 'PAUSED'
        },
        false
      )
    )
  }

  function nextRunCalculate() {
    let nrDate = null
    let repeatStart = Math.floor(new Date(testSuite.repeat_start).getTime() / 1000 / 60) * 60 * 1000 // down approximation to minutes
    let repeatEnd = Math.floor(new Date(testSuite.repeat_end).getTime() / 1000 / 60) * 60 * 1000 // down approximation to minutes
    let repeatInterval = testSuite.repeat_interval * 1000
    let now = Math.floor(new Date().getTime() / 1000 / 60) * 60 * 1000 // down approximation to minutes
    if (testSuite) {
      if (
        (testSuiteStatuses.some(tSS => tSS.id === testSuite.status) &&
          (testSuiteStatuses.find(tSS => tSS.id === testSuite.status).name === 'FINISHED' ||
            testSuiteStatuses.find(tSS => tSS.id === testSuite.status).name === 'PAUSED')) ||
        repeatEnd < now
      ) {
        nrDate = '-'
      } else if (repeatStart > now) {
        nrDate = <Moment format="DD-MM-YYYY HH:mm:ss">{new Date(repeatStart)}</Moment>
      } else {
        let i = 0
        while (repeatStart + i * repeatInterval <= now) {
          i++
        }
        if (repeatStart + i * repeatInterval > repeatEnd) {
          nrDate = '-'
        } else {
          nrDate = <Moment format="DD-MM-YYYY HH:mm:ss">{new Date(repeatStart + i * repeatInterval)}</Moment>
        }
      }
      setNextRunTime(nrDate)
    } else {
      setNextRunTime(null)
    }
  }

  return (
    <Card className="top-line-info" style={{ height: '95%' }}>
      <CardHeader className="no-border">
        <CardTitle>Schedule</CardTitle>
      </CardHeader>
      <CardBody>
        <Table responsive size="sm" borderless className="mb-0">
          <tbody>
            <tr>
              <th>Start Time</th>
              <td style={{ textAlign: 'right' }}>
                {testSuite && <Moment format="DD-MM-YYYY HH:mm:ss">{testSuite.repeat_start}</Moment>}
              </td>
            </tr>
            <tr>
              <th>End Time</th>
              <td style={{ textAlign: 'right' }}>
                {testSuite && <Moment format="DD-MM-YYYY HH:mm:ss">{testSuite.repeat_end}</Moment>}
              </td>
            </tr>
            <tr>
              <th>Repeat interval [s]</th>
              <td style={{ textAlign: 'right' }}>{testSuite && testSuite.repeat_interval}</td>
            </tr>
            <tr>
              <th>Next Run</th>
              <td style={{ textAlign: 'right' }}>{testSuite && nextRunTime}</td>
            </tr>
            {testSuite &&
              ['PENDING', 'RUNNING', 'PAUSED'].includes(
                testSuiteStatuses.some(tSS => tSS.id === testSuite.status) &&
                  testSuiteStatuses.find(tSS => tSS.id === testSuite.status).name
              ) && (
                <tr>
                  <th></th>
                  <td className="d-flex justify-content-end">
                    {!testSuiteStatuses.some(tSS => tSS.id === testSuite.status) ||
                    testSuiteStatuses.find(tSS => tSS.id === testSuite.status).name !== 'PAUSED' ? (
                      <Button size="sm" color="warning" onClick={() => setMonitoringStatus()}>
                        <FaPause /> Pause
                      </Button>
                    ) : (
                      <Button size="sm" color="success" onClick={() => setMonitoringStatus()}>
                        <FaPlay /> Resume
                      </Button>
                    )}
                  </td>
                </tr>
              )}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  )
}
