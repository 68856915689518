import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import { ContainerFluid, Content, ContentHeader, Spinner } from '../../components'
import GroupEditForm from './Form'
import * as actions from '../../store/actions'
import { adminURI } from '../../api/endpoints'
import { usePrevious } from '../../utilities/customHooks'

export default function GroupEdit({ match }) {
  const isEditPage = match.path === '/groups/edit/:id'
  const defaultGroup = {
    name: '',
    users: [],
    permissions: [],
    permission_ids: []
  }

  const history = useHistory()
  const dispatch = useDispatch()
  const token = useSelector(state => state.auth.token)
  const permissions = useSelector(state => state.permissions.data)
  const group = isEditPage ? useSelector(state => state.group.data) : defaultGroup
  const groups = useSelector(state => state.groups.data)
  const loading = useSelector(state => state.permissions.loading || state.group.loading || state.groups.loading)
  const error = useSelector(state => state.groups.error)
  const prevError = usePrevious(error)
  const [isAfterDispatch, setIsAfterDispatch] = useState(false)

  useEffect(() => {
    document.title = 'vUTP | Groups'
    dispatch(actions.fetchData(token, 'PERMISSIONS', adminURI))
    if (isEditPage) {
      dispatch(actions.fetchDataDetails(token, 'GROUPS', match.params.id, adminURI))
    }
  }, [])

  useEffect(() => {
    if (isAfterDispatch && !prevError && !error) {
      history.push('/groups', { noFetch: true })
    }
  }, [groups])

  function onDataUpdated(data) {
    if (isEditPage) {
      dispatch(actions.updateData(token, 'GROUPS', match.params.id, data, true, adminURI))
    } else {
      dispatch(actions.addData(token, 'GROUPS', data, true, adminURI))
    }
    setIsAfterDispatch(true)
  }

  return (
    <>
      <ContentHeader title={`${isEditPage ? 'Edit' : 'Add'}  Group`} />
      <Content>
        <ContainerFluid>
          <Row>
            <Col xl="9">
              {loading ? (
                <Spinner />
              ) : (
                group && (
                  <GroupEditForm
                    groupProp={group}
                    id={match.params.id}
                    path={match.path}
                    permissions={permissions}
                    token={token}
                    onDataUpdated={onDataUpdated}
                  />
                )
              )}
            </Col>
          </Row>
        </ContainerFluid>
      </Content>
    </>
  )
}
