const convertJsonStringToLabel = string => {
  let strArray = string.split('_')
  let strArray2 = []
  strArray.map(str => strArray2.push(str.charAt(0).toUpperCase() + str.slice(1)))
  return strArray2.join(' ')
}

const isJsonString = str => {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

const getJsonError = str => {
  let error = null
  try {
    JSON.parse(str)
  } catch (err) {
    error = err.message
  }
  return error
}

const convertMessageToArray = (message, header, messageArray = []) => {
  switch (typeof message) {
    case 'string':
      messageArray.push(header ? `${header}:${message}` : `${message}`)
      break
    case 'object':
      if (Array.isArray(message)) {
        message.forEach(value => {
          convertMessageToArray(value, header, messageArray)
        })
      } else {
        Object.keys(message).forEach(key => {
          convertMessageToArray(message[key], key, messageArray)
        })
      }
      break
    default:
      messageArray.push(header ? `${header}:${message}` : `${message}`)
      break
  }
  return messageArray
}

const convertStringToOtherType = (value, type) => {
  let returnValue = value
  switch (type) {
    case 'int':
      returnValue = parseInt(value)
      break
    case 'float':
      returnValue = parseFloat(value)
      break
    default:
      returnValue = value
      break
  }
  return returnValue
}

const mathSignsLabels = [
  { sign: '=', label: 'equals' },
  { sign: '>', label: 'greater than' },
  { sign: '>=', label: 'greater than or equal to' },
  { sign: '<', label: 'less than' },
  { sign: '<=', label: 'less than or equal to' }
]

const convertDigitalValueToLabel = (value, baseUnit = 'B') => {
  return value >= 1024 * 1024 * 1024
    ? `${Math.round((value / (1024 * 1024 * 1024)) * 100) / 100} Gi${baseUnit}`
    : value >= 1024 * 1024
    ? `${Math.round((value / (1024 * 1024)) * 100) / 100} Mi${baseUnit}`
    : value >= 1024
    ? `${Math.round((value / 1024) * 100) / 100} Ki${baseUnit}`
    : `${Math.round(value * 100) / 100} ${baseUnit}`
}

export { convertJsonStringToLabel, isJsonString, getJsonError, convertMessageToArray, convertStringToOtherType, mathSignsLabels, convertDigitalValueToLabel }
