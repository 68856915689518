export const AUTH_START = 'AUTH_START'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_FAIL = 'AUTH_FAIL'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH'

export const SET_ACTIVE_PAGE = 'SET_ACTIVE_PAGE'
export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB'
export const DESELECT_ITEM_TO_DELETE = 'DESELECT_ITEM_TO_DELETE'
export const SELECT_ITEM_TO_DELETE = 'SELECT_ITEM_TO_DELETE'
export const UPDATE_SCROLL_POSITION = 'UPDATE_SCROLL_POSITION'

export const TOGGLE_MODAL = 'TOGGLE_MODAL'
