import React from 'react'
import { ContentWrapper, ErrorBoundary } from '../../components'
import { Navbar, ErrorsToastContainer, ConnectionAlert, Footer, Sidebar } from '..'

const Layout = props => (
  <>
    <Navbar />
    <Sidebar />
    <ContentWrapper>
      <ErrorBoundary>
        <ErrorsToastContainer />
        <ConnectionAlert />
        {props.children}
      </ErrorBoundary>
    </ContentWrapper>
    <Footer />
  </>
)

export default Layout
