import * as Api from '../../api'
import * as actions from '../../store/actions'

const sessionExpiredHttpCodes = [401, 403]

export const addData = (token, name, data, withFetch, uri) => dispatch => {
  dispatch({ type: `ADD_${name}_START` })

  Api.addData(token, name, data, uri)
    .then(({ data }) => {
      dispatch({ type: `ADD_${name}_SUCCESS` })
      if (withFetch) {
        dispatch(fetchData(token, name, uri))
      }
      if (name === 'TEST_SUITES') {
        dispatch({ type: 'SET_CREATED_TEST', payload: { data } })
      }
    })
    .catch(error => dispatch({ type: `ADD_${name}_FAIL`, payload: { error } }))
}

export const addDataFunction = (token, name, funcName, data, withFetch, fetchParams, uri) => dispatch => {
  dispatch({ type: `ADD_${name}_${funcName}_START` })

  Api.addDataFunction(token, name, funcName, data, uri)
    .then(({ data }) => {
      dispatch({ type: `ADD_${name}_${funcName}_SUCCESS`, payload: { data } })
      if (withFetch) {
        if (fetchParams) {
          dispatch(fetchDataParams(token, name, fetchParams, uri))
        } else {
          dispatch(fetchData(token, name, uri))
        }
      }
    })
    .catch(error => dispatch({ type: `ADD_${name}_${funcName}_FAIL`, payload: { error } }))
}

export const addDataDetailsFunction = (token, name, id, funcName, data, withFetch, uri) => dispatch => {
  dispatch({ type: `ADD_${name}_${funcName}_START` })

  Api.addDataDetailsFunction(token, name, id, funcName, data, uri)
    .then(({ data }) => {
      dispatch({ type: `ADD_${name}_${funcName}_SUCCESS`, payload: { data } })
      if (withFetch) {
        if (funcName === 'SET_AS_PRESET') {
          dispatch(fetchData(token, 'TEST_SUITE_PRESETS', uri))
        } else {
          dispatch(fetchDataDetails(token, name, id, uri))
          dispatch(fetchData(token, name, uri))
        }
      }
    })
    .catch(error => dispatch({ type: `ADD_${name}_${funcName}_FAIL`, payload: { error } }))
}

export const deleteData = (token, name, id, fetchParams, uri) => dispatch => {
  dispatch({ type: `DELETE_${name}_START` })

  Api.deleteData(token, name, id, uri)
    .then(() => {
      dispatch({ type: `DELETE_${name}_SUCCESS` })
      if (fetchParams) {
        dispatch(fetchDataParams(token, name, fetchParams, uri))
      } else {
        dispatch(fetchData(token, name, uri))
      }
    })
    .catch(error => dispatch({ type: `DELETE_${name}_FAIL`, payload: { error } }))
}

export const fetchData = (token, name, uri) => dispatch => {
  dispatch({ type: `FETCH_${name}_START` })

  Api.fetchData(token, name, uri)
    .then(({ data }) => dispatch({ type: `FETCH_${name}_SUCCESS`, payload: { data } }))
    .catch(error => {
      dispatch({ type: `FETCH_${name}_FAIL`, payload: { error } })
      if (error && error.response && sessionExpiredHttpCodes.includes(error.response.status)) {
        dispatch(actions.logout('Your session is expired'))
      }
    })
}

export const fetchDataParams = (token, name, params, uri) => dispatch => {
  dispatch({ type: `FETCH_${name}_START`, payload: { params } })

  Api.fetchDataParams(token, name, params, uri)
    .then(({ data }) => dispatch({ type: `FETCH_${name}_SUCCESS`, payload: { data, params } }))
    .catch(error => {
      dispatch({ type: `FETCH_${name}_FAIL`, payload: { error } })
      if (error && error.response && sessionExpiredHttpCodes.includes(error.response.status)) {
        dispatch(actions.logout('Your session is expired'))
      }
    })
}

export const fetchDataDetails = (token, name, id, uri) => dispatch => {
  dispatch({ type: `FETCH_${name}_DETAILS_START` })

  Api.fetchDataDetails(token, name, id, uri)
    .then(({ data }) => dispatch({ type: `FETCH_${name}_DETAILS_SUCCESS`, payload: { data } }))
    .catch(error => {
      dispatch({ type: `FETCH_${name}_DETAILS_FAIL`, payload: { error } })
      if (error && error.response && sessionExpiredHttpCodes.includes(error.response.status)) {
        dispatch(actions.logout('Your session is expired'))
      }
    })
}

export const updateData = (token, name, id, data, withFetch, uri) => dispatch => {
  dispatch({ type: `UPDATE_${name}_START` })

  Api.updateData(token, name, id, data, uri)
    .then(() => {
      dispatch({ type: `UPDATE_${name}_SUCCESS` })
      if (withFetch) {
        dispatch(fetchDataDetails(token, name, id, uri))
        dispatch(fetchData(token, name, uri))
      }
      if (name === 'NOTIFICATIONS') {
        dispatch(actions.fetchUnseenNotifications(token))
      }
    })
    .catch(error => dispatch({ type: `UPDATE_${name}_FAIL`, payload: { error } }))
}

export const setData = (name, data) => dispatch => {
  dispatch({ type: `SET_${name}`, payload: { data } })
}

export const clearData = name => dispatch => {
  dispatch({ type: `CLEAR_${name}` })
}

export const clearDataDetails = name => dispatch => {
  dispatch({ type: `CLEAR_${name}_DETAILS` })
}
