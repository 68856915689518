import { compareArrays } from './mathOperations'
import { locationOptions } from './locations'
import { checkValidityParams } from './testParameters'
import { isJsonString } from './stringFormatting'
import { v4 as uuid } from 'uuid'

const createTestSuiteMessage = (
  testname,
  schedule,
  globalLocations,
  priorityLimit,
  options,
  defaultNotificationsOptions,
  steps
) => {
  let message = {}
  message.name = testname
  message.repeat_interval = schedule && schedule.enabled ? schedule.repeat_interval : 0
  message.repeat_start = schedule && schedule.enabled ? schedule.start_monitoring_date : null
  message.repeat_end = schedule && schedule.enabled ? schedule.end_monitoring_date : null
  message.cloud_locations = globalLocations
    ? globalLocations.filter(el => el.type === 'location').map(el => el.value)
    : []
  message.on_premise_test_agents = globalLocations
    ? globalLocations.filter(el => el.type === 'worker').map(el => el.value)
    : []
  message.options = setOptionsMessage(priorityLimit, options, defaultNotificationsOptions)
  message.test_blocks = setTestBlocksMessage(steps, options)
  return message
}

const createPresetMessage = (priorityLimit, options, defaultNotificationsOptions, steps) => {
  let message = {}
  message.options = setOptionsMessage(priorityLimit, options, defaultNotificationsOptions)
  message.test_blocks = setTestBlocksMessage(steps)
  return message
}

const setOptionsMessage = (priorityLimit, options, defaultNotificationsOptions) => {
  let _options = {}
  _options = {}
  _options.priority_limit = priorityLimit !== '' && priorityLimit !== null ? priorityLimit : undefined
  _options.notifications = defaultNotificationsOptions
  options &&
    options.notifications &&
    Object.keys(options.notifications).forEach(optNotif => {
      if (!compareArrays(options.notifications[optNotif], defaultNotificationsOptions[optNotif])) {
        _options.notifications[optNotif] = options.notifications[optNotif]
      }
    })
  _options.thresholds = (options && options.thresholds) || { enabled: false }
  if (options && options['5g_config'] && options['5g_config'].enabled) {
    _options['5g_config'] = { ...options['5g_config'] }
    _options['5g_config'] && delete _options['5g_config'].enabled
  }
  return _options
}

const setTestBlocksMessage = (steps, options) => {
  let test_blocks = []
  steps &&
    steps.forEach((step, step_index) =>
      step.tests.forEach(test => {
        let block = {}
        block.name = test.name
        block.stage_no = step_index + 1
        //block.timeout = null
        block.task_type = test.task_type
        block.task_type_name = test.task_type_name
        block.cloud_locations =
          test.overrideLocations && test.locations
            ? test.locations.filter(el => el.type === 'location').map(el => el.value)
            : []
        block.on_premise_test_agents =
          test.overrideLocations && test.locations
            ? test.locations.filter(el => el.type === 'worker').map(el => el.value)
            : []
        block.options = {
          params: test.params,
          thresholds: {
            enabled: test.additional_options.thresholds.enabled,
            mode: test.additional_options.thresholds.mode,
            configuration: isJsonString(test.additional_options.thresholds.configuration)
              ? JSON.parse(test.additional_options.thresholds.configuration)
              : {},
            code: test.additional_options.thresholds.code
          },
          priority: test.priority !== '' ? test.priority : undefined,
          '5g_enabled': options && options['5g_config'] && options['5g_config'].enabled ? test['5g_enabled'] : undefined
        }
        //dedicated_instances: test.additional_options.dedicated_instances
        test_blocks.push(block)
      })
    )
  return test_blocks
}

const createRerunParams = (
  rerunTestSuite,
  testAgents,
  locations,
  testsDetails,
  testCategoriesDetails,
  defaultNotificationsOptions,
  notificationsDeliveryConfigs
) => {
  let states = {}

  states.testname = rerunTestSuite.name || ''

  if (testAgents && locations && rerunTestSuite.cloud_locations && rerunTestSuite.on_premise_test_agents) {
    states.globalLocations = [...locationOptions(testAgents, locations)].filter(
      locationOption =>
        (locationOption.type === 'location' && rerunTestSuite.cloud_locations.includes(locationOption.value)) ||
        (locationOption.type === 'worker' && rerunTestSuite.on_premise_test_agents.includes(locationOption.value))
    )
  }
  states.priorityLimit =
    rerunTestSuite.options && rerunTestSuite.options.priority_limit !== undefined
      ? rerunTestSuite.options.priority_limit
      : ''

  const rerunSteps = []
  const stages = reduceTestSuiteToSteps(rerunTestSuite)
  Object.keys(stages).forEach(stage => {
    let rerunStage = []
    stages[stage].forEach(test => {
      let rerunTest = {}
      rerunTest.task_type = test.task_type
      rerunTest.task_type_name = test.task_type_name
      rerunTest.tile_id = uuid()
      const taskType = testsDetails && testsDetails.find(taskType => taskType.name === test.task_type_name)
      rerunTest.display_name = taskType && taskType.display_name
      const categoryDetails =
        testCategoriesDetails && testCategoriesDetails.find(taskType => taskType.name === test.task_type_name)
      rerunTest.category = categoryDetails && categoryDetails.category.id
      rerunTest.subcategory = categoryDetails && categoryDetails.subcategory.id
      rerunTest.name = test.name || ''
      rerunTest.priority = test.options && test.options.priority
      rerunTest['5g_enabled'] = test.options && test.options['5g_enabled']
      if (
        (test.cloud_locations && test.cloud_locations.length > 0) ||
        (test.on_premise_test_agents && test.on_premise_test_agents.length > 0)
      ) {
        rerunTest.overrideLocations = true
        rerunTest.locations =
          testAgents &&
          locations &&
          [...locationOptions(testAgents, locations)].filter(
            locationOption =>
              (locationOption.type === 'location' && test.cloud_locations.includes(locationOption.value)) ||
              (locationOption.type === 'worker' && test.on_premise_test_agents.includes(locationOption.value))
          )
      } else {
        rerunTest.overrideLocations = false
        rerunTest.locations =
          testAgents &&
          locations &&
          rerunTestSuite.cloud_locations &&
          rerunTestSuite.on_premise_test_agents &&
          [...locationOptions(testAgents, locations)].filter(
            locationOption =>
              (locationOption.type === 'location' && rerunTestSuite.cloud_locations.includes(locationOption.value)) ||
              (locationOption.type === 'worker' && rerunTestSuite.on_premise_test_agents.includes(locationOption.value))
          )
      }
      let incorrectParams = checkValidityParams(taskType, test.options.params)
      if (incorrectParams.length > 0) {
        rerunTest.params = {}
        taskType.options.params.forEach(option => {
          rerunTest.params[option.name] =
            'initial' in option.attributes ? option.attributes.initial : option.type === 'multipleinput' ? [''] : null
        })
      } else {
        rerunTest.params = test.options.params
      }
      rerunTest.additional_options = {
        thresholds: {
          enabled: (test.options.thresholds && test.options.thresholds.enabled) || false,
          mode:
            (test.options.thresholds && test.options.thresholds.mode) ||
            (taskType.options &&
            taskType.options.additional_options &&
            taskType.options.additional_options.thresholds &&
            taskType.options.additional_options.thresholds.enabled
              ? 'json'
              : 'python'),
          code: (test.options.thresholds && test.options.thresholds.code) || '',
          configuration: JSON.stringify(
            (test.options.thresholds && test.options.thresholds.configuration) || {},
            null,
            '\t'
          )
        },
        dedicated_instances: test.options.dedicated_instances
      }
      rerunStage.push(rerunTest)
    })
    rerunSteps.push({ tests: rerunStage })
  })
  rerunSteps.push({ tests: [] })
  states.steps = rerunSteps
  let options = {}
  options.notifications = {}
  Object.keys(defaultNotificationsOptions).forEach(
    key =>
      (options.notifications[key] =
        rerunTestSuite.options && rerunTestSuite.options.notifications && key in rerunTestSuite.options.notifications
          ? rerunTestSuite.options.notifications[key].filter(
              deliveryConfig =>
                notificationsDeliveryConfigs && notificationsDeliveryConfigs.map(nDC => nDC.id).includes(deliveryConfig)
            )
          : defaultNotificationsOptions[key])
  )
  options.thresholds = rerunTestSuite.options.thresholds || { enabled: false }
  options['5g_config'] = rerunTestSuite.options['5g_config']
  if (options['5g_config'] && Object.keys(options['5g_config']).length > 0) {
    options['5g_config'].enabled = true
  }
  states.options = options

  return states
}

const testSuiteLocalConfNotifTypesNames = [
  'TASK_STARTED',
  'TASK_SUCCEEDED',
  'TASK_FAILED',
  'THRESHOLD_EXCEEDED',
  'TEST_SUITE_STARTED',
  'TEST_SUITE_FINISHED'
]

const getDefaultNotificationsOptions = (enabledNotifications, notificationsTypes) => {
  if (enabledNotifications && notificationsTypes) {
    let defaultNotifOptions = {}
    notificationsTypes
      .filter(nT => testSuiteLocalConfNotifTypesNames.includes(nT.name))
      .forEach(nT => {
        defaultNotifOptions[JSON.stringify(nT.id)] = []
      })
    Object.keys(defaultNotifOptions).forEach(notifType => {
      if (enabledNotifications.some(notif => notif.notification_type === parseInt(notifType))) {
        defaultNotifOptions[notifType] = enabledNotifications
          .filter(notif => notif.notification_type === parseInt(notifType))
          .map(notif => notif.delivery_config)
      }
    })
    return defaultNotifOptions
  }
  return {}
}

const reduceTestSuiteToSteps = testSuite => {
  return testSuite
    ? testSuite.test_blocks.reduce((r, a) => {
        r[a.stage_no] = [...(r[a.stage_no] || []), a]
        return r
      }, {})
    : []
}

const getTestBlocksString = (testBlocks, taskTypes) => {
  let testBlocksList = []
  testBlocks.map(
    test_block =>
      taskTypes &&
      taskTypes.some(taskType => taskType.id === test_block.task_type) &&
      testBlocksList.push(taskTypes.find(taskType => taskType.id === test_block.task_type).display_name)
  )
  const testBlocksObj = testBlocksList.reduce((acc, curr) => {
    acc[curr] = (acc[curr] || 0) + 1
    return acc
  }, {})
  const testBlocksStrings = Object.keys(testBlocksObj).map(
    testBlock => `${testBlock}${testBlocksObj[testBlock] > 1 ? ` x${testBlocksObj[testBlock]}` : ''}`
  )
  return testBlocksStrings.join(', ')
}

export {
  createTestSuiteMessage,
  createPresetMessage,
  createRerunParams,
  testSuiteLocalConfNotifTypesNames,
  getDefaultNotificationsOptions,
  reduceTestSuiteToSteps,
  getTestBlocksString
}
