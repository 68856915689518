// TODO: Array.prototype.sortBy
const sortBy = (array, paramName) => {
  let _array = [...array]
  if (paramName.charAt(0) === '-') {
    _array = _array.sort(function(a, b) {
      let _paramName = paramName.slice(1, paramName.length)
      if (a[_paramName] instanceof Date) {
        return new Date(b[_paramName]) - new Date(a[_paramName])
      } else if (Number.isInteger(a[_paramName])) {
        return b[_paramName] - a[_paramName]
      } else {
        return (b[_paramName] && b[_paramName].toLowerCase()) > (a[_paramName] && a[_paramName].toLowerCase()) ? 1 : -1
      }
    })
  } else {
    _array = _array.sort(function(a, b) {
      if (a[paramName] instanceof Date) {
        return new Date(a[paramName]) - new Date(b[paramName])
      } else if (Number.isInteger(a[paramName])) {
        return a[paramName] - b[paramName]
      } else {
        return (a[paramName] && a[paramName].toLowerCase()) > (b[paramName] && b[paramName].toLowerCase()) ? 1 : -1
      }
    })
  }
  return _array
}

function intersection() {
  /* Possible call function via
   * intersection(array1, array2, array3, array4)
   * or
   * intersection([array1, array2, array3, array4])
   */

  let result = []
  let lists

  if (arguments.length === 1) {
    lists = arguments[0]
  } else {
    lists = arguments
  }

  for (let i = 0; i < lists.length; i++) {
    let currentList = lists[i]
    for (let y = 0; y < currentList.length; y++) {
      let currentValue = currentList[y]
      if (result.indexOf(currentValue) === -1) {
        let existsInAll = true
        for (let x = 0; x < lists.length; x++) {
          if (lists[x].indexOf(currentValue) === -1) {
            existsInAll = false
            break
          }
        }
        if (existsInAll) {
          result.push(currentValue)
        }
      }
    }
  }
  return result
}

function compareValues(firstValue, operator, secondValue) {
  // function replace eval function due to console warning "eval can be harmful"
  let result = undefined
  switch (operator) {
    case '>':
      result = firstValue > secondValue
      break
    case '>=':
      result = firstValue >= secondValue
      break
    case '=':
      result = firstValue === secondValue
      break
    case '<=':
      result = firstValue <= secondValue
      break
    case '<':
      result = firstValue < secondValue
      break
    default:
      break
  }
  return result
}

function compareArrays(array1, array2) {
  const array2Sorted = array2.slice().sort()
  return (
    array1.length === array2.length &&
    array1
      .slice()
      .sort()
      .every(function(value, index) {
        return value === array2Sorted[index]
      })
  )
}

function getNextOrdinalNumber(array, substring) {
  let numberSuffixes = array
    .filter(str => str.toString().includes(substring))
    .map(str => parseInt(str.replace(substring, '')))
    .filter(str => Number.isInteger(str))
  return numberSuffixes.length > 0 ? Math.max(...numberSuffixes) + 1 : 1
}

function onlyUnique(value, index, array) {
  return array.indexOf(value) === index;
}

export { sortBy, intersection, compareValues, compareArrays, getNextOrdinalNumber, onlyUnique }
