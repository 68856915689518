import React, { useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'reactstrap'
import Moment from 'react-moment'
import { FaTrashAlt, FaEdit, FaDownload } from 'react-icons/fa'
import { toast } from 'react-toastify'
import { Modal } from '../../components'
import * as actions from '../../store/actions'
import { adminURI } from '../../api/endpoints'
import * as Api from '../../api'

const UpdateRow = ({ update }) => {
  const dispatch = useDispatch()
  const modalRef = useRef()
  const token = useSelector(state => state.auth.token)
  const [downloadProgress, setDownloadProgress] = useState(0)
  const [downloadedUpdateId, setDownloadedUpdateId] = useState(null)

  function deleteItem(update) {
    dispatch(actions.deleteData(token, 'UPDATES', update.id, false, adminURI))
  }

  function onProgress(percent) {
    setDownloadProgress(percent)
  }

  //save update package on disk
  function downloadPackage(update) {
    setDownloadProgress(0)
    setDownloadedUpdateId(update.id)
    Api.getDataDetailsFunction(token, 'UPDATES', update.id, 'DOWNLOAD', onProgress, adminURI)
      .then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement('a')
        link.href = url
        let headerLine = res.headers['content-disposition']
        let startFileNameIndex = headerLine.indexOf('"') + 1
        let endFileNameIndex = headerLine.lastIndexOf('"')
        let filename = headerLine.substring(startFileNameIndex, endFileNameIndex)
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
      })
      .catch(error => {
        toast.error(`Error occurred when trying to download update package (${error})`)
        setDownloadProgress(0)
        setDownloadedUpdateId(null)
      })
  }

  return (
    <tr>
      <td>{update.display_name}</td>
      <td>{update.package}</td>
      <td>{update.description}</td>
      <td>
        <Moment format="DD-MM-YYYY HH:mm:ss">{update.timestamp_created}</Moment>
      </td>
      <td style={{ textAlign: 'center' }}>
        <Button
          color="link"
          disabled={downloadProgress > 0 && downloadProgress < 100}
          onClick={() => downloadPackage(update)}
        >
          <FaDownload title="Download package" />{' '}
          {downloadProgress && update.id === downloadedUpdateId ? `${downloadProgress} % ` : ''}
        </Button>
        <NavLink className="p-3" to={`/updates/edit/${update.id}`}>
          <FaEdit title="Edit update package" />
        </NavLink>
        <Modal
          ref={modalRef}
          title={`Delete update package "${update.name}"`}
          content={`Are you sure you want to delete "${update.name}" update package?`}
          onApply={() => deleteItem(update)}
        />
        <Button color="link" onClick={() => modalRef.current.openModal()}>
          <FaTrashAlt title="Delete update package" />
        </Button>
      </td>
    </tr>
  )
}

export default UpdateRow
