import React, { Fragment } from 'react'

const convertMessageToArray = (message, header, messageArray = []) => {
  switch (typeof message) {
    case 'string':
      messageArray.push(header ? `${header}:${message}` : `${message}`)
      break
    case 'object':
      if (Array.isArray(message)) {
        message.forEach(value => {
          convertMessageToArray(value, header, messageArray)
        })
      } else {
        Object.keys(message).forEach(key => {
          convertMessageToArray(message[key], key, messageArray)
        })
      }
      break
    default:
      messageArray.push(header ? `${header}:${message}` : `${message}`)
      break
  }
  return messageArray
}

const ToastContent = ({ data }) => (
  <div>
    {convertMessageToArray(data).map((message, index) => {
      return (
        <Fragment key={index}>
          {message} <br />
        </Fragment>
      )
    })}
  </div>
)

export default ToastContent
