import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import * as actions from '../../store/actions'
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  CustomInput,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Spinner as SpinnerR,
  Button
} from 'reactstrap'
import { TableBody, Spinner } from '../../components'
import DeliveryConfigHeader from './DeliveryConfigHeader'

export default function NotificationsConfig() {
  const dispatch = useDispatch()
  const token = useSelector(state => state.auth.token)
  const account = useSelector(state => state.account.data)
  const notificationsTypes = useSelector(state => state.notifications_types.data)
  const enabledNotifications = useSelector(state => state.enabled_notifications.data)
  const notificationsDeliveryConfigs = useSelector(state => state.notifications_delivery_configs.data)
  const loading = useSelector(
    state =>
      state.notifications_types.loading ||
      state.enabled_notifications.loading ||
      state.notifications_delivery_configs.loading
  )
  const accountLoading = useSelector(state => state.account.loading)
  const [notifExpirationTime, setNotifExpirationTime] = useState('')

  useEffect(() => {
    setNotifExpirationTime(
      account && Number.isInteger(account.notification_expiration_time) ? account.notification_expiration_time : ''
    )
  }, [account])

  useEffect(() => {
    let timer = null
    if (account && notifExpirationTime !== account.notification_expiration_time) {
      timer = setTimeout(() => {
        dispatch(
          actions.updateData(token, 'ACCOUNT', account.id, { notification_expiration_time: notifExpirationTime }, true)
        )
      }, 1000)
    }
    return () => {
      clearTimeout(timer)
    }
  }, [notifExpirationTime])

  function onChange(e, notifType, deliveryConfig) {
    if (e.target.checked) {
      dispatch(
        actions.addData(
          token,
          'ENABLED_NOTIFICATIONS',
          { notification_type: notifType, delivery_config: deliveryConfig },
          true
        )
      )
    } else {
      const enabledNotifItem = enabledNotifications.find(
        enabledNotif => enabledNotif.notification_type === notifType && enabledNotif.delivery_config === deliveryConfig
      )
      if (enabledNotifItem) {
        dispatch(actions.deleteData(token, 'ENABLED_NOTIFICATIONS', enabledNotifItem.id))
      }
    }
  }

  return (
    <Card className="top-line-info">
      <CardHeader>
        <CardTitle>Notifications Configuration</CardTitle>
      </CardHeader>
      <CardBody>
        {loading ? (
          <Spinner />
        ) : (
          <>
            <InputGroup className="pb-2">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>Notifications Expiration Time (in days)</InputGroupText>
              </InputGroupAddon>
              <Input
                type="number"
                min={0}
                value={notifExpirationTime}
                onChange={e => {
                  setNotifExpirationTime(parseInt(e.target.value))
                }}
              />
              {accountLoading && (
                <InputGroupAddon addonType="append">
                  <InputGroupText>
                    <SpinnerR style={{ width: '1.5rem', height: '1.5rem' }} />
                  </InputGroupText>
                </InputGroupAddon>
              )}
            </InputGroup>
            {notificationsTypes &&
              enabledNotifications &&
              notificationsDeliveryConfigs &&
              (notificationsTypes.length === 0 ? (
                'There is no notifications types in database.'
              ) : notificationsDeliveryConfigs.length === 0 ? (
                'There is no notifications delivery configs in database.'
              ) : (
                <Table responsive bordered>
                  <thead>
                    <tr>
                      <th></th>
                      {notificationsDeliveryConfigs &&
                        notificationsDeliveryConfigs.map(nDC => (
                          <th key={nDC.id}>
                            <DeliveryConfigHeader nDC={nDC} />
                          </th>
                        ))}
                    </tr>
                  </thead>
                  <TableBody>
                    {notificationsTypes.filter(notifType => notifType.name !== "BACKEND_ERROR").map(notifType => (
                      <tr key={notifType.id}>
                        <th>{notifType.display_name}</th>
                        {notificationsDeliveryConfigs.map(nDC => (
                          <td key={nDC.id}>
                            <CustomInput
                              type="checkbox"
                              id={`${notifType.id}_${nDC.id}`}
                              checked={enabledNotifications.some(
                                enabledNotif =>
                                  enabledNotif.notification_type === notifType.id &&
                                  enabledNotif.delivery_config === nDC.id
                              )}
                              onChange={e => onChange(e, notifType.id, nDC.id)}
                            />
                          </td>
                        ))}
                      </tr>
                    ))}
                  </TableBody>
                </Table>
              ))}
            <NavLink to="/notifications/smtp_server_configuration">
              <Button color="info">SMTP Server Configuration</Button>
            </NavLink>
            <NavLink to="/notifications/delivery_configs/add">
              <Button color="info" className="float-right">
                Add new Delivery Config
              </Button>
            </NavLink>
          </>
        )}
      </CardBody>
    </Card>
  )
}
