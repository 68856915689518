import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import { ContainerFluid, Content, ContentHeader, Spinner } from '../../components'
import UserEditForm from './Form'
import * as actions from '../../store/actions'
import { adminURI } from '../../api/endpoints'
import { usePrevious } from '../../utilities/customHooks'

export default function UserEdit({ match }) {
  const isEditPage = match.path === '/users/edit/:id'
  const defaultUser = {
    username: '',
    first_name: '',
    last_name: '',
    email: '',
    is_superuser: false,
    credits: 0,
    group_ids: [],
    policy_config_id: 0,
    cloud_config_id: 0,
    subscription_id: 0,
    timezone: null
  }

  const history = useHistory()
  const dispatch = useDispatch()
  const token = useSelector(state => state.auth.token)
  const user = isEditPage ? useSelector(state => state.user.data) : defaultUser
  const users = useSelector(state => state.users.data)
  const loading = useSelector(
    state =>
      state.user.loading ||
      state.users.loading ||
      state.policies.loading ||
      state.cloud_configs.loading ||
      state.groups.loading ||
      state.subscriptions.loading
  )
  const error = useSelector(state => state.users.error)
  const prevError = usePrevious(error)
  const [isAfterDispatch, setIsAfterDispatch] = useState(false)

  useEffect(() => {
    document.title = 'vUTP | Users'
    dispatch(actions.fetchData(token, 'CLOUD_CONFIGS', adminURI))
    dispatch(actions.fetchData(token, 'GROUPS', adminURI))
    dispatch(actions.fetchData(token, 'POLICIES', adminURI))
    dispatch(actions.fetchData(token, 'SUBSCRIPTIONS', adminURI))

    if (isEditPage) {
      dispatch(actions.fetchDataDetails(token, 'USERS', match.params.id, adminURI))
    }
  }, [])

  useEffect(() => {
    if (isAfterDispatch && !prevError && !error) {
      history.push('/users', { noFetch: true })
    }
  }, [users])

  function onDataUpdated(data) {
    if (isEditPage) {
      dispatch(actions.updateData(token, 'USERS', match.params.id, data, true, adminURI))
    } else {
      dispatch(actions.addData(token, 'USERS', data, true, adminURI))
    }
    setIsAfterDispatch(true)
  }

  return (
    <>
      <ContentHeader title={`${isEditPage ? 'Edit' : 'Add'} User`} />
      <Content>
        <ContainerFluid>
          <Row>
            <Col xl="12">{loading ? <Spinner /> : user && <UserEditForm userProp={user} onDataUpdated={onDataUpdated} />}</Col>
          </Row>
        </ContainerFluid>
      </Content>
    </>
  )
}
