import React, { useEffect } from 'react'
import { Col, Row } from 'reactstrap'
import { ContainerFluid, Content, ContentHeader } from '../../components'
import TestSuitesProvider from '../../context/TestSuitesContext'
import { ResultsCard } from './'

export default function Results({ match, location }) {
  useEffect(() => {
    document.title = 'vUTP | Results'
  }, [])

  return (
    <TestSuitesProvider>
      <ContentHeader title="Results" />
      <Content>
        <ContainerFluid>
          <Row>
            <Col xl="12" lg="12" md="12" sm="12" xs="12">
              <ResultsCard match={match} location={location} />
            </Col>
          </Row>
        </ContainerFluid>
      </Content>
    </TestSuitesProvider>
  )
}
