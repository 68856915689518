import React, { useEffect, useState } from 'react'

const { REACT_APP_VERSION } = process.env

const Footer = () => {
  const [version, setVersion] = useState()
  useEffect(() => {
    let raw = null
    try {
      raw = require('../../gitInfo.txt')
    } catch (err) {
      console.warn('File gitInfo.txt not exists - probability not displaying the application version.')
      raw = null
    }
    raw && readFile(raw)
  }, [])
  const readFile = raw => {
    fetch(raw)
      .then(r => r.text())
      .then(text => {
        setVersion(text)
      })
  }

  return (
    <footer className="main-footer">
      <div className="float-right d-sm-none d-md-block" />
      <strong>
        Copyright &copy; 2019-{new Date().getFullYear()} <a href="http://netrsr.com/">NetResearch</a>.
      </strong>{' '}
      All rights reserved.
      <div className="float-right">
        {REACT_APP_VERSION && version && (
          <p>
            <b>Version</b> {`${REACT_APP_VERSION}.${version}`}
          </p>
        )}
      </div>
    </footer>
  )
}

export default Footer
