import React from 'react'
import { Table } from 'reactstrap'
import { convertDigitalValueToLabel } from '../../../../utilities/stringFormatting'

export default function ResultWWW({ result }) {
  return (
    <Table bordered style={{ textAlign: 'center' }}>
      <thead>
        <tr>
          <th>URI</th>
          <th>Result</th>
          <th>Size</th>
          <th>Avg. bitrate</th>
          <th>Download time</th>
        </tr>
      </thead>
      <tbody>
        {result.map(value => (
          <tr key={value['url']}>
            {value['succeeded'] ? (
              <>
                <td>{value['url']}</td>
                <td style={{ color: 'green' }}>PASS</td>

                <td>{convertDigitalValueToLabel(value['size'], 'B')}</td>
                <td>{value['avg_bitrate'].toFixed(4)} Mb/s</td>
                <td>{value['download_time']} ms</td>
              </>
            ) : (
              <>
                <td>{value['url']}</td>
                <td style={{ color: 'red' }}>FAILED</td>
                <td colSpan="3" key={value + '_dt'}>
                  {value['error']}
                </td>
              </>
            )}
          </tr>
        ))}
      </tbody>
    </Table>
  )
}
