import React, { useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody, CardHeader, CardTitle, Table, Button } from 'reactstrap'
import { FaExclamationTriangle, FaCheckCircle } from 'react-icons/fa'
import Moment from 'react-moment'
import { Spinner } from '../../../components'
import { TestSuiteStatus } from '../../../containers'
import { TestSuiteContext } from '../../../context/TestSuiteContext'

export default function ResultDetailsCard() {
  const loading = useSelector(state => state.test_suite.loading)
  const { testSuite } = useContext(TestSuiteContext)
  const [thresholdsResultOpened, setThresholdsResultOpened] = useState(
    !(testSuite && testSuite.options && testSuite.options.thresholds && testSuite.options.thresholds.code !== undefined)
  )

  return (
    <Card className="top-line-info" style={{ height: '95%' }}>
      <CardHeader className="no-border">
        <CardTitle>Details</CardTitle>
      </CardHeader>
      <CardBody>
        {loading ? (
          <Spinner />
        ) : (
          <Table responsive size="sm" borderless className="mb-0">
            <tbody>
              <tr>
                <th>Status</th>
                <td style={{ textAlign: 'right' }}>{testSuite && <TestSuiteStatus status={testSuite.status} />}</td>
              </tr>
              <tr>
                <th>Created</th>
                <td style={{ textAlign: 'right' }}>
                  {testSuite && <Moment format="DD-MM-YYYY HH:mm:ss">{testSuite.timestamp_created}</Moment>}
                </td>
              </tr>
              {testSuite && testSuite.options.priority_limit && testSuite.options.priority_limit ? (
                <tr>
                  <th>Priority Limit</th>
                  <td className="text-right">{testSuite.options.priority_limit}</td>
                </tr>
              ) : null}
              {testSuite && testSuite.last_run_group_threshold_exceeded != null ? (
                <tr>
                  <th>Last Threshold Status</th>
                  <td className="text-right">
                    {testSuite.last_run_group_threshold_exceeded ? (
                      <>
                        <FaExclamationTriangle color="red" /> <b style={{ color: 'red' }}>Exceeded</b>
                      </>
                    ) : (
                      <>
                        <FaCheckCircle color="green" /> OK
                      </>
                    )}
                  </td>
                </tr>
              ) : null}
              {testSuite &&
              testSuite.options &&
              testSuite.options.thresholds &&
              testSuite.options.thresholds.enabled ? (
                <tr>
                  <th>Thresholds</th>
                  <td className="float-right">
                    <Button
                      color="link"
                      className="p-0 float-right text-right"
                      onClick={() => setThresholdsResultOpened(prev => !prev)}
                    >
                      {`${thresholdsResultOpened ? 'Hide' : 'Show'} param value`}
                    </Button>
                    <br />
                    <pre className="p-0 m-0 float-right">
                      {thresholdsResultOpened && testSuite.options.thresholds.code}
                    </pre>
                  </td>
                </tr>
              ) : null}
            </tbody>
          </Table>
        )}
      </CardBody>
    </Card>
  )
}
