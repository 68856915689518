import React from 'react'
import { NavLink } from 'react-router-dom'
import { Table, Button } from 'reactstrap'

const PresetsList = ({ children, presets }) => {
  return presets && presets.length > 0 ? (
    <Table responsive size="sm">
      <thead>
        <tr>
          <th>Name</th>
          <th>Description</th>
          <th>Test Blocks</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </Table>
  ) : (
    <NavLink to="/test-suite">
      <Button color="info">Create first preset in Test Suite configurator</Button>
    </NavLink>
  )
}

export default PresetsList
