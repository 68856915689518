import React from 'react'
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap'
import { GrafanaFrame } from '..'

export default function MapCard({ cardClassName }) {
  return (
    <Card className={`top-line-info ${cardClassName ? cardClassName : ''}`} style={{ height: '97%', minHeight: '300px' }}>
      <CardHeader className="no-border">
        <CardTitle>Test Agents: Map</CardTitle>
      </CardHeader>
      <CardBody className="p-0 d-md-flex">
        <GrafanaFrame
          panel_id={4} // panelId from Grafana, defines the chart
          style={{ width: '99%', position: 'relative', marginBottom: '6px' }}
          title={'map'}
        />
      </CardBody>
    </Card>
  )
}
