import React from 'react'
import { Table, Progress } from 'reactstrap'

export default function ResultRAMRead({ result }) {
  const throughput = Math.round((result.read_size / result.time_ns) * 1000000) / 1000
  return (
    <div>
      <Table responsive size="sm">
        <thead>
          <tr>
            <th>Memory</th>
            <th>Throughput</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>0</td>
            <td>
              <div className="text-center">{`${throughput} Gb/s`}</div>
              <Progress value={throughput} max={10} color="primary" />
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  )
}
