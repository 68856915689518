import React from 'react'
import Moment from 'react-moment'
import 'moment-timezone'

export const createTitle = (key) => {
  return key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
}

export const enhanceDate = (timestamp = '') => (
  <Moment format='YYYY-MM-DD HH:mm:ss'>
    {timestamp}
  </Moment>
)

export const displayObjectRepr = (value = '') => {
  if (value === null) return
  if (value.includes('VutpWorker')) {
    return displayDefault(value.substring(0, value.indexOf('-')).concat('...>'))
  }
  if (value.includes('TaskSchedule')) {
    return displayDefault(value.substring(0, value.indexOf('-')).concat('...)>'))
  }
  return value
}

export const listOption = (key, value) => {
  return (
    <p key={key}>
      <span>{displayDefault(key)}: </span>
      <span className='font-weight-bold'>{displayDefault(value)}</span>
    </p>
  )
}

export const displayOptions = (options) => {
  return Object.values(options).map(option => {
    return Object.entries(option).map(([key, value]) => {
      switch (key) {
        case 'uuid':
          return listOption(key, displayObjectRepr(value))
        default:
          return listOption(key, value)
      }
    })
  })
}

export const displaySchedule = (schedule) => {
  return Object.entries(schedule).map(([key, value]) => {
    switch (key) {
      case 'uuid':
        return listOption(key, displayObjectRepr(value))
      default:
        return listOption(key, value)
    }
  })
}

export const displayItems = (items) => {
  return items.map(item => {
    return <p key={item}>{displayDefault(item)}</p>
  })
}

export const displayDefault = (object) => {
  if (Array.isArray(object) && object.length > 1) {
    return displayItems(object)
  }
  return String(object)
}

export const displayDetails = (key, value) => {
  switch (key) {
    case 'options':
      return displayOptions(value)
    case 'schedule':
      return displaySchedule(value)
    default:
      return displayDefault(value)
  }
}
