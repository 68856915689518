import React from 'react'
import { Table } from 'reactstrap'

export default function ResultPathping({ result }) {
  return result.map(value => (
    <>
      <b>{value.address}</b>
      <div key={value}>
        {'succeeded' in value.traceroute && value.traceroute.succeeded.toString() === 'false' ? (
          <Table responsive size="sm">
            <thead>
              <th>Test Succeeded</th>
              <th>Failure Reason</th>
            </thead>
            <tbody>
              <tr>
                <td>{value.traceroute.succeeded.toString()}</td>
                <td>{value.traceroute.reason}</td>
              </tr>
            </tbody>
          </Table>
        ) : (
          <Table responsive size="sm">
            <thead>
              <tr>
                <th>Sequence Number</th>
                <th>Succeeded Hops Count</th>
                <th>IP Address</th>
                <th>RTT</th>
                <th>Failure Reason</th>
              </tr>
            </thead>
            <tbody>
              {value.pathping.map((item, ind) => (
                <tr key={item.address}>
                  <td>{ind}</td>
                  <td>{item.succeeded_count}</td>
                  <td>
                    {(value.traceroute &&
                      value.traceroute.hops &&
                      value.traceroute.hops[ind] &&
                      value.traceroute.hops[ind].ip_address) ||
                      '-'}
                  </td>
                  <td>{'mean_rtt' in item ? Math.round(item.mean_rtt * 100) / 100 + ' ms' : '-'}</td>
                  <td>{item.reason || '-'}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </div>
    </>
  ))
}
