import { createStore, applyMiddleware, compose, combineReducers } from 'redux'
import thunk from 'redux-thunk'
import authReducer from './store/reducers/auth'
import dataReducer from './store/reducers/data'
import dataDetailsReducer from './store/reducers/data_details'
import dataInfoReducer from './store/reducers/data_info'
import dataFunctionsReducer from './store/reducers/data_functions'
import connectionStatusReducer from './store/reducers/connectionStatus'
import othersReducer from './store/reducers/others'
//import uiReducer from './store/reducers/ui'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const rootReducer = combineReducers({
  auth: authReducer,
  results: dataReducer('RESULTS'),
  result_details: dataDetailsReducer('RESULTS'),
  results_to_charts: othersReducer(),
  results_meta: dataDetailsReducer('RESULTS_META'),
  test_categories: dataReducer('CATEGORIES'),
  test_categories_details: dataDetailsReducer('CATEGORIES'),
  test_suites: dataReducer('TEST_SUITES'),
  test_suite: dataDetailsReducer('TEST_SUITES'),
  created_test: dataInfoReducer('CREATED_TEST'),
  task_types: dataReducer('TASK_TYPES'),
  top_tests: dataReducer('TOP_TESTS'),
  test_suite_presets: dataReducer('TEST_SUITE_PRESETS'),
  workers: dataReducer('TEST_AGENTS'),
  locations: dataReducer('LOCATIONS'),
  notifications: dataReducer('NOTIFICATIONS'),
  notifications_types: dataReducer('NOTIFICATION_TYPES'),
  last_seen_notifications: dataInfoReducer('SEEN_NOTIFICATIONS'),
  notifications_unseen: dataReducer('UNSEEN_NOTIFICATIONS'),
  notifications_open_panels_counter: dataInfoReducer('NOTIFICATIONS_OPEN_PANELS_COUNTER'),
  enabled_notifications: dataReducer('ENABLED_NOTIFICATIONS'),
  notifications_delivery_types: dataReducer('NOTIFICATION_DELIVERY_TYPES'),
  notifications_delivery_configs: dataReducer('NOTIFICATION_DELIVERY_CONFIGS'),
  notifications_delivery_config: dataDetailsReducer('NOTIFICATION_DELIVERY_CONFIGS'),
  account: dataReducer('ACCOUNT'),
  connectionStatus: connectionStatusReducer(),
  functions_data: dataFunctionsReducer(),
  updates: dataReducer('UPDATES'),
  update: dataDetailsReducer('UPDATES'),
  //ui: uiReducer,
  // admin collections
  users: dataReducer('USERS'),
  user: dataDetailsReducer('USERS'),
  groups: dataReducer('GROUPS'),
  group: dataDetailsReducer('GROUPS'),
  permissions: dataReducer('PERMISSIONS'),
  cloud_configs: dataReducer('CLOUD_CONFIGS'),
  cloud_config: dataDetailsReducer('CLOUD_CONFIGS'),
  logs: dataReducer('LOGS'),
  log: dataDetailsReducer('LOGS'),
  policies: dataReducer('POLICIES'),
  policy: dataDetailsReducer('POLICIES'),
  subscriptions: dataReducer('SUBSCRIPTIONS'),
  subscription: dataDetailsReducer('SUBSCRIPTIONS')
})

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)))

export default store
