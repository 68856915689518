import React from 'react'
import Group from './Group'
import Rule from './Rule'

export default function QueryBuilder({ query, setQuery, thresholdsParams, treeLevel }) {

  const currentTreeLevel = treeLevel ? treeLevel + 1 : 1

  function onChangeQueryBuilder(newQuery, index) {
    let updatedQuery = { ...query }
    updatedQuery.thresholds[index] = newQuery
    updatedQuery.thresholds = updatedQuery.thresholds.filter(thr => Object.keys(thr).length > 0)
    setQuery(updatedQuery)
  }

  return query.type === 'threshold' ? (
    <Rule query={query} setQuery={setQuery} thresholdsParams={thresholdsParams} />
  ) : query.type === 'operator' ? (
    <Group query={query} setQuery={setQuery} thresholdsParams={thresholdsParams} treeLevel={currentTreeLevel}>
      {query.thresholds &&
        query.thresholds.map((threshold, index) => (
          <QueryBuilder
            key={index}
            query={threshold}
            setQuery={newQuery => onChangeQueryBuilder(newQuery, index)}
            thresholdsParams={thresholdsParams}
            treeLevel={currentTreeLevel}
          />
        ))}
    </Group>
  ) : (
    <></>
  )
}
