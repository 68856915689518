import React from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Card, CardBody, CardHeader, CardTitle, Button } from 'reactstrap'
import { Spinner, CardTools } from '../../../../components'
import { PresetModal } from '../../../../containers'
import { reduceTestSuiteToSteps } from '../../../../utilities/testSuite'
import { EnvStep, Step } from '../..'

export default function TestSuiteCard() {
  const history = useHistory()
  const testSuite = useSelector(state => state.test_suite.data)
  const loading = useSelector(
    state =>
      state.test_suite.loading ||
      state.task_types.loading ||
      state.test_categories.loading ||
      state.test_categories_details.loading
  )

  const testSteps = reduceTestSuiteToSteps(testSuite)

  function rerunTest() {
    history.push(`/test-suite/`, { rerunTest: testSuite })
  }

  return (
    <Card className="top-line-info" style={{ height: '95%' }}>
      <CardHeader className="no-border">
        <CardTitle>Test Suite</CardTitle>
        <CardTools>
          <Button color="info" onClick={rerunTest}>
            Rerun Test Suite
          </Button>{' '}
          <PresetModal testSuiteId={testSuite.id} />
        </CardTools>
      </CardHeader>
      <CardBody>
        {loading ? (
          <Spinner />
        ) : (
          <div style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
            <EnvStep isPreStep={true} />
            {Object.keys(testSteps).map((step, step_number) => (
              <Step step={step} step_number={step_number} key={step_number} />
            ))}
            <EnvStep isPreStep={false} />
          </div>
        )}
      </CardBody>
    </Card>
  )
}
