import React from 'react'
import { useSelector } from 'react-redux'
import { TimeChart } from '../../'

export default function IPTV() {
  const testSuite = useSelector(state => state.test_suite.data)
  const monitoring = testSuite.repeat_interval

  return monitoring ? (
    <>
      {/* Bandwidth, Audio Bandwidth, Video Bandwidth */}
      <TimeChart panel_id={42} />
      {/* Packes Lost, Continuity Error, CRC Error */}
      <TimeChart panel_id={52} />
    </>
  ) : (
    'No chart'
  )
}
