import React, { createContext, useState, useEffect, useRef, useContext } from 'react'
import { useSelector } from 'react-redux'
import WSProvider from './WSProvider'
import TestSuiteInResults from './TestSuiteInResultsContext'

export const ResultsContext = createContext({})

export default function ResultsProvider({ children, testSuiteId }) {
  const token = useSelector(state => state.auth.token)
  const [results, setResults] = useState([])
  const ws = useRef(null)
  const [wsActive, setWsActive] = useState(false)
  const ws_scheme = process.env.REACT_APP_HTTPS ? 'wss' : 'ws'
  const { selectedBlock } = useContext(TestSuiteInResults)

  useEffect(() => {
    ws.current = new WebSocket(`${ws_scheme}://${window.location.host}/ws/tasks/${testSuiteId}/?_token=${token}`)
  }, [])

  useEffect(() => {
    if (ws && ws.current) {
      setWsActive(true)
      ws.current.onmessage = evt => {
        // listen to data sent from the websocket server
        const message = JSON.parse(evt.data)
        setResults(prev => {
          if (prev.some(result => result.id === message.id)) {
            return prev.map(result => {
              return result.id === message.id ? message : result
            })
          } else {
            return [...prev, message]
          }
        })
      }
    }
  }, [ws && ws.current])

  useEffect(() => {
    setResults([])
  }, [selectedBlock])

  return (
    ws &&
    wsActive &&
    ws.current && (
      <WSProvider ws={ws} contextName="tasks" contextSubname={testSuiteId} storeKeyName="results">
        <ResultsContext.Provider
          value={{
            resultsWS: results.sort(function(a, b) {
              return new Date(b.timestamp_created).getTime() - new Date(a.timestamp_created).getTime()
            })
          }}
        >
          {children}
        </ResultsContext.Provider>
      </WSProvider>
    )
  )
}
