import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from 'reactstrap'
import { CardTools, ContainerFluid, Content, ContentHeader, PaginationPanel } from '../../components'
import * as actions from '../../store/actions'
import { adminURI } from '../../api/endpoints'
import LogList from './List'
import LogsFilters from './LogsFilters'

export default function Logs({ match, location }) {
  const dispatch = useDispatch()
  const history = useHistory()
  const limit = 100
  const token = useSelector(state => state.auth.token)
  const logs = useSelector(state => state.logs.data)
  const loading = useSelector(state => state.logs.loading)
  const pagesCount = useSelector(state => Math.ceil(state.logs.count / limit))
  const filters = { ...location.state }

  useEffect(() => {
    document.title = 'vUTP | Logs'
    dispatch(actions.fetchData(token, 'USERS', adminURI))
  }, [])

  useEffect(() => {
    dispatch(
      actions.fetchDataParams(
        token,
        'LOGS',
        {
          limit: limit,
          offset: match.params.page ? (parseInt(match.params.page) - 1) * limit : 0,
          user: filters.user || undefined,
          action: filters.action !== '' ? filters.action : undefined,
          timestamp_after: filters.startTime,
          timestamp_before: filters.endTime,
          ip: filters.ip !== '' ? filters.ip : undefined
        },
        adminURI
      )
    )
  }, [match.params.page, location.state])

  function handlePageClick(e, index) {
    history.push(`/logs/${index}`, { ...filters })
  }

  function viewPageWithFilters(newFilters) {
    history.push(`/logs/1`, {
      user: newFilters.user !== undefined ? newFilters.user : filters.user,
      action: newFilters.action !== undefined ? newFilters.action : filters.action,
      startTime: newFilters.startTime !== undefined ? newFilters.startTime : filters.startTime,
      endTime: newFilters.endTime !== undefined ? newFilters.endTime : filters.endTime,
      ip: newFilters.ip !== undefined ? newFilters.ip : filters.ip
    })
  }

  return (
    <>
      <ContentHeader title="Logs" />
      <Content>
        <ContainerFluid>
          <Row>
            <Col xl="9">
              <Card>
                <CardHeader className="no-border">
                  <CardTitle>Log List</CardTitle>
                  <CardTools>
                    <LogsFilters filters={location.state} viewPageWithFilters={viewPageWithFilters} />
                    <PaginationPanel
                      currentPage={parseInt(match.params.page) || 1}
                      pagesCount={pagesCount}
                      handlePageClick={handlePageClick}
                    />
                  </CardTools>
                </CardHeader>
                <CardBody className="p-0">
                  <LogList loading={loading} logs={logs} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ContainerFluid>
      </Content>
    </>
  )
}
