import React, { useContext } from 'react'
import { Input, FormFeedback } from 'reactstrap'
import TestSuiteConfigurator from '../../context/TestSuiteConfiguratorContext'

const PasswordInput = ({ props }) => {
  const { option, validator, removeValidationParamsFromMultipleInput, handleBlur, validateInput, ...otherProps } = props
  const { selectedBlock, handleFormChange } = useContext(TestSuiteConfigurator)
  return (
    <>
      <Input
        type="password"
        id={option.name}
        value={(selectedBlock.params && selectedBlock.params[option.name]) || ''}
        name={option.name}
        valid={validator.enabled[option.name] && validator.isValid[option.name] === true}
        invalid={validator.enabled[option.name] && validator.isValid[option.name] === false}
        onChange={e => {
          handleFormChange(e.target.value, option.name, 'params')
          option.attributes.required &&
            validateInput(option.name, 'text', e.target.value, option.label, {
              validators: option.validators
            })
        }}
        onBlur={e => {
          option.attributes.required && handleBlur(e.target.name)
          option.attributes.required &&
            validateInput(option.name, 'text', e.target.value, option.label, {
              validators: option.validators
            })
        }}
        onClick={e => {
          e.stopPropagation()
        }}
        {...otherProps}
      />
      <FormFeedback>{validator.feedback[option.name]}</FormFeedback>
    </>
  )
}

export default PasswordInput
