import React, { useContext, useState } from 'react'
import { Input, Label, Button, ButtonDropdown, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import Switch from 'react-switch'
import DatePicker from 'react-datepicker'
import { convertSecondsToLabel } from '../../utilities/dateFormatting'
import TestSuiteConfigurator from '../../context/TestSuiteConfiguratorContext'

export default function DropdownButton({
  validateDateTimeInput,
  inputValid,
  disabledButton,
  validatorEnabled,
  validatorFeedback,
  setValidatorEnabled
}) {
  const { schedule, setSchedule } = useContext(TestSuiteConfigurator)

  const [dropMenuHidden, setDropMenuHidden] = useState(false)
  const dropdownToggle = () => setDropMenuHidden(!dropMenuHidden)

  function handleChange(e) {
    setSchedule({ ...schedule, enabled: e })
  }

  function setValidateClassName(paramName) {
    let validateClassName = ''
    if (validatorEnabled[paramName] && paramName in inputValid) {
      if (inputValid[paramName]) {
        validateClassName = 'is-valid'
      } else {
        validateClassName = 'is-invalid'
      }
    }
    return validateClassName
  }

  return (
    <Dropdown>
      <ButtonDropdown isOpen={dropMenuHidden} toggle={dropdownToggle} className="block">
        <Button color="success" form="testform" type="submit" disabled={disabledButton}>
          Start
        </Button>
        <DropdownToggle
          caret
          split
          color="success"
          disabled={disabledButton}
          className="dropdown-schedule-toggle"
        ></DropdownToggle>
        <DropdownMenu className="dropdown-schedule-menu">
          <DropdownItem header>
            <label className="sw_label">
              <span>Monitoring mode</span>
              <Switch
                onChange={() => {
                  handleChange(!schedule.enabled)
                }}
                checked={schedule.enabled}
                onColor="#86d3ff"
                onHandleColor="#2693e6"
                handleDiameter={20}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={16}
                width={36}
                className="react-switch"
                id="material-switch"
                aria-labelledby="neat-label"
              />
            </label>
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem header>
            <Label for="startDateMonitoring">Start Time:</Label>
            {/* start time field */}
            {schedule.enabled ? (
              <>
                <DatePicker
                  name="startDateMonitoring"
                  selected={schedule.start_monitoring_date}
                  onChange={e => {
                    setSchedule({ ...schedule, start_monitoring_date: e })
                    validateDateTimeInput(e, schedule.end_monitoring_date)
                  }}
                  onBlur={e => {
                    setValidatorEnabled({ ...validatorEnabled, startDateMonitoring: true })
                    validateDateTimeInput(schedule.start_monitoring_date, schedule.end_monitoring_date)
                  }}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  dateFormat="dd.MM.yyyy kk:mm"
                  maxDate={schedule.end_monitoring_date}
                  minTime={new Date(new Date().setHours(0)).setMinutes(0)}
                  maxTime={
                    schedule.end_monitoring_date.getDate() === schedule.start_monitoring_date.getDate()
                      ? new Date(schedule.end_monitoring_date).setMinutes(schedule.end_monitoring_date.getMinutes() - 1)
                      : new Date(new Date().setHours(23)).setMinutes(59)
                  }
                  popperPlacement="bottom-end"
                  onKeyDown={e => {
                    e.preventDefault()
                  }}
                  className={`form-control ${setValidateClassName('startDateMonitoring')}`}
                />
                <small className="text-danger dropdown-warning">{validatorFeedback.startDateMonitoring}</small>
              </>
            ) : (
              <Input id="now" value="now" name="startDateMonitoring" disabled />
            )}
          </DropdownItem>
          <DropdownItem header>
            <Label for="endDateMonitoring">End Time:</Label>
            {/* end time field */}
            {schedule.enabled ? (
              <>
                <DatePicker
                  name="endDateMonitoring"
                  selected={schedule.end_monitoring_date}
                  onChange={e => {
                    setSchedule({ ...schedule, end_monitoring_date: e })
                    validateDateTimeInput(schedule.start_monitoring_date, e)
                  }}
                  onBlur={e => {
                    setValidatorEnabled({ ...validatorEnabled, endDateMonitoring: true })
                    validateDateTimeInput(schedule.start_monitoring_date, schedule.end_monitoring_date)
                  }}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  dateFormat="dd.MM.yyyy kk:mm"
                  minDate={schedule.start_monitoring_date}
                  maxTime={new Date(new Date().setHours(23)).setMinutes(59)}
                  minTime={
                    schedule.end_monitoring_date.getDate() === schedule.start_monitoring_date.getDate()
                      ? new Date(schedule.start_monitoring_date).setMinutes(
                          schedule.start_monitoring_date.getMinutes() + 1
                        )
                      : new Date(new Date().setHours(0)).setMinutes(0)
                  }
                  popperPlacement="bottom-end"
                  onKeyDown={e => {
                    e.preventDefault()
                  }}
                  className={`form-control ${setValidateClassName('endDateMonitoring')}`}
                />
                <small className="text-danger dropdown-warning">{validatorFeedback.endDateMonitoring}</small>
              </>
            ) : (
              <Input id="na" value="n/a" name="endDateMonitoring" disabled />
            )}
          </DropdownItem>
          <DropdownItem header>
            <Label for="repeatInterval">Repeat Interval:</Label>
            {/* repeat interval field */}
            {schedule.enabled ? (
              <Input
                type="select"
                id="repeat_interval"
                name="repeat_interval"
                value={schedule.repeat_interval}
                disabled={!schedule.enabled}
                onChange={e => {
                  setSchedule({
                    ...schedule,
                    repeat_interval: parseInt(e.target.value)
                  })
                  schedule.enabled = true
                }}
              >
                {[
                  60,
                  60 * 5,
                  60 * 10,
                  60 * 15,
                  60 * 30,
                  60 * 60,
                  60 * 60 * 2,
                  60 * 60 * 6,
                  60 * 60 * 12,
                  60 * 60 * 24,
                  60 * 60 * 24 * 7
                ].map(secs => (
                  <option key={secs} value={secs}>
                    {convertSecondsToLabel(secs)}
                  </option>
                ))}
              </Input>
            ) : (
              <Input id="repeat_interval_na" value="n/a" disabled />
            )}
          </DropdownItem>
          {/**    ENABLE NOTIFICATIONS CHECKBOX
            <div className="enable-notif-checkbox">
            <Label check>
            <Input type="checkbox" checked={notificationsEnabled} onChange={() => {setNotificationsEnabled(!notificationsEnabled)}}/> Enable notifications
            </Label>
          </div>*/}
        </DropdownMenu>
      </ButtonDropdown>
    </Dropdown>
  )
}
