import React from 'react'
import Moment from 'react-moment'
import { Table } from 'reactstrap'
import { convertDigitalValueToLabel } from '../../../../utilities/stringFormatting'

export default function ResultOTTStress({ result }) {
  return (
    <div>
      {result.succeeded ? (
        <Table responsive size="sm">
          <thead>
            <tr>
              <th>Chunk Rate</th>
              <th>Succeeded</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{Math.round(result.chunk_rate * 1000) / 1000}</td>
              <td style={{ color: result.succeeded ? 'green' : 'red' }}>{result.succeeded ? 'PASS' : 'FAILED'}</td>
            </tr>
          </tbody>
        </Table>
      ) : (
        <Table responsive size="sm">
          <thead>
            <tr>
              <th>Succeeded</th>
              <th>Failure reason</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ color: result.succeeded ? 'green' : 'red' }}>{result.succeeded ? 'PASS' : 'FAILED'}</td>
              <td>{result.reason}</td>
            </tr>
          </tbody>
        </Table>
      )}
      {result.succeeded && (
        <Table responsive size="sm">
          <thead>
            <tr>
              <th>Chunk url</th>
              <th>Started</th>
              <th>Size</th>
              <th>Duration</th>
              <th>Download Time</th>
              <th>Response Time</th>
              <th>Total Load</th>
              <th>Downloaded ratio</th>
            </tr>
          </thead>
          <tbody>
            {result.details.map((item, index) => (
              <tr key={index}>
                <td>{item.uri}</td>
                <td>
                  <Moment format="DD-MM-YYYY HH:mm:ss">{item.timestamp}</Moment>
                </td>
                <td>{item.size ? convertDigitalValueToLabel(Math.round(item.size * 100) / 100) : '-'}</td>
                <td>{item.duration ? `${Math.round(item.duration * 1000) / 1000} s` : '-'}</td>
                <td>{item.time ? `${Math.round(item.time * 1000) / 1000} s` : '-'}</td>
                <td>{item.response_time ? `${Math.round(item.response_time * 10000) / 10} ms` : '-'}</td>
                <td>{item.total_load ? `${Math.round(item.total_load * 8 / 100000) / 10} Mb/s` : '-'}</td>
                <td>{item.downloaded_ratio ? Math.round(item.downloaded_ratio * 1000) / 1000 : '-'}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  )
}
