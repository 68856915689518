import React from 'react'
import { Table } from 'reactstrap'
import { HorizontalBar } from 'react-chartjs-2'

export default function ResultPing({ result }) {
  return (
        <div key={result}>
          <HorizontalBar
            data={{
              labels: ['Min RTT', 'Mean RTT', 'Max RTT'],
              datasets: [
                {
                  backgroundColor: ['#25ba27', '#36a2eb', '#ff6384'],
                  data: [result.min_rtt, result.mean_rtt, result.max_rtt]
                }
              ]
            }}
            options={{
              scales: {
                xAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                      callback: function(value) {
                        return value + 'ms'
                      }
                    }
                  }
                ]
              },
              legend: {
                display: false
              }
            }}
            height={30}
          />
          <Table responsive size="sm">
            <thead>
              <tr>
                <th>ICMP Sequence Number</th>
                <th>Result Succeeded</th>
                <th>RTT</th>
                <th>Failure Reason</th>
              </tr>
            </thead>
            <tbody>
              {result.list.map((item, index) => (
                <tr key={index}>
                  <td>{index}</td>
                  <td>{item.succeeded.toString()}</td>
                  <td>{item.rtt ? `${item.rtt} ms` : '-'}</td>
                  <td>{item.reason || '-'}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
  )
}
