import React, { useState } from 'react'
import { Form, Formik, ErrorMessage, Field } from 'formik'
import {
  FormGroup,
  Input,
  Label,
  CustomInput,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Table
} from 'reactstrap'
import classnames from 'classnames'
import * as Yup from 'yup'
import { useSelector } from 'react-redux'

const tabs = ['Locations', 'Tests', 'Others']

export const PermissionsTable = ({ group, permissions, onPermissionChange, perm_category }) => {
  return (
    <Table size="sm">
      <thead>
        <tr>
          <th>Name</th>
          <th>Category</th>
          <th style={{ textAlign: 'center' }}>Allowed</th>
        </tr>
      </thead>
      <tbody>
        {permissions.map(permission => (
          <tr key={permission.id}>
            <td>{permission.name}</td>
            <td>{perm_category}</td>
            <td style={{ textAlign: 'center' }}>
              <CustomInput
                type="checkbox"
                id={permission.id}
                checked={group.permission_ids.includes(permission.id)}
                onChange={e => {
                  onPermissionChange(permission.id, e.target.checked)
                }}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default function GroupEditForm({ groupProp, onDataUpdated }) {
  const permissions = useSelector(state => state.permissions.data)
  const [group, setGroup] = useState(groupProp)
  const [activeTab, setActiveTab] = useState(1)

  function onPermissionChange(id, value) {
    let _group = { ...group }
    if (value && !_group.permission_ids.includes(id)) {
      _group.permission_ids.push(id)
    } else if (!value) {
      let index = _group.permission_ids.indexOf(id)
      if (index > -1) {
        _group.permission_ids.splice(index, 1)
      }
    }
    setGroup(_group)
  }

  function onCheckAllChange(value) {
    let _group = { ...group }
    if (value) {
      _group.permission_ids = permissions.map(permission => {
        return permission.id
      })
    } else {
      _group.permission_ids = []
    }
    setGroup(_group)
  }

  function onFormSubmit(values) {
    const updatedGroup = {
      ...group,
      permissions: permissions.filter(permission => group.permission_ids.includes(permission.id)),
      ...values
    }
    onDataUpdated(updatedGroup)
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .max(80, '80 characters or fewer')
      .required('Field is required')
  })

  return (
    <Formik
      initialValues={{
        name: group.name
      }}
      onSubmit={onFormSubmit}
      validationSchema={validationSchema}
      validateOnMount={true}
    >
      {({ errors, isValid, touched }) => (
        <Card>
          <Form>
            <CardHeader>
              <CardTitle>Form</CardTitle>
            </CardHeader>
            <CardBody>
              <FormGroup>
                <Label for="name">Group Name</Label>
                <Input
                  name="name"
                  placeholder="Select group name"
                  tag={Field}
                  invalid={touched.name && Boolean(errors.name)}
                />
                <ErrorMessage name="name" component="div" className="invalid-feedback" />
              </FormGroup>
              {permissions && (
                <>
                  <FormGroup>
                    <CustomInput
                      type="checkbox"
                      id="checkall"
                      label="Check/Uncheck all permissions"
                      checked={group.permission_ids.length === permissions.length}
                      onChange={e => {
                        onCheckAllChange(e.target.checked)
                      }}
                    />
                  </FormGroup>
                  <Nav tabs>
                    {tabs.map((tab, index) => (
                      <NavItem key={index}>
                        <NavLink
                          className={classnames({ active: activeTab === index + 1 })}
                          onClick={() => {
                            setActiveTab(index + 1)
                          }}
                        >
                          {tab}
                        </NavLink>
                      </NavItem>
                    ))}
                  </Nav>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId={1}>
                      <PermissionsTable
                        group={group}
                        permissions={permissions.filter(permission => permission.codename.includes('start_location'))}
                        onPermissionChange={onPermissionChange}
                        perm_category="Locations"
                      />
                    </TabPane>
                    <TabPane tabId={2}>
                      <PermissionsTable
                        group={group}
                        permissions={permissions.filter(permission => permission.codename.includes('start_task_type'))}
                        onPermissionChange={onPermissionChange}
                        perm_category="Tests"
                      />
                    </TabPane>
                    <TabPane tabId={3}>
                      <PermissionsTable
                        group={group}
                        permissions={permissions.filter(
                          permission =>
                            !permission.codename.includes('start_location') &&
                            !permission.codename.includes('start_task_type')
                        )}
                        onPermissionChange={onPermissionChange}
                        perm_category="Others"
                      />
                    </TabPane>
                  </TabContent>
                </>
              )}
            </CardBody>
            <CardFooter>
              <Button color="info" className="float-right" type="submit" disabled={!isValid}>
                Apply
              </Button>
            </CardFooter>
          </Form>
        </Card>
      )}
    </Formik>
  )
}
