import React from 'react'
import { FaCloud, FaMicrochip } from 'react-icons/fa'
import { testAgentStatuses } from './statuses'

const locationOptions = (workers, locations) => {
  let locationOptions = []
  if (workers) {
    locationOptions = [
      ...locationOptions,
      ...workers
        .filter(
          worker =>
            testAgentStatuses.some(tAS => tAS.id === worker.status) &&
            testAgentStatuses.find(tAS => tAS.id === worker.status).name === 'ONLINE'
        )
        .map(worker => {
          return {
            label: (
              <>
                <FaMicrochip /> {worker.name} ({worker.ip_local})
              </>
            ),
            value: worker.id,
            type: 'worker'
          }
        })
    ]
  }
  if (locations) {
    locationOptions = [
      ...locationOptions,
      ...locations.map(location => {
        return {
          label: (
            <>
              <FaCloud /> {location.name}
            </>
          ),
          value: location.id,
          type: 'location'
        }
      })
    ]
  }

  return locationOptions
}
export { locationOptions }
