export const convertSecondsToLabel = secs => {
  let label = ''
  let weeks = Math.floor(secs / (3600 * 24 * 7))
  if (weeks > 0) {
    label = weeks + (weeks > 1 ? ' weeks' : ' week')
  } else {
    let days = Math.floor(secs / (3600 * 24))
    if (days > 0) {
      label = days + (days > 1 ? ' days' : ' day')
    } else {
      let hours = Math.floor(secs / 3600)
      if (hours > 0) {
        label = hours + (hours > 1 ? ' hours' : ' hour')
      } else {
        let minutes = Math.floor(secs / 60)
        if (minutes > 0) {
          label = minutes + (minutes > 1 ? ' minutes' : ' minute')
        } else {
          label = secs + (secs > 1 ? ' seconds' : ' second')
        }
      }
    }
  }
  return label
}

export function toTimestamp(strDate) {
  var datum = Date.parse(strDate)
  return Math.round(datum)
}
