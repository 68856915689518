import React from 'react'
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap'
import { useSelector } from 'react-redux'
import { testAgentStatuses } from '../../utilities/statuses'

export default function WorkersSummary() {
  const workers = useSelector(state => state.workers.data)

  return (
    <Card className="h-100 top-line-success mb-4">
      <CardHeader>
        <CardTitle>Summary</CardTitle>
      </CardHeader>
      <CardBody className=" bg-green">
        {workers && (
          <div className="pad box-pane-right bg-green">
            <div className="description-block margin-bottom">
              <h5 className="description-header">{workers.length}</h5>
              <span className="description-text">TOTAL</span>
            </div>
            <hr />
            <div className="description-block margin-bottom">
              <h5 className="description-header">
                {
                  workers.filter(
                    worker =>
                      testAgentStatuses.some(tAS => tAS.id === worker.status) &&
                      testAgentStatuses.find(tAS => tAS.id === worker.status).name === 'ONLINE'
                  ).length
                }
              </h5>
              <span className="description-text">ONLINE</span>
            </div>
            <div className="description-block margin-bottom">
              <h5 className="description-header">
                {
                  workers.filter(
                    worker =>
                      !testAgentStatuses.some(tAS => tAS.id === worker.status) ||
                      testAgentStatuses.find(tAS => tAS.id === worker.status).name !== 'ONLINE'
                  ).length
                }
              </h5>
              <span className="description-text">OFFLINE</span>
            </div>
            <hr />
            <div className="description-block margin-bottom">
              <h5 className="description-header">
                {workers.filter(worker => worker.running_monitoring_count > 0).length}
              </h5>
              <span className="description-text">MONITORING</span>
            </div>
            <div className="description-block margin-bottom">
              <h5 className="description-header">
                {workers.filter(worker => worker.running_monitoring_count === 0).length}
              </h5>
              <span className="description-text">NON-MONITORING</span>
            </div>
          </div>
        )}
      </CardBody>
    </Card>
  )
}
