import React, { useContext, useEffect, useRef } from 'react'
import { Input, FormFeedback } from 'reactstrap'
import { intersection } from '../../utilities/mathOperations'
import { TestAgentsContext } from '../../context/TestAgentsContext'
import TestSuiteConfigurator from '../../context/TestSuiteConfiguratorContext'
import { usePrevious } from '../../utilities/customHooks'

export default function TextInterfaceInput({ props }) {
  const { option, validator, removeValidationParamsFromMultipleInput, handleBlur, validateInput, ...otherProps } = props
  const { selectedBlock, handleFormChange } = useContext(TestSuiteConfigurator)

  const inputInterfaceRef = useRef()
  const { testAgents } = useContext(TestAgentsContext)
  const prevSelectedBlock = usePrevious(selectedBlock)

  useEffect(() => {
    if (inputInterfaceRef && inputInterfaceRef.current && prevSelectedBlock) {
      let interfaces = getInterfaces()
      if (!interfaces.includes(selectedBlock.params && selectedBlock.params[option.name])) {
        handleFormChange('', option.name, 'params')
        option.attributes.required &&
          validateInput(option.name, 'text', '', option.label, {
            validators: option.validators
          })
      }
    }
  }, [selectedBlock.locations])

  function getInterfaces() {
    const testLocations =
      selectedBlock.locations &&
      selectedBlock.locations.filter(location => location.type === 'worker').map(location => location.value)
    const selectedTA =
      testAgents && testLocations && testAgents.filter(testAgent => testLocations.includes(testAgent.id))
    let listOfInterfaces = []
    selectedTA &&
      selectedTA.forEach(
        ta =>
          ta.additional_data &&
          ta.additional_data.interfaces &&
          listOfInterfaces.push(Object.keys(ta.additional_data.interfaces))
      )
    return intersection(listOfInterfaces)
  }

  let interfacesOptions = getInterfaces()

  return (
    <>
      <Input
        type="select"
        id={option.name}
        name={option.name}
        valid={validator.enabled[option.name] && validator.isValid[option.name] === true}
        invalid={validator.enabled[option.name] && validator.isValid[option.name] === false}
        value={(selectedBlock.params && selectedBlock.params[option.name]) || ''}
        onChange={e => {
          handleFormChange(e.target.value, option.name, 'params')
          option.attributes.required &&
            validateInput(option.name, 'text', e.target.value, option.label, {
              validators: option.validators
            })
        }}
        onBlur={e => {
          option.attributes.required && handleBlur(e.target.name)
          option.attributes.required &&
            validateInput(option.name, 'text', e.target.value, option.label, {
              validators: option.validators
            })
        }}
        onClick={e => {
          e.stopPropagation()
        }}
        ref={inputInterfaceRef}
        {...otherProps}
      >
        <option value=""></option>
        {interfacesOptions.map(interf => (
          <option key={interf} value={interf}>
            {interf}
          </option>
        ))}
      </Input>
      <FormFeedback>
        {validator.feedback[option.name]}{' '}
        {selectedBlock.locations && interfacesOptions.length === 0
          ? 'There are no common interfaces for selected test agents. Please consider divide this test block into many blocks.'
          : ''}
      </FormFeedback>
    </>
  )
}
