import React, { createContext, useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'
import * as actions from '../store/actions'
import WSProvider from './WSProvider'
import { compareValues } from '../utilities/mathOperations'

export const NotificationsContext = createContext({})

export default function NotificationsProvider({ children }) {
  const dispatch = useDispatch()
  const limit = 20
  const token = useSelector(state => state.auth.token)
  const notificationsApi = useSelector(state => state.notifications.data)
  const notificationsApiParams = useSelector(state => state.notifications.params)
  const lastSeenNotifications = useSelector(state => state.last_seen_notifications.data)
  const notificationsOpenPanelsCounter = useSelector(state => state.notifications_open_panels_counter.data)
  const notificationsTypes = useSelector(state => state.notifications_types.data)
  const [notifications, setNotifications] = useState([])
  const ws = useRef(null)
  const [wsActive, setWsActive] = useState(false)
  const ws_scheme = process.env.REACT_APP_HTTPS ? 'wss' : 'ws'

  useEffect(() => {
    if (notificationsApi) {
      ws.current = new WebSocket(`${ws_scheme}://${window.location.host}/ws/notifications/?_token=${token}`)
      if (notifications.length > 0) {
        if (notificationsApiParams.offset > 0) {
          setNotifications(notifications.concat(notificationsApi))
        } else {
          setNotifications(notificationsApi)
        }
      } else {
        setNotifications(notificationsApi)
      }
    }
  }, [notificationsApi])

  useEffect(() => {
    if (lastSeenNotifications && lastSeenNotifications.length > 0) {
      lastSeenNotifications.forEach(lsNotif => {
        let _notifications = [...notifications]
        let notif = _notifications.find(notif => notif.id === lsNotif.id)
        if (notif) {
          notif.seen = true
          setNotifications(_notifications)
        }
      })
    }
  }, [lastSeenNotifications])

  useEffect(() => {
    if (!notificationsOpenPanelsCounter) {
      setNotifications(notifications.slice(0, limit))
    }
  }, [notificationsOpenPanelsCounter])

  useEffect(() => {
    if (ws && ws.current) {
      setWsActive(true)
      ws.current.onmessage = evt => {
        // listen to data sent from the websocket server
        const message = JSON.parse(evt.data)
        if (!notifications.some(notification => notification.id === message.id)) {
          dispatch(actions.incrementUnseenNotificationsCount())
        }
        setNotifications(prev => {
          if (prev.some(notification => notification.id === message.id)) {
            return prev.map(notification => {
              return notification.id === message.id ? message : notification
            })
          } else {
            const severityArray =
              notificationsApiParams &&
              notificationsApiParams.severity &&
              notificationsApiParams.severity
                .toString()
                .replace(/'/g, '')
                .split(/(\d+)/)
                .filter(Boolean)
            if (
              !notificationsApiParams ||
              ((!notificationsApiParams.type ||
                (notificationsApiParams.type &&
                  notificationsApiParams.type
                    .split('|')
                    .map(type => parseInt(type))
                    .includes(message.type))) &&
                (notificationsApiParams.severity === undefined ||
                  (severityArray.length <= 1 && message.severity === notificationsApiParams.severity) ||
                  (severityArray.length > 1 && compareValues(message.severity, severityArray[0], severityArray[1]))))
            ) {
              if (!notificationsOpenPanelsCounter) {
                return [message, ...prev.slice(0, limit - 1)]
              } else {
                return [message, ...prev]
              }
            } else {
              return prev
            }
          }
        })
        const notifTypeBackendError =
          notificationsTypes && notificationsTypes.find(notifType => notifType.name === 'BACKEND_ERROR')
        if (
          message.type === (notifTypeBackendError && notifTypeBackendError.id) &&
          message.additional_data &&
          message.additional_data.text
        ) {
          toast.error(`Backend error: ${message.additional_data.text}`)
        }
      }
    }
  }, [ws && ws.current, notificationsOpenPanelsCounter, notificationsApiParams, notificationsTypes])

  return (
    <>
      <ToastContainer position="top-right" newestOnTop />
      {ws && wsActive && ws.current && (
        <WSProvider ws={ws} contextName="notifications" storeKeyName="notifications">
          <NotificationsContext.Provider
            value={{
              notifications: notifications
            }}
          >
            {children}
          </NotificationsContext.Provider>
        </WSProvider>
      )}
    </>
  )
}
