import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'

export const ProtectedRoute = ({ path, component: Component, render, ...rest }) => {
  const isAdmin = useSelector(state => state.auth.is_superuser)
  return (
    <Route
      path={path}
      {...rest}
      render={props => {
        if (isAdmin) {
          return Component ? <Component {...props} /> : render(props)
        } else {
          return <Redirect to="/dashboard" />
        }
      }}
    />
  )
}

export default ProtectedRoute
