import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Col, Row, Tooltip, Button } from 'reactstrap'
import { GrafanaFrame } from '../../../../containers'
import { FaSearchMinus } from 'react-icons/fa'
import { toTimestamp } from '../../../../utilities/dateFormatting'
import { TestSuiteContext } from '../../../../context/TestSuiteContext'
import { testSuiteStatuses, taskStatuses } from '../../../../utilities/statuses'
import { sortBy } from '../../../../utilities/mathOperations'
import { ResultsContext } from '../../../../context/ResultsContext'
import TestSuiteInResults from '../../../../context/TestSuiteInResultsContext'

export default function TimeChart({ panel_id, param, style }) {
  const locations = useSelector(state => state.locations.data)
  const workers = useSelector(state => state.workers.data)
  const { resultsWS } = useContext(ResultsContext)
  const resultsApi = useSelector(state => state.results.data)
  const resultsToCharts = useSelector(state => state.results_to_charts.data)
  let resultsToChart = sortBy(resultsToCharts, 'timestamp_updated')

  const { selectedBlock } = useContext(TestSuiteInResults)
  const { testSuite } = useContext(TestSuiteContext)
  const testSuiteStatus = testSuite && testSuiteStatuses.find(tSS => tSS.id === testSuite.status)
  const finished = testSuiteStatus && testSuiteStatus.name === 'FINISHED'
  const monitoring = testSuite && testSuite.repeat_interval
  const [timerange, setTimerange] = useState({ from: null, to: null })

  const defaultStyle = { width: '100%', height: '400px', marginLeft: '5px', marginRight: '5px', paddingRight: '5px' }
  const chartStyle = { ...defaultStyle, ...style }

  const [tooltipOpen, setTooltipOpen] = useState(false)
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const timerangeValues = [
    { display_name: 'All results', from: timerange.from, to: !finished ? 'now' : timerange.to },
    { display_name: 'Last 15 min', from: 'now-15m', to: 'now' },
    { display_name: 'Last 30 min', from: 'now-30m', to: 'now' },
    { display_name: 'Last 1 hour', from: 'now-1h', to: 'now' },
    { display_name: 'Last 3 hours', from: 'now-3h', to: 'now' },
    { display_name: 'Last 12 hours', from: 'now-12h', to: 'now' },
    { display_name: 'Last 24 hours', from: 'now-24h', to: 'now' },
    { display_name: 'Last 3 days', from: 'now-3d', to: 'now' },
    { display_name: 'Last week', from: 'now-7d', to: 'now' }
  ]

  const [selectedTimerange, setSelectedTimerange] = useState(
    monitoring && !finished ? timerangeValues[1] : timerangeValues[0]
  )

  const [rerenderGraphCounter, setRerenderGraphCounter] = useState(1)

  useEffect(() => {
    if (resultsToChart && resultsToChart.length > 1) {
      if (monitoring) {
        const timerangeMonitoring = {
          from: resultsToChart.length > 0 && toTimestamp(resultsToChart[0].timestamp_updated) - 5000,
          to: resultsToChart.length > 1 && toTimestamp(resultsToChart[1].timestamp_updated) + 5000
        }
        setTimerange(timerangeMonitoring)
        setSelectedTimerange(
          monitoring && !finished
            ? timerangeValues[1]
            : { display_name: 'All results', from: timerangeMonitoring.from, to: timerangeMonitoring.to }
        )
      } else {
        switch (selectedBlock.task_type_name) {
          case 'ott_stress_test':
            const timerangeOTTStress = {
              from:
                resultsToChart.length > 0 &&
                resultsToChart[0].result &&
                resultsToChart[0].result.result &&
                toTimestamp(resultsToChart[0].result.result.details[0].timestamp) - 5000,
              to:
                resultsToChart.length > 1 &&
                resultsToChart[1].result &&
                resultsToChart[1].result.result &&
                toTimestamp(
                  resultsToChart[1].result.result.details[resultsToChart[1].result.result.details.length - 1].timestamp
                ) + 5000
            }
            setTimerange(timerangeOTTStress)
            setSelectedTimerange({
              display_name: 'All results',
              from: timerangeOTTStress.from,
              to: timerangeOTTStress.to
            })
            break
          case 'rfc6349_test':
            const statusFinishedId =
              taskStatuses &&
              taskStatuses.some(tS => tS.name === 'FINISHED') &&
              taskStatuses.find(tS => tS.name === 'FINISHED').id
            const result =
              resultsApi &&
              resultsWS
                .concat(resultsApi)
                .find(
                  result =>
                    result.test_block === selectedBlock.id &&
                    result.status === statusFinishedId &&
                    result.result &&
                    result.result.result &&
                    result.result.result.forward
                )
            if (param === 'uplink') {
              const timerangeRFCuplink = {
                from: result && result.result.result.forward.summary.stream_data[0].timestamp * 1000 - 1000,
                to:
                  result &&
                  result.result.result.forward.summary.stream_data[
                    result.result.result.forward.summary.stream_data.length - 1
                  ].timestamp *
                    1000 +
                    1000
              }
              setTimerange(timerangeRFCuplink)
              setSelectedTimerange({
                display_name: 'All results',
                from: timerangeRFCuplink.from,
                to: timerangeRFCuplink.to
              })
            } else if (param === 'downlink') {
              const timerangeRFCdownlink = {
                from: result && result.result.result.reverse.summary.stream_data[0].timestamp * 1000 - 1000,
                to:
                  result &&
                  result.result.result.reverse.summary.stream_data[
                    result.result.result.reverse.summary.stream_data.length - 1
                  ].timestamp *
                    1000 +
                    1000
              }
              setTimerange(timerangeRFCdownlink)
              setSelectedTimerange({
                display_name: 'All results',
                from: timerangeRFCdownlink.from,
                to: timerangeRFCdownlink.to
              })
            }
            break
          case 'hls_test':
            const timerangeOTT = {
              from:
                resultsToChart.length > 0 &&
                resultsToChart[0].result &&
                toTimestamp(resultsToChart[0].result.result.details[0].timestamp) - 1000,
              to:
                resultsToChart.length > 0 &&
                resultsToChart[0].result &&
                toTimestamp(
                  resultsToChart[0].result.result.details[resultsToChart[0].result.result.details.length - 1].timestamp
                ) + 1000
            }
            setTimerange(timerangeOTT)
            setSelectedTimerange(
              monitoring && !finished
                ? timerangeValues[1]
                : { display_name: 'All results', from: timerangeOTT.from, to: timerangeOTT.to }
            )
            break
          default:
            break
        }
      }
    }
  }, [resultsToCharts])

  return workers && locations ? (
    <>
      <div className="float-right">
        <Col>
          <Row>
            <Button
              color="info"
              id="zoom-out-button"
              style={{ marginRight: '5px' }}
              className="clickable"
              onClick={
                () => setRerenderGraphCounter(prev => prev + 1) //rerender GrafanaFrame after click this button
              }
            >
              <FaSearchMinus />
              <Tooltip
                placement="auto"
                isOpen={tooltipOpen}
                target="zoom-out-button"
                toggle={() => setTooltipOpen(prev => !prev)}
              >
                Time range zoom out Ctrl+Z
              </Tooltip>
            </Button>
            {!finished && (
              <Dropdown isOpen={dropdownOpen} toggle={() => setDropdownOpen(prevState => !prevState)}>
                <DropdownToggle caret color="info">
                  {selectedTimerange.display_name}
                </DropdownToggle>
                <DropdownMenu right>
                  {timerangeValues.map(value => (
                    <DropdownItem key={value.from} onClick={() => setSelectedTimerange(value)}>
                      {value.display_name}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            )}
          </Row>
        </Col>
      </div>
      <GrafanaFrame
        panel_id={panel_id} // panelId from Grafana, defines the chart | render different chart if test featured more than one TestAgent
        test_block_id={selectedBlock.id}
        selectedTimerange={selectedTimerange}
        refreshFlag={!finished}
        style={chartStyle}
        title={'vutp-grafana'}
        rerenderGraphCounter={rerenderGraphCounter} // set only for rerender frame
      />
    </>
  ) : (
    'No chart'
  )
}
