import React from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Table } from 'reactstrap'
import { FaInfo } from 'react-icons/fa'
import Moment from 'react-moment'
import { Spinner } from '../../components'

const LogList = ({ loading, logs }) => {
  const detailsLink = log => {
    switch (log.object_type) {
      case 'testsuite':
        if (log.object_id) {
          return `/results/details/${log.object_id}`
        } else {
          return '/results'
        }
      case 'vutpuser':
        if (log.object_id) {
          return `/users/edit/${log.object_id}`
        } else {
          return '/users'
        }
      case 'testagent':
        if (log.object_id) {
          return `/test-agents/${log.object_id}`
        } else {
          return 'test-agents'
        }
      default:
        return '/logs'
    }
  }
  return (
    <Table className="table-valign-middle" striped>
      <thead>
        <tr>
          <th>ID</th>
          <th>Timestamp</th>
          <th>User</th>
          <th>Action</th>
          <th>Details</th>
        </tr>
      </thead>
      <tbody>
        {loading ? (
          <Spinner columns={5} />
        ) : (
          logs &&
          logs.map(log => (
            <tr key={log.id}>
              <td>{log.id}</td>
              <td>
                <Moment format="DD-MM-YYYY HH:mm:ss">{log.timestamp}</Moment>
              </td>
              <td>{log.user}</td>
              <td>
                <NavLink to={detailsLink(log)}>{log.action}</NavLink>
              </td>
              <td>
                <NavLink to={`/logs/details/${log.id}`}>
                  <FaInfo />
                </NavLink>
              </td>
            </tr>
          ))
        )}
      </tbody>
    </Table>
  )
}

LogList.propTypes = {
  loading: PropTypes.bool.isRequired,
  logs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      timestamp: PropTypes.string.isRequired,
      user: PropTypes.string.isRequired,
      action: PropTypes.string.isRequired,
      object_type: PropTypes.string.isRequired,
      object_id: PropTypes.number,
      data: PropTypes.object.isRequired
    })
  )
}

export default LogList
