import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, CardBody, CardHeader, CardFooter, CardTitle, Button, FormGroup, Input, Label } from 'reactstrap'
import { Map, IPInput } from '../../components'
import * as actions from '../../store/actions'
import { Marker, Popup } from 'react-leaflet'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { ipRegex } from '../../utilities/regularExpressions'

export default function TestAgentEdit({ testAgent, submitForm }) {
  const dispatch = useDispatch()
  const token = useSelector(state => state.auth.token)

  const [worker, setWorker] = useState(null)
  const [mapZoom, setMapZoom] = useState(5)

  useEffect(() => {
    if (!worker) {
      setWorker(testAgent)
    }
  }, [testAgent])

  const validationSchema = Yup.object().shape({
    geo_location_lat: Yup.number()
      .max(90, 'Value must be less than 90')
      .min(-90, 'Value must be greater than -90'),
    geo_location_lon: Yup.number()
      .max(180, 'Value must be less than 180')
      .min(-180, 'Value must be greater than -180'),
    ip_external: Yup.string().matches(ipRegex, 'Invalid IP Address')
  })

  function onFormSubmit(values) {
    const updatedWorker = {
      ...worker,
      ...values
    }
    dispatch(actions.updateData(token, 'TEST_AGENTS', testAgent.id, updatedWorker, true))
    submitForm()
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        name: (worker && worker.name) || '',
        ip_external: (worker && worker.ip_external) || '',
        geo_location_lat: (worker && worker.geo_location_lat) || '',
        geo_location_lon: (worker && worker.geo_location_lon) || '',
        authorized: (worker && worker.authorized) || false
      }}
      onSubmit={onFormSubmit}
      validationSchema={validationSchema}
      validateOnMount={true}
    >
      {({ errors, isValid, touched, values, handleChange, handleBlur, setValues }) => (
        <Card className="top-line-info" style={{ height: '98%' }}>
          <Form>
            <CardHeader className="no-border">
              <CardTitle>Test Agent Update</CardTitle>
            </CardHeader>
            <CardBody>
              <FormGroup>
                <Label>Name:</Label>
                <Input name="name" tag={Field} />
              </FormGroup>
              <FormGroup>
                <Label>External IP Address:</Label>
                <IPInput
                  name="ip_external"
                  value={values.ip_external}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={touched.ip_external && Boolean(errors.ip_external)}
                />
                <ErrorMessage name="ip_external" component="div" className="invalid-feedback" />
              </FormGroup>
              <Label style={{ paddingRight: '25px' }}>Authorized:</Label>
              <Input name="authorized" type="checkbox" checked={values.authorized} tag={Field} />
              <FormGroup>
                <Label>Geo location:</Label>
                <div>
                  <Map
                    center={
                      values.geo_location_lat && values.geo_location_lon
                        ? [values.geo_location_lat, values.geo_location_lon]
                        : [50, 20]
                    }
                    width={'300px'}
                    height={'200px'}
                    zoom={mapZoom}
                    attributionControl
                    zoomControl
                    doubleClickZoom
                    scrollWheelZoom
                    dragging
                    onClick={e => {
                      setValues({
                        ...values,
                        geo_location_lat: e.latlng.lat,
                        geo_location_lon: e.latlng.lng
                      })
                    }}
                    onZoom={e => setMapZoom(e.target.zoom)}
                  >
                    {values.geo_location_lat && values.geo_location_lon && (
                      <Marker
                        position={[values.geo_location_lat, values.geo_location_lon]}
                        draggable
                        onDragEnd={e => {
                          setValues({
                            ...values,
                            geo_location_lon: e.target._latlng.lng,
                            geo_location_lat: e.target._latlng.lat
                          })
                        }}
                      >
                        <Popup>{values.name}</Popup>
                      </Marker>
                    )}
                  </Map>
                </div>
              </FormGroup>
              <FormGroup>
                <Label>Geo location latitude:</Label>
                <Input
                  name="geo_location_lat"
                  type="number"
                  tag={Field}
                  invalid={touched.geo_location_lat && Boolean(errors.geo_location_lat)}
                  step="any"
                />
                <ErrorMessage name="geo_location_lat" component="div" className="invalid-feedback" />
              </FormGroup>
              <FormGroup>
                <Label>Geo location longitude:</Label>
                <Input
                  name="geo_location_lon"
                  type="number"
                  tag={Field}
                  invalid={touched.geo_location_lon && Boolean(errors.geo_location_lon)}
                  step="any"
                />
                <ErrorMessage name="geo_location_lon" component="div" className="invalid-feedback" />
              </FormGroup>
              <small>Change test agent parameters from list above and click 'Update' button</small>
            </CardBody>
            <CardFooter>
              <Button color="info" className="float-right" type="submit" disabled={!isValid}>
                Update
              </Button>
            </CardFooter>
          </Form>
        </Card>
      )}
    </Formik>
  )
}
