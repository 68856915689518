import React from 'react'
import { Table } from 'reactstrap'

export default function ResultHTTPSLatency({ result }) {
  return (
    <div>
      <Table responsive size="sm">
        <thead>
          <tr>
            <th>URL</th>
            <th>Verify Certificate</th>
            <th>Succeeded</th>
            <th>Response Code</th>
            <th>Time</th>
          </tr>
        </thead>
        <tbody>
          {result.map(value => (
            <tr key={value.url}>
              <td>{value.url}</td>
              <td>-</td>
              <td>{value.succeeded.toString()}</td>
              <td>{value.code}</td>
              <td>{value.time_ms ? (Math.round(value.time_ms * 1000) / 1000 + ' ms') : '-'}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}
