import React, { useEffect, useState } from 'react'
import { Switch, Route, Redirect, withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from './store/actions'
import {
  Dashboard,
  Map,
  Results,
  ResultDetails,
  TestAgents,
  TestAgentUpdate,
  Account,
  ChangePassword,
  SMTPServerConfiguration,
  Notifications,
  DeliveryConfigEdit,
  Test,
  Presets,
  Users,
  UserEdit,
  Groups,
  GroupEdit,
  Policies,
  PolicyEdit,
  Subscriptions,
  SubscriptionEdit,
  CloudConfigs,
  CloudConfigEdit,
  Logs,
  LogDetails,
  Updates,
  UpdateEdit
} from './pages'
import { Auth, Logout, ProtectedRoute, Layout } from './containers'
import NotificationsProvider from './context/NotificationsContext'
import TestAgentsProvider from './context/TestAgentsContext'
import { commonURI } from './api/endpoints'

function App({ location }) {
  const dispatch = useDispatch()
  const token = useSelector(state => state.auth.token)
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated)
  const [lastLocation, setLastLocation] = useState('/dashboard')

  useEffect(() => {
    dispatch(actions.authCheckState())
  }, [])

  useEffect(() => {
    if (token) {
      dispatch(actions.fetchData(token, 'TEST_AGENTS'))
      dispatch(actions.fetchData(token, 'LOCATIONS'))
      dispatch(actions.fetchData(token, 'ACCOUNT'))
      dispatch(actions.fetchData(token, 'TASK_TYPES', commonURI))
      dispatch(actions.fetchData(token, 'NOTIFICATION_TYPES', commonURI))
      dispatch(actions.fetchDataParams(token, 'NOTIFICATIONS', { limit: 20, offset: 0, severity: '>=0' }))
      dispatch(actions.fetchData(token, 'ENABLED_NOTIFICATIONS'))
      dispatch(actions.fetchData(token, 'NOTIFICATION_DELIVERY_TYPES', commonURI))
      dispatch(actions.fetchData(token, 'NOTIFICATION_DELIVERY_CONFIGS'))
      dispatch(actions.fetchUnseenNotifications(token))
      dispatch(actions.fetchCategories(token))
    }
  }, [token])

  useEffect(() => {
    if (location.pathname !== '/logout' && location.pathname !== '/auth') {
      setLastLocation(location.pathname)
    }
  }, [location.pathname])

  let routes = null
  switch (isAuthenticated) {
    case true:
      routes = (
        <NotificationsProvider>
          <TestAgentsProvider>
            <Layout>
              <Switch>
                <Route path="/dashboard" exact component={Dashboard} />
                <Route path="/map" exact component={Map} />
                <Route path="/test-suite" exact component={Test} />
                <Route path="/presets" exact component={Presets} />
                <Route path="/results" exact component={Results} />
                <Route path="/results/details/:id" exact component={ResultDetails} />
                <Route path="/results/:page" exact component={Results} />
                <Route path="/test-agents" exact component={TestAgents} />
                <Route path="/test-agents/:id" exact component={TestAgentUpdate} />
                <Route path="/account" exact component={Account} />
                <Route path="/account/change_password" exact component={ChangePassword} />
                <Route path="/notifications/smtp_server_configuration" exact component={SMTPServerConfiguration} />
                <Route path="/notifications" exact component={Notifications} />
                <Route path="/notifications/delivery_configs/add" component={DeliveryConfigEdit} />
                <Route path="/notifications/delivery_configs/edit/:id" component={DeliveryConfigEdit} />
                <Route path="/logout" exact component={Logout} />
                <ProtectedRoute path="/users" exact component={Users} />
                <ProtectedRoute path="/users/add" component={UserEdit} />
                <ProtectedRoute path="/users/edit/:id" component={UserEdit} />
                <ProtectedRoute path="/groups" exact component={Groups} />
                <ProtectedRoute path="/groups/add" component={GroupEdit} />
                <ProtectedRoute path="/groups/edit/:id" component={GroupEdit} />
                <ProtectedRoute path="/policies" exact component={Policies} />
                <ProtectedRoute path="/policies/add" component={PolicyEdit} />
                <ProtectedRoute path="/policies/edit/:id" component={PolicyEdit} />
                <ProtectedRoute path="/subscriptions" exact component={Subscriptions} />
                <ProtectedRoute path="/subscriptions/add" component={SubscriptionEdit} />
                <ProtectedRoute path="/subscriptions/edit/:id" component={SubscriptionEdit} />
                <ProtectedRoute path="/cloud_configs" exact component={CloudConfigs} />
                <ProtectedRoute path="/cloud_configs/add" component={CloudConfigEdit} />
                <ProtectedRoute path="/cloud_configs/edit/:id" component={CloudConfigEdit} />
                <ProtectedRoute path="/logs" exact component={Logs} />
                <ProtectedRoute path="/logs/:page" exact component={Logs} />
                <ProtectedRoute path="/logs/details/:id" component={LogDetails} />
                <ProtectedRoute path="/updates" exact component={Updates} />
                <ProtectedRoute path="/updates/add" component={UpdateEdit} />
                <ProtectedRoute path="/updates/edit/:id" component={UpdateEdit} />
                <Redirect from="/auth" to={lastLocation} />
                <Redirect to="/dashboard" />
              </Switch>
            </Layout>
          </TestAgentsProvider>
        </NotificationsProvider>
      )
      break
    case false:
      routes = (
        <Switch>
          <Route path="/auth" exact component={Auth} />
          <Redirect to="/auth" />
        </Switch>
      )
      break
    default:
      break
  }
  return routes
}

export default withRouter(App)
