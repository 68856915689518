import React from 'react'
import { Table } from 'reactstrap'
import { convertDigitalValueToLabel } from '../../../../utilities/stringFormatting'

export default function ResultFTPDownload({ result }) {
  return (
    <div>
      <Table responsive size="sm">
        <thead>
          <tr>
            <th>Succeeded</th>
            <th>Filesize</th>
            <th>Time</th>
            <th>Bandwidth</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{result.succeeded.toString()}</td>
            <td>{result.file_size_B ? convertDigitalValueToLabel(result.file_size_B, 'B') : '-'}</td>
            <td>{result.time_ms ? `${Math.round(result.time_ms * 100) / 100} ms` : '-'}</td>
            <td>{result.bandwidth_MiB_s ? `${Math.round(result.bandwidth_MiB_s * 8 * 100) / 100} MiB/s` : '-'}</td>
          </tr>
        </tbody>
      </Table>
    </div>
  )
}
