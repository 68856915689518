import React from 'react'
import { Form, Formik, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import {
  FormGroup,
  Label,
  Input,
  Col,
  Row,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter,
  Button
} from 'reactstrap'

export default function PolicyEditForm({ policy, onDataUpdated }) {
  const constraints = [
    { paramName: 'maximum_tests', label: 'Maximum Tests' },
    { paramName: 'maximum_workers', label: 'Maximum Workers' },
    { paramName: 'maximum_schedules', label: 'Maximum Schedules' },
    { paramName: 'maximum_cloud_workers', label: 'Maximum Cloud Workers' },
    { paramName: 'maximum_cloud_locations', label: 'Maximum Cloud Locations' },
    { paramName: 'maximum_days_in_advance_schedule', label: 'Maximum Days in Advance Schedule' }
  ]

  const constraints2 = [
    { paramName: 'test_block_count_limit', label: 'Maximum Test Blocks' },
    { paramName: 'test_stage_count_limit', label: 'Maximum Stages' },
    { paramName: 'test_stage_size_limit', label: 'Maximum Test Blocks in Stage' }
  ]

  function onFormSubmit(values) {
    const updatedPolicy = {
      name: values.name,
      description: values.description,
      policy: {
        max: values.max,
        interval: values.interval,
        increment: values.increment,
        data_retention: values.data_retention,
        constraints: {
          maximum_tests: values.maximum_tests,
          maximum_workers: values.maximum_workers,
          maximum_schedules: values.maximum_schedules,
          maximum_cloud_workers: values.maximum_cloud_workers,
          maximum_cloud_locations: values.maximum_cloud_locations,
          maximum_days_in_advance_schedule: values.maximum_days_in_advance_schedule,
          test_block_count_limit: values.test_block_count_limit,
          test_stage_count_limit: values.test_stage_count_limit,
          test_stage_size_limit: values.test_stage_size_limit
        }
      }
    }
    onDataUpdated(updatedPolicy)
  }

  const numberValidation = Yup.number()
    .min(0, 'Min value is 0')
    .required('Value cannot be empty and must be a number')
    .integer()

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .max(150, 'Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only')
      .required('Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only'),
    max: numberValidation,
    interval: numberValidation,
    increment: numberValidation,
    data_retention: numberValidation,
    maximum_tests: numberValidation,
    maximum_workers: numberValidation,
    maximum_schedules: numberValidation,
    maximum_cloud_workers: numberValidation,
    maximum_cloud_locations: numberValidation,
    maximum_days_in_advance_schedule: numberValidation,
    test_block_count_limit: numberValidation,
    test_stage_count_limit: numberValidation,
    test_stage_size_limit: numberValidation
  })

  return policy ? (
    <Formik
      initialValues={{
        name: policy.name,
        description: policy.description,
        max: policy.policy.max,
        interval: policy.policy.interval,
        increment: policy.policy.increment,
        data_retention: policy.policy.data_retention,
        maximum_tests: policy.policy.constraints.maximum_tests,
        maximum_workers: policy.policy.constraints.maximum_workers,
        maximum_schedules: policy.policy.constraints.maximum_schedules,
        maximum_cloud_workers: policy.policy.constraints.maximum_cloud_workers,
        maximum_cloud_locations: policy.policy.constraints.maximum_cloud_locations,
        maximum_days_in_advance_schedule: policy.policy.constraints.maximum_days_in_advance_schedule,
        test_block_count_limit: policy.policy.constraints.test_block_count_limit,
        test_stage_count_limit: policy.policy.constraints.test_stage_count_limit,
        test_stage_size_limit: policy.policy.constraints.test_stage_size_limit
      }}
      onSubmit={onFormSubmit}
      validationSchema={validationSchema}
      validateOnMount={true}
    >
      {({ errors, isValid, touched }) => (
        <Card>
          <Form>
            <CardHeader>
              <CardTitle>Form</CardTitle>
            </CardHeader>
            <CardBody>
              <Row>
                <Col sm="4">
                  <FormGroup>
                    <Label for="name">Name</Label>
                    <Input
                      name="name"
                      placeholder="Select name"
                      tag={Field}
                      invalid={touched.name && Boolean(errors.name)}
                    />
                    <ErrorMessage name="name" component="div" className="invalid-feedback" />
                  </FormGroup>
                  <FormGroup>
                    <Label for="description">Description</Label>
                    <Input
                      name="description"
                      placeholder="Select description"
                      tag={Field}
                      invalid={Boolean(errors.description)}
                    />
                    <ErrorMessage name="description" component="div" className="invalid-feedback" />
                  </FormGroup>
                  <FormGroup>
                    <Label for="max">Max Credits</Label>
                    <Input
                      type="number"
                      name="max"
                      min="0"
                      placeholder="Select max"
                      tag={Field}
                      invalid={touched.max && Boolean(errors.max)}
                    />
                    <ErrorMessage name="max" component="div" className="invalid-feedback" />
                  </FormGroup>
                  <FormGroup>
                    <Label for="interval">Interval Credits Update</Label>
                    <Input
                      type="number"
                      name="interval"
                      min="0"
                      placeholder="Select interval"
                      tag={Field}
                      invalid={touched.interval && Boolean(errors.interval)}
                    />
                    <ErrorMessage name="interval" component="div" className="invalid-feedback" />
                  </FormGroup>
                  <FormGroup>
                    <Label for="increment">Increment Credits</Label>
                    <Input
                      type="number"
                      name="increment"
                      min="0"
                      placeholder="Select increment"
                      tag={Field}
                      invalid={touched.increment && Boolean(errors.increment)}
                    />
                    <ErrorMessage name="increment" component="div" className="invalid-feedback" />
                  </FormGroup>
                  <FormGroup>
                    <Label for="increment">Data Retention (in Days)</Label>
                    <Input
                      type="number"
                      name="data_retention"
                      min="0"
                      placeholder="Select data retention"
                      tag={Field}
                      invalid={touched.data_retention && Boolean(errors.data_retention)}
                    />
                    <ErrorMessage name="data_retention" component="div" className="invalid-feedback" />
                  </FormGroup>
                </Col>
                <Col sm="4">
                  {constraints.map(param => (
                    <FormGroup key={param.paramName}>
                      <Label for="increment">{param.label}</Label>
                      <Input
                        type="number"
                        name={param.paramName}
                        min="0"
                        placeholder={`Select ${param.label}`}
                        tag={Field}
                        invalid={touched[param.paramName] && Boolean(errors[param.paramName])}
                      />
                      <ErrorMessage name={param.paramName} component="div" className="invalid-feedback" />
                    </FormGroup>
                  ))}
                </Col>
                <Col sm="4">
                  {constraints2.map(param => (
                    <FormGroup key={param.paramName}>
                      <Label for="increment">{param.label}</Label>
                      <Input
                        type="number"
                        name={param.paramName}
                        min="0"
                        placeholder={`Select ${param.label}`}
                        tag={Field}
                        invalid={touched[param.paramName] && Boolean(errors[param.paramName])}
                      />
                      <ErrorMessage name={param.paramName} component="div" className="invalid-feedback" />
                    </FormGroup>
                  ))}
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <Button color="info" className="float-right" type="submit" disabled={!isValid}>
                Apply
              </Button>
            </CardFooter>
          </Form>
        </Card>
      )}
    </Formik>
  ) : (
    <></>
  )
}
