import React, { useEffect } from 'react'
import { ContainerFluid, Content, ContentHeader } from '../../components'
import { Col, Row } from 'reactstrap'
import { MapCard } from '../../containers'
import WorkersSummary from './WorkersSummary'

export default function Map() {
  useEffect(() => {
    document.title = 'vUTP | Map'
  }, [])

  return (
    <>
      <ContentHeader title="Map" />
      <Content>
        <ContainerFluid>
          <Row>
            <Col xl="10" lg="10" md="12" sm="12" xs="12">
              <MapCard cardClassName='h-100'/>
            </Col>
            <Col xl="2" lg="2" md="12" sm="12" xs="12">
              <WorkersSummary />
            </Col>
          </Row>
        </ContainerFluid>
      </Content>
    </>
  )
}
