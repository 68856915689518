import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CardBody, FormGroup, Button } from 'reactstrap'
import * as actions from '../../../store/actions'

export default function Reboot({ testAgentApi, submitForm }) {
  const dispatch = useDispatch()
  const token = useSelector(state => state.auth.token)
  const [testAgent, setTestAgent] = useState(null)

  useEffect(() => {
    if (!testAgent) {
      setTestAgent(testAgentApi)
    }
  }, [testAgentApi])

  function rebootTestAgent() {
    dispatch(actions.addDataDetailsFunction(token, 'TEST_AGENTS', testAgent.id, 'REBOOT', {}, true))
    submitForm()
  }

  return (
    <CardBody>
      <p>To reboot test agent, click Reboot button.</p>
      <FormGroup>
        <Button color="info" onClick={rebootTestAgent}>
          Reboot
        </Button>
      </FormGroup>
    </CardBody>
  )
}
