import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Col, Row, Card, CardBody, CardHeader, CardTitle } from 'reactstrap'
import { ContainerFluid, Content, ContentHeader, Spinner } from '../../components'
import { TestAgentsList, TestAgent } from './'
import { TestAgentsContext } from '../../context/TestAgentsContext'
import { sortBy } from '../../utilities/mathOperations'

export default function TestAgents({ location }) {
  const history = useHistory()
  const loading = useSelector(state => state.workers.loading)
  const { testAgents } = useContext(TestAgentsContext)
  const sort = location && location.state && location.state.sort ? location.state.sort : '-status'
  const [sortedTestAgents, setSortedTestAgents] = useState(testAgents)

  useEffect(() => {
    document.title = 'vUTP | Test Agents'
  }, [])

  useEffect(() => {
    if (location && location.state && location.state.sort) {
      setSortedTestAgents(sortBy(testAgents, location.state.sort))
    } else {
      setSortedTestAgents(testAgents)
    }
  }, [testAgents, location && location.state && location.state.sort])

  function setSort(sortParam) {
    history.push(`/test-agents/`, {
      sort: sortParam
    })
  }

  return (
    <>
      <ContentHeader title="Test Agents" />
      <Content>
        <ContainerFluid>
          <Row>
            <Col xl="12" lg="12" md="12" sm="12" xs="12">
              <Card className="top-line-info">
                <CardHeader className="no-border">
                  <CardTitle>On-Premises Test Agents</CardTitle>
                </CardHeader>
                <CardBody>
                  {loading ? (
                    <Spinner />
                  ) : (
                    <TestAgentsList workers={sortedTestAgents} sort={sort} setSort={setSort}>
                      {sortedTestAgents &&
                        sortedTestAgents.map(worker => <TestAgent key={worker.id} worker={worker} />)}
                    </TestAgentsList>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ContainerFluid>
      </Content>
    </>
  )
}
