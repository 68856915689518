import React, { createContext, useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import WSProvider from './WSProvider'

export const TestAgentsContext = createContext({})

export default function TestAgentsProvider({ children }) {
  const token = useSelector(state => state.auth.token)
  const testAgentsApi = useSelector(state => state.workers.data)
  const [testAgents, setTestAgents] = useState([])
  const ws = useRef(null)
  const [wsActive, setWsActive] = useState(false)
  const ws_scheme = process.env.REACT_APP_HTTPS ? 'wss' : 'ws'

  useEffect(() => {
    if (testAgentsApi) {
      ws.current = new WebSocket(`${ws_scheme}://${window.location.host}/ws/test_agents/?_token=${token}`)
      setTestAgents([...testAgentsApi])
    }
  }, [testAgentsApi])

  useEffect(() => {
    if (ws && ws.current) {
      setWsActive(true)
      ws.current.onmessage = evt => {
        // listen to data sent from the websocket server
        const message = JSON.parse(evt.data)
        setTestAgents(prev => {
          if (prev.some(testAgent => testAgent.id === message.id)) {
            return prev.map(testAgent => {
              return testAgent.id === message.id ? message : testAgent
            })
          } else {
            return [...prev, message]
          }
        })
      }
    }
  }, [ws && ws.current])

  return (
    ws &&
    wsActive &&
    ws.current && (
      <WSProvider ws={ws} contextName="test_agents" storeKeyName="test_agents">
        <TestAgentsContext.Provider
          value={{
            testAgents: testAgents.sort((a, b) => {
              return b.status - a.status
            })
          }}
        >
          {children}
        </TestAgentsContext.Provider>
      </WSProvider>
    )
  )
}
