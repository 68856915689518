import React, { useContext } from 'react'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import TestTileInStep from './TestTileInStep'
import { FaTimes } from 'react-icons/fa'
import TestSuiteConfigurator from '../../../context/TestSuiteConfiguratorContext'

export default function TestSuiteStep({ items, step_number, isLastStep, removeItem, removeStep }) {
  const { selectedBlock, setSelectedBlock } = useContext(TestSuiteConfigurator)
  return (
    <>
      {items.length === 0 && !isLastStep && (
        <small className="text-danger text-center d-block">Step cannot be empty.</small>
      )}
      <Droppable droppableId={step_number.toString()}>
        {(provided, snapshot) => (
          <div
            className="border-info"
            style={{
              minHeight: '120px',
              textAlign: 'center',
              borderStyle: 'dashed',
              borderWidth: '2px',
              borderRadius: '10px'
            }}
          >
            {!isLastStep && (
              <div className="p-2">
                <h4 style={{ display: 'inline' }}>Step {step_number + 1}</h4>
                <FaTimes color="info" className="float-right clickable" onClick={() => removeStep(step_number)} />
              </div>
            )}
            <ul ref={provided.innerRef} className="p-0">
              {items.length > 0 ? (
                <>
                  {items.map((item, index) => (
                    <Draggable key={item.tile_id} draggableId={item.tile_id.toString()} index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            ...provided.draggableProps.style
                          }}
                          onClick={() => setSelectedBlock(item)}
                        >
                          <TestTileInStep
                            item={item}
                            selected={selectedBlock && selectedBlock.tile_id === item.tile_id}
                            removeItem={e => removeItem(e, step_number, index)}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  <div className="text-muted px-3" style={{ whiteSpace: 'normal' }}>
                    Drag the test tile to add a parallel test.
                  </div>
                </>
              ) : (
                <div className="text-muted px-3" style={{ whiteSpace: 'normal' }}>
                  Drag the test tile to add a step.
                </div>
              )}
              {provided.placeholder}
            </ul>
          </div>
        )}
      </Droppable>
    </>
  )
}
