import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import { ContainerFluid, Content, ContentHeader, Spinner } from '../../components'
import UpdateEditForm from './Form'
import * as actions from '../../store/actions'
import { adminURI } from '../../api/endpoints'
import { usePrevious } from '../../utilities/customHooks'

export default function UpdateEdit({ match }) {
  const isEditPage = match.path === '/updates/edit/:id'
  const defaultUpdate = {
    name: '',
    display_name: '',
    description: '',
    package: null
  }

  const history = useHistory()
  const dispatch = useDispatch()
  const token = useSelector(state => state.auth.token)
  const update = isEditPage ? useSelector(state => state.update.data) : defaultUpdate
  const updates = useSelector(state => state.updates.data)
  const loading = useSelector(state => state.update.loading || state.updates.loading)
  const error = useSelector(state => state.updates.error)
  const prevError = usePrevious(error)
  const [isAfterDispatch, setIsAfterDispatch] = useState(false)

  useEffect(() => {
    document.title = 'vUTP | Updates'
    if (isEditPage) {
      dispatch(actions.fetchDataDetails(token, 'UPDATES', match.params.id, adminURI))
    }
  }, [])

  useEffect(() => {
    if (isAfterDispatch && !prevError && !error) {
      history.push('/updates', { noFetch: true })
    }
  }, [updates])

  function onDataUpdated(data) {
    if (isEditPage) {
      dispatch(actions.updateData(token, 'UPDATES', match.params.id, data, true, adminURI))
    } else {
      dispatch(actions.addData(token, 'UPDATES', data, true, adminURI))
    }
    setIsAfterDispatch(true)
  }

  return (
    <>
      <ContentHeader title={`${isEditPage ? 'Edit' : 'Add'} Update Package`} />
      <Content>
        <ContainerFluid>
          <Row>
            <Col xl="9">
              {loading ? <Spinner /> : update && <UpdateEditForm updateProp={update} onDataUpdated={onDataUpdated} />}
            </Col>
          </Row>
        </ContainerFluid>
      </Content>
    </>
  )
}
