import React from 'react'
import { Table } from 'reactstrap'

export default function ResultTasksList({ children, results }) {
  return results && results.length > 0 ? (
    <Table responsive size="sm">
      <thead>
        <tr>
          <th>Test</th>
          <th>Location</th>
          <th>Status</th>
          <th className="text-center">Thresholds</th>
          <th>Date Created</th>
          <th>Date Updated</th>
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </Table>
  ) : (
    'No results'
  )
}
