import React from 'react'
import { Map as LeafletMap, TileLayer } from 'react-leaflet'
import L from 'leaflet'
import icon from './images/marker-icon.png'

let DefaultIcon = L.icon({
  iconUrl: icon,
  iconSize: [28, 40],
  iconAnchor: [14, 40]
})

L.Marker.prototype.options.icon = DefaultIcon

const Map = props => (
  <LeafletMap
    style={{ width: props.width, height: props.height }}
    center={props.center}
    zoom={props.zoom}
    maxZoom={props.maxZoom}
    attributionControl={props.attributionControl}
    zoomControl={props.zoomControl}
    doubleClickZoom={props.doubleClickZoom}
    scrollWheelZoom={props.scrollWheelZoom}
    dragging={props.dragging}
    animate={props.animate}
    easeLinearity={props.easeLinearity}
    onClick={props.onClick}
    onZoom={props.onZoom}
  >
    <TileLayer
      attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
    />
    {props.children}
  </LeafletMap>
)

export default Map
