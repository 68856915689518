import React from 'react'
import { Table } from 'reactstrap'
import { Spinner, TableBody } from '../../components'

import SubscriptionRow from './Row'

const SubscriptionList = ({ loading, subscriptions }) => {
  return loading || (subscriptions && subscriptions.length > 0) ? (
    <Table className="table-valign-middle" striped>
      <thead>
        <tr>
          <th>Name</th>
          <th>Last Updated</th>
          <th>Created At</th>
          <th>Valid Until</th>
          <th>Period</th>
          <th>Is Active</th>
          <th style={{ textAlign: 'center' }}>Actions</th>
        </tr>
      </thead>
      <TableBody>
        {loading ? (
          <Spinner columns={7} />
        ) : (
          subscriptions &&
          subscriptions.map(subscription => <SubscriptionRow key={subscription.id} subscription={subscription} />)
        )}
      </TableBody>
    </Table>
  ) : (
    <div className="p-3">No Subscriptions</div>
  )
}

export default SubscriptionList
