import React from 'react'
import { FaSortAmountDownAlt, FaSortAmountUp } from 'react-icons/fa'

const SortOtherIcon = ({ sort, setSort, param }) => {
  switch (sort) {
    case param:
      return (
        <FaSortAmountDownAlt
          onClick={() => {
            setSort(`-${param}`)
          }}
          className="clickable"
          style={{ paddingLeft: '4px', paddingBottom: '2px' }}
        />
      )
    case `-${param}`:
      return (
        <FaSortAmountUp
          onClick={() => {
            setSort(param)
          }}
          className="clickable"
          style={{ paddingLeft: '4px', paddingBottom: '2px' }}
        />
      )
    default:
      return (
        <FaSortAmountUp
          onClick={() => {
            setSort(param)
          }}
          className="clickable"
          style={{ paddingLeft: '4px', paddingBottom: '2px', color: 'gray' }}
        />
      )
  }
}

export default SortOtherIcon