import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import { ContainerFluid, Content, ContentHeader, Spinner } from '../../../components'
import DeliveryConfigEditForm from './Form'
import * as actions from '../../../store/actions'
import { usePrevious } from '../../../utilities/customHooks'

export default function DeliveryConfigEdit({ match }) {
  const isEditPage = match.path === '/notifications/delivery_configs/edit/:id'
  const defaultDeliveryConfig = {
    delivery_type: 0,
    display_name: '',
    contact_points: ''
  }

  const history = useHistory()
  const dispatch = useDispatch()
  const token = useSelector(state => state.auth.token)
  const deliveryConfigs = useSelector(state => state.notifications_delivery_configs.data)
  const deliveryConfigApi = useSelector(state => state.notifications_delivery_config.data)
  const deliveryConfig = isEditPage ? deliveryConfigApi : defaultDeliveryConfig

  const loading = useSelector(
    state => state.notifications_types.loading || state.notifications_delivery_configs.loading
  )
  const error = useSelector(state => state.notifications_delivery_configs.error)
  const prevError = usePrevious(error)
  const [isAfterDispatch, setIsAfterDispatch] = useState(false)

  useEffect(() => {
    document.title = 'vUTP | Notification Delivery Configs'
    dispatch(actions.fetchData(token, 'NOTIFICATION_DELIVERY_CONFIGS'))

    if (isEditPage) {
      dispatch(actions.fetchDataDetails(token, 'NOTIFICATION_DELIVERY_CONFIGS', match.params.id))
    }
    return () => {
      dispatch(actions.clearDataDetails('NOTIFICATION_DELIVERY_CONFIGS'))
    }
  }, [])

  useEffect(() => {
    if (isAfterDispatch && !prevError && !error) {
      history.push('/notifications', { noFetch: true })
    }
  }, [deliveryConfigs])

  function onDataUpdated(data) {
    if (isEditPage) {
      dispatch(actions.updateData(token, 'NOTIFICATION_DELIVERY_CONFIGS', match.params.id, data, true))
    } else {
      dispatch(actions.addData(token, 'NOTIFICATION_DELIVERY_CONFIGS', data, true))
    }
    setIsAfterDispatch(true)
  }

  return (
    <>
      <ContentHeader
        title={`${isEditPage ? 'Edit' : 'Add'} Notifications Delivery Config`}
        breadcrumbs={['Notifications', `${isEditPage ? 'Edit' : 'Add'} Notifications Delivery Config`]}
      />
      <Content>
        <ContainerFluid>
          <Row>
            <Col md="6" sm="8">
              {loading ? (
                <Spinner />
              ) : (
                deliveryConfig && (
                  <DeliveryConfigEditForm
                    deliveryConfig={deliveryConfig}
                    deliveryConfigApi={deliveryConfigApi}
                    onDataUpdated={onDataUpdated}
                  />
                )
              )}
            </Col>
          </Row>
        </ContainerFluid>
      </Content>
    </>
  )
}
