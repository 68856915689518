import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../store/actions'
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap'
import { Notifications } from '../../containers/'

export default function NotificationsCard() {
  const dispatch = useDispatch()
  const notificationsOpenPanelsCounter = useSelector(state => state.notifications_open_panels_counter.data)

  useEffect(() => {
    dispatch(actions.setData('NOTIFICATIONS_OPEN_PANELS_COUNTER', notificationsOpenPanelsCounter + 1))
    return () => {
      dispatch(actions.setData('NOTIFICATIONS_OPEN_PANELS_COUNTER', notificationsOpenPanelsCounter))
    }
  }, [])

  return (
    <Card className="top-line-info">
      <CardHeader>
        <CardTitle>Notifications</CardTitle>
      </CardHeader>
      <CardBody>
        <div style={{ height: '75vh' }}>
          <Notifications />
        </div>
      </CardBody>
    </Card>
  )
}
