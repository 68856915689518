import React from 'react'
import Moment from 'react-moment'
import { Table } from 'reactstrap'

export default function ResultTwamp({ result, parameters }) {
  const argsSuffixes = [
    { key: '', direction: 'Both' },
    { key: '_fwd', direction: `${parameters.direction[0]} -> ${parameters.direction[1]}` },
    { key: '_bck', direction: `${parameters.direction[1]} -> ${parameters.direction[0]}` }
  ]
  if (parameters.mode === 'client' && result.succeeded) {
    return (
      <Table responsive size="sm">
        <thead>
          <tr>
            <th>Direction</th>
            <th>Min Delay</th>
            <th>Median Delay</th>
            <th>Max Delay</th>
            <th>Error Estimate</th>
            <th>Jitter</th>
            <th>Packets Sent</th>
            <th>Packets Lost</th>
            <th>Start Time</th>
            <th>End Time</th>
            <th>Succeeded</th>
          </tr>
        </thead>
        <tbody>
          {argsSuffixes.map(argSuffix => (
            <tr key={argSuffix.key}>
              <td>{argSuffix.direction}</td>
              <td>
                {result[`min${argSuffix.key}`] !== undefined ? `${result[`min${argSuffix.key}`].toFixed(3)} s` : '-'}
              </td>
              <td>
                {result[`median${argSuffix.key}`] !== undefined
                  ? `${result[`median${argSuffix.key}`].toFixed(3)} s`
                  : '-'}
              </td>
              <td>
                {result[`max${argSuffix.key}`] !== undefined ? `${result[`max${argSuffix.key}`].toFixed(3)} s` : '-'}
              </td>
              <td>
                {result[`maxerr${argSuffix.key}`] !== undefined
                  ? `${result[`maxerr${argSuffix.key}`].toFixed(3)} s`
                  : '-'}
              </td>
              <td>
                {result[`pdv${argSuffix.key}`] !== undefined ? `${result[`pdv${argSuffix.key}`].toFixed(3)} s` : '-'}
              </td>
              <td>{result[`sent${argSuffix.key}`] !== undefined ? result[`sent${argSuffix.key}`] : '-'}</td>
              <td>{result[`lost${argSuffix.key}`] !== undefined ? result[`lost${argSuffix.key}`] : '-'}</td>
              <td>
                {result[`unix_start_time${argSuffix.key}`] !== undefined ? (
                  <Moment format="DD-MM-YYYY HH:mm:ss">{result[`unix_start_time${argSuffix.key}`]}</Moment>
                ) : (
                  '-'
                )}
              </td>
              <td>
                {result[`unix_end_time${argSuffix.key}`] !== undefined ? (
                  <Moment format="DD-MM-YYYY HH:mm:ss">{result[`unix_end_time${argSuffix.key}`]}</Moment>
                ) : (
                  '-'
                )}
              </td>
              <td style={{ color: result.succeeded ? 'green' : 'red' }}>{result.succeeded ? 'PASS' : 'FAILED'}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    )
  } else {
    return (
      <Table responsive size="sm">
        <thead>
          <tr>
            <th>Succeeded</th>
            {result.error && <th>Error</th>}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ color: result.succeeded ? 'green' : 'red' }}>{result.succeeded ? 'PASS' : 'FAILED'}</td>
            {result.error && <td>{result.error}</td>}
          </tr>
        </tbody>
      </Table>
    )
  }
}
