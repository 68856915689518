import React from 'react'
import { Table } from 'reactstrap'
import { SortStringIcon, SortOtherIcon, SortNumericIcon } from '../../components'

const TestAgentsList = ({ children, workers, mini, sort, setSort, sortDisabled }) => {
  return workers && workers.length > 0 ? (
    <Table responsive size="sm">
      <thead>
        <tr>
          <th>Name {!sortDisabled && <SortStringIcon sort={sort} setSort={setSort} param="name" />}</th>
          {!mini && <th>Release</th>}
          {!mini && <th>IP</th>}
          <th>Status {!sortDisabled && <SortOtherIcon sort={sort} setSort={setSort} param="status" />}</th>
          {!mini && <th>Authorized</th>}
          <th>Running Test Suites {!sortDisabled && <SortNumericIcon sort={sort} setSort={setSort} param="running_monitoring_count" />}</th>
          {!mini && <th>Last Status Changed {!sortDisabled && <SortOtherIcon sort={sort} setSort={setSort} param="last_status_change" />}</th>}
          {!mini && <th>Actions</th>}
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </Table>
  ) : (
    'No agents'
  )
}

export default TestAgentsList
