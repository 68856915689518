import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Tooltip } from 'reactstrap'
import { FaBars, FaDatabase, FaPowerOff } from 'react-icons/fa'
import { UserMenuDropdown, NotificationsDropdown } from '../../containers'
import netrsrLogo from '../../assets/netrsr_logo_alpha.png'

export default function Navbar() {
  const notifications = useSelector(state => state.notifications.data)
  const account = useSelector(state => state.account.data)
  const [creditsTooltip, setCreditsTooltip] = useState(false)
  const [logoutTooltip, setLogoutTooltip] = useState(false)

  return (
    <nav className="main-header navbar navbar-expand bg-info navbar-dark border-bottom">
      <ul className="navbar-nav">
        <li className="nav-item">
          <a
            className="nav-link"
            data-widget="pushmenu"
            href="/app/user/dashboard"
            onClick={e => {
              e.preventDefault()
            }}
          >
            <FaBars />
          </a>
        </li>
      </ul>
      <ul className="navbar-nav ml-auto">
        <a href="/app/user/dashboard" className="bg-info notransition" style={{ textAlign: 'center', padding: '0px' }}>
          <img src={netrsrLogo} alt="netrsr logo" style={{ height: '41px' }} />
        </a>
      </ul>
      <ul className="navbar-nav ml-auto d-flex align-items-center">
        <li className="nav-item">
          {account && (
            <div className="d-inline-block">
              <div className="nav-link text-center p-0 h-auto" id="creditsTooltip">
                <FaDatabase /> {account.credits}
              </div>
              <Tooltip
                placement="top"
                isOpen={creditsTooltip}
                target={'creditsTooltip'}
                toggle={() => setCreditsTooltip(!creditsTooltip)}
              >
                Credits
              </Tooltip>
            </div>
          )}
        </li>
        {notifications && <NotificationsDropdown />}
        {account && <UserMenuDropdown account={account} />}
        <li className="nav-item">
          <div id="logoutTooltip">
            <NavLink to="/logout" className="nav-link">
              <FaPowerOff />
            </NavLink>
          </div>
          <Tooltip
            placement="top"
            isOpen={logoutTooltip}
            target={'logoutTooltip'}
            toggle={() => setLogoutTooltip(!logoutTooltip)}
          >
            Logout
          </Tooltip>
        </li>
      </ul>
    </nav>
  )
}
