import React from 'react'
import { Table } from 'reactstrap'

export default function ResultTraceroute({ result }) {
  return (
    <>
      {result.succeeded === false ? (
        <Table responsive size="sm">
          <thead>
            <tr>
              <th>Result Succeeded</th>
              <th>Failure Reason</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{result.succeeded.toString()}</td>
              <td>{result.reason}</td>
            </tr>
          </tbody>
        </Table>
      ) : (
        result.map(value => (
          <div>
            <b>{value.address}</b>
            <Table responsive size="sm">
              <thead>
                <tr>
                  <th>Sequence Number</th>
                  <th>Result Succeeded</th>
                  <th>IP Address</th>
                  <th>Hostname</th>
                  <th>Failure Reason</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(value.hops).map(key => (
                  <tr key={key}>
                    <td>{key}</td>
                    <td>{value.hops[key].succeeded.toString()}</td>
                    <td>{value.hops[key].ip_address || '-'}</td>
                    <td>{value.hops[key].hostname || '-'}</td>
                    <td>{value.hops[key].reason || '-'}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        ))
      )}
    </>
  )
}
