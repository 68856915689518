import React from 'react'
import { Button, Form, Input } from 'reactstrap'
import { FaTrashAlt } from 'react-icons/fa'

export default function Group({ query, setQuery, thresholdsParams, treeLevel, children }) {
  function onChange(e) {
    let updatedQuery = { ...query }
    updatedQuery.operator_type = e.target.value
    setQuery(updatedQuery)
  }

  function addRule() {
    let updatedQuery = { ...query }
    updatedQuery.thresholds.push({
      type: 'threshold',
      value: 0,
      variable: thresholdsParams[0].param,
      threshold_type: 'gte'
    })
    setQuery(updatedQuery)
  }

  function addGroup() {
    let updatedQuery = { ...query }
    updatedQuery.thresholds.push({
      type: 'operator',
      operator_type: 'and',
      thresholds: []
    })
    setQuery(updatedQuery)
  }

  function deleteGroup() {
    setQuery({})
  }

  return (
    <div className="p-2 rounded" style={{ backgroundColor: `rgba(25, 105, 255, ${treeLevel * 0.1})` }}>
      <Form inline>
        <Input type="select" id="rule" name="rule" value={query.operator_type} onChange={onChange} className="m-1">
          <option value="and">AND</option>
          <option value="or">OR</option>
        </Input>
        <Button onClick={addRule} className="m-1">
          + Add Rule
        </Button>
        <Button onClick={addGroup} className="m-1">
          + Add Group
        </Button>
        <Button onClick={deleteGroup} className="m-1">
          <FaTrashAlt />
        </Button>
      </Form>
      {children}
    </div>
  )
}
