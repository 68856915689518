function apiValidateInput(value, validators, validator_type) {
  let isValid = []
  validators.forEach(validator => {
    if (validator.validators) {
      isValid.push(apiValidateInput(value, validator.validators, validator.type).isValid)
    } else if (validator.regex) {
      isValid.push(value.match(validator.regex) !== null)
    }
  })
  let messageValidatorIndex = isValid.findIndex(valid => valid === false)
  if (validator_type === 'or_validator') {
    return {
      isValid: isValid.some(valid => valid === true),
      message: messageValidatorIndex > -1 ? validators[messageValidatorIndex].message : ''
    }
  } else {
    return {
      isValid: !isValid.some(valid => valid === false),
      message: messageValidatorIndex > -1 ? validators[messageValidatorIndex].message : ''
    }
  }
}

function validateTextInput(value, display_name, validators) {
  let isValid = true
  let feedback = ''
  if (value === '' || value === null || value === undefined) {
    isValid = false
    feedback = `${display_name} cannot be empty.`
  } else {
    let apiValidator = validators && apiValidateInput(value, validators, 'and_validator')
    if (apiValidator && !apiValidator.isValid) {
      isValid = false
      feedback = apiValidator.message
    } else {
      isValid = true
      feedback = ''
    }
  }
  return { isValid, feedback }
}

function validateNumberInput(value, display_name, attributes) {
  let isValid = true
  let feedback = ''
  if (!Number.isInteger(parseInt(value))) {
    isValid = false
    feedback = `The ${display_name} value must be a number.`
  } else if (attributes.min_value && parseInt(value) < attributes.min_value) {
    isValid = false
    feedback = `The ${display_name} value must be greater or equal ${attributes.min_value}.`
  } else if (attributes.max_value && parseInt(value) > attributes.max_value) {
    isValid = false
    feedback = `The ${display_name} value must be less or equal ${attributes.max_value}.`
  } else {
    isValid = true
    feedback = ''
  }
  return { isValid, feedback }
}

function validateMultiSelect(value, display_name) {
  let isValid = true
  let feedback = ''
  isValid = value.length > 0
  feedback = value.length > 0 ? '' : `Select ${display_name}.`

  return { isValid, feedback }
}

function validateLocations(locationsInputList, display_name, cloud_available = true, on_premises_available = true) {
  let isValid = true
  let feedback = ''
  if (
    !cloud_available &&
    locationsInputList &&
    locationsInputList.filter(location => location.type === 'location').length > 0
  ) {
    isValid = false
    feedback = 'Cloud Locations are not available for this test.'
  } else if (
    !on_premises_available &&
    locationsInputList &&
    locationsInputList.filter(location => location.type === 'worker').length > 0
  ) {
    isValid = false
    feedback = 'On Premises Locations are not available for this test.'
  } else {
    isValid = locationsInputList ? locationsInputList.length > 0 : false
    feedback = locationsInputList && locationsInputList.length > 0 ? '' : `Select ${display_name}.`
  }
  return { isValid, feedback }
}

function validateDateTimeRange(value, display_name, isEndDate, secondDate) {
  let isValid = true
  let feedback = ''
  if (value === '' || value === null) {
    isValid = false
    feedback = `${display_name} cannot be empty.`
  } else if ((!isEndDate && value > secondDate) || (isEndDate && value < secondDate)) {
    isValid = false
    feedback = 'Start Time must be less than End Time.'
  } else {
    isValid = true
    feedback = ''
  }
  return { isValid, feedback }
}

function validateAdditionalConfiguration(additional_configuration, display_name) {
  let isValid = true
  let feedback = ''
  isValid = !additional_configuration.enabled || !additional_configuration.error
  feedback = additional_configuration.error ? '' : `There is some error in ${display_name} module.`
  return { isValid, feedback }
}

function compareTwoInputs(firstValue, secondValue, display_name) {
  let isValid = true
  let feedback = ''
  isValid = firstValue === secondValue
  feedback = firstValue === secondValue ? '' : `Your ${display_name} and confirmation ${display_name} do not match.`
  return { isValid, feedback }
}

function validateUniqueParam(value, paramName, display_name, collection, allowedValue) {
  let isValid = true
  let feedback = ''
  let isUnique = collection.filter(item => value !== allowedValue && item[paramName] === value).length === 0
  isValid = isUnique
  feedback = isUnique ? '' : `This ${display_name} is already taken.`
  return { isValid, feedback }
}

function validateTestForm(testDetails, test, withoutLocations) {
  let testValid = true
  let isValid = {}
  let feedback = {}

  let validName = validateTextInput(test.name, 'Task Name')
  isValid.name = validName.isValid
  feedback.name = validName.feedback
  testValid = testValid && validName.isValid

  if (!withoutLocations) {
    let validLocation = validateLocations(
      test.locations,
      'Locations',
      testDetails.cloud_available,
      testDetails.on_premises_available
    )
    isValid.locations = validLocation.isValid
    feedback.locations = validLocation.feedback
    testValid = testValid && validLocation.isValid
  }

  testDetails.options &&
    testDetails.options.params.forEach(option => {
      if (option.attributes.required) {
        switch (option.type) {
          case 'text':
          case 'password':
          case 'textarea':
          case 'text-interface':
          case 'text-test-agent':
            let validOptionText = validateTextInput(test.params[option.name], option.label, option.validators)
            isValid[option.name] = validOptionText.isValid
            feedback[option.name] = validOptionText.feedback
            testValid = testValid && validOptionText.isValid
            break
          case 'integer':
            let validOptionNum = validateNumberInput(test.params[option.name], option.label, option.attributes)
            isValid[option.name] = validOptionNum.isValid
            feedback[option.name] = validOptionNum.feedback
            testValid = testValid && validOptionNum.isValid
            break
          case 'multipleinput':
            if (Array.isArray(test.params[option.name])) {
              test.params[option.name].forEach((singleValue, index) => {
                let validOptionMulti = validateTextInput(singleValue, option.label, option.validators)
                if (!(option.name in isValid)) {
                  isValid[option.name] = []
                }
                isValid[option.name][index] = validOptionMulti.isValid
                if (!(option.name in feedback)) {
                  feedback[option.name] = []
                }
                feedback[option.name][index] = validOptionMulti.feedback
                testValid = testValid && validOptionMulti.isValid
              })
            } else {
              testValid = false
            }
            break
          default:
            break
        }
      }
    })

  if (
    testDetails.options &&
    testDetails.options.additional_options &&
    testDetails.options.additional_options.thresholds
  ) {
    let validThresholds = validateAdditionalConfiguration(test.additional_options.thresholds, 'Thredholds')
    isValid.thresholds = validThresholds.isValid
    feedback.thresholds = validThresholds.feedback
    testValid = testValid && validThresholds.isValid
  }

  return { isValid: isValid, feedback: feedback, testValid: testValid }
}

export {
  apiValidateInput,
  validateTestForm,
  validateTextInput,
  validateNumberInput,
  validateMultiSelect,
  validateDateTimeRange,
  validateLocations,
  compareTwoInputs,
  validateUniqueParam
}
