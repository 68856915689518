import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody, CardHeader, CardTitle, Table } from 'reactstrap'
import { FaMicrochip, FaCloud } from 'react-icons/fa'
import { onlyUnique } from '../../../utilities/mathOperations'
import TestSuiteInResults from '../../../context/TestSuiteInResultsContext'

export default function ResultParametersEnvTypeCard() {
  const { selectedEnvBlock } = useContext(TestSuiteInResults)
  const locations = useSelector(state => state.locations.data)
  const workers = useSelector(state => state.workers.data)
  const testSuite = useSelector(state => state.test_suite.data)

  const taskName = () => {
    let _taskName = ''
    if (selectedEnvBlock && selectedEnvBlock.env_type === '5g_infra') {
      _taskName = `${selectedEnvBlock.step === 'pre' ? 'Run' : 'Terminate'} 5G Infra`
    }
    return _taskName
  }

  const testAgentsAndLocations = () => {
    let TAaL = {
      cloud_locations: testSuite.cloud_locations,
      on_premise_test_agents: testSuite.on_premise_test_agents
    }
    if (selectedEnvBlock.env_type === '5g_infra') {
      testSuite.test_blocks.forEach(testBlock => {
        if (testBlock.options['5g_enabled']) {
          TAaL.cloud_locations.concat(testBlock.cloud_locations)
          TAaL.on_premise_test_agents.concat(testBlock.on_premise_test_agents)
        }
      })
    }
    TAaL.cloud_locations.filter(onlyUnique)
    TAaL.on_premise_test_agents.filter(onlyUnique)
    return TAaL
  }

  return (
    <Card className="top-line-info" style={{ height: '95%' }}>
      <CardHeader className="no-border">
        <CardTitle>Parameters</CardTitle>
      </CardHeader>
      <CardBody>
        <Table responsive size="sm" borderless className="mb-0">
          <tbody>
            <tr>
              <th>Environment Task</th>
              <td style={{ textAlign: 'right' }}>{taskName()}</td>
            </tr>
            <tr>
              <th>Locations</th>
              <td style={{ textAlign: 'right' }}>
                {locations &&
                  testAgentsAndLocations().cloud_locations.map(location => (
                    <p key={location} className="mb-0">
                      {locations.find(_location => _location.id === location).name} <FaCloud />
                    </p>
                  ))}
                {workers &&
                  testAgentsAndLocations().on_premise_test_agents.map(worker => (
                    <p key={worker} className="mb-0">
                      {workers.some(_worker => _worker.id === worker)
                        ? workers.find(_worker => _worker.id === worker).name
                        : '(Deleted test agent)'}{' '}
                      <FaMicrochip />
                    </p>
                  ))}
              </td>
            </tr>
            {testSuite &&
              testSuite.options &&
              testSuite.options['5g_config'] &&
              Object.keys(testSuite.options['5g_config']).length > 0 && (
                <>
                  <tr>
                    <th>MME Address</th>
                    <td className="float-right">{testSuite.options['5g_config'].mme_address}</td>
                  </tr>
                  <tr>
                    <th>IMSI</th>
                    <td className="float-right">{testSuite.options['5g_config'].imsi}</td>
                  </tr>
                  <tr>
                    <th>OPC</th>
                    <td className="float-right">{testSuite.options['5g_config'].opc}</td>
                  </tr>
                  <tr>
                    <th>Key</th>
                    <td className="float-right">{testSuite.options['5g_config'].k}</td>
                  </tr>
                </>
              )}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  )
}
