import React, { useEffect, useState } from 'react'
import { Form, Formik, ErrorMessage, Field } from 'formik'
import {
  FormGroup,
  Label,
  Input,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardFooter,
  Col,
  Row,
  Button
} from 'reactstrap'
import * as Yup from 'yup'
import { untar } from '../../utilities/fileOperations'

export default function UpdateEditForm({ updateProp, onDataUpdated }) {
  const [updatePackage, setUpdatePackage] = useState()
  const [update, setUpdate] = useState(updateProp)

  useEffect(() => {
    setUpdate(updateProp)
  }, [updateProp])

  function onFormSubmit(values) {
    if (updateProp.name) {
      onDataUpdated({ ...values })
    } else {
      let formData = new FormData()
      formData.append('package', updatePackage)
      Object.keys(values).forEach(key => {
        formData.append(key, values[key])
      })
      onDataUpdated(formData)
    }
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().max(150, '150 characters or fewer. Letters, digits and @/./+/-/_ only'),
    display_name: Yup.string().max(150, '150 characters or fewer. Letters, digits and @/./+/-/_ only'),
    description: Yup.string().max(150, '150 characters or fewer. Letters, digits and @/./+/-/_ only')
  })

  const hiddenFileInput = React.useRef(null)

  const handleClick = () => {
    hiddenFileInput.current.click()
  }

  const handleChange = event => {
    const fileUploaded = event.target.files[0]
    const reader = new FileReader()
    reader.onload = async e => {
      const buffer = e.target.result
      let metadataString = await untar(new Buffer(buffer), 'metadata.json')
      let metadataJson = JSON.parse(metadataString)
      let newUpdateData = {}
      newUpdateData.name = metadataJson.name || update.name
      newUpdateData.display_name = metadataJson.display_name || update.display_name
      newUpdateData.description = metadataJson.description || update.description
      setUpdate(newUpdateData)
    }
    reader.readAsArrayBuffer(fileUploaded)

    setUpdatePackage(fileUploaded)
  }

  return update ? (
    <Formik
      initialValues={{
        name: update.name,
        display_name: update.display_name,
        description: update.description
      }}
      onSubmit={onFormSubmit}
      validationSchema={validationSchema}
      validateOnMount={true}
      enableReinitialize={true}
    >
      {({ errors, isValid, touched }) => (
        <Card>
          <Form>
            <CardHeader>
              <CardTitle>Form</CardTitle>
            </CardHeader>
            <CardBody>
              <Row>
                <Col sm="12">
                  <FormGroup>
                    <Label for="name">Name</Label>
                    <Input
                      name="name"
                      placeholder="Select update name"
                      tag={Field}
                      invalid={touched.name && Boolean(errors.name)}
                      disabled={updateProp.name}
                    />
                    <ErrorMessage name="name" component="div" className="invalid-feedback" />
                  </FormGroup>
                  <FormGroup>
                    <Label for="display_name">Display Name</Label>
                    <Input
                      name="display_name"
                      placeholder="Select update display name"
                      tag={Field}
                      invalid={touched.display_name && Boolean(errors.display_name)}
                    />
                    <ErrorMessage name="display_name" component="div" className="invalid-feedback" />
                  </FormGroup>
                  <FormGroup>
                    <Label for="description">Description</Label>
                    <Input
                      name="description"
                      placeholder="Select update description"
                      tag={Field}
                      invalid={touched.description && Boolean(errors.description)}
                    />
                    <ErrorMessage name="description" component="div" className="invalid-feedback" />
                  </FormGroup>
                  {!updateProp.name && (
                    <FormGroup>
                      <Button color="info" onClick={handleClick}>
                        Upload a file
                      </Button>{' '}
                      {/*fileLoading && <Spinner />*/}
                      {updatePackage && updatePackage.name}
                      <input
                        type="file"
                        id="networkInterfaces"
                        ref={hiddenFileInput}
                        accept=".tar.gz"
                        onChange={handleChange}
                        style={{ display: 'none' }}
                      />
                    </FormGroup>
                  )}
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <Button color="info" className="float-right" type="submit" disabled={!isValid}>
                Apply
              </Button>
            </CardFooter>
          </Form>
        </Card>
      )}
    </Formik>
  ) : (
    <></>
  )
}
