import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import { ContainerFluid, Content, ContentHeader, Spinner } from '../../components'
import CloudConfigEditForm from './Form'
import * as actions from '../../store/actions'
import { adminURI } from '../../api/endpoints'
import { usePrevious } from '../../utilities/customHooks'

export default function CloudConfigEdit({ match }) {
  const account = useSelector(state => state.account.data)
  const isEditPage = match.path === '/cloud_configs/edit/:id'

  const defaultCloudConfig = {
    name: '',
    owner_id: account && account.id
  }

  const history = useHistory()
  const dispatch = useDispatch()
  const token = useSelector(state => state.auth.token)
  const cloudConfig = isEditPage ? useSelector(state => state.cloud_config.data) : defaultCloudConfig
  const cloudConfigs = useSelector(state => state.cloud_configs.data)
  const loading = useSelector(state => state.cloud_configs.loading || state.cloud_config.loading)
  const error = useSelector(state => state.cloud_configs.error)
  const prevError = usePrevious(error)
  const [isAfterDispatch, setIsAfterDispatch] = useState(false)

  useEffect(() => {
    document.title = 'vUTP | Cloud Configs'
    if (isEditPage) {
      dispatch(actions.fetchDataDetails(token, 'CLOUD_CONFIGS', match.params.id, adminURI))
    }
  }, [])

  useEffect(() => {
    if (isAfterDispatch && !prevError && !error) {
      history.push('/cloud_configs', { noFetch: true })
    }
  }, [cloudConfigs])

  function onDataUpdated(data) {
    if (isEditPage) {
      dispatch(actions.updateData(token, 'CLOUD_CONFIGS', match.params.id, data, true, adminURI))
    } else {
      dispatch(actions.addData(token, 'CLOUD_CONFIGS', data, true, adminURI))
    }
    setIsAfterDispatch(true)
  }

  return (
    <>
      <ContentHeader title={`${isEditPage ? 'Edit' : 'Add'} Cloud Config`} />
      <Content>
        <ContainerFluid>
          <Row>
            <Col xl="9">
              {loading ? (
                <Spinner />
              ) : (
                cloudConfig && <CloudConfigEditForm cloudConfigProp={cloudConfig} onDataUpdated={onDataUpdated} />
              )}
            </Col>
          </Row>
        </ContainerFluid>
      </Content>
    </>
  )
}
