import { updateObject } from '../utilities'

const reducer = (name = '') => {
  const initialState = {
    data: null,
    loading: false,
    error: null
  }

  return (state = initialState, { type, payload }) => {
    switch (type) {
      case `FETCH_${name}_DETAILS_START`:
        return updateObject(state, { loading: true, data: null, error: null })
      case `FETCH_${name}_DETAILS_SUCCESS`:
        return updateObject(state, { loading: false, data: payload.data })
      case `FETCH_${name}_DETAILS_FAIL`:
        return updateObject(state, { loading: false, data: null, error: payload.error })
      case `CLEAR_${name}_DETAILS`:
        return updateObject(state, { data: null })
      default:
        return state
    }
  }
}

export default reducer
