import classNames from 'classnames'
import React from 'react'
import { FaMemory, FaTv, FaNetworkWired } from 'react-icons/fa'
import { getNextOrdinalNumber } from './mathOperations'

const categoryIcon = (categoryName, selected) => {
  let icon = null
  switch (categoryName) {
    case 'node':
      icon = <FaMemory className={classNames(`d-inline icon-${categoryName}`, { active: selected })} size={30} />
      break
    case 'services':
      icon = <FaTv className={classNames(`d-inline icon-${categoryName}`, { active: selected })} size={30} />
      break
    case 'network':
      icon = <FaNetworkWired className={classNames(`d-inline icon-${categoryName}`, { active: selected })} size={30} />
      break
    default:
      break
  }
  return icon
}

const testIcon = (taskTypeName, categoryName, selected) => {
  let icon = null
  switch (taskTypeName) {
    case 'hdd_random_read_test': // Node -> Storage Performance -> Random Read
    case 'hdd_write_test': // Node -> Storage Performance -> Write
    case 'hdd_read_test': // Node -> Storage Performance -> Read
    case 'hdd_latency_test': // Node -> Storage Performance -> Latency
    case 'hdd_rewrite_test': // Node -> Storage Performance -> Re-Write
    case 'ram_latency_test': // Node -> RAM Performance -> Latency
    case 'ram_read_test': // Node -> RAM Performance -> Read
    case 'ram_write_test': // Node -> RAM Performance -> Write
    case 'cpu_hash_test': // Node -> CPU Performance -> Hashing
    case 'cpu_aes_test': // Node -> CPU Performance -> AES Encryption
    case 'cpu_compression_test': // Node -> CPU Performance -> DEFLATE Compression
    case 'cpu_rsa_test': // Node -> CPU Performance -> RSA Encryption
    case 'cpu_fft_test': // Node -> CPU Performance -> Math (FFT) Operation
    case 'info_memory': // Node -> OS Info -> RAM
    case 'info_swap': // Node -> OS Info -> SWAP
    case 'info_cpu_times': // Node -> OS Info -> CPU Utilization
    case 'info_disk_usage': // Node -> OS Info -> Storage
    case 'info_network_counters': // Node -> OS Info -> Net Interfaces
    case 'info_cpu': // Node -> OS Info -> CPU Resources
      icon = <FaMemory className={classNames(`d-inline icon-${categoryName}`, { active: selected })} size={30} />
      break
    case 'hls_test': // Services -> Services -> OTT
    case 'iptv_test': // Services -> Services -> IPTV
    case 'har_test': // Services -> Services -> HAR Test
    case 'ott_stress_test': // Services -> Services -> OTT Stress Test
    case 'voip_test': // Services -> Services -> VoIP Test
    case 'bash_script': // Services -> Services -> Bash Script
      icon = <FaTv className={classNames(`d-inline icon-${categoryName}`, { active: selected })} size={30} />
      break
    case 'http_ping_test': // Network -> Performance -> HTTP/S Latency
    case 'ftp_file_download_test': // Network -> Performance -> FTP Download
    case 'http_file_download_test': // Network -> Performance -> HTTP/S Throughput
    case 'iperf_test': // Network -> Performance -> iperf Test
    case 'network_ping': // Network -> Connectivity -> Ping
    case 'traceroute_test': // Network -> Connectivity -> Traceroute
    case 'https_test': // Network -> Connectivity -> HTTPS Security
    case 'dns_test': // Network -> Connectivity -> DNS
    case 'ftp_availability_test': // Network -> Connectivity -> FTP Availability
    case 'pathping_test': // Network -> Connectivity -> Pathping
    case 'tcp_syn_scan_test': // Network -> Connectivity -> TCP Initial Scan
    case 'tcp_connection_scan_test': // Network -> Connectivity -> TCP Connectivity Scan
    case 'udp_scan_test': // Network -> Connectivity -> UDP Scan
    case 'www_test': // Network -> Connectivity -> WWW Test
    case 'scraper_test': // Network -> Connectivity -> Scraper Test
    case 'dhcp_test': // Network -> Connectivity -> DHCP Test
    case 'rfc6349_test': // Network -> Connectivity -> RFC6349
    case 'twamp_test': // Network -> Connectivity -> TWAMP
      icon = <FaNetworkWired className={classNames(`d-inline icon-${categoryName}`, { active: selected })} size={30} />
      break
    default:
      break
  }
  return icon
}

const checkValidityParams = (taskType, testParams) => {
  let incorrectParams = []
  taskType.options &&
    taskType.options.params &&
    taskType.options.params
      .filter(
        param =>
          (param.attributes && param.attributes.required) ||
          (testParams[param.name] !== null && testParams[param.name] !== undefined)
      )
      .forEach(param => {
        let foundParam = testParams[param.name]
        if (foundParam !== undefined) {
          switch (param.type) {
            case 'text':
            case 'password':
            case 'textarea':
              if (typeof foundParam !== 'string') {
                incorrectParams.push(param.name + ' param has the wrong data type.')
              }
              break
            case 'integer':
              if (isNaN(parseInt(foundParam))) {
                incorrectParams.push(param.name + ' param has the wrong data type.')
              }
              break
            case 'checkbox':
              if (!(foundParam === false || foundParam === true || foundParam === 1 || foundParam === 0)) {
                incorrectParams.push(param.name + ' param has the wrong data type.')
              }
              break
            case 'select':
              if (
                param.attributes.value_type &&
                ((param.attributes.value_type === 'int' && isNaN(parseInt(foundParam))) ||
                  (param.attributes.value_type === 'float' && isNaN(parseFloat(foundParam))))
              ) {
                incorrectParams.push(param.name + ' param has the wrong data type.')
              }
              break
            case 'multipleinput':
              if (!Array.isArray(foundParam)) {
                incorrectParams.push(param.name + ' param has the wrong data type.')
              }
              break
            default:
              break
          }
        } else {
          incorrectParams.push(param.name + ' param does not exist.')
        }
      })
  return incorrectParams
}

export default function defaultTestForm(testDetails, steps, globalLocations) {
  let testsArray = []
  steps.forEach(step => testsArray.push(...step.tests))
  let nameSuffix = getNextOrdinalNumber(testsArray.map(test => test.name), testDetails.display_name)
  let test = {
    display_name: testDetails.display_name,
    task_type: testDetails.id,
    task_type_name: testDetails.name,
    category: testDetails.subcategories[0].category,
    subcategory: testDetails.subcategories[0].id,
    name: testDetails.display_name + nameSuffix,
    overrideLocations: false,
    locations: globalLocations,
    additional_options: {
      thresholds: {
        enabled: false,
        mode: 'json',
        configuration: { type: 'threshold', threshold_type: 'gte', value: 0 }
      },
      dedicated_instances: { enabled: false, quantity: 0 }
    },
    priority: 0,
    params: {}
  }
  if (testDetails && testDetails.options && testDetails.options.params) {
    testDetails.options.params.forEach(option => {
      test.params[option.name] =
        'initial' in option.attributes ? option.attributes.initial : option.type === 'multipleinput' ? [''] : null
    })
    if (
      testDetails.options.additional_options &&
      testDetails.options.additional_options.thresholds &&
      testDetails.options.additional_options.thresholds.params &&
      testDetails.options.additional_options.thresholds.params.length > 0
    ) {
      test.additional_options.thresholds.configuration.variable =
        testDetails.options.additional_options.thresholds.params[0].param
    }
  }
  test.additional_options.thresholds.configuration = JSON.stringify(
    test.additional_options.thresholds.configuration,
    null,
    '\t'
  )
  return test
}

export { categoryIcon, testIcon, checkValidityParams, defaultTestForm }
