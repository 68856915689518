import React from 'react'
import { Table } from 'reactstrap'

export default function ResultTCP({ result }) {
  return (
    <div>
      <Table responsive size="sm">
        <thead>
          <tr>
            <th>Address</th>
            <th>Succeeded</th>
            <th>Open Ports</th>
            <th>Filtered Ports</th>
            <th>Closed Ports</th>
            <th>Failure Reason</th>
          </tr>
        </thead>
        <tbody>
          {result && result.map(value => (
            <tr key={value.address}>
              <td>{value.address}</td>
              <td>{value.succeeded !== undefined && value.succeeded.toString()}</td>
              <td>
                {value.open_ports && value.open_ports.length > 0
                  ? value.open_ports.sort().map((port, index) => `${port}${index < value.open_ports.length - 1 ? ', ' : ''}`)
                  : '-'}
              </td>
              <td>
                {value.filtered_ports && value.filtered_ports.length > 0
                  ? value.filtered_ports.sort().map((port, index) => `${port}${index < value.filtered_ports.length - 1 ? ', ' : ''}`)
                  : '-'}
              </td>
              <td>
                {value.closed_ports && value.closed_ports.length > 0
                  ? value.closed_ports.sort().map((port, index) => `${port}${index < value.closed_ports.length - 1 ? ', ' : ''}`)
                  : '-'}
              </td>
              <td>{value.reason || '-'}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}
