import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useHistory } from 'react-router-dom'
import { Card, CardBody, CardHeader, CardTitle, Col, Row, Button } from 'reactstrap'
import { ContainerFluid, Content, ContentHeader, CardTools } from '../../components'
import UserList from './List'
import * as actions from '../../store/actions'
import { adminURI } from '../../api/endpoints'

export default function Users({ location }) {
  const dispatch = useDispatch()
  const history = useHistory()

  const token = useSelector(state => state.auth.token)
  const users = useSelector(state => state.users.data)
  const account = useSelector(state => state.account.data)
  const loading = useSelector(state => state.users.loading || state.account.loading)

  useEffect(() => {
    document.title = 'vUTP | Users'
    if (!(location && location.state && location.state.noFetch)) {
      dispatch(actions.fetchData(token, 'USERS', adminURI))
    } else if (location && location.state){
      history.replace()
    }
  }, [])

  return (
    <>
      <ContentHeader title="Users" />
      <Content>
        <ContainerFluid>
          <Row>
            <Col xl="9">
              <Card>
                <CardHeader className="no-border">
                  <CardTitle>User List</CardTitle>
                  <CardTools>
                    <NavLink to="/users/add">
                      <Button color="info">Add User</Button>
                    </NavLink>
                  </CardTools>
                </CardHeader>
                <CardBody className="p-0">
                  <UserList loading={loading} token={token} users={users} currentUser={account} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ContainerFluid>
      </Content>
    </>
  )
}
