import React from 'react'
import { Table } from 'reactstrap'

export default function ResultScraper({ result, parameters }) {
  const getKeys = function() {
    return Object.keys(result)
  }
  var ids = []
  var xpath = []
  if (parameters['mode'] === 'id') {
    ids = parameters['values']
  } else {
    xpath = parameters['values']
  }

  return (
    <Table bordered style={{ textAlign: 'center' }}>
      <thead>
        <tr>
          <th>URI</th>
          {ids && ids.map(value => <th key={value + '_i'}>ID: {value}</th>)}
          {xpath && xpath.map(value => <th key={value + '_x'}>XPath: {value}</th>)}
        </tr>
      </thead>
      <tbody>
        {getKeys().map(value => (
          <tr key={value}>
            <td key={value + '_uri'}>{value}</td>
            {ids && result[value]['error'] ? (
              <td style={{ color: 'red' }}>ERROR</td>
            ) : (
              ids.map(val => (
                <td key={val + '_i'} style={{ color: result[value]['id'][val] ? 'green' : 'red' }}>
                  {result[value]['id'][val] ? 'PASS' : 'FAILED'}
                </td>
              ))
            )}
            {xpath && result[value]['error'] ? (
              <td style={{ color: 'red' }}>ERROR</td>
            ) : (
              xpath.map(val => (
                <td key={val + '_i'} style={{ color: result[value]['xpath'][val] ? 'green' : 'red' }}>
                  {result[value]['id'][val] ? 'PASS' : 'FAILED'}
                </td>
              ))
            )}
          </tr>
        ))}
      </tbody>
    </Table>
  )
}
