import React, { useState } from 'react'
import { Card, CardBody, CardHeader, CardTitle, Row } from 'reactstrap'
import { GrafanaFrame } from '../../containers'
import { TimerangeDropdown, timerangeValues1h1y } from '../../components'

export default function TestsPerType() {
  const [selectedTimerange, setSelectedTimerange] = useState(timerangeValues1h1y[0])

  return (
    <Card className="w-100 top-line-info" style={{ height: '97%' }}>
      <CardHeader className="no-border">
        <CardTitle>Tasks: Most Frequently Performed Test Types</CardTitle>
      </CardHeader>
      <CardBody style={{ textAlign: 'center', padding: '2px', paddingLeft: '5px' }}>
        <Row className="float-right pr-2">
          <TimerangeDropdown selectedTimerange={selectedTimerange} setSelectedTimerange={setSelectedTimerange} />
        </Row>
        <GrafanaFrame
          panel_id={8} // panelId from Grafana, defines the chart
          selectedTimerange={selectedTimerange.from ? selectedTimerange : undefined}
          style={{ width: '100%', height: '100%', marginBottom: '6px', paddingBottom: '40px' }}
          title={'tests-per-type'}
        />
      </CardBody>
    </Card>
  )
}
