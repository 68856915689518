import React from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody } from 'reactstrap'
import { FaTimes } from 'react-icons/fa'
import { categoryIcon } from '../../../utilities/testParameters'
import classNames from 'classnames'

export default function TestTileInStep({ item, selected, removeItem }) {
  const categories = useSelector(state => state.test_categories.data)
  const category = categories && categories.find(category => category.id === item.category)
  const categoryName = category && category.name
  const subcategory = category && category.subcategories.find(subcat => subcat.id === item.subcategory)
  return (
    <Card className={classNames(`m-2 tile-${categoryName}`, { active: selected })}>
      <CardBody className={classNames(`p-1 tile-${categoryName}`, { active: selected })}>
        <div>
          {categoryIcon(category && category.name, selected)} <p className="d-inline">{item.display_name}</p>
          <FaTimes className="float-right clickable" onClick={removeItem} />
        </div>
        <small className={classNames({ 'text-muted': !selected })}>
          {category && category.display_name}: {subcategory && subcategory.display_name}
        </small>
      </CardBody>
    </Card>
  )
}
