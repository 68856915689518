import React from 'react'
import { Table } from 'reactstrap'

export default function ResultUniversal({ task_type, result }) {
  const result_params = task_type.result_params

  function renderResult(key, object) {
    if (key === 'list' && object.length <= 1) {
      return object.map(obj => renderResultListByType(key, obj))
    } else {
      return renderResultByType(key, object)
    }
  }

  function renderResultByType(key, object) {
    switch (object.type) {
      case 'int':
        return <td key={key}>{parseInt(result[key])}</td>
      case 'float':
        return <td key={key}>{parseFloat(result[key].toFixed(3))}</td>
      case 'bool':
        return <td key={key}>{result[key].toString()}</td>
      default:
        return <td key={key}>{result[key]}</td>
    }
  }

  function renderResultListByType(key, object) {
    switch (object.type) {
      case 'int':
        return (
          <td key={key}>
            {result[key].map((value, index) => (
              <p key={index} className="m-0">
                {parseInt(value)}
              </p>
            ))}
          </td>
        )
      case 'float':
        return (
          <td>
            {result[key].map((value, index) => (
              <p key={index} className="m-0">
                {parseFloat(value).toFixed(3)}
              </p>
            ))}
          </td>
        )
      case 'bool':
        return (
          <td>
            {result[key].map((value, index) => (
              <p key={index} className="m-0">
                {value.toString()}
              </p>
            ))}
          </td>
        )
      default:
        return (
          <td>
            {result[key].map((value, index) => (
              <p key={index} className="m-0">
                {value}
              </p>
            ))}
          </td>
        )
    }
  }

  return (
    <div>
      {result_params && Object.keys(result_params).length > 0 && (
        <Table responsive size="sm">
          <thead>
            <tr>
              {Object.keys(result_params).map((key, index) => (
                <th key={index}>{key === 'list' ? 'List' : result_params[key].name}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>{Object.keys(result_params).map(key => renderResult(key, result_params[key]))}</tr>
          </tbody>
        </Table>
      )}
    </div>
  )
}
