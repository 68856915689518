import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { TimeChart } from '../../'
import { onlyUnique } from '../../../../utilities/mathOperations'
import TestSuiteInResults from '../../../../context/TestSuiteInResultsContext'

export default function Ping() {
  const { selectedBlock } = useContext(TestSuiteInResults)
  const testSuite = useSelector(state => state.test_suite.data)
  const monitoring = testSuite.repeat_interval
  const multi_agent =
    selectedBlock.on_premise_test_agents
      .concat(selectedBlock.cloud_locations)
      .concat(testSuite.on_premise_test_agents)
      .concat(testSuite.cloud_locations)
      .filter(onlyUnique).length > 1
      
  return monitoring ? <TimeChart panel_id={multi_agent ? 14 : 12} /> : 'No chart'
}
