import React, { useState } from 'react'
import { FaInfoCircle } from 'react-icons/fa'
import { Tooltip as TooltipReactstrap } from 'reactstrap'

const Tooltip = props => {
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const toggle = () => setTooltipOpen(!tooltipOpen)

  return (
    <>
      <FaInfoCircle color={props.color || 'blue'} id={'Tooltip-' + props.name} />
      <TooltipReactstrap
        placement="top"
        autohide={false}
        isOpen={tooltipOpen}
        target={'Tooltip-' + props.name}
        toggle={toggle}
      >
        {props.content}
      </TooltipReactstrap>
    </>
  )
}

export default Tooltip
