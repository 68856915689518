import React from 'react'
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Form, Formik, ErrorMessage, Field } from 'formik'
import {
  FormGroup,
  Label,
  Input,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardFooter,
  Col,
  Row,
  Button
} from 'reactstrap'
import * as Yup from 'yup'
import { emailRegex, httpsUrlRegex } from '../../../utilities/regularExpressions'

export default function DeliveryConfigEditForm({ deliveryConfig, deliveryConfigApi, onDataUpdated }) {
  const notificationsDeliveryTypes = useSelector(state => state.notifications_delivery_types.data)
  const deliveryConfigs = useSelector(state => state.notifications_delivery_configs.data)
  const emailNotificationDeliveryTypeId =
    notificationsDeliveryTypes &&
    notificationsDeliveryTypes.some(nDT => nDT.name === 'email') &&
    notificationsDeliveryTypes.find(nDT => nDT.name === 'email').id
  const account = useSelector(state => state.account.data)
  const emailHost =
    account &&
    account.delivery_channels_config &&
    account.delivery_channels_config.email &&
    account.delivery_channels_config.email.host

  function onFormSubmit(values) {
    const updatedDeliveryConfig = {
      ...deliveryConfig,
      ...values
    }
    onDataUpdated(updatedDeliveryConfig)
  }

  function deliveryTypeErrorMessage(delivery_type, message) {
    let errorMessage = ''
    if (parseInt(delivery_type) === emailNotificationDeliveryTypeId && !emailHost) {
      errorMessage = (
        <div>
          Before adding the e-mail notification delivery config you must{' '}
          <NavLink
            to="/notifications/smtp_server_configuration"
            style={{ textDecoration: 'underline', color: '#dc3545' }}
          >
            set the SMTP server configuration
          </NavLink>
        </div>
      )
    } else {
      errorMessage = message
    }
    return errorMessage
  }

  const validationSchema = Yup.object().shape({
    display_name: Yup.string()
      .max(150, 'Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only')
      .required('Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only')
      .test('Unique', 'Name needs to be unique', values => {
        return (
          !deliveryConfigs ||
          !deliveryConfigs
            .filter(dC => !deliveryConfigApi || dC.display_name !== deliveryConfigApi.display_name)
            .map(dC => dC.display_name)
            .includes(values)
        )
      }),
    contact_points: Yup.string()
      .max(150, 'Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only')
      .required('Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only')
      .when('delivery_type', delivery_type => {
        let deliveryType =
          notificationsDeliveryTypes && notificationsDeliveryTypes.find(nDT => nDT.id === delivery_type)
        switch (deliveryType && deliveryType.name) {
          case 'email':
            return Yup.string().matches(emailRegex, 'The email contact point must be in the email format')
          case 'webhook':
            return Yup.string().matches(httpsUrlRegex, 'The webhook contact point must be in the https url format')
          default:
            return Yup.mixed()
        }
      }),

    delivery_type: Yup.number()
      .min(1, 'Required. Select delivery type')
      .test(
        'smtp-server-config-exists',
        'Before adding the e-mail notifications delivery config you must set the SMTP server configuration',
        value => {
          return parseInt(value) !== emailNotificationDeliveryTypeId || emailHost
        }
      )
  })

  return deliveryConfig ? (
    <Formik
      initialValues={{
        delivery_type: deliveryConfig.delivery_type,
        display_name: deliveryConfig.display_name,
        contact_points: deliveryConfig.contact_points
      }}
      onSubmit={onFormSubmit}
      validationSchema={validationSchema}
      validateOnMount={true}
    >
      {({ errors, isValid, values, touched, handleChange, handleBlur }) => (
        <Card className="top-line-info">
          <Form>
            <CardHeader>
              <CardTitle>Form</CardTitle>
            </CardHeader>
            <CardBody>
              <Row>
                <Col>
                  <FormGroup>
                    <Label for="display_name">Name</Label>
                    <Input
                      name="display_name"
                      placeholder="Select name"
                      tag={Field}
                      invalid={touched.display_name && Boolean(errors.display_name)}
                      valid={touched.display_name && !Boolean(errors.display_name)}
                    />
                    <ErrorMessage name="display_name" component="div" className="invalid-feedback" />
                  </FormGroup>
                  <FormGroup>
                    <Label for="delivery_type">Delivery Type</Label>
                    <Input
                      type="select"
                      name="delivery_type"
                      value={values.delivery_type}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={touched.delivery_type && Boolean(errors.delivery_type)}
                    >
                      {!values.delivery_type && <option value={0}>Select Delivery Type</option>}
                      {notificationsDeliveryTypes &&
                        notificationsDeliveryTypes
                          .filter(dT => dT.name !== 'gui')
                          .map(deliveryType => (
                            <option key={deliveryType.id} value={deliveryType.id}>
                              {deliveryType.name}
                            </option>
                          ))}
                    </Input>
                    {touched.delivery_type ? (
                      <div className="invalid-feedback">
                        {deliveryTypeErrorMessage(values.delivery_type, errors.delivery_type)}
                      </div>
                    ) : null}
                  </FormGroup>
                  <FormGroup>
                    <Label for="contact_points">Contact Points</Label>
                    <Input
                      name="contact_points"
                      type="contact_points"
                      placeholder="Select contact points"
                      tag={Field}
                      valid={touched.contact_points && !Boolean(errors.contact_points)}
                      invalid={touched.contact_points && Boolean(errors.contact_points)}
                    />
                    <ErrorMessage name="contact_points" component="div" className="invalid-feedback" />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <Button color="info" className="float-right" type="submit" disabled={!isValid}>
                Apply
              </Button>
            </CardFooter>
          </Form>
        </Card>
      )}
    </Formik>
  ) : (
    <></>
  )
}
