import React from 'react'
import { FaSortAmountDownAlt, FaSortAmountUp, FaSortAlphaDown, FaSortAlphaDownAlt } from 'react-icons/fa'

const testSuiteStatuses = [
  {
    id: 0,
    name: 'UNKNOWN',
    display_name: 'Unknown'
  },
  {
    id: 1,
    name: 'PENDING',
    display_name: 'Pending'
  },
  {
    id: 2,
    name: 'RUNNING',
    display_name: 'Running'
  },
  {
    id: 3,
    name: 'PAUSED',
    display_name: 'Paused'
  },
  {
    id: 4,
    name: 'FINISHED',
    display_name: 'Finished'
  }
]

const taskStatuses = [
  {
    id: 0,
    name: 'UNKNOWN',
    display_name: 'Unknown'
  },
  {
    id: 4,
    name: 'SENT',
    display_name: 'Sent'
  },
  {
    id: 5,
    name: 'RECEIVED',
    display_name: 'Received'
  },
  {
    id: 6,
    name: 'STARTED',
    display_name: 'Started'
  },
  {
    id: 7,
    name: 'FINISHED',
    display_name: 'Finished'
  },
  {
    id: 8,
    name: 'FAILED',
    display_name: 'Failed'
  }
]

const testAgentStatuses = [
  {
    id: 2,
    name: 'OFFLINE',
    display_name: 'Offline'
  },
  {
    id: 3,
    name: 'ONLINE',
    display_name: 'Online'
  }
]

const SortDateIcon = ({ sort, setSort }) => {
  switch (sort) {
    case 'timestamp_created':
      return (
        <FaSortAmountDownAlt
          onClick={() => {
            setSort('-timestamp_created')
          }}
          className="clickable"
          style={{ paddingLeft: '4px', paddingBottom: '4px' }}
        />
      )
    case '-timestamp_created':
      return (
        <FaSortAmountUp
          onClick={() => {
            setSort('timestamp_created')
          }}
          className="clickable"
          style={{ paddingLeft: '4px', paddingBottom: '4px' }}
        />
      )
    default:
      return (
        <FaSortAmountUp
          onClick={() => {
            setSort('-timestamp_created')
          }}
          className="clickable"
          style={{ paddingLeft: '4px', paddingBottom: '4px', color: 'gray' }}
        />
      )
  }
}

const SortNameIcon = ({ sort, setSort }) => {
  switch (sort) {
    case 'name':
      return (
        <FaSortAlphaDown
          onClick={() => {
            setSort('-name')
          }}
          className="clickable"
          style={{ paddingLeft: '4px', paddingBottom: '2px' }}
        />
      )
    case '-name':
      return (
        <FaSortAlphaDownAlt
          onClick={() => {
            setSort('name')
          }}
          className="clickable"
          style={{ paddingLeft: '4px', paddingBottom: '2px' }}
        />
      )
    default:
      return (
        <FaSortAlphaDown
          onClick={() => {
            setSort('name')
          }}
          className="clickable"
          style={{ paddingLeft: '4px', paddingBottom: '2px', color: 'gray' }}
        />
      )
  }
}

export { taskStatuses, testSuiteStatuses, testAgentStatuses, SortDateIcon, SortNameIcon }
