import React, { Component } from 'react'
import classnames from 'classnames'
import { Button, Card, CardBody, CardFooter, CardHeader, CardTitle, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'

class CardMenu extends Component {
  state = {
    activeTab: 0
  }
  toggle = (tab) => {
    const { activeTab } = this.state
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab
      })
    }
  }
  render () {
    const { children, disabled, noSubmittable, tabs, title } = this.props
    return (
      <Card>
        <CardHeader className='d-flex p-0'>
          <CardTitle className='p-3'>{title}</CardTitle>
          <Nav pills className='ml-auto p-2'>
            {tabs && tabs.map((tab) => (
              <NavItem key={tab.id}>
                <NavLink
                  href='#'
                  className={classnames({ active: this.state.activeTab === tab.id })}
                  onClick={() => this.toggle(tab.id)}>
                  {tab.name}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
        </CardHeader>
        <CardBody>
          <TabContent activeTab={this.state.activeTab}>
            {Array.isArray(children)
              ? children.map((child, i) => (
                <TabPane key={i} tabId={i}>
                  {child}
                </TabPane>))
              : (
                <TabPane tabId={0}>
                  {children}
                </TabPane>
              )}
          </TabContent>
        </CardBody>
        { !noSubmittable &&
          <CardFooter>
            <Button
              color='info'
              className='float-right'
              type='submit'
              disabled={!(tabs && children) || disabled}>
              Apply
            </Button>
          </CardFooter>}
      </Card>
    )
  }
}

export default CardMenu
