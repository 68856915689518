import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'
import { usePrevious } from '../../utilities/customHooks'
import { ToastContent } from '../../components'

export default function ErrorsToastContainer({ children }) {
  const collectionNames = {
    workers: { singular: 'test agent', plural: 'test agents' },
    test_suites: { singular: 'test suite', plural: 'test suites' },
    test_suite_presets: { singular: 'test suite preset', plural: 'test suites presets' },
    test_agents: { singular: 'test agent', plural: 'test agents' },
    account: { singular: 'account', plural: 'accounts' },
    notifications_types: { singular: 'notification type', plural: 'notification types' },
    enabled_notifications: { singular: 'enabled notifications item', plural: 'enabled notifications list' },
    notifications_delivery_types: { singular: 'notifications delivery type', plural: 'notifications delivery types' },
    notifications_delivery_configs: { singular: 'notifications delivery config', plural: 'notifications delivery configs' },
    cloud_configs: { singular: 'cloud config', plural: 'cloud configs' },
    groups: { singular: 'group', plural: 'groups' },
    logs: { singular: 'log', plural: 'logs' },
    policies: { singular: 'policy', plural: 'policies' },
    subscriptions: { singular: 'subscription', plural: 'subscriptions' },
    users: { singular: 'user', plural: 'users' },
    updates: { singular: 'update package', plural: 'update packages' }
  }

  const errors = {
    workers: useSelector(state => state.workers.error),
    test_suites: useSelector(state => state.test_suites.error),
    test_suites_delete: useSelector(state => state.functions_data.test_suites.delete.error),
    test_suites_set_as_preset: useSelector(state => state.functions_data.test_suites.set_as_preset.error),
    test_suite_presets: useSelector(state => state.test_suite_presets.error),
    test_agents: useSelector(state => state.workers.error),
    test_agents_network_config: useSelector(state => state.functions_data.test_agents.network_config.error),
    test_agents_reboot: useSelector(state => state.functions_data.test_agents.reboot.error),
    test_agents_update: useSelector(state => state.functions_data.test_agents.update.error),
    account: useSelector(state => state.account.error),
    notifications_types: useSelector(state => state.notifications_types.error),
    enabled_notifications: useSelector(state => state.enabled_notifications.error),
    notifications_delivery_types: useSelector(state => state.notifications_delivery_types.error),
    notifications_delivery_configs: useSelector(state => state.notifications_delivery_configs.error),
    cloud_configs: useSelector(state => state.cloud_configs.error),
    groups: useSelector(state => state.groups.error),
    logs: useSelector(state => state.logs.error),
    policies: useSelector(state => state.policies.error),
    subscriptions: useSelector(state => state.subscriptions.error),
    users: useSelector(state => state.users.error),
    updates: useSelector(state => state.updates.error)
  }

  const prevErrors = {
    workers: usePrevious(errors.workers),
    test_suites: usePrevious(errors.test_suites),
    test_suites_delete: usePrevious(errors.test_suites_delete),
    test_suites_set_as_preset: usePrevious(errors.test_suites_set_as_preset),
    test_suite_presets: usePrevious(errors.test_suite_presets),
    test_agents: usePrevious(errors.test_agents),
    test_agents_network_config: usePrevious(errors.test_agents_network_config),
    test_agents_reboot: usePrevious(errors.test_agents_reboot),
    test_agents_update: usePrevious(errors.test_agents_update),
    account: usePrevious(errors.account),
    notifications_types: usePrevious(errors.notifications_types),
    enabled_notifications: usePrevious(errors.enabled_notifications),
    notifications_delivery_types: usePrevious(errors.notifications_delivery_types),
    notifications_delivery_configs: usePrevious(errors.notifications_delivery_configs),
    cloud_configs: usePrevious(errors.cloud_configs),
    groups: usePrevious(errors.groups),
    logs: usePrevious(errors.logs),
    policies: usePrevious(errors.policies),
    subscriptions: usePrevious(errors.subscriptions),
    users: usePrevious(errors.users),
    updates: usePrevious(errors.updates)
  }

  const [error, setError] = useState()
  const [lastOccurrence, setLastOccurrence] = useState()

  useEffect(() => {
    Object.keys(errors).forEach(errorCollection => {
      if (errors[errorCollection] && prevErrors[errorCollection] === null) {
        setError(errors[errorCollection])
        setLastOccurrence(errorCollection)
      }
    })
  }, [errors])

  useEffect(() => {
    let guiMessage = ''
    if (lastOccurrence === 'test_agents_network_config') {
      guiMessage = 'Error occurred when trying to update network configuration on test agent.'
    } else if (lastOccurrence === 'test_suites_delete') {
      guiMessage = 'Error occurred when trying to delete many test suites.'
    } else if (lastOccurrence === 'test_agents_reboot') {
      guiMessage = 'Error occurred when trying to reboot test agent.'
    } else if (lastOccurrence === 'test_agents_update') {
      guiMessage = 'Error occurred when trying to update test agent.'
    } else if (lastOccurrence === 'test_suites_set_as_preset') {
      guiMessage = 'Error occurred when trying to set test suite as preset.'
    }else if (error && error.response && error.response.config && error.response.config.method) {
      switch (error.response.config.method) {
        case 'delete':
          guiMessage = `Error occurred while trying to remove ${collectionNames[lastOccurrence].singular}.`
          break
        case 'patch':
          guiMessage = `Error occurred while trying to edit ${collectionNames[lastOccurrence].singular}.`
          break
        case 'post':
          guiMessage = `Error occurred while trying to add ${collectionNames[lastOccurrence].singular}.`
          break
        case 'get':
          guiMessage = `Error occurred while trying to get ${collectionNames[lastOccurrence].plural}.`
          break
        default:
          break
      }
    }
    let apiMessage = ''
    if (
      error &&
      error.response &&
      error.response.data &&
      typeof error.response.data === 'object' &&
      Object.keys(error.response.data).length > 0
    ) {
      let apiMessagesList = []
      Object.keys(error.response.data).forEach(errorKey =>
        apiMessagesList.push(
          `${
            errorKey !== 'detail' &&
            errorKey !== 'non_field_errors' &&
            isNaN(parseInt(errorKey)) /*parseInt - disable array indexes*/
              ? `${errorKey}: `
              : ''
          }${error.response.data[errorKey]}`
        )
      )
      apiMessagesList.forEach((errorValue, index) =>
        Array.isArray(errorValue)
          ? errorValue.forEach((errorVal, index) => (apiMessage += (index !== 0 ? '\n' : '') + errorVal))
          : (apiMessage += (index !== 0 ? '\n' : '') + errorValue)
      )
    } else {
      apiMessage = error && error.response && error.response.data && 'Unknown Server Error'
    }

    let noToast =
      error &&
      error.response &&
      error.response.config &&
      (error.response.config.method === 'post' && lastOccurrence === 'test_suites')
    if (!noToast && (guiMessage || apiMessage)) {
      toast.error(<ToastContent data={`${guiMessage} (${apiMessage})`} />)
    }
  }, [error])

  return (
    <>
      <ToastContainer position="top-right" newestOnTop />
      {children}
    </>
  )
}
