import React from 'react'
import { Table } from 'reactstrap'

export default function ResultHTTPSSecurity({ result, parameters }) {
  return result.succeeded === false ? (
    <Table responsive size="sm">
      <thead>
        <tr>
          <th>Certificate</th>
          <th>Succeeded</th>
          <th>Reason</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{result.certificate}</td>
          <td>{result.succeeded !== undefined && result.succeeded.toString()}</td>
          <td>{result.reason}</td>
        </tr>
      </tbody>
    </Table>
  ) : (
    <Table responsive size="sm">
      <thead>
        <tr>
          <th>URL</th>
          <th>Certificate</th>
          <th>Succeeded</th>
          <th>Response Code</th>
          <th>Time</th>
        </tr>
      </thead>
      <tbody>
        {result.map(value => (
          <tr key={value.url}>
            <td>{value.url}</td>
            <td>{value.certificate}</td>
            <td>{value.succeeded.toString()}</td>
            <td>{value.code}</td>
            <td>{Math.round(value.time_ms * 1000) / 1000} ms</td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}
