import React, { useState, useContext, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap'
import * as actions from '../../../store/actions'
import { CardTools, PaginationPanel, Spinner } from '../../../components'
import { ResultsContext } from '../../../context/ResultsContext'
import { ResultTasksList, ResultTask, ResultTasksFilter } from '../'
import TestSuiteInResults from '../../../context/TestSuiteInResultsContext'

export default function ResultTasksCard() {
  const dispatch = useDispatch()
  const token = useSelector(state => state.auth.token)
  const loading = useSelector(state => state.results.loading)
  const resultsApi = useSelector(state => state.results.data)
  const testSuite = useSelector(state => state.test_suite.data)
  const limit = 10
  const pagesCount = useSelector(state => Math.ceil(state.results.count / limit))
  const [currentPage, setCurrentPage] = useState(1)
  const { resultsWS } = useContext(ResultsContext)
  const [results, setResults] = useState([])
  const [filters, setFilters] = useState({ status: [], threshold: 0 })
  const { selectedBlock, selectedEnvBlock } = useContext(TestSuiteInResults)

  useEffect(() => {
    if (selectedBlock) {
      getResultsApi(1, selectedBlock)
    } else if (selectedEnvBlock) {
      if (selectedEnvBlock.step === 'pre') {
        getResultsApi(pagesCount, testSuite.test_blocks[0]) // get results from first block
      } else if (selectedEnvBlock.step === 'post') {
        getResultsApi(1, testSuite.test_blocks[testSuite.test_blocks.length - 1]) // get results from last block
      }
    }
  }, [selectedBlock, selectedEnvBlock])

  useEffect(() => {
    setResults(concatResultsApiAndWS())
  }, [resultsApi, resultsWS, filters])

  function concatResultsApiAndWS() {
    let _results = resultsApi || []
    if (resultsApi && currentPage === 1) {
      _results = selectedBlock
        ? [...resultsWS.filter(result => result.test_block === selectedBlock.id)]
        : [...resultsWS]
      if (_results.length < limit) {
        //concat resultsAPI to resultsWS if resultsWS.length is less by limit
        resultsApi.forEach(resultApi => {
          if (!_results.find(result => result.id === resultApi.id)) {
            _results.push(resultApi)
          }
        })
      }
      const statusFilter = (filters && filters.status) || []
      const thresholdFilter = filters && filters.threshold
      _results = _results.filter(
        result =>
          (statusFilter.length === 0 || statusFilter.map(sF => sF.id).includes(result.status)) &&
          (!thresholdFilter ||
            thresholdFilter ===
              (result.threshold_exceeded !== null && result.threshold_exceeded !== undefined
                ? result.threshold_exceeded.toString()
                : result.threshold_exceeded))
      )
    }
    if (selectedEnvBlock) {
      // work only for parameters run true/false
      _results = _results.filter(
        result =>
          result.result &&
          result.result.env_type === selectedEnvBlock.env_type &&
          ((selectedEnvBlock.step === 'pre' && result.result.parameters.run) ||
            (selectedEnvBlock.step === 'post' && !result.result.parameters.run))
      )
    } else {
      _results = _results.filter(result => !result.result || !result.result.env_type)
    }
    return _results.slice(0, limit)
  }

  function handlePageClick(e, index) {
    setCurrentPage(index)
    getResultsApi(index, selectedBlock)
  }

  function getResultsApi(page, selectedBlock) {
    if (selectedBlock || selectedEnvBlock) {
      dispatch(
        actions.fetchDataParams(token, 'RESULTS', {
          test_block: selectedBlock.id,
          limit: limit,
          offset: (page - 1) * limit,
          ordering: '-timestamp_created',
          status:
            filters.status && filters.status.length > 0 ? filters.status.map(item => item.id).join('|') : undefined,
          threshold_exceeded: filters.threshold || undefined
        })
      )
    } /*else {
      dispatch(
        actions.fetchDataParams(token, 'RESULTS', {
          test_suite: testSuite.id,
          limit: limit,
          offset: (page - 1) * limit,
          ordering: '-timestamp_created'
        })
      )
    }*/
  }

  function onChangeFilters(newFilters) {
    setCurrentPage(1)
    setFilters(newFilters)
    dispatch(
      actions.fetchDataParams(token, 'RESULTS', {
        test_block: selectedBlock.id,
        limit: limit,
        ordering: '-timestamp_created',
        status:
          newFilters.status && newFilters.status.length > 0
            ? newFilters.status.map(item => item.id).join('|')
            : undefined,
        threshold_exceeded: newFilters.threshold || undefined
      })
    )
  }

  return (
    <Card className="top-line-info">
      <CardHeader className="no-border">
        <CardTitle>Results</CardTitle>
        <CardTools>
          {selectedBlock && <ResultTasksFilter filters={filters} onChangeFilters={onChangeFilters} />}
          {selectedBlock && (
            <PaginationPanel currentPage={currentPage} pagesCount={pagesCount} handlePageClick={handlePageClick} />
          )}
        </CardTools>
      </CardHeader>
      <CardBody>
        {loading ? (
          <Spinner />
        ) : (
          <ResultTasksList results={results}>
            {results && results.length > 0 && results.map(result => <ResultTask key={result.id} result={result} />)}
          </ResultTasksList>
        )}
      </CardBody>
    </Card>
  )
}
