import { updateObject } from '../utilities'

const reducer = () => {
  const initialState = {
    ws_test_agents: null,
    ws_notifications: null,
    ws_results: null,
    ws_test_suites: null,
    ws_test_suite: null
  }

  return (state = initialState, { type, payload }) => {
    switch (type) {
      case `SET_WS_TEST_AGENTS_CONNECTION_STATUS`:
        return updateObject(state, { ws_test_agents: payload.data })
      case `SET_WS_NOTIFICATIONS_CONNECTION_STATUS`:
        return updateObject(state, { ws_notifications: payload.data })
      case `SET_WS_RESULTS_CONNECTION_STATUS`:
        return updateObject(state, { ws_results: payload.data })
      case `SET_WS_TEST_SUITES_CONNECTION_STATUS`:
        return updateObject(state, { ws_test_suites: payload.data })
      case `SET_WS_TEST_SUITE_CONNECTION_STATUS`:
        return updateObject(state, { ws_test_suite: payload.data })
      default:
        return state
    }
  }
}

export default reducer
