import React from 'react'
import { Table } from 'reactstrap'
import { Spinner, TableBody } from '../../components'
import { ToastContainer } from 'react-toastify'
import UpdateRow from './Row'

const UpdateList = ({ loading, updates }) => {
  return (
    <>
      <ToastContainer position="top-right" newestOnTop />
      {loading || (updates && updates.length > 0) ? (
        <Table className="table-valign-middle" striped>
          <thead>
            <tr>
              <th>Package Name</th>
              <th>File Name</th>
              <th>Description</th>
              <th>Timestamp Created</th>
              <th style={{ textAlign: 'center' }}>Actions</th>
            </tr>
          </thead>
          <TableBody>
            {loading ? (
              <Spinner columns={5} />
            ) : (
              updates && updates.map(update => <UpdateRow key={update.id} update={update} />)
            )}
          </TableBody>
        </Table>
      ) : (
        <div className="p-3">No Updates</div>
      )}
    </>
  )
}

export default UpdateList
