import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, Col, Form, FormGroup, Input, Row, UncontrolledAlert } from 'reactstrap'
import { Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { LoginBox, LoginLogo, LoginPage, Spinner } from '../../components'
import * as actions from '../../store/actions'
import { ToastContainer, toast } from 'react-toastify'

export default function Auth() {
  const dispatch = useDispatch()
  const isAuthenticated = useSelector(state => state.auth.token !== null)
  const authRedirectPath = useSelector(state => state.auth.authRedirectPath)
  const loading = useSelector(state => state.auth.loading)
  const error = useSelector(state => state.auth.error)
  const session_error = useSelector(state => state.auth.session_error)

  const [controls, setControls] = useState({
    username: {
      type: 'text',
      placeholder: 'Username',
      value: '',
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },
    password: {
      type: 'password',
      placeholder: 'Password',
      value: '',
      validation: {
        required: true,
        minLength: 6
      },
      valid: false,
      touched: false
    }
  })

  function checkValidity(value, rules) {
    let isValid = true
    if (!rules) {
      return true
    }
    if (rules.required) {
      isValid = value.trim() !== '' && isValid
    }
    if (rules.minLength) {
      isValid = value.length >= rules.minLength && isValid
    }
    if (rules.maxLength) {
      isValid = value.length <= rules.maxLength && isValid
    }
    if (rules.isEmail) {
      const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
      isValid = pattern.test(value) && isValid
    }
    if (rules.isNumeric) {
      const pattern = /^\d+$/
      isValid = pattern.test(value) && isValid
    }
    return isValid
  }

  function onInputChange(event, controlName) {
    const updatedControls = {
      ...controls,
      [controlName]: {
        ...controls[controlName],
        value: event.target.value,
        valid: checkValidity(event.target.value, controls[controlName].validation),
        touched: true
      }
    }
    setControls(updatedControls)
  }

  function onFormSubmitted(event) {
    event.preventDefault()
    dispatch(actions.auth(controls.username.value, controls.password.value))
  }

  useEffect(() => {
    if (authRedirectPath !== '/dashboard') {
      dispatch(actions.setAuthRedirectPath('/dashboard'))
    }
    if (session_error) {
      toast.error(session_error)
    }
  }, [])

  const formElementsArray = []
  for (let key in controls) {
    formElementsArray.push({
      id: key,
      config: controls[key]
    })
  }
  const form = formElementsArray.map(formElement => (
    <FormGroup key={formElement.id}>
      <Input
        type={formElement.config.type}
        placeholder={formElement.config.placeholder}
        value={formElement.config.value}
        invalid={!formElement.config.valid && formElement.config.touched}
        onChange={event => onInputChange(event, formElement.id)}
      />
    </FormGroup>
  ))
  let errorMessage = null
  if (error) {
    errorMessage = <UncontrolledAlert color="danger">Unsuccessful login attempt</UncontrolledAlert>
  }
  let authPage = (
    <Card>
      <CardBody className="login-card-body">
        <Form onSubmit={onFormSubmitted}>
          {errorMessage}
          {form}
          <Row>
            <Col xs="8" />
            <Col xs="4">
              <Button color="info" className="btn-block">
                Login
              </Button>
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  )
  if (loading) {
    authPage = <Spinner />
  }
  let authRedirect = null
  if (isAuthenticated) {
    authRedirect = <Redirect to={authRedirectPath} />
  }
  return (
    <LoginPage>
      <ToastContainer position="top-right" newestOnTop />
      <LoginBox>
        {authRedirect}
        <LoginLogo>
          <a href="http://www.netrsr.com/" target="blank">
            <b>Net</b>Research
          </a>
        </LoginLogo>
        {authPage}
      </LoginBox>
    </LoginPage>
  )
}
