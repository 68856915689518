import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Form, Formik, ErrorMessage, Field } from 'formik'
import {
  FormGroup,
  Label,
  Input,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardFooter,
  Col,
  Row,
  Button,
  CustomInput
} from 'reactstrap'
import * as Yup from 'yup'
import Select from 'react-select'

export default function UserEditForm({ userProp, onDataUpdated }) {
  const policies = useSelector(state => state.policies.data)
  const cloudConfigs = useSelector(state => state.cloud_configs.data)
  const subscriptions = useSelector(state => state.subscriptions.data)
  const groups = useSelector(state => state.groups.data)
  const groupOptions =
    groups &&
    groups.map(group => {
      return { label: group.name, value: group.id }
    })
  const [user, setUser] = useState(userProp)

  function onInputChange(event) {
    const name = event.target.name
    const type = event.target.type
    let value = null
    switch (type) {
      case 'checkbox':
        value = event.target.checked
        break
      case 'date':
        value = event.target.value
        break
      case 'number':
        value = Number(event.target.value)
        break
      default:
        value = event.target.value
    }
    setUser({ ...user, [name]: value })
  }

  function onFormSubmit(values) {
    const updatedUser = {
      ...user,
      ...values
    }
    if (updatedUser.password === '******') {
      delete updatedUser.password
    }
    onDataUpdated(updatedUser)
  }

  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .max(150, 'Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only')
      .required('Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only'),
    password: Yup.string()
      .min(6, 'Required. At least 6 characters or fewer. Letters, digits and @/./+/-/_ only')
      .required('Required. At least 6 characters or fewer. Letters, digits and @/./+/-/_ only'),
      //.notOneOf(['******'], 'This password is unavailable'), //disable schema, because this is default password during edit user
    first_name: Yup.string().max(30, 'First Name must be 30 characters or fewer'),
    last_name: Yup.string().max(30, 'Last Name must be 30 characters or fewer'),
    email: Yup.string()
      .max(254, 'Email must be 254 characters or fewer')
      .email('Enter a valid email address'),
    credits: Yup.number()
      .max(Math.pow(10, 9), 'Max value is 1 000 000 000')
      .min(-Math.pow(10, 9), 'Min value is -1 000 000 000')
      .required('Value cannot be empty and must be a number')
      .integer(),
    cloud_config_id: Yup.number().min(1, 'Required. Select cloud config'),
    policy_config_id: Yup.number().min(1, 'Required. Select credits policy'),
    subscription_id: Yup.number().min(1, 'Required. Select subscription')
  })

  return user ? (
    <Formik
      initialValues={{
        username: user.username,
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        credits: user.credits,
        cloud_config_id: user.cloud_config_id,
        policy_config_id: user.policy_config_id,
        subscription_id: user.subscription_id,
        group_ids: user.group_ids,
        password: '******',
        timezone: user.timezone
      }}
      onSubmit={onFormSubmit}
      validationSchema={validationSchema}
      validateOnMount={true}
    >
      {({ errors, isValid, values, touched, handleChange, handleBlur, setFieldValue }) => (
        <Card>
          <Form>
            <CardHeader>
              <CardTitle>Form</CardTitle>
            </CardHeader>
            <CardBody>
              <Row>
                <Col sm="6">
                  <FormGroup>
                    <Label for="username">Username</Label>
                    <Input
                      name="username"
                      placeholder="Select username"
                      tag={Field}
                      invalid={touched.username && Boolean(errors.username)}
                      //valid={touched.username && !Boolean(errors.username)}
                    />
                    <ErrorMessage name="username" component="div" className="invalid-feedback" />
                  </FormGroup>
                  <FormGroup>
                    <Label for="password">Password</Label>
                    <Input
                      name="password"
                      type="password"
                      placeholder="Select password"
                      tag={Field}
                      invalid={touched.password && Boolean(errors.password)}
                    />
                    <ErrorMessage name="password" component="div" className="invalid-feedback" />
                  </FormGroup>
                  <FormGroup>
                    <Label for="first_name">First Name</Label>
                    <Input
                      name="first_name"
                      placeholder="Select first name"
                      tag={Field}
                      invalid={touched.first_name && Boolean(errors.first_name)}
                    />
                    <ErrorMessage name="first_name" component="div" className="invalid-feedback" />
                  </FormGroup>
                  <FormGroup>
                    <Label for="last_name">Last Name</Label>
                    <Input
                      name="last_name"
                      placeholder="Select last name"
                      tag={Field}
                      invalid={touched.last_name && Boolean(errors.last_name)}
                    />
                    <ErrorMessage name="last_name" component="div" className="invalid-feedback" />
                  </FormGroup>
                  <FormGroup>
                    <Label for="email">Email</Label>
                    <Input
                      name="email"
                      placeholder="Select email"
                      type="email"
                      tag={Field}
                      invalid={touched.email && Boolean(errors.email)}
                    />
                    <ErrorMessage name="email" component="div" className="invalid-feedback" />
                  </FormGroup>
                  <FormGroup>
                    <Label for="credits">Credits</Label>
                    <Input
                      type="number"
                      name="credits"
                      placeholder="Select number of credits"
                      tag={Field}
                      invalid={touched.credits && Boolean(errors.credits)}
                    />
                    <ErrorMessage name="credits" component="div" className="invalid-feedback" />
                  </FormGroup>
                </Col>
                <Col sm="6">
                  <FormGroup>
                    <Label for="cloud_config_id">Cloud Config</Label>
                    <Input
                      type="select"
                      name="cloud_config_id"
                      value={values.cloud_config_id}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={touched.cloud_config_id && Boolean(errors.cloud_config_id)}
                    >
                      {!values.cloud_config_id && <option value={0}>Select cloud config</option>}
                      {cloudConfigs &&
                        cloudConfigs.map(cloudConfig => (
                          <option key={cloudConfig.id} value={cloudConfig.id}>
                            {cloudConfig.name}
                          </option>
                        ))}
                    </Input>
                    <ErrorMessage name="cloud_config_id" component="div" className="invalid-feedback" />
                  </FormGroup>
                  <FormGroup>
                    <Label for="policy_config_id">Policy</Label>
                    <Input
                      type="select"
                      name="policy_config_id"
                      value={values.policy_config_id}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={touched.policy_config_id && Boolean(errors.policy_config_id)}
                    >
                      {!values.policy_config_id && <option value={0}>Select credits policy</option>}
                      {policies &&
                        policies.map(policy => (
                          <option key={policy.id} value={policy.id}>
                            {policy.name}
                          </option>
                        ))}
                    </Input>
                    <ErrorMessage name="policy_config_id" component="div" className="invalid-feedback" />
                  </FormGroup>
                  <FormGroup>
                    <Label for="subscription_id">Subscription</Label>
                    <Input
                      type="select"
                      name="subscription_id"
                      value={values.subscription_id}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={touched.subscription_id && Boolean(errors.subscription_id)}
                    >
                      {!values.subscription_id && <option value={0}>Select subscription</option>}
                      {subscriptions &&
                        subscriptions.map(subscription => (
                          <option key={subscription.id} value={subscription.id}>
                            {subscription.subscr_id}
                          </option>
                        ))}
                    </Input>
                    <ErrorMessage name="subscription_id" component="div" className="invalid-feedback" />
                  </FormGroup>
                  <FormGroup>
                    <Label for="group_ids">Groups</Label>
                    <Select
                      id="group_ids"
                      name="group_ids"
                      isMulti
                      options={groupOptions}
                      value={groupOptions && groupOptions.filter(group => values.group_ids.includes(group.value))}
                      placeholder="Select an option from a list..."
                      onChange={e => setFieldValue('group_ids', e ? e.map(val => val.value) : [])}
                      tag={Field}
                      styles={{
                        menu: provided => ({ ...provided, zIndex: 9999, marginTop: 0 })
                      }}
                    />
                    <ErrorMessage name="group_ids" component="div" className="invalid-feedback" />
                  </FormGroup>
                  <FormGroup>
                    <CustomInput
                      type="checkbox"
                      id="is_superuser"
                      name="is_superuser"
                      label="Superuser Status"
                      checked={user.is_superuser}
                      onChange={onInputChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <Button color="info" className="float-right" type="submit" disabled={!isValid}>
                Apply
              </Button>
            </CardFooter>
          </Form>
        </Card>
      )}
    </Formik>
  ) : (
    <></>
  )
}
