import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import { ContainerFluid, Content, ContentHeader, Spinner } from '../../components'
import PolicyEditForm from './Form'
import * as actions from '../../store/actions'
import { adminURI } from '../../api/endpoints'
import { usePrevious } from '../../utilities/customHooks'

export default function PolicyEdit({ match }) {
  const isEditPage = match.path === '/policies/edit/:id'
  const defaultPolicy = {
    name: '',
    description: '',
    policy: {
      max: 100,
      interval: 300,
      increment: 50,
      data_retention: 360,
      constraints: {
        maximum_tests: 50000,
        maximum_workers: 1000,
        maximum_schedules: 100,
        maximum_cloud_workers: 200,
        maximum_cloud_locations: 25,
        maximum_days_in_advance_schedule: 30,
        test_block_count_limit: 30,
        test_stage_count_limit: 3,
        test_stage_size_limit: 20
      }
    }
  }

  const history = useHistory()
  const dispatch = useDispatch()
  const token = useSelector(state => state.auth.token)
  const policy = isEditPage ? useSelector(state => state.policy.data) : defaultPolicy
  const policies = useSelector(state => state.policies.data)
  const loading = useSelector(state => state.policy.loading || state.policies.loading)
  const error = useSelector(state => state.policies.error)
  const prevError = usePrevious(error)
  const [isAfterDispatch, setIsAfterDispatch] = useState(false)

  useEffect(() => {
    document.title = 'vUTP | Policies'
    if (isEditPage) {
      dispatch(actions.fetchDataDetails(token, 'POLICIES', match.params.id, adminURI))
    }
  }, [])

  useEffect(() => {
    if (isAfterDispatch && !prevError && !error) {
      history.push('/policies', { noFetch: true })
    }
  }, [policies])

  function onDataUpdated(data) {
    if (isEditPage) {
      dispatch(actions.updateData(token, 'POLICIES', match.params.id, data, true, adminURI))
    } else {
      dispatch(actions.addData(token, 'POLICIES', data, true, adminURI))
    }
    setIsAfterDispatch(true)
  }

  return (
    <>
      <ContentHeader title={`${isEditPage ? 'Edit' : 'Add'} Policy`} />
      <Content>
        <ContainerFluid>
          <Row>
            <Col xl="12">
              {loading ? <Spinner /> : policy && <PolicyEditForm policy={policy} onDataUpdated={onDataUpdated} />}
            </Col>
          </Row>
        </ContainerFluid>
      </Content>
    </>
  )
}
