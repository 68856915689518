import React from 'react'
import AceEditor from 'react-ace'
import 'ace-builds/src-noconflict/mode-python'
import 'ace-builds/src-noconflict/mode-json'
import 'ace-builds/src-noconflict/theme-tomorrow'
import 'ace-builds/src-noconflict/ext-language_tools'

export default function Editor(props) {
  // AceEditor with different defaults
  return (
    <AceEditor
      //name=""
      //mode=""
      //value={value}
      //onChange={e => handleChange(e)}
      //placeholder={null}
      theme="tomorrow"
      maxLines={Infinity}
      width="100%"
      fontSize={14}
      showPrintMargin={false}
      showGutter={true}
      highlightActiveLine={true}
      wrapEnabled={true}
      setOptions={{
        enableBasicAutocompletion: true,
        enableLiveAutocompletion: true,
        enableSnippets: true,
        showLineNumbers: true,
        tabSize: 2,
        useWorker: false
      }}
      {...props}
    />
  )
}
