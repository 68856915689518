import React from 'react'
import { PulseLoader } from 'react-spinners'
import { Badge } from 'reactstrap'
import { testSuiteStatuses } from '../../utilities/statuses'

const TestSuiteStatus = ({ status, className, children }) => {
  const testSuiteStatus = testSuiteStatuses.find(taskS => taskS.id === status)
  let color = ''
  if (testSuiteStatus) {
    switch (testSuiteStatus.name) {
      case 'UNKNOWN':
        color = 'dark'
        break
      case 'PENDING':
        color = 'secondary'
        break
      case 'RUNNING':
        color = 'primary'
        break
      case 'PAUSED':
        color = 'warning'
        break
      case 'FINISHED':
        color = 'success'
        break
      default:
        break
    }
    return (
      <Badge color={color} style={{ width: '80px' }} className={className}>
        {testSuiteStatus.display_name} {testSuiteStatus.name === 'RUNNING' && <PulseLoader size={5} color={'white'} />}
        {children}
      </Badge>
    )
  } else {
    return
  }
}

export default TestSuiteStatus
