import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import { ContainerFluid, Content, ContentHeader, Spinner } from '../../components'
import SubscriptionEditForm from './Form'
import * as actions from '../../store/actions'
import { adminURI } from '../../api/endpoints'
import { usePrevious } from '../../utilities/customHooks'

export default function SubscriptionEdit({ match }) {
  const isEditPage = match.path === '/subscriptions/edit/:id'
  const defaultSubscription = {
    subscr_id: '',
    period: 0,
    active: false
  }

  const history = useHistory()
  const dispatch = useDispatch()
  const token = useSelector(state => state.auth.token)
  const subscription = isEditPage ? useSelector(state => state.subscription.data) : defaultSubscription
  const subscriptions = useSelector(state => state.subscriptions.data)
  const loading = useSelector(state => state.subscription.loading || state.subscriptions.loading)
  const error = useSelector(state => state.subscriptions.error)
  const prevError = usePrevious(error)
  const [isAfterDispatch, setIsAfterDispatch] = useState(false)

  useEffect(() => {
    document.title = 'vUTP | Subscriptions'
    if (isEditPage) {
      dispatch(actions.fetchDataDetails(token, 'SUBSCRIPTIONS', match.params.id, adminURI))
    }
  }, [])

  useEffect(() => {
    if (isAfterDispatch && !prevError && !error) {
      history.push('/subscriptions', { noFetch: true })
    }
  }, [subscriptions])

  function onDataUpdated(data) {
    if (isEditPage) {
      dispatch(actions.updateData(token, 'SUBSCRIPTIONS', match.params.id, data, true, adminURI))
    } else {
      dispatch(actions.addData(token, 'SUBSCRIPTIONS', data, true, adminURI))
    }
    setIsAfterDispatch(true)
  }

  return (
    <>
      <ContentHeader title={`${isEditPage ? 'Edit' : 'Add'} Subscription`} />
      <Content>
        <ContainerFluid>
          <Row>
            <Col xl="9">
              {loading ? (
                <Spinner />
              ) : (
                subscription && <SubscriptionEditForm subscription={subscription} onDataUpdated={onDataUpdated} />
              )}
            </Col>
          </Row>
        </ContainerFluid>
      </Content>
    </>
  )
}
