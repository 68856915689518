import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap'
import { TimeChart } from '../'
import { Spinner } from '../../../components'
import TestSuiteInResults from '../../../context/TestSuiteInResultsContext'

export default function ResultThresholdHealthCard() {
  const { selectedBlock } = useContext(TestSuiteInResults)
  const loading = useSelector(state => state.results_to_charts.loading)
  return (
    <Card className="top-line-info">
      <CardHeader className="no-border">
        <CardTitle>Test Suite: Threshold Monitoring Health</CardTitle>
      </CardHeader>
      <CardBody style={{ textAlign: 'center' }}>
        {selectedBlock ? (
          !loading ? (
            <TimeChart panel_id={64} style={{ height: '100px' }} />
          ) : (
            <Spinner />
          )
        ) : (
          ' Click on the test block to show the chart.'
        )}
      </CardBody>
    </Card>
  )
}
