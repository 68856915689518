import * as Api from '../../api'

export const fetchUnseenNotifications = (token, uri) => dispatch => {
    dispatch({ type: `FETCH_UNSEEN_NOTIFICATIONS_START` })
  
    Api.fetchDataParams(token, 'notifications', { seen: false, limit: 1 }, uri)
      .then(({ data }) => dispatch({ type: `FETCH_UNSEEN_NOTIFICATIONS_SUCCESS`, payload: { data } }))
      .catch(error => dispatch({ type: `FETCH_UNSEEN_NOTIFICATIONS_FAIL`, payload: { error } }))
  }
  
  export const unseenAllNotifications = (token, uri) => dispatch => {
    dispatch({ type: `UNSEEN_ALL_NOTIFICATIONS_START` })
  
    Api.addDataFunction(token, 'notifications', 'mark_seen', null, uri)
      .then(({ data }) => {
        dispatch({ type: `UNSEEN_ALL_NOTIFICATIONS_SUCCESS`, payload: { data } })
        dispatch(fetchUnseenNotifications(token))
      })
      .catch(error => dispatch({ type: `UNSEEN_ALL_NOTIFICATIONS_FAIL`, payload: { error } }))
  }
  
  export const incrementUnseenNotificationsCount = () => dispatch => {
    dispatch({ type: `INCREMENT_UNSEEN_NOTIFICATIONS_COUNT` })
  }
  