import React, { useContext } from 'react'
import { Input, FormFeedback } from 'reactstrap'
import {
  SelectInput,
  IntegerInput,
  TextInput,
  SliderInput,
  CheckboxInput,
  PasswordInput,
  MultipleInput,
  TextareaInput,
  TextInterfaceInput,
  TextTestAgentInput
} from '../../containers'
import TestSuiteConfigurator from '../../context/TestSuiteConfiguratorContext'

const OptionInput = props => {
  const { selectedBlock, handleFormChange } = useContext(TestSuiteConfigurator)
  switch (props.option.type) {
    case 'select':
      return <SelectInput props={props} />
    case 'integer':
      return <IntegerInput props={props} />
    case 'text':
      return <TextInput props={props} />
    case 'slider':
      return <SliderInput props={props} />
    case 'checkbox':
      return <CheckboxInput props={props} />
    case 'password':
      return <PasswordInput props={props} />
    case 'multipleinput':
      return <MultipleInput props={props} />
    case 'textarea':
      return <TextareaInput props={props} />
    case 'text-interface':
      return <TextInterfaceInput props={props} />
    case 'text-test-agent':
      return <TextTestAgentInput props={props} />
    default:
      return (
        <>
          <Input
            id={props.option.name}
            value={(selectedBlock.params && selectedBlock.params[props.option.name]) || ''}
            onChange={e => {
              handleFormChange(e.target.value, props.option.name, 'params')
            }}
          />
          <FormFeedback>{props.validator.feedback[props.option.name]}</FormFeedback>
        </>
      )
  }
}

export default OptionInput
