import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import * as actions from '../../store/actions'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Spinner
} from 'reactstrap'
import { toast } from 'react-toastify'
import { createPresetMessage } from '../../utilities/testSuite'
import { usePrevious } from '../../utilities/customHooks'
import { validateTextInput, validateUniqueParam } from '../../utilities/formValidator'
import TestSuiteConfigurator from '../../context/TestSuiteConfiguratorContext'

export default function PresetModal({ testSuiteId, onlyAddPreset }) {
  const dispatch = useDispatch()
  const history = useHistory()
  const token = useSelector(state => state.auth.token)
  const presets = useSelector(state => state.test_suite_presets.data)
  const error = useSelector(state => state.test_suite_presets.error)
  const loading = useSelector(
    state => /*state.test_suite_presets.loading ||*/ state.functions_data.test_suites.set_as_preset.loading
  )
  const prevError = usePrevious(error)
  const [isAfterDispatch, setIsAfterDispatch] = useState(false)
  const [modal, setModal] = useState(false)
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [isValid, setIsValid] = useState({})
  const [feedback, setFeedback] = useState({})
  const [validatorEnabled, setValidatorEnabled] = useState({})

  const { steps, options, defaultNotificationsOptions, priorityLimit, presetId, checkAllTestsValid } = useContext(
    TestSuiteConfigurator
  )

  const toggle = () => setModal(!modal)

  function addEditPreset() {
    let message = {}
    message.display_name = name
    message.description = description
    if (testSuiteId) {
      dispatch(actions.addDataDetailsFunction(token, 'TEST_SUITES', testSuiteId, 'SET_AS_PRESET', message, true))
    } else {
      message.config = createPresetMessage(priorityLimit, options, defaultNotificationsOptions, steps)
      if (!onlyAddPreset && presetId) {
        dispatch(actions.updateData(token, 'TEST_SUITE_PRESETS', presetId, message, true))
      } else {
        dispatch(actions.addData(token, 'TEST_SUITE_PRESETS', message, true))
      }
    }
    setIsAfterDispatch(true)
  }

  useEffect(() => {
    let preset = !onlyAddPreset && presets && presets.find(pr => pr.id === presetId)
    if (preset) {
      setName(preset.display_name || '')
      setDescription(preset.description || '')
    }
  }, [presets, presetId])

  useEffect(() => {
    if (isAfterDispatch && !prevError && !error) {
      history.push('/presets', { noFetch: true })
    }
  }, [presets])

  useEffect(() => {
    if (!presets) {
      dispatch(actions.fetchData(token, 'TEST_SUITE_PRESETS'))
    }
  }, [])

  function validateName(value, paramName, display_name) {
    let nameValidator = validateTextInput(value, display_name)
    let preset = !onlyAddPreset && presets && presets.find(pr => pr.id === presetId)
    let uniqueValidator = validateUniqueParam(
      value,
      'display_name',
      display_name,
      presets,
      preset && preset.display_name
    )
    setIsValid({ ...isValid, [paramName]: nameValidator.isValid && uniqueValidator.isValid })
    setFeedback({
      ...feedback,
      [paramName]: nameValidator.feedback !== '' ? nameValidator.feedback : uniqueValidator.feedback
    })
    return nameValidator.isValid && uniqueValidator.isValid
  }

  function isValidForm() {
    let isValidName = validateName(name, 'name', 'Name')
    setValidatorEnabled({ ...validatorEnabled, name: true })
    return isValidName
  }

  return (
    <>
      <Button
        color="info"
        onClick={() => {
          if (testSuiteId || checkAllTestsValid(true)) {
            setModal(true)
          } else {
            toast.error(
              'Error occurred when trying to add/edit preset. (There is some errors in Test Configuration form.)'
            )
          }
        }}
        disabled={!testSuiteId && (steps.length === 1 || steps.slice(0, -1).some(step => step.tests.length === 0))}
      >
        {!onlyAddPreset && presetId ? 'Edit Preset' : 'Add Test Suite as Preset'}
        {loading && <Spinner style={{ width: '1.5rem', height: '1.5rem' }} />}
      </Button>
      <Modal isOpen={modal} toggle={toggle} zIndex="1200">
        <ModalHeader toggle={toggle}>
          {!onlyAddPreset && presetId ? 'Edit Preset' : 'Add Test Suite as Preset'}
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="name">Name</Label>
            <Input
              name="name"
              id="name"
              value={name}
              valid={validatorEnabled.name && isValid.name}
              invalid={validatorEnabled.name && !isValid.name}
              onChange={e => {
                setName(e.target.value)
                validateName(e.target.value, e.target.name, 'Name')
              }}
              onBlur={e => {
                setValidatorEnabled({ ...validatorEnabled, name: true })
                validateName(e.target.value, e.target.name, 'Name')
              }}
            />
            <FormFeedback>{feedback.name}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="description">Description</Label>
            <Input
              name="description"
              id="description"
              value={description}
              onChange={e => setDescription(e.target.value)}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            color="info"
            onClick={() => {
              if (isValidForm()) {
                setModal(false)
                addEditPreset()
              }
            }}
          >
            {!onlyAddPreset && presetId ? 'Edit Preset' : 'Add New Preset'}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}
