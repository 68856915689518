import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

const BasicModal = forwardRef(({ title, content, onCancel, onApply }, ref) => {
  const [modal, setModal] = useState(false)

  const toggle = () => setModal(!modal)

  useImperativeHandle(ref, () => ({
    openModal() {
      setModal(true)
    }
  }))

  return (
    <Modal isOpen={modal} toggle={toggle} zIndex="1200">
      {title && <ModalHeader>{title}</ModalHeader>}
      {content && <ModalBody>{content}</ModalBody>}
      <ModalFooter>
        <Button
          color="secondary"
          onClick={() => {
            setModal(false)
            onCancel && onCancel()
          }}
        >
          Cancel
        </Button>{' '}
        <Button
          color="info"
          onClick={() => {
            setModal(false)
            onApply && onApply()
          }}
        >
          Apply
        </Button>
      </ModalFooter>
    </Modal>
  )
})

export default BasicModal
