import React from 'react'
import { PulseLoader } from 'react-spinners'
import { Badge } from 'reactstrap'
import { taskStatuses } from '../../utilities/statuses'

const ResultTaskStatus = ({ status }) => {
  const taskStatus = taskStatuses.find(taskS => taskS.id === status)
  let color = ''
  if (taskStatus) {
    switch (taskStatus.name) {
      case 'UNKNOWN':
        color = 'dark'
        break
      case 'SENT':
      case 'RECEIVED':
      case 'STARTED':
        color = 'info'
        break
      case 'FINISHED':
        color = 'success'
        break
      case 'FAILED':
        color = 'danger'
        break
      default:
        break
    }
    return (
      <Badge color={color} style={{ width: '80px' }}>
        {taskStatus.display_name} {taskStatus.name === 'STARTED' && <PulseLoader size={5} color={'white'} />}
      </Badge>
    )
  } else {
    return
  }
}

export default ResultTaskStatus
