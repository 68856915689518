import React from 'react'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import TestTile from './TestTile'

export default function TestList({ items, tabId, testTileClick }) {
  return (
    <Droppable droppableId={`TESTS_LIST_${tabId}`} isDropDisabled={true}>
      {(provided, snapshot) => (
        <div ref={provided.innerRef} className="mb-0 p-1">
          {items.map((item, index) => (
            <Draggable key={`${item.id}_${tabId}`} draggableId={`${item.id}_${tabId}`} index={index}>
              {(provided, snapshot) => (
                <>
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className="pr-0 pb-2 w-100"
                    style={{ ...provided.draggableProps.style }}
                  >
                    <TestTile item={item} onClick={e => testTileClick(e, item)} />
                  </div>
                  {snapshot.isDragging && (
                    <>
                      <style>{`.test-tile ~ div { transform: none !important; }`}</style>
                      <div className="pr-0 pb-2 w-100 test-tile">
                        <TestTile item={item} />
                      </div>
                    </>
                  )}
                </>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  )
}
