import React from 'react'
import { Table } from 'reactstrap'

export default function ResultDNS({ result, parameters }) {
  const getKeys = function() {
    return Object.keys(result)
  }
  switch (parameters.record_type) {
    case 'A':
    case 'AAAA':
      return (
        <Table bordered style={{ textAlign: 'center' }}>
          <thead>
            <tr>
              <th>Domain name</th>
              <th>IP address</th>
              <th>DNS Server</th>
              <th>Time (ms)</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {getKeys().map(value => (
              <tr key={value}>
                <td>{result[value].domain}</td>
                <td>
                  {Array.isArray(result[value].result)
                    ? result[value].result.map(res => <div key={res}>{res}</div>)
                    : result[value].result}
                </td>
                <td>{parameters.nameserver}</td>
                <td>{result[value].succeeded && result[value].time_ms.toFixed(2)}</td>
                <td style={{ color: result[value].succeeded ? 'green' : 'red' }}>
                  {result[value].succeeded ? 'PASS' : 'FAILED'}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )
    case 'SOA':
      return (
        <Table bordered style={{ textAlign: 'center' }}>
          <thead>
            <tr>
              <th>Domain name</th>
              <th>DNS Server</th>
              <th>Serial</th>
              <th>MNAME</th>
              <th>RNAME</th>
              <th>Expire</th>
              <th>Retry</th>
              <th>Refresh</th>
              <th>TTL</th>
              <th>Time (ms)</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {getKeys().map(value => (
              <tr key={value}>
                <td>{result[value].domain}</td>
                <td>{parameters.nameserver}</td>
                <td>{result[value].result[0].serial}</td>
                <td>{result[value].result[0].mname}</td>
                <td>{result[value].result[0].rname}</td>
                <td>{result[value].result[0].expire}</td>
                <td>{result[value].result[0].retry}</td>
                <td>{result[value].result[0].refresh}</td>
                <td>{result[value].result[0].ttl}</td>
                <td>{result[value].succeeded && result[value].time_ms.toFixed(2)}</td>
                <td style={{ color: result[value].succeeded ? 'green' : 'red' }}>
                  {result[value].succeeded ? 'PASS' : 'FAILED'}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )
    case 'MX':
    case 'TXT':
      return (
        <Table bordered style={{ textAlign: 'center' }}>
          <thead>
            <tr>
              <th>Domain name</th>
              <th>DNS Server</th>
              <th>{parameters.record_type === 'TXT'? 'Text notes': 'Mail servers'}</th>
              <th>Time (ms)</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {getKeys().map(value => (
              <tr key={value}>
                <td>{result[value].domain}</td>
                <td>{parameters.nameserver}</td>
                <td>
                  {Array.isArray(result[value].result)
                    ? result[value].result.map(res => <div key={res}>{res}</div>)
                    : result[value].result}
                </td>
                <td>{result[value].succeeded && result[value].time_ms.toFixed(2)}</td>
                <td style={{ color: result[value].succeeded ? 'green' : 'red' }}>
                  {result[value].succeeded ? 'PASS' : 'FAILED'}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )
    default:
      return ''
  }
}
