import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap'
import { Spinner } from '../../components'
import { TestAgentsList, TestAgent } from '../TestAgents'
import { TestAgentsContext } from '../../context/TestAgentsContext'

export default function TestAgentsMini() {
  const limit = 5
  const { testAgents } = useContext(TestAgentsContext)
  const loading = useSelector(state => state.workers.loading)

  return (
    <Card className="top-line-info" style={{ height: '97%' }}>
      <CardHeader className="no-border">
        <CardTitle>Test Agents</CardTitle>
      </CardHeader>
      <CardBody>
        {loading ? (
          <Spinner />
        ) : (
          <>
            {testAgents && (
              <TestAgentsList workers={testAgents} mini sortDisabled>
                {testAgents.slice(0, limit).map(testAgent => (
                  <TestAgent key={testAgent.id} worker={testAgent} mini />
                ))}
              </TestAgentsList>
            )}
            {testAgents && testAgents.length > limit && <NavLink to="/test-agents">See more test agents...</NavLink>}
          </>
        )}
      </CardBody>
    </Card>
  )
}
