import React, { createContext, useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import WSProvider from './WSProvider'

export const TestSuiteContext = createContext({})

export default function TestSuiteProvider({ children, testSuiteId }) {
  const token = useSelector(state => state.auth.token)
  const testSuiteApi = useSelector(state => state.test_suite.data)
  const [testSuite, setTestSuite] = useState()
  const ws = useRef(null)
  const [wsActive, setWsActive] = useState(false)
  const ws_scheme = process.env.REACT_APP_HTTPS ? 'wss' : 'ws'

  useEffect(() => {
    setTestSuite(testSuiteApi)
    ws.current = new WebSocket(`${ws_scheme}://${window.location.host}/ws/test_suites/${testSuiteId}/?_token=${token}`)
  }, [testSuiteApi])

  useEffect(() => {
    if (ws && ws.current) {
      setWsActive(true)
      ws.current.onmessage = evt => {
        // listen to data sent from the websocket server
        const message = JSON.parse(evt.data)
        setTestSuite(message)
      }
    }
  }, [ws && ws.current])

  return (
    ws &&
    wsActive &&
    ws.current && (
      <WSProvider ws={ws} contextName="test_suites" contextSubname={testSuiteId} storeKeyName="test_suite">
        <TestSuiteContext.Provider
          value={{
            testSuite: testSuite
          }}
        >
          {children}
        </TestSuiteContext.Provider>
      </WSProvider>
    )
  )
}
