import React, { useContext } from 'react'
import { Input, FormFeedback } from 'reactstrap'
import { testAgentStatuses } from '../../utilities/statuses'
import { TestAgentsContext } from '../../context/TestAgentsContext'
import TestSuiteConfigurator from '../../context/TestSuiteConfiguratorContext'

const TextTestAgentInput = ({ props }) => {
  const { option, validator, removeValidationParamsFromMultipleInput, handleBlur, validateInput, ...otherProps } = props
  const { selectedBlock, handleFormChange } = useContext(TestSuiteConfigurator)

  const { testAgents } = useContext(TestAgentsContext)

  return (
    <>
      <Input
        type="select"
        id={option.name}
        name={option.name}
        valid={validator.enabled[option.name] && validator.isValid[option.name] === true}
        invalid={validator.enabled[option.name] && validator.isValid[option.name] === false}
        value={(selectedBlock.params && selectedBlock.params[option.name]) || ''}
        onChange={e => {
          handleFormChange(parseInt(e.target.value), option.name, 'params')
          option.attributes.required &&
            validateInput(option.name, 'text', e.target.value, option.label, {
              validators: option.validators
            })
        }}
        onBlur={e => {
          option.attributes.required && handleBlur(e.target.name)
          option.attributes.required &&
            validateInput(option.name, 'text', e.target.value, option.label, {
              validators: option.validators
            })
        }}
        onClick={e => {
          e.stopPropagation()
        }}
        {...otherProps}
      >
        <option value=""></option>
        {testAgents
          .filter(
            worker =>
              testAgentStatuses.some(tAS => tAS.id === worker.status) &&
              testAgentStatuses.find(tAS => tAS.id === worker.status).name === 'ONLINE'
          )
          .map(testAgent => (
            <option key={testAgent.id} value={parseInt(testAgent.id)}>
              {testAgent.name}
            </option>
          ))}
      </Input>
      <FormFeedback>{validator.feedback[option.name]}</FormFeedback>
    </>
  )
}

export default TextTestAgentInput
