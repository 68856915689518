import React, { useState, useEffect, useRef, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Col, Row, Card, CardBody, CardHeader, CardTitle } from 'reactstrap'
import { toast } from 'react-toastify'
import { ContainerFluid, Content, ContentHeader, Spinner, ButtonFixed, CardTools } from '../../components'
import { IncompatibleTestsAlert, TestSuiteErrorToastContainer } from '../../containers'
import * as actions from '../../store/actions'
import TestConfiguration from './TestConfiguration'
import AdditionalConfiguration from './AdditionalConfiguration'
import TestSuite from './TestSuite/TestSuite'
import TestSchedule from './TestSchedule'
import AdvancedSettings from './AdvancedSettings'
import { TestAgentsContext } from '../../context/TestAgentsContext'
import { validateTestForm } from '../../utilities/formValidator'
import { createTestSuiteMessage, createRerunParams, getDefaultNotificationsOptions } from '../../utilities/testSuite'
import { FaAngleDoubleDown, FaTimes } from 'react-icons/fa'
import TestSuiteConfigurator from '../../context/TestSuiteConfiguratorContext'

export default function Test({ location }) {
  const testsDetails = useSelector(state => state.task_types.data)
  const testCategoriesDetails = useSelector(state => state.test_categories_details.data)
  const notificationsDeliveryConfigs = useSelector(state => state.notifications_delivery_configs.data)
  const loading = useSelector(
    state => state.task_types.loading || state.test_categories_details.loading || state.enabled_notifications.loading
  )
  const token = useSelector(state => state.auth.token)
  const { testAgents } = useContext(TestAgentsContext)
  const locations = useSelector(state => state.locations.data)
  const createdTest = useSelector(state => state.created_test.data)
  const enabledNotifications = useSelector(state => state.enabled_notifications.data)
  const notificationsTypes = useSelector(state => state.notifications_types.data)
  const dispatch = useDispatch()
  const testConfigurationRef = useRef()
  const history = useHistory()

  const [testname, setTestname] = useState('')
  const [globalLocations, setGlobalLocations] = useState([])
  const [priorityLimit, setPriorityLimit] = useState(0)
  const [defaultNotificationsOptions, setDefaultNotificationsOptions] = useState()
  const [options, setOptions] = useState({ thresholds: { enabled: false, code: '' } })

  const [steps, setSteps] = useState([{ tests: [] }])
  const [selectedBlock, setSelectedBlock] = useState(null)
  const [selTestIndex, setSelTestIndex] = useState(null)
  const [selStepIndex, setSelStepIndex] = useState(null)
  const [activationValidationMode, setActivationValidationMode] = useState()
  const testDetails = testsDetails && selectedBlock && testsDetails.find(test => test.id === selectedBlock.task_type)

  let startDateMonitoring = new Date()
  startDateMonitoring.setMilliseconds(Math.ceil(startDateMonitoring.getMilliseconds() / 1000) * 1000)
  startDateMonitoring.setSeconds(Math.ceil(startDateMonitoring.getSeconds() / 60) * 60)
  startDateMonitoring.setMinutes(Math.ceil(startDateMonitoring.getMinutes() / 15) * 15)
  let endDateMonitoring = new Date(startDateMonitoring)
  endDateMonitoring.setHours(endDateMonitoring.getHours() + 1)
  const [schedule, setSchedule] = useState({
    enabled: false,
    start_monitoring_date: startDateMonitoring,
    end_monitoring_date: endDateMonitoring,
    repeat_interval: 600
  })

  const [presetId, setPresetId] = useState()

  const [isTestConfigurationOnScreen, setIsTestConfigurationOnScreen] = useState(false)

  useEffect(() => {
    document.title = 'vUTP | Test Suite'
    window.addEventListener('scroll', scrollHandler)
    return () => window.removeEventListener('scroll', scrollHandler)
  }, [])

  const scrollHandler = () => {
    if (
      testConfigurationRef.current &&
      window.pageYOffset + window.innerHeight >= testConfigurationRef.current.getBoundingClientRect().top + 300
    ) {
      setIsTestConfigurationOnScreen(true)
    } else {
      setIsTestConfigurationOnScreen(false)
    }
  }

  useEffect(() => {
    setSelTestIndexes(steps)
  }, [selectedBlock])

  useEffect(() => {
    if (createdTest && createdTest.id) {
      history.push(`/results/details/${createdTest.id}`)
    }
  }, [createdTest])

  useEffect(() => {
    if (enabledNotifications) {
      let defaultNotifOptions = getDefaultNotificationsOptions(enabledNotifications, notificationsTypes)
      setOptions({ ...options, notifications: defaultNotifOptions })
      setDefaultNotificationsOptions(defaultNotifOptions)
    }
  }, [enabledNotifications, notificationsTypes])

  useEffect(() => {
    if (
      location &&
      location.state &&
      location.state.rerunTest &&
      testsDetails &&
      testCategoriesDetails &&
      defaultNotificationsOptions
    ) {
      let rerunParams = createRerunParams(
        location.state.rerunTest,
        testAgents,
        locations,
        testsDetails,
        testCategoriesDetails,
        defaultNotificationsOptions,
        notificationsDeliveryConfigs
      )
      setTestname(rerunParams.testname)
      setGlobalLocations(rerunParams.globalLocations)
      setPriorityLimit(rerunParams.priorityLimit)
      setSteps(rerunParams.steps)
      setOptions(rerunParams.options)
    }
  }, [
    location && location.state && location.state.rerunTest,
    testsDetails,
    testCategoriesDetails,
    defaultNotificationsOptions,
    notificationsDeliveryConfigs
  ])

  useEffect(() => {
    if (location && location.state && location.state.presetId) {
      setPresetId(location.state.presetId)
    }
  }, [location && location.state && location.state.presetId])

  function setSelTestIndexes(steps) {
    if (selectedBlock) {
      let selectedBlockExist = false
      steps.forEach((step, step_index) => {
        let test_index = step.tests.findIndex(test => test.tile_id === selectedBlock.tile_id)
        if (test_index > -1) {
          setSelTestIndex(test_index)
          setSelStepIndex(step_index)
          selectedBlockExist = true
        }
      })
      if (!selectedBlockExist) {
        setSelTestIndex(null)
        setSelStepIndex(null)
        setSelectedBlock(null)
      }
    }
  }

  function setSmallDescriptionText() {
    let smallDescriptionText = ''
    if (testDetails.on_premises_available && testDetails.cloud_available) {
      // workers and cloud
      smallDescriptionText = 'This test can be run both on cloud and onsite agents.'
    } else if (testDetails.on_premises_available && !testDetails.cloud_available) {
      // only workers
      smallDescriptionText = 'This test can be run only on onsite agents.'
    } else if (!testDetails.on_premises_available && testDetails.cloud_available) {
      // only cloud
      smallDescriptionText = 'This test can be run only on cloud agents.'
    }
    return smallDescriptionText
  }

  function handleFormChange(value, paramName, parentName) {
    let _test = steps[selStepIndex].tests[selTestIndex]
    if (parentName) {
      _test[parentName][paramName] = value
    } else {
      _test[paramName] = value
    }
    setTestInSteps(_test)
  }

  function handleMultipleInputChange(value, index, paramName, parentName) {
    let _test = steps[selStepIndex].tests[selTestIndex]
    if (parentName) {
      _test[parentName][paramName][index] = value
    } else {
      _test[paramName][index] = value
    }
    setTestInSteps(_test)
  }

  function addInputToMultiple(paramName, parentName) {
    let _test = steps[selStepIndex].tests[selTestIndex]
    _test[parentName][paramName].push('')
    setTestInSteps(_test)
  }

  function removeInputFromMultiple(paramName, parentName, index) {
    let _test = steps[selStepIndex].tests[selTestIndex]
    _test[parentName][paramName].splice(index, 1)
    setTestInSteps(_test)
  }

  function handleOverrideLocations(value) {
    let _test = steps[selStepIndex].tests[selTestIndex]
    _test.overrideLocations = value
    if (!value) {
      _test.locations = globalLocations
    }
    setTestInSteps(_test)
  }

  function handleGlobalLocations(e) {
    setGlobalLocations(e)
    let _steps = [...steps]
    _steps.forEach(step =>
      step.tests.forEach(test => {
        if (!test.overrideLocations) {
          test.locations = e
        }
      })
    )
    setSteps(_steps)
  }

  function handleAdditionalConfigurationChange(value, name, configurationName) {
    let _test = steps[selStepIndex].tests[selTestIndex]
    _test.additional_options[configurationName][name] = value
    setTestInSteps(_test)
  }

  function handleThresholdsChange(value, error, mode = 'thresholds') {
    let _test = steps[selStepIndex].tests[selTestIndex]
    _test.additional_options.thresholds[mode] = value
    _test.additional_options.thresholds.error = error
    setTestInSteps(_test)
  }

  function setTestInSteps(_test) {
    setSteps(
      steps.map((step, index) =>
        index === selStepIndex
          ? { ...step, tests: step.tests.map((test, _index) => (_index === selTestIndex ? _test : test)) }
          : step
      )
    )
  }

  function checkAllTestsValid(withoutLocations) {
    let invalidTestStepNumber = -1
    let invalidTestIndex = -1
    steps.some((step, step_number) =>
      step.tests.some((test, test_index) => {
        const _testDetails = testsDetails.find(tDetails => tDetails.name === test.task_type_name)
        if (_testDetails) {
          let validResult = validateTestForm(_testDetails, test, withoutLocations)
          if (!validResult.testValid) {
            invalidTestStepNumber = step_number
            invalidTestIndex = test_index
            setSelTestIndex(invalidTestIndex)
            setSelStepIndex(invalidTestStepNumber)
            setSelectedBlock(steps[invalidTestStepNumber].tests[invalidTestIndex])
          }
          return !validResult.testValid
        }
        return true
      })
    )
    setActivationValidationMode(withoutLocations ? 'preset' : 'test_suite')
    return invalidTestStepNumber === -1
  }

  function submitTest(isScheduleValid) {
    if (isScheduleValid) {
      if (checkAllTestsValid()) {
        let message = createTestSuiteMessage(
          testname,
          schedule,
          globalLocations,
          priorityLimit,
          options,
          defaultNotificationsOptions,
          steps
        )
        dispatch(actions.addData(token, `TEST_SUITES`, message))
        //toast.success('Test has been requested.')
      } else {
        toast.error('Error occurred when trying to run test suite. (There is some errors in Test Configuration form.)')
        testConfigurationRef.current.scrollIntoView({
          behavior: 'smooth'
        })
      }
    } else {
      toast.error('Error occurred when trying to run test suite. (There is some errors in Settings form.)')
    }
  }

  return loading ? (
    <Spinner />
  ) : (
    <>
      <ContentHeader title="Test Suite" breadcrumbs={['Test Suite']} />
      <Content>
        <ContainerFluid>
          <TestSuiteConfigurator.Provider
            value={{
              selectedBlock,
              setSelectedBlock,
              steps,
              setSteps,
              setSelTestIndexes,
              testname,
              setTestname,
              schedule,
              setSchedule,
              globalLocations,
              handleGlobalLocations,
              options,
              setOptions,
              defaultNotificationsOptions,
              priorityLimit,
              setPriorityLimit,
              presetId,
              checkAllTestsValid,
              handleFormChange,
              handleMultipleInputChange,
              handleOverrideLocations,
              addInputToMultiple,
              removeInputFromMultiple,
              handleThresholdsChange,
              handleAdditionalConfigurationChange,
              submitTest
            }}
          >
            <TestSuiteErrorToastContainer />
            <IncompatibleTestsAlert
              test_blocks={
                location && location.state && location.state.rerunTest && location.state.rerunTest.test_blocks
              }
            />
            <Row>
              <Col>
                <TestSchedule
                  disabledButton={steps.length === 1 || steps.slice(0, -1).some(step => step.tests.length === 0)}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <AdvancedSettings />
              </Col>
            </Row>
            <Row>
              <Col xl="12" lg="12" md="12" sm="12" xs="12">
                <TestSuite />
              </Col>
            </Row>

            {testDetails && (
              <>
                <Row>
                  <Col>
                    <Card className="top-line-info">
                      <CardBody>
                        {testDetails.description}
                        <br />
                        <small className="text-danger">{setSmallDescriptionText()}</small>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Card className="top-line-info">
                      <CardHeader className="no-border">
                        <CardTitle>Test Block Configuration</CardTitle>
                        <CardTools>
                          <FaTimes
                            onClick={() => {
                              setSelectedBlock(null)
                              setSelTestIndex(null)
                              setSelStepIndex(null)
                            }}
                          />
                        </CardTools>
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col md="6">
                            <TestConfiguration
                              ref={testConfigurationRef}
                              activationValidationMode={activationValidationMode}
                            />
                          </Col>
                          <Col md="6">
                            <AdditionalConfiguration />
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                {selectedBlock &&
                  (!isTestConfigurationOnScreen && (
                    <ButtonFixed
                      style={{ width: '600px' }}
                      onClick={() =>
                        testConfigurationRef.current.scrollIntoView({
                          behavior: 'smooth'
                        })
                      }
                    >
                      Test Configuration <FaAngleDoubleDown />
                    </ButtonFixed>
                  ))}
              </>
            )}
          </TestSuiteConfigurator.Provider>
        </ContainerFluid>
      </Content>
    </>
  )
}
