import React, { Fragment, useContext } from 'react'
import { Input, FormFeedback, Button, InputGroup, InputGroupAddon } from 'reactstrap'
import { FaTimes } from 'react-icons/fa'
import TestSuiteConfigurator from '../../context/TestSuiteConfiguratorContext'

const MultipleInput = ({ props }) => {
  const { option, validator, removeValidationParamsFromMultipleInput, handleBlur, validateInput, ...otherProps } = props
  const { selectedBlock, handleMultipleInputChange, addInputToMultiple, removeInputFromMultiple } = useContext(
    TestSuiteConfigurator
  )
  return (
    <>
      {selectedBlock.params && selectedBlock.params[option.name] && Array.isArray(selectedBlock.params[option.name]) && (
        <>
          {selectedBlock.params[option.name].map((singleValue, index) => (
            <Fragment key={index}>
              <InputGroup>
                <Input
                  id={`${option.name}_${index}`}
                  value={singleValue || ''}
                  name={option.name}
                  valid={
                    validator.enabled[option.name] &&
                    validator.enabled[option.name][index] &&
                    validator.isValid[option.name] &&
                    validator.isValid[option.name][index] === true
                  }
                  invalid={
                    validator.enabled[option.name] &&
                    validator.enabled[option.name][index] &&
                    validator.isValid[option.name] &&
                    validator.isValid[option.name][index] === false
                  }
                  onChange={e => {
                    handleMultipleInputChange(e.target.value.trim(), index, option.name, 'params')
                    validateInput(e.target.name, 'multipleinput', e.target.value.trim(), option.label, {
                      validators: option.validators,
                      index: index
                    })
                  }}
                  onBlur={e => {
                    handleBlur(option.name, index)
                    validateInput(e.target.name, 'multipleinput', e.target.value, option.label, {
                      validators: option.validators,
                      index: index
                    })
                  }}
                  onClick={e => {
                    e.stopPropagation()
                  }}
                  {...otherProps}
                />
                {index > 0 && (
                  <InputGroupAddon addonType="append">
                    <Button
                      outline
                      onClick={e => {
                        e.preventDefault()
                        removeInputFromMultiple(option.name, 'params', index)
                        removeValidationParamsFromMultipleInput(option.name, index)
                      }}
                    >
                      <FaTimes />
                    </Button>
                  </InputGroupAddon>
                )}
                {validator.feedback[option.name] && (
                  <FormFeedback>{validator.feedback[option.name][index]}</FormFeedback>
                )}
              </InputGroup>
              <div style={{ marginBottom: '10px' }}></div>
            </Fragment>
          ))}
          <Button color="info" onClick={e => addInputToMultiple(option.name, 'params')}>
            Add
          </Button>
        </>
      )}
    </>
  )
}

export default MultipleInput
