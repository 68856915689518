import React from 'react'

const GrafanaFrame = ({
  panel_id,
  test_block_id,
  selectedTimerange,
  refreshFlag,
  style,
  title,
  rerenderGraphCounter
}) => {
  const srcParams = []
  if (selectedTimerange) {
    srcParams.push(`from=${selectedTimerange.from}&to=${selectedTimerange.to}`)
  }
  if (refreshFlag) {
    srcParams.push('refresh=1m&')
  }
  srcParams.push('orgId=1')
  srcParams.push(`var-token=${localStorage.getItem('token')}`)
  if (test_block_id) {
    srcParams.push(`var-block_id=${test_block_id}`)
  }
  srcParams.push('theme=light')
  if (panel_id) {
    srcParams.push(`panelId=${panel_id}`)
  }
  srcParams.push(`rerender=${rerenderGraphCounter}`) // set only for rerender frame
  return (
    <iframe
      src={`/grafana/d-solo/4bV0IfYGk/vutp-test-dashboard${srcParams.length > 0 ? `?${srcParams.join('&')}` : ''}`}
      style={style}
      title={title}
      frameBorder="0"
    />
  )
}

export default GrafanaFrame
