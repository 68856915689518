import React from 'react'
import { Table } from 'reactstrap'
import { Spinner, TableBody } from '../../components'
import CloudConfigRow from './Row'

const CloudConfigList = ({ loading, cloudConfigs }) => {
  return loading || (cloudConfigs && cloudConfigs.length > 0) ? (
    <Table className="table-valign-middle" striped>
      <thead>
        <tr>
          <th>Name</th>
          <th style={{ textAlign: 'center' }}>Actions</th>
        </tr>
      </thead>
      <TableBody>
        {loading ? (
          <Spinner columns={2} />
        ) : (
          cloudConfigs &&
          cloudConfigs.map(cloudConfig => <CloudConfigRow key={cloudConfig.id} cloudConfig={cloudConfig} />)
        )}
      </TableBody>
    </Table>
  ) : (
    <div className="p-3">No Cloud Configs</div>
  )
}

export default CloudConfigList
