import React, { useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody, CardHeader, CardTitle, Table, Button } from 'reactstrap'
import { FaMicrochip, FaCloud } from 'react-icons/fa'
import { convertJsonStringToLabel } from '../../../utilities/stringFormatting'
import TestSuiteInResults from '../../../context/TestSuiteInResultsContext'

export default function ResultParametersCard() {
  const { selectedBlock } = useContext(TestSuiteInResults)
  const taskTypes = useSelector(state => state.task_types.data)
  const taskType = taskTypes && taskTypes.find(task_type => task_type.name === selectedBlock.task_type_name)
  const locations = useSelector(state => state.locations.data)
  const workers = useSelector(state => state.workers.data)
  const testSuite = useSelector(state => state.test_suite.data)

  const globalLocations =
    selectedBlock.cloud_locations.length + selectedBlock.on_premise_test_agents.length > 0
      ? { cloud_locations: [], on_premise_test_agents: [] }
      : {
          cloud_locations: testSuite.cloud_locations,
          on_premise_test_agents: testSuite.on_premise_test_agents
        }

  const [thresholdsResultOpened, setThresholdsResultOpened] = useState(
    selectedBlock.options.thresholds && Object.keys(selectedBlock.options.thresholds).length === 0
  )

  function setParamValue(paramName) {
    let value = ''
    if (taskType && taskType.options.params.some(param => param.name === paramName)) {
      switch (taskType.options.params.find(param => param.name === paramName).type) {
        case 'text':
        case 'textarea':
        case 'text-interface':
          value = selectedBlock.options.params[paramName]
          break
        case 'password':
          value = selectedBlock.options.params[paramName] && '*'.repeat(selectedBlock.options.params[paramName].length)
          break
        case 'integer':
        case 'slider':
          value = selectedBlock.options.params[paramName]
          break
        case 'checkbox':
          value =
            selectedBlock.options.params[paramName] === false
              ? 'false'
              : selectedBlock.options.params[paramName].toString()
          break
        case 'select':
          let taskParam = taskType.options.params.find(param => param.name === paramName)
          let choice =
            taskParam &&
            taskParam.attributes &&
            taskParam.attributes.choices &&
            Array.isArray(taskParam.attributes.choices) &&
            taskParam.attributes.choices.find(choice => choice.value === selectedBlock.options.params[paramName])
          value = choice && choice.name
          break
        case 'multipleinput':
          value = Array.isArray(selectedBlock.options.params[paramName])
            ? selectedBlock.options.params[paramName].map(val => {
                return <div key={val}>{val}</div>
              })
            : selectedBlock.options.params[paramName].toString()
          break
        case 'text-test-agent':
          let testAgent = workers && workers.find(worker => worker.id === selectedBlock.options.params[paramName])
          value = testAgent && `${testAgent.name} (#${testAgent.id})`
          break
        default:
          break
      }
    } else {
      value =
        selectedBlock.options.params[paramName] !== undefined &&
        selectedBlock.options.params[paramName] !== null &&
        (Array.isArray(selectedBlock.options.params[paramName])
          ? selectedBlock.options.params[paramName].map(val => {
              return <div key={val}>{val}</div>
            })
          : selectedBlock.options.params[paramName].toString())
    }
    return value
  }

  return (
    <Card className="top-line-info" style={{ height: '95%' }}>
      <CardHeader className="no-border">
        <CardTitle>Parameters</CardTitle>
      </CardHeader>
      <CardBody>
        <Table responsive size="sm" borderless className="mb-0">
          <tbody>
            <tr>
              <th>Test</th>
              <td style={{ textAlign: 'right' }}>{taskType && taskType.display_name}</td>
            </tr>
            <tr>
              <th>Locations</th>
              <td style={{ textAlign: 'right' }}>
                {selectedBlock.cloud_locations.length +
                  globalLocations.cloud_locations.length +
                  selectedBlock.on_premise_test_agents.length +
                  globalLocations.on_premise_test_agents.length >
                5 ? (
                  <>
                    {locations &&
                      selectedBlock.cloud_locations
                        .concat(globalLocations.cloud_locations)
                        .map((location, index, array) => (
                          <div key={index} className="d-inline-block">
                            {locations.find(_location => _location.id === location).name} <FaCloud />
                            {index !== array.length - 1 && ', '}
                          </div>
                        ))}
                  </>
                ) : (
                  <>
                    {locations &&
                      selectedBlock.cloud_locations.concat(globalLocations.cloud_locations).map(location => (
                        <p key={location} className="mb-0">
                          {locations.find(_location => _location.id === location).name} <FaCloud />
                        </p>
                      ))}
                    {workers &&
                      selectedBlock.on_premise_test_agents
                        .concat(globalLocations.on_premise_test_agents)
                        .map(worker => (
                          <p key={worker} className="mb-0">
                            {workers.some(_worker => _worker.id === worker)
                              ? workers.find(_worker => _worker.id === worker).name
                              : '(Deleted test agent)'}{' '}
                            <FaMicrochip />
                          </p>
                        ))}
                  </>
                )}
              </td>
            </tr>
            {selectedBlock && selectedBlock.options && selectedBlock.options.priority ? (
              <tr>
                <th>Notifications Priority</th>
                <td className="text-right">{selectedBlock.options.priority}</td>
              </tr>
            ) : null}
            {selectedBlock &&
              selectedBlock.options &&
              selectedBlock.options.params &&
              taskType &&
              taskType.options &&
              taskType.options.params &&
              Object.keys(selectedBlock.options.params).map(option => (
                <tr key={option}>
                  <th>
                    {taskType.options.params.some(param => param.name === option)
                      ? taskType.options.params.find(param => param.name === option).label
                      : convertJsonStringToLabel(option)}
                  </th>
                  <td style={{ textAlign: 'right', wordBreak: 'break-word' }}>{setParamValue(option)}</td>
                </tr>
              ))}
            {selectedBlock.options.thresholds && selectedBlock.options.thresholds.enabled ? (
              <tr>
                <th>Thresholds</th>
                <td className="float-right">
                  <Button
                    color="link"
                    className="p-0 float-right text-right"
                    onClick={() => setThresholdsResultOpened(prev => !prev)}
                  >
                    {`${thresholdsResultOpened ? 'Hide' : 'Show'} param value`}
                  </Button>
                  <br />
                  <pre className="p-0 m-0 float-right">
                    {thresholdsResultOpened &&
                      (selectedBlock.options.thresholds.mode === 'python'
                        ? selectedBlock.options.thresholds.code
                        : JSON.stringify(selectedBlock.options.thresholds.configuration, null, '\t'))}
                  </pre>
                </td>
              </tr>
            ) : null}
            {testSuite.options['5g_config'] && Object.keys(testSuite.options['5g_config']).length > 0 && (
              <tr>
                <th>5G Enabled</th>
                <td style={{ textAlign: 'right' }}>{selectedBlock.options['5g_enabled'].toString()}</td>
              </tr>
            )}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  )
}
