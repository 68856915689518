import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Dropdown, DropdownMenu, DropdownToggle, Button, Row, Col } from 'reactstrap'
import Moment from 'react-moment'
import user from '../../assets/user.png'

export default function UserMenuDropdown({ account }) {
  const [userProfileDropdown, setUserProfileDropdown] = useState(false)

  return (
    <li className="user-menu">
      <Dropdown isOpen={userProfileDropdown} toggle={() => setUserProfileDropdown(prevState => !prevState)}>
        <DropdownToggle tag="span" className="nav-link px-0">
          <img src={user} className="user-image img-circle elevation-2 mr-0" alt="User" />
          <span className="d-none d-sm-inline-block ml-1">{account.username}</span>
        </DropdownToggle>
        <DropdownMenu
          right
          className="pt-0 px-2"
          modifiers={{
            setWidth: {
              enabled: true,
              order: 890,
              fn: data => {
                return {
                  ...data,
                  styles: {
                    ...data.styles,
                    width: '300px'
                  }
                }
              }
            }
          }}
        >
          <Row className="bg-info">
            <img src={user} className="img-circle elevation-2 m-2 mx-auto" alt="User" height="100px" />
          </Row>
          <Row className="bg-info">
            <span className="mx-auto">
              {account.first_name} {account.last_name}
            </span>
          </Row>
          <Row className="bg-info">
            <span className="mx-auto">
              <small>
                Member since <Moment format="DD-MM-YYYY HH:mm:ss">{account.date_joined}</Moment>
              </small>
            </span>
          </Row>
          <Row className="pt-2">
            <Col sm={6}>
              <Button block outline color="info" size="sm" onClick={() => setUserProfileDropdown(false)}>
                <NavLink to="/account">Account Info</NavLink>
              </Button>
            </Col>
            <Col sm={6}>
              <Button block outline color="info" size="sm" onClick={() => setUserProfileDropdown(false)}>
                <NavLink to="/account/change_password">Change Password</NavLink>
              </Button>
            </Col>
          </Row>
        </DropdownMenu>
      </Dropdown>
    </li>
  )
}
