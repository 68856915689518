import React, { useState } from 'react'
import { Form, Formik, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import {
  FormGroup,
  Label,
  Input,
  Col,
  Row,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter,
  Button,
  CustomInput,
  Alert
} from 'reactstrap'
import { CardTools, Editor } from '../../components'

export default function CloudConfigEditForm({ cloudConfigProp, onDataUpdated }) {
  const [cloudConfig, setCloudConfig] = useState(cloudConfigProp)
  const cloudKeys = [
    { paramName: 'api_host', label: 'API Host' },
    { paramName: 'api_port', label: 'API Port' },
    { paramName: 'broker_host', label: 'Broker Host' },
    { paramName: 'broker_port', label: 'Broker Port' },
    { paramName: 'gcp_service_account_email', label: 'GCP Service Account Email' },
    { paramName: 'gcp_project_id', label: 'GCP Project Id' },
    { paramName: 'gcp_key_content', label: 'GCP Key Content', type: 'textarea' },
    { paramName: 'aws_key_id', label: 'AWS Key Id' },
    { paramName: 'aws_key_content', label: 'AWS Key Content', type: 'textarea' },
    { paramName: 'aws_ssh_keyname', label: 'AWS SSH Keyname' }
  ]

  const [switchChecked, setSwitchChecked] = useState(false)
  const [jsonInputError, setJsonInputError] = useState()
  const { owner_id, id, ...keysConfig } = cloudConfig
  const [keysConfigJson, setKeysConfigJson] = useState(JSON.stringify(keysConfig, null, '\t'))

  function handleJSONChange(e) {
    let error = null
    let eJson = {}
    try {
      eJson = JSON.parse(e)
    } catch (err) {
      error = err.message
    }
    setJsonInputError(error)
    setCloudConfig({ id, owner_id, ...eJson })
    setKeysConfigJson(e)
  }

  function handleInputChange({ target }, parent) {
    const { name, value } = target
    if (parent) {
      setCloudConfig({ ...cloudConfig, [parent]: { ...cloudConfig[parent], [name]: value } })
      setKeysConfigJson(
        JSON.stringify({ ...keysConfig, [parent]: { ...keysConfig[parent], [name]: value } }, null, '\t')
      )
    } else {
      setCloudConfig({ ...cloudConfig, [name]: value })
      setKeysConfigJson(JSON.stringify({ ...keysConfig, [name]: value }, null, '\t'))
    }
    setJsonInputError(null)
  }

  function onFormSubmit(values) {
    let updatedCloudConfig = {}
    if (switchChecked) {
      updatedCloudConfig = JSON.parse(keysConfigJson)
      updatedCloudConfig.owner_id = cloudConfigProp.owner_id
    } else {
      updatedCloudConfig = {
        name: values.name,
        owner_id: cloudConfigProp.owner_id,
        config: {
          api_host: values.api_host !== '' ? values.api_host : undefined,
          api_port: values.api_port !== '' ? values.api_port : undefined,
          broker_host: values.broker_host !== '' ? values.broker_host : undefined,
          broker_port: values.broker_port !== '' ? values.broker_port : undefined,
          aws_key_id: values.aws_key_id !== '' ? values.aws_key_id : undefined,
          gcp_service_account_email:
            values.gcp_service_account_email !== '' ? values.gcp_service_account_email : undefined,
          gcp_project_id: values.gcp_project_id !== '' ? values.gcp_project_id : undefined,
          gcp_key_content: values.gcp_key_content !== '' ? values.gcp_key_content : undefined,
          aws_key_content: values.aws_key_content !== '' ? values.aws_key_content : undefined,
          aws_ssh_keyname: values.aws_ssh_keyname !== '' ? values.aws_ssh_keyname : undefined
        }
      }
    }
    onDataUpdated(updatedCloudConfig)
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .max(150, 'Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only')
      .required('Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only')
  })

  return (
    <Card>
      <CardHeader>
        <CardTitle>Form</CardTitle>
        <CardTools>
          <CustomInput
            type="switch"
            id="switch"
            name="switch"
            label="Edit Cloud Config by JSON Editor"
            checked={switchChecked}
            onChange={() => setSwitchChecked(!switchChecked)}
          />
        </CardTools>
      </CardHeader>
      {switchChecked ? (
        <>
          <CardBody>
            {jsonInputError && <Alert color="danger">{jsonInputError}</Alert>}
            <Editor
              placeholder="Set Cloud Configuration"
              mode="json"
              name="cloudConfigJson"
              onChange={e => handleJSONChange(e)}
              value={keysConfigJson || ''}
            />
          </CardBody>
          <CardFooter>
            <Button
              color="info"
              className="float-right"
              onClick={() => onFormSubmit(cloudConfig)}
              disabled={jsonInputError}
            >
              Apply
            </Button>
          </CardFooter>
        </>
      ) : (
        cloudConfig && (
          <Formik
            initialValues={{
              name: cloudConfig.name || '',
              api_host: (cloudConfig.config && cloudConfig.config.api_host) || '',
              api_port: (cloudConfig.config && cloudConfig.config.api_port) || '',
              broker_host: (cloudConfig.config && cloudConfig.config.broker_host) || '',
              broker_port: (cloudConfig.config && cloudConfig.config.broker_port) || '',
              aws_key_id: (cloudConfig.config && cloudConfig.config.aws_key_id) || '',
              gcp_service_account_email: (cloudConfig.config && cloudConfig.config.gcp_service_account_email) || '',
              gcp_project_id: (cloudConfig.config && cloudConfig.config.gcp_project_id) || '',
              gcp_key_content: (cloudConfig.config && cloudConfig.config.gcp_key_content) || '',
              aws_key_content: (cloudConfig.config && cloudConfig.config.aws_key_content) || '',
              aws_ssh_keyname: (cloudConfig.config && cloudConfig.config.aws_ssh_keyname) || ''
            }}
            onSubmit={onFormSubmit}
            validationSchema={validationSchema}
          >
            {({ errors, isValid, touched, handleChange }) => (
              <Form>
                <CardBody>
                  <Row>
                    <Col sm="6">
                      <FormGroup>
                        <Label for="name">Name</Label>
                        <Input
                          id="name"
                          name="name"
                          placeholder="Select name"
                          onChange={e => {
                            handleChange(e)
                            handleInputChange(e)
                          }}
                          tag={Field}
                          invalid={touched.name && Boolean(errors.name)}
                        />
                        <ErrorMessage name="name" component="div" className="invalid-feedback" />
                      </FormGroup>
                      {cloudKeys.map(param => (
                        <FormGroup key={param.paramName}>
                          <Label for={param.paramName}>{param.label}</Label>
                          <Input
                            id={param.paramName}
                            component={param.type}
                            name={param.paramName}
                            placeholder={`Select ${param.label}`}
                            onChange={e => {
                              handleChange(e)
                              handleInputChange(e, 'config')
                            }}
                            tag={Field}
                            invalid={Boolean(errors[param.paramName])}
                          />
                          <ErrorMessage name={param.paramName} component="div" className="invalid-feedback" />
                        </FormGroup>
                      ))}
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <Button color="info" className="float-right" type="submit" disabled={!isValid}>
                    Apply
                  </Button>
                </CardFooter>
              </Form>
            )}
          </Formik>
        )
      )}
    </Card>
  )
}
