import React, { useContext } from 'react'
import { Input, FormFeedback } from 'reactstrap'
import TestSuiteConfigurator from '../../context/TestSuiteConfiguratorContext'

const IntegerInput = ({ props }) => {
  const { option, validator, handleBlur, validateInput, removeValidationParamsFromMultipleInput, ...otherProps } = props
  const { selectedBlock, handleFormChange } = useContext(TestSuiteConfigurator)
  return (
    <>
      <Input
        type="number"
        id={option.name}
        value={
          Number.isInteger(selectedBlock.params && selectedBlock.params[option.name])
            ? selectedBlock.params && selectedBlock.params[option.name]
            : ''
        }
        name={option.name}
        min={option.attributes.min_value}
        max={option.attributes.max_value}
        valid={validator.enabled[option.name] && validator.isValid[option.name] === true}
        invalid={validator.enabled[option.name] && validator.isValid[option.name] === false}
        onChange={e => {
          handleFormChange(parseInt(e.target.value), option.name, 'params')
          option.attributes.required &&
            validateInput(option.name, 'number', e.target.value, option.label, {
              attributes: option.attributes
            })
        }}
        onBlur={e => {
          option.attributes.required && handleBlur(e.target.name)
          option.attributes.required &&
            validateInput(option.name, 'number', e.target.value, option.label, {
              attributes: option.attributes
            })
        }}
        onClick={e => {
          e.stopPropagation()
        }}
        {...otherProps}
      />
      <FormFeedback>{validator.feedback[option.name]}</FormFeedback>
    </>
  )
}

export default IntegerInput
