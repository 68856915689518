import React, { useContext } from 'react'
import { CustomInput, FormFeedback } from 'reactstrap'
import TestSuiteConfigurator from '../../context/TestSuiteConfiguratorContext'

const CheckboxInput = ({ props }) => {
  const { option, validator, removeValidationParamsFromMultipleInput, handleBlur, validateInput, ...otherProps } = props
  const { selectedBlock, handleFormChange } = useContext(TestSuiteConfigurator)
  return (
    <>
      <CustomInput
        type="checkbox"
        id={option.name}
        checked={(selectedBlock.params && selectedBlock.params[option.name]) || false}
        onChange={e => {
          handleFormChange(e.target.checked, option.name, 'params')
        }}
        onClick={e => {
          e.stopPropagation()
        }}
        {...otherProps}
      />
      <FormFeedback>{validator.feedback[option.name]}</FormFeedback>
    </>
  )
}

export default CheckboxInput
