import React from 'react'
import { Table } from 'reactstrap'
import { convertDigitalValueToLabel } from '../../../../utilities/stringFormatting'

export default function ResultIPTV({ result }) {
  const getKeys = function() {
    return Object.keys(result['data']['channels']['group_0'])
  }

  return (
    <Table bordered style={{ textAlign: 'center' }}>
      <thead>
        <tr>
          <th>Group</th>
          <th>Result</th>
          <th>Bandwidth</th>
          <th>Audio bandwidth</th>
          <th>Video bandwidth</th>
          <th>Recieved packets</th>
          <th>Lost packets</th>
          <th>CRC error</th>
          <th>Continuity error</th>
        </tr>
      </thead>
      <tbody>
        {getKeys().map(value => (
          <tr key={value}>
            <td>{value}</td>
            <td style={{ color: result['data']['channels']['group_0'][value]['verdict'] === 'PASS' ? 'green' : 'red' }}>
              {result['data']['channels']['group_0'][value]['verdict']}
            </td>
            <td>{convertDigitalValueToLabel(result['data']['channels']['group_0'][value]['bandwidth'], 'bit/s')}</td>
            <td>
              {convertDigitalValueToLabel(result['data']['channels']['group_0'][value]['audio_bandwidth'], 'bit/s')}
            </td>
            <td>
              {convertDigitalValueToLabel(result['data']['channels']['group_0'][value]['video_bandwidth'], 'bit/s')}
            </td>
            <td>{result['data']['channels']['group_0'][value]['rx_packets']}</td>
            <td>{result['data']['channels']['group_0'][value]['packets_lost']}</td>
            <td>{result['data']['channels']['group_0'][value]['crc_err']}</td>
            <td>{result['data']['channels']['group_0'][value]['continuity_err']}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}
