import React from 'react'
import { Table } from 'reactstrap'

export default function ResultDHCP({ result }) {
  const not_to_show_keys = ['result', 'mac_tested', 'test_time']
  const inter = Object.keys(result['data'])[0]

  const getKeys = function() {
    var tmp = Object.keys(result['data'][inter])
    return tmp.filter(f => !not_to_show_keys.includes(f))
  }

  return (
    <Table bordered style={{ textAlign: 'center' }}>
      <thead>
        <tr>
          <th>Result type</th>
          <th>Result value</th>
          <th>Status</th>
          <th>Lease time</th>
          <th>Mask</th>
          <th>Gateway</th>
          <th>Time [ms]</th>
        </tr>
      </thead>
      <tbody>
        {getKeys().map(value => (
          <tr key={value}>
            <td>{value}</td>
            <td>{result['data'][inter][value]['result-value']}</td>
            <td style={{ color: result['data'][inter][value]['result'] === 'PASS' ? 'green' : 'red' }}>
              {result['data'][inter][value]['result']}
            </td>
            <td>{result['data'][inter][value]['Lease time']}</td>
            <td>{result['data'][inter][value]['Mask']}</td>
            <td>{result['data'][inter][value]['Gateway']}</td>
            <td>{result['data'][inter][value]['test_time'] || result['data'][inter][value]['discovery_time'] || result['data'][inter][value]['time']}</td>
          </tr>
        ))}
            <tr>
            <td>Total time</td>
            <td>{result['data'][inter]['test_time']} ms</td>
          </tr>
      </tbody>
    </Table>
  )
}
