import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Alert } from 'reactstrap'
import { checkValidityParams } from '../../utilities/testParameters'

export default function IncompatibleTestsAlert({ test_blocks }) {
  const testsDetails = useSelector(state => state.task_types.data)
  const [incompatibleTests, setIncompatibleTests] = useState([])

  useEffect(() => {
    if (testsDetails && test_blocks) {
      let _incompatibleTests = []
      test_blocks.forEach(test => {
        const taskType = testsDetails && testsDetails.find(taskType => taskType.name === test.task_type_name)
        let incorrectParams = checkValidityParams(taskType, test.options.params)
        if (incorrectParams.length > 0) {
          if (!_incompatibleTests.includes(taskType.display_name)) {
            _incompatibleTests.push(taskType.display_name)
          }
        }
      })
      setIncompatibleTests(_incompatibleTests)
    }
  }, [test_blocks])

  return (
    incompatibleTests.length > 0 && (
      <Alert color="warning" className="my-2">
        {incompatibleTests.map((item, index) => {
          return `${item}${index !== incompatibleTests.length - 1 ? ', ' : ''}`
        })}{' '}
        test block{incompatibleTests.length > 1 ? 's are' : ' is'} incompatible with the current tests version.
      </Alert>
    )
  )
}
