import React from 'react'
import ReactJson from 'react-json-view'

function JsonView({ src, collapsed }) {
  return (
    <div>
      <ReactJson
        collapsed={collapsed}
        collapseStringsAfterLength={150}
        groupArraysAfterLength={10}
        src={src || { message: 'No result.' }}
        name={false}
        displayDataTypes={false}
        displayObjectSize={false}
        enableClipboard={false}
        style={{ wordBreak: 'break-word' }}
      />
    </div>
  )
}

export default JsonView
