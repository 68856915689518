import { createContext } from 'react'

// set the defaults
const TestSuiteInResults = createContext({
  selectedBlock: {},
  setSelectedBlock: () => {},
  selectedEnvBlock: {},
  setSelectedEnvBlock: () => {}
})

export default TestSuiteInResults
