import React from 'react'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'

const PaginationPanel = ({ pagesCount, currentPage, handlePageClick }) =>
  pagesCount && pagesCount > 1 ? (
    <div style={{ float: 'right', marginTop: '5px' }}>
      <Pagination size="sm">
        <PaginationItem disabled={currentPage <= 1}>
          <PaginationLink first onClick={e => handlePageClick(e, 1)} />
        </PaginationItem>
        <PaginationItem disabled={currentPage <= 1}>
          <PaginationLink previous onClick={e => handlePageClick(e, currentPage - 1)} />
        </PaginationItem>
        {currentPage > 3 && (
          <PaginationItem key={1}>
            <PaginationLink onClick={e => handlePageClick(e, 1)}>{1}</PaginationLink>
          </PaginationItem>
        )}
        {currentPage > 4 && (
          <PaginationItem key={'a'}>
            <PaginationLink>...</PaginationLink>
          </PaginationItem>
        )}
        {currentPage > 2 && (
          <PaginationItem key={currentPage - 2}>
            <PaginationLink onClick={e => handlePageClick(e, currentPage - 2)}>{currentPage - 2}</PaginationLink>
          </PaginationItem>
        )}
        {currentPage > 1 && (
          <PaginationItem key={currentPage - 1}>
            <PaginationLink onClick={e => handlePageClick(e, currentPage - 1)}>{currentPage - 1}</PaginationLink>
          </PaginationItem>
        )}
        <PaginationItem active key={currentPage}>
          <PaginationLink onClick={e => handlePageClick(e, currentPage)}>{currentPage}</PaginationLink>
        </PaginationItem>
        {currentPage < pagesCount && (
          <PaginationItem key={currentPage + 1}>
            <PaginationLink onClick={e => handlePageClick(e, currentPage + 1)}>{currentPage + 1}</PaginationLink>
          </PaginationItem>
        )}
        {currentPage < pagesCount - 1 && (
          <PaginationItem key={currentPage + 2}>
            <PaginationLink onClick={e => handlePageClick(e, currentPage + 2)}>{currentPage + 2}</PaginationLink>
          </PaginationItem>
        )}
        {currentPage < pagesCount - 3 && (
          <PaginationItem key={'z'}>
            <PaginationLink>...</PaginationLink>
          </PaginationItem>
        )}
        {currentPage < pagesCount - 2 && (
          <PaginationItem key={pagesCount}>
            <PaginationLink onClick={e => handlePageClick(e, pagesCount)}>{pagesCount}</PaginationLink>
          </PaginationItem>
        )}
        <PaginationItem disabled={currentPage >= pagesCount}>
          <PaginationLink next onClick={e => handlePageClick(e, currentPage + 1)} />
        </PaginationItem>
        <PaginationItem disabled={currentPage >= pagesCount}>
          <PaginationLink last onClick={e => handlePageClick(e, pagesCount)} />
        </PaginationItem>
      </Pagination>
    </div>
  ) : (
    ''
  )

export default PaginationPanel
