import React, { useContext } from 'react'
import { Input, FormFeedback } from 'reactstrap'
import { convertStringToOtherType } from '../../utilities/stringFormatting'
import TestSuiteConfigurator from '../../context/TestSuiteConfiguratorContext'

const SelectInput = ({ props }) => {
  const { option, validator, handleBlur, validateInput, removeValidationParamsFromMultipleInput, ...otherProps } = props
  const { selectedBlock, handleFormChange } = useContext(TestSuiteConfigurator)
  return (
    <>
      <Input
        type="select"
        id={option.name}
        value={selectedBlock.params && selectedBlock.params[option.name]}
        onChange={e => {
          handleFormChange(
            convertStringToOtherType(e.target.value, option.attributes.value_type),
            option.name,
            'params'
          )
        }}
        onClick={e => {
          e.stopPropagation()
        }}
        {...otherProps}
      >
        {option.attributes.choices.map(choice => (
          <option key={choice.value} value={choice.value}>
            {choice.name}
          </option>
        ))}
      </Input>
      <FormFeedback>{validator.feedback[option.name]}</FormFeedback>
    </>
  )
}

export default SelectInput
