import React, { useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'
import TestSuiteConfigurator from '../../context/TestSuiteConfiguratorContext'

export default function ErrorsToastContainer() {
  const testsDetails = useSelector(state => state.task_types.data)
  const createTestError = useSelector(state => state.test_suites.error)
  const { steps } = useContext(TestSuiteConfigurator)

  useEffect(() => {
    if (createTestError && createTestError.response && createTestError.response.data) {
      let messages = createTestErrorMessage(steps)
      toast.error(
        <div>
          Error occurred when trying to run test suite. <br />
          {messages.map((message, index) => {
            return (
              <div key={index}>
                {message.split('\n').map((str, indx) => (
                  <div key={indx}>{str}</div>
                ))}
              </div>
            )
          })}
        </div>
      )
    }
  }, [createTestError])

  function createTestErrorMessage() {
    let messages = []
    if (createTestError.response.data.non_field_errors) {
      createTestError.response.data.non_field_errors.forEach(error => messages.push(error))
    }
    if (createTestError.response.data.test_blocks) {
      createTestError.response.data.test_blocks.forEach((error_block, index) => {
        let steps_short = []
        steps.forEach((step, step_index) =>
          step.tests.forEach(test => {
            let test_short = {}
            test_short.task_type = testsDetails.some(task_type => task_type.name === test.task_type_name)
              ? testsDetails.find(task_type => task_type.name === test.task_type_name).display_name
              : test.task_type_name
            test_short.stage_no = step_index + 1
            steps_short.push(test_short)
          })
        )
        if (Object.keys(error_block).length > 0) {
          if (error_block.non_field_errors) {
            error_block.non_field_errors.forEach(error =>
              messages.push(`Step ${steps_short[index].stage_no}, ${steps_short[index].task_type}: ${error}`)
            )
          }
          Object.keys(error_block)
            .filter(key => key !== 'non_field_errors')
            .forEach(field_name => {
              error_block[field_name].forEach(message => messages.push(`${field_name}: ${message}`))
            })
        }
      })
    }
    Object.keys(createTestError.response.data)
      .filter(key => key !== 'test_blocks' && key !== 'non_field_errors')
      .forEach(field_name => {
        createTestError.response.data[field_name].forEach(message => messages.push(`${field_name}: ${message}`))
      })

    return messages
  }

  return <ToastContainer position="top-right" newestOnTop />
}
