import React from 'react'
import { Table } from 'reactstrap'

export default function ResultNICInfo({ result }) {
  const getKeys = function() {
    return Object.keys(result)
  }

  return (
    <Table bordered style={{ textAlign: 'center' }}>
      <thead>
        <tr>
          <th>Interface</th>
          <th>Error IN</th>
          <th>Drop IN</th>
          <th>Error OUT</th>
          <th>Drop OUT</th>
          <th>Bytes rcv</th>
          <th>Bytes snt</th>
          <th>Packets rcv</th>
          <th>Packets snt</th>
        </tr>
      </thead>
      <tbody>
        {getKeys().map(value => (
          <tr key={value}>
            <td>{value}</td>
            <td>{result[value].errin}</td>
            <td>{result[value].dropin}</td>
            <td>{result[value].errout}</td>
            <td>{result[value].dropout}</td>
            <td>{result[value].bytes_recv}</td>
            <td>{result[value].bytes_sent}</td>
            <td>{result[value].packets_recv}</td>
            <td>{result[value].packets_sent}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}
