import React, { useState, useEffect, useContext, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import * as actions from '../../store/actions'
import { Col, Row, Card, CardBody, CardHeader, CardTitle, Input, Form, FormGroup, Button } from 'reactstrap'
import { PaginationPanel, Spinner, CardTools, Modal } from '../../components'
import { FaTrashAlt } from 'react-icons/fa'
import { ResultsList, Result, TestSuitesFilters } from './'
import { TestSuitesContext } from '../../context/TestSuitesContext'
import { sortBy } from '../../utilities/mathOperations'

export default function ResultsCard({ match, location }) {
  const limit = 10
  const history = useHistory()
  const dispatch = useDispatch()
  const token = useSelector(state => state.auth.token)
  const testSuitesApi = useSelector(state => state.test_suites.data)
  const { testSuitesWS } = useContext(TestSuitesContext)
  const loading = useSelector(state => state.test_suites.loading)
  const pagesCount = useSelector(state => Math.ceil(state.test_suites.count / limit))
  const deleteFunction = useSelector(state => state.functions_data.test_suites.delete)

  const [searchQuery, setSearchQuery] = useState('')
  const filters = { ...location.state }
  const currentPage = match.params.page ? parseInt(match.params.page) : 1

  const testSuites = concatTestSuitesApiAndWS()

  const modalRef = useRef()
  const [selectedTestSuites, setSelectedTestSuites] = useState([])
  const checkAllRef = useRef()

  useEffect(() => {
    dispatch(
      actions.fetchDataParams(token, 'TEST_SUITES', {
        ordering: filters.sortParam || '-timestamp_created',
        limit: limit,
        search: filters.searchQuery,
        status:
          filters.statusFilter && filters.statusFilter.length > 0
            ? filters.statusFilter.map(item => item.id).join('|')
            : undefined,
        offset: (currentPage - 1) * limit || undefined,
        on_premise_test_agents:
          filters.testAgentFilter && filters.testAgentFilter.length > 0
            ? filters.testAgentFilter.map(tA => tA.id).join('|')
            : undefined,
        test_blocks__task_type: filters.taskFilter || undefined
      })
    )
  }, [currentPage, location.state])

  useEffect(() => {
    let timer = null
    // use timer so to limit API requests, do not sent on every change in the search string
    timer = setTimeout(() => {
      viewPageWithFilters({ searchQuery })
    }, 300)
    return () => {
      clearTimeout(timer)
    }
  }, [searchQuery])

  useEffect(() => {
    setSearchQuery(location.state ? location.state.searchQuery : '')
  }, [location.state && location.state.searchQuery])

  useEffect(() => {
    checkCheckAllCheckbox(selectedTestSuites)
  }, [testSuites])

  useEffect(() => {
    if (deleteFunction && !deleteFunction.loading) {
      setSelectedTestSuites([]) //clear selectedTestSuite after delete test suites
    }
  }, [deleteFunction])

  function viewPageWithFilters(newFilters) {
    history.push(`/results/${newFilters.page !== undefined ? newFilters.page : 1}`, {
      statusFilter:
        newFilters.statusFilter !== undefined ? newFilters.statusFilter : filters ? filters.statusFilter : undefined,
      taskFilter:
        newFilters.taskFilter !== undefined ? newFilters.taskFilter : filters ? filters.taskFilter : undefined,
      testAgentFilter:
        newFilters.testAgentFilter !== undefined
          ? newFilters.testAgentFilter
          : filters
          ? filters.testAgentFilter
          : undefined,
      sortParam: newFilters.sortParam !== undefined ? newFilters.sortParam : filters ? filters.sortParam : undefined,
      searchQuery:
        newFilters.searchQuery !== undefined ? newFilters.searchQuery : filters ? filters.searchQuery : undefined
    })
  }

  function concatTestSuitesApiAndWS() {
    let _testSuites = testSuitesApi
      ? testSuitesApi.map(testSuite => {
          return testSuitesWS.some(tsWS => tsWS.id === testSuite.id)
            ? testSuitesWS.find(tsWS => tsWS.id === testSuite.id)
            : testSuite
        })
      : []
    /* uncomment to remove test suites with changed status in ws message if new status is not included in statusFilter
     * danger: less quantity of test suites in one page
     * comment to not remove test suites, when new status is not in the statusFilter
     * danger: the possibility of a test suite with a status that is not in the statusFilter
     */
    /*if (statusFilter.length > 0) {
      _testSuites = _testSuites.filter(_tS => statusFilter.map(sF => sF.id).includes(_tS.status))
    }*/

    if (currentPage <= 1) {
      const statusFilter = (filters && filters.statusFilter) || []
      const testAgentFilter = (filters && filters.testAgentFilter) || []
      const taskFilter = filters && filters.taskFilter
      testSuitesWS.forEach(testSuiteWS => {
        if (
          !_testSuites.some(testSuite => testSuite.id === testSuiteWS.id) &&
          testSuiteWS.name.includes(searchQuery) &&
          (statusFilter.length === 0 || statusFilter.map(sF => sF.id).includes(testSuiteWS.status)) &&
          (!taskFilter || testSuiteWS.test_blocks.some(testBlock => testBlock.task_type === taskFilter)) &&
          (testAgentFilter.length === 0 ||
            testAgentFilter.some(tAF => testSuiteWS.on_premise_test_agents.includes(tAF.id)) ||
            testAgentFilter.some(tAF =>
              testSuiteWS.test_blocks.some(testBlock => testBlock.on_premise_test_agents.includes(tAF.id))
            ))
        ) {
          _testSuites.push(testSuiteWS)
        }
      })
      _testSuites = sortBy(_testSuites, (filters && filters.sortParam) || '-timestamp_created')
    }
    return _testSuites.slice(0, limit)
  }

  function handlePageClick(e, index) {
    viewPageWithFilters({ page: index })
  }

  function rowCheckChange(testSuiteId, isChecked) {
    let _selectedTestSuites = [...selectedTestSuites]
    if (isChecked) {
      !_selectedTestSuites.includes(testSuiteId) && _selectedTestSuites.push(testSuiteId)
    } else {
      const index = _selectedTestSuites.indexOf(testSuiteId)
      if (index > -1) {
        _selectedTestSuites.splice(index, 1)
      }
    }
    setSelectedTestSuites(_selectedTestSuites)
    checkCheckAllCheckbox(_selectedTestSuites)
  }

  function checkAllChange(isChecked) {
    let _selectedTestSuites = [...selectedTestSuites]
    if (isChecked) {
      testSuites
        // uncomment to disable check running test suites
        /*.filter(
          testSuite =>
            !testSuiteStatuses.some(tSS => tSS.id === testSuite.status) ||
            testSuiteStatuses.find(tSS => tSS.id === testSuite.status).name !== 'RUNNING'
        )*/
        .forEach(testSuite => {
          !_selectedTestSuites.includes(testSuite.id) && _selectedTestSuites.push(testSuite.id)
        })
    } else {
      testSuites.forEach(testSuite => {
        const index = _selectedTestSuites.indexOf(testSuite.id)
        if (index > -1) {
          _selectedTestSuites.splice(index, 1)
        }
      })
    }
    setSelectedTestSuites(_selectedTestSuites)
  }

  function checkCheckAllCheckbox(_selectedTestSuites) {
    if (checkAllRef.current) {
      if (!testSuites.some(tS => _selectedTestSuites.includes(tS.id))) {
        checkAllRef.current.checked = false
        checkAllRef.current.indeterminate = false
      } else if (!testSuites.some(tS => !_selectedTestSuites.includes(tS.id))) {
        checkAllRef.current.checked = true
        checkAllRef.current.indeterminate = false
      } else {
        checkAllRef.current.indeterminate = true
      }
    }
  }

  function deleteCheckedTestSuites() {
    dispatch(
      actions.addDataFunction(token, 'TEST_SUITES', 'DELETE', { test_suites: selectedTestSuites }, true, {
        ordering: filters.sortParam || '-timestamp_created',
        limit: limit,
        search: filters.searchQuery,
        status:
          filters.statusFilter && filters.statusFilter.length > 0
            ? filters.statusFilter.map(item => item.id).join('|')
            : undefined,
        offset: (currentPage - 1) * limit || undefined,
        on_premise_test_agents:
          filters.testAgentFilter && filters.testAgentFilter.length > 0
            ? filters.testAgentFilter.map(tA => tA.id).join('|')
            : undefined,
        test_blocks__task_type: filters.taskFilter || undefined
      })
    )
  }

  return (
    <Card className="top-line-info">
      <CardHeader className="no-border">
        <CardTitle>Test Suites</CardTitle>
        <CardTools>
          <Modal
            ref={modalRef}
            title="Delete test suites"
            content={`Are you sure you want to delete ${selectedTestSuites.length} test suites?`}
            onApply={() => deleteCheckedTestSuites()}
          />
          <Button color="link" disabled={selectedTestSuites.length === 0} onClick={() => modalRef.current.openModal()}>
            <FaTrashAlt title="Delete selected items" />
          </Button>
        </CardTools>
      </CardHeader>
      <CardBody>
        <Row>
          <Col md="2" sm="4" style={{ paddingTop: '5px', paddingLeft: '15px' }}>
            <TestSuitesFilters filters={location.state} viewPageWithFilters={viewPageWithFilters} />
          </Col>
          <Col md="4" sm="8">
            <Form>
              <FormGroup style={{ marginBottom: '20px', width: '90%' }}>
                <Input
                  type="search"
                  name="search-query"
                  id="result-search-field"
                  value={searchQuery || ''}
                  onChange={e => {
                    setSearchQuery(e.target.value)
                  }}
                  placeholder="Search"
                />
              </FormGroup>
            </Form>
          </Col>
          <Col md="6" sm="12" xs="12">
            {!loading && (
              <PaginationPanel
                currentPage={currentPage}
                pagesCount={pagesCount}
                handlePageClick={handlePageClick}
                style={{ marginTop: '50px' }}
              />
            )}
          </Col>
        </Row>
        {loading ? (
          <Spinner />
        ) : (
          <>
            <ResultsList
              testSuites={testSuites}
              filters={filters}
              viewPageWithFilters={viewPageWithFilters}
              checkAllRef={checkAllRef}
              checkAllChange={checkAllChange}
            >
              {testSuites &&
                testSuites.length > 0 &&
                testSuites.map(testSuite => (
                  <Result
                    key={testSuite.id}
                    result={testSuite}
                    limit={limit}
                    currentPage={currentPage}
                    checked={selectedTestSuites.includes(testSuite.id)}
                    rowCheckChange={rowCheckChange}
                  />
                ))}
            </ResultsList>
            {testSuites && testSuites.length > 0 && (
              <PaginationPanel currentPage={currentPage} pagesCount={pagesCount} handlePageClick={handlePageClick} />
            )}
          </>
        )}
      </CardBody>
    </Card>
  )
}
