import React from 'react'
import { useSelector } from 'react-redux'
import { TimeChart } from '../../'

export default function IPerf() {
  const testSuite = useSelector(state => state.test_suite.data)
  const monitoring = testSuite.repeat_interval

  return monitoring ? <TimeChart panel_id={48} /> : 'No chart'
}
