import classNames from 'classnames'
import React, { useContext } from 'react'
import { FaArrowRight, FaBroadcastTower, FaMobileAlt } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { Card, CardBody } from 'reactstrap'
import TestSuiteInResults from '../../../../context/TestSuiteInResultsContext'

export default function EnvStep({ isPreStep }) {
  const testSuite = useSelector(state => state.test_suite.data)
  const step = isPreStep ? 'pre' : 'post'
  const stepName = name => {
    return name.charAt(0).toUpperCase() + name.slice(1)
  }

  const { setSelectedBlock, selectedEnvBlock, setSelectedEnvBlock } = useContext(TestSuiteInResults)

  return testSuite.options['5g_config'] && Object.keys(testSuite.options['5g_config']).length > 0 ? (
    <div
      className="border-info"
      style={{
        textAlign: 'center',
        borderStyle: 'dotted',
        borderWidth: '2px',
        borderRadius: '10px',
        width: '10rem',
        display: 'inline-block',
        verticalAlign: 'top',
        marginRight: '10px'
      }}
    >
      <div className="p-2">
        <h5>{stepName(step)}-Step</h5>
      </div>
      <Card
        className={classNames('m-2 tile-env', {
          active: selectedEnvBlock && (selectedEnvBlock.env_type === '5g_infra' && selectedEnvBlock.step === step)
        })}
        onClick={() => {
          setSelectedBlock()
          setSelectedEnvBlock({ step: step, env_type: '5g_infra' })
        }}
      >
        <CardBody
          className={classNames('p-1 tile-env', {
            active: selectedEnvBlock && (selectedEnvBlock.env_type === '5g_infra' && selectedEnvBlock.step === step)
          })}
        >
          <div>
            <p style={{ display: 'inline' }}>{isPreStep ? 'Run' : 'Terminate'} 5G Infra</p>
          </div>
          <div>
            <FaMobileAlt /> <FaArrowRight size={10} /> <FaBroadcastTower size={20} />
          </div>
        </CardBody>
      </Card>
    </div>
  ) : (
    <></>
  )
}
