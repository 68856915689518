import React from 'react'
import { withRouter } from 'react-router-dom'
import { Button } from 'reactstrap'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }

    const { history } = this.props

    history.listen(() => {
      if (this.state.hasError) {
        this.setState({
          hasError: false
        })
      }
    })
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  /*componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    logErrorToMyService(error, errorInfo)
  }*/

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <h3 className="pt-5 text-center">
          There was an error in loading this page.{' '}
          <Button
            color="link"
            className='p-0 m-0'
            onClick={() => {
              window.location.reload()
            }}
          >
            <h3>
            Reload this page.
            </h3>
          </Button>
        </h3>
      )
    }

    return this.props.children
  }
}

export default withRouter(ErrorBoundary)
