import React, { useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input } from 'reactstrap'
import { FaFilter } from 'react-icons/fa'
import Select from 'react-select'
import { taskStatuses } from '../../../utilities/statuses'

export default function ResultTasksFilters({ filters, onChangeFilters }) {
  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)

  return (
    <>
      <Button
        color={filters && ((filters.status && filters.status.length > 0) || filters.threshold) ? 'danger' : 'secondary'}
        outline
        size="sm"
        onClick={toggle}
        className="mr-2"
      >
        <FaFilter /> Filters
      </Button>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Filters</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="statusFilter">Status</Label>
              <Select
                id="statusFilter"
                name="statusFilter"
                isMulti
                options={
                  taskStatuses &&
                  taskStatuses.map(tSS => {
                    return { value: tSS.id, label: tSS.display_name }
                  })
                }
                value={
                  filters &&
                  filters.status &&
                  filters.status.map(tS => {
                    return { value: tS.id, label: tS.display_name }
                  })
                }
                placeholder="Select a status..."
                onChange={e => {
                  onChangeFilters({
                    ...filters,
                    status: e
                      ? e.map(item => {
                          return taskStatuses.find(tS => tS.id === item.value)
                        })
                      : []
                  })
                }}
                styles={{
                  menu: provided => ({ ...provided, zIndex: 9999 })
                }}
              />
            </FormGroup>
            <FormGroup>
              <Label for="thresholdFilter">Threshold Exceeded</Label>
              <Input
                type="select"
                name="thresholdFilter"
                id="thresholdFilter"
                value={filters ? filters.threshold || '' : ''}
                onChange={e => {
                  onChangeFilters({ ...filters, threshold: e.target.value === '0' ? 0 : e.target.value })
                }}
              >
                <option value={0}>All results</option>
                <option value="true">Exceeded</option>
                <option value="false">Not Exceeded</option>
              </Input>
            </FormGroup>
          </Form>
        </ModalBody>
      </Modal>
    </>
  )
}
