import React, { useState, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import * as actions from '../../store/actions'
import { Dropdown, DropdownMenu, DropdownToggle, Row, Button, CardFooter } from 'reactstrap'
import { FaRegFlag } from 'react-icons/fa'
import { Notifications } from '..'
import { NotificationsContext } from '../../context/NotificationsContext'

export default function NotificationsDropdown() {
  const dispatch = useDispatch()
  const [notificationsDropdown, setNotificationsDropdown] = useState(false)
  const token = useSelector(state => state.auth.token)
  const notificationsUnseenCount = useSelector(state => state.notifications_unseen.count)
  const { notifications } = useContext(NotificationsContext)
  const notificationsOpenPanelsCounter = useSelector(state => state.notifications_open_panels_counter.data)

  function setNotifOpenPanelsCounter(notificationsDropdown) {
    dispatch(
      actions.setData(
        'NOTIFICATIONS_OPEN_PANELS_COUNTER',
        notificationsDropdown ? notificationsOpenPanelsCounter + 1 : notificationsOpenPanelsCounter - 1
      )
    )
  }

  return (
    <li className="user-menu">
      <Dropdown
        isOpen={notificationsDropdown}
        toggle={() => {
          setNotificationsDropdown(prevState => !prevState)
          setNotifOpenPanelsCounter(!notificationsDropdown)
        }}
      >
        <DropdownToggle tag="span" className="nav-link">
          <FaRegFlag />
          {notificationsUnseenCount > 0 && (
            <span className="badge badge-warning navbar-badge">{notificationsUnseenCount}</span>
          )}
        </DropdownToggle>
        <DropdownMenu
          className="py-0"
          modifiers={{
            setWidth: {
              enabled: true,
              order: 890,
              fn: data => {
                return {
                  ...data,
                  styles: {
                    ...data.styles,
                    width: '300px'
                  }
                }
              }
            }
          }}
        >
          <div className="p-2" style={{ height: '80vh' }}>
            <Notifications
              hideDropdown={() => {
                setNotificationsDropdown(false)
                setNotifOpenPanelsCounter(!notificationsDropdown)
              }}
            />
          </div>
          <CardFooter>
            <Row className="pt-0 justify-content-center">
              <Button
                color="link"
                className="p-0"
                onClick={() => {
                  dispatch(actions.setData('SEEN_NOTIFICATIONS', notifications))
                  dispatch(actions.unseenAllNotifications(token))
                }}
              >
                Mark all notifications as read
              </Button>
            </Row>
            <Row className="pt-0  justify-content-center">
              <NavLink style={{ textAlign: 'center' }} to="/notifications" onClick={() => setNotificationsDropdown(false)}>
                Show all notifications
              </NavLink>
            </Row>
          </CardFooter>
        </DropdownMenu>
      </Dropdown>
    </li>
  )
}
