import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  Col,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  FormFeedback
} from 'reactstrap'
import { ContainerFluid, Content, ContentHeader } from '../../components'
import * as actions from '../../store/actions'
import { compareTwoInputs, validateTextInput } from '../../utilities/formValidator'

export default function ChangePassword() {
  const dispatch = useDispatch()
  const history = useHistory()
  const token = useSelector(state => state.auth.token)
  const account = useSelector(state => state.account.data)

  const [values, setValues] = useState({ newPassword: '', confirmedPassword: '' })
  const [isValid, setIsValid] = useState({})
  const [error, setError] = useState({})
  const [touched, setTouched] = useState({})

  useEffect(() => {
    document.title = 'vUTP | Account'
  }, [])

  function validatePasswords(newPassword, confirmedPassword) {
    let validatePassword = compareTwoInputs(newPassword, confirmedPassword, 'password')
    let validateConfirmedPassword = validatePassword
    if (validatePassword.isValid) {
      validatePassword = validateTextInput(newPassword, 'Password')
      validateConfirmedPassword = validateTextInput(confirmedPassword, 'Confirmed Password')
    }
    setIsValid({ newPassword: validatePassword.isValid, confirmedPassword: validateConfirmedPassword.isValid })
    setError({ newPassword: validatePassword.feedback, confirmedPassword: validateConfirmedPassword.feedback })
  }

  function handleChange({ target }) {
    const { name, value } = target
    let updatedPassword = { ...values, [name]: value }
    setValues(updatedPassword)
    validatePasswords(updatedPassword.newPassword, updatedPassword.confirmedPassword)
  }

  function handleBlur({ target }) {
    const { name } = target
    setTouched({ ...touched, [name]: true })
  }

  function onSubmit(e) {
    e.preventDefault()
    dispatch(actions.updateData(token, 'ACCOUNT', account.id, { password: values.newPassword }))
    history.push('/account')
  }
  return (
    <>
      <ContentHeader title="Account" />
      <Content>
        <ContainerFluid>
          <Row>
            <Col xl="6" lg="6" md="6" sm="12" xs="12">
              <Card className="top-line-info">
                <CardHeader className="no-border">
                  <CardTitle>Change Password</CardTitle>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={onSubmit}>
                    <FormGroup>
                      <Label for="newPassword">New Password:</Label>
                      <Input
                        id="newPassword"
                        name="newPassword"
                        type="password"
                        value={values.newPassword}
                        onChange={handleChange}
                        onBlur={e => {
                          handleBlur(e)
                          !touched.newPassword && validatePasswords(values.newPassword, values.confirmedPassword)
                        }}
                        valid={touched.newPassword && touched.confirmedPassword && isValid.newPassword}
                        invalid={touched.newPassword && touched.confirmedPassword && !isValid.newPassword}
                      />
                      <FormFeedback>
                        {touched.newPassword && touched.confirmedPassword && error.newPassword}
                      </FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label for="confirmedPassword">Confirm Password:</Label>
                      <Input
                        id="confirmedPassword"
                        name="confirmedPassword"
                        type="password"
                        value={values.confirmedPassword}
                        onChange={handleChange}
                        onBlur={e => {
                          handleBlur(e)
                          !touched.confirmedPassword && validatePasswords(values.newPassword, values.confirmedPassword)
                        }}
                        valid={touched.newPassword && touched.confirmedPassword && isValid.confirmedPassword}
                        invalid={touched.newPassword && touched.confirmedPassword && !isValid.confirmedPassword}
                      />
                      <FormFeedback>
                        {touched.newPassword && touched.confirmedPassword && error.confirmedPassword}
                      </FormFeedback>
                    </FormGroup>
                    <Button
                      color="info"
                      type="submit"
                      block
                      disabled={!isValid.newPassword || !isValid.confirmedPassword}
                    >
                      Change Password
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ContainerFluid>
      </Content>
    </>
  )
}
