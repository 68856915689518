import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import * as serviceWorker from './serviceWorker'
import 'admin-lte/dist/js/adminlte.min.js'
//import { debugContextDevtool } from 'react-context-devtool';

import App from './App'
import store from './store'

import './index.css'

const app = (
  <Provider store={store}>
    <BrowserRouter basename="/app/user">
      <App />
    </BrowserRouter>
  </Provider>
)

ReactDOM.render(app, document.getElementById('root'))

//const container = document.getElementById('root')
//ReactDOM.render(app, container)
//debugContextDevtool(container)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
