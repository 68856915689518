import React, { useContext } from 'react'
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap'
import { useSelector } from 'react-redux'
import { Spinner } from '../../../components'
import { Ping, RAMInfo, OTT, DNS, FTP, IPTV, DHCP, WWW, IPerf, HTTPSLatency, RFC6349, OTTStress } from '../'
import TestSuiteInResults from '../../../context/TestSuiteInResultsContext'

export default function ResultChartCard() {
  const resultsToCharts = useSelector(state => state.results_to_charts.data)
  const loading = useSelector(state => state.results_to_charts.loading)

  const { selectedBlock } = useContext(TestSuiteInResults)

  function renderChart(task_type) {
    switch (task_type) {
      case 'info_disk_usage':
      case 'info_memory':
        return <RAMInfo />
      case 'dns_test':
        return <DNS />
      case 'hls_test':
        return <OTT />
      case 'ott_stress_test':
        return <OTTStress />
      case 'network_ping':
        return <Ping />
      case 'ftp_file_download_test':
        return <FTP />
      case 'iptv_test':
        return <IPTV />
      case 'dhcp_test':
        return <DHCP />
      case 'www_test':
        return <WWW />
      case 'iperf_test':
        return <IPerf />
      case 'rfc6349_test':
        return <RFC6349 />
      case 'http_ping_test':
        return <HTTPSLatency />
      default:
        return 'No chart'
    }
  }

  return (
    <Card className="top-line-info">
      <CardHeader className="no-border">
        <CardTitle>Chart</CardTitle>
      </CardHeader>
      <CardBody style={{ textAlign: 'center' }}>
        {selectedBlock ? (
          !loading ? (
            resultsToCharts.length > 1 ? (
              renderChart(selectedBlock.task_type_name)
            ) : (
              'No results'
            )
          ) : (
            <Spinner />
          )
        ) : (
          ' Click on the test block to show the chart.'
        )}
      </CardBody>
    </Card>
  )
}
