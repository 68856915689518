import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody } from 'reactstrap'
import { categoryIcon } from '../../../../utilities/testParameters'
import { reduceTestSuiteToSteps } from '../../../../utilities/testSuite'
import classNames from 'classnames'
import TestSuiteInResults from '../../../../context/TestSuiteInResultsContext'

export default function Step({ step, step_number }) {
  const testSuite = useSelector(state => state.test_suite.data)
  const taskTypes = useSelector(state => state.task_types.data)
  const testsCategories = useSelector(state => state.test_categories_details.data)

  const { selectedBlock, setSelectedBlock, setSelectedEnvBlock } = useContext(TestSuiteInResults)

  const testSteps = reduceTestSuiteToSteps(testSuite)

  const itemCategoryName = item => {
    const testCat = testCategory(item.task_type_name)
    return testCat && testCat.category && testCat.category.name
  }

  const testCategory = taskTypeName => {
    const testItem = testsCategories && testsCategories.find(testItem => testItem.name === taskTypeName)
    if (testItem) {
      return {
        category: testItem.category,
        subcategory: testItem.subcategory
      }
    }
    return null
  }

  return (
    <div style={{ width: '14.75rem', display: 'inline-block', verticalAlign: 'top', marginRight: '10px' }}>
      <div
        className="border-info"
        style={{ textAlign: 'center', borderStyle: 'dashed', borderWidth: '3px', borderRadius: '10px' }}
      >
        <div className="p-2">
          <h4>Step {step_number + 1}</h4>
        </div>
        <ul style={{ padding: '0px' }}>
          {testSteps[step].map(item => {
            let _testCategory = testCategory(item.task_type_name)
            return (
              <Card
                key={item.id}
                className={classNames(`m-2 tile-${itemCategoryName(item)}`, {
                  active: selectedBlock && selectedBlock.id === item.id
                })}
                onClick={() => {
                  setSelectedBlock(item)
                  setSelectedEnvBlock()
                }}
              >
                <CardBody
                  className={classNames(`p-1 tile-${itemCategoryName(item)}`, {
                    active: selectedBlock && selectedBlock.id === item.id
                  })}
                >
                  <div>
                    {categoryIcon(
                      _testCategory && _testCategory.category.name,
                      selectedBlock && selectedBlock.id === item.id
                    )}{' '}
                    <p style={{ display: 'inline' }}>
                      {taskTypes && taskTypes.find(taskType => taskType.id === item.task_type).display_name}
                    </p>
                  </div>
                  <small
                    className={classNames({
                      'text-muted': !(selectedBlock && selectedBlock.id === item.id)
                    })}
                  >
                    {_testCategory && _testCategory.category.display_name}:{' '}
                    {_testCategory && _testCategory.subcategory.display_name}
                  </small>
                </CardBody>
              </Card>
            )
          })}
        </ul>
      </div>
    </div>
  )
}
