import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CardBody, CardFooter, FormGroup, FormText, Button, CustomInput, Alert } from 'reactstrap'
import * as actions from '../../../store/actions'
import AceEditor from 'react-ace'
import 'ace-builds/src-noconflict/mode-sass'
import 'ace-builds/src-noconflict/theme-tomorrow'
import 'ace-builds/src-noconflict/ext-language_tools'
import yaml from 'js-yaml'

export default function NetworkInterfaces({ testAgentApi, submitForm }) {
  const dispatch = useDispatch()
  const token = useSelector(state => state.auth.token)
  const [testAgent, setTestAgent] = useState(null)
  const [reboot, setReboot] = useState(false)
  const [yamlAlert, setYamlAlert] = useState('')

  useEffect(() => {
    if (!testAgent) {
      setTestAgent(testAgentApi)
    }
  }, [testAgentApi])

  useEffect(() => {
    if (testAgent && testAgent.additional_data && testAgent.additional_data.network_config) {
      let networkConfig = null
      let _yamlAlert = ''
      try {
        networkConfig = yaml.load(testAgent.additional_data.network_config)
      } catch (e) {
        _yamlAlert = 'Invalid YAML.'
      }
      if (
        networkConfig &&
        networkConfig.network &&
        networkConfig.network.ethernets &&
        testAgent &&
        testAgent.additional_data &&
        testAgent.additional_data.interfaces
      ) {
        let notExistInterfaces = []
        if (typeof networkConfig.network.ethernets === 'object' && !Array.isArray(networkConfig.network.ethernets)) {
          Object.keys(networkConfig.network.ethernets).forEach(interf => {
            if (!Object.keys(testAgent.additional_data.interfaces).includes(interf)) {
              notExistInterfaces.push(interf)
            }
          })
        } else if (Array.isArray(networkConfig.network.ethernets)) {
          networkConfig.network.ethernets.forEach(interf => {
            if (!Object.keys(testAgent.additional_data.interfaces).includes(interf)) {
              notExistInterfaces.push(interf)
            }
          })
        } else {
          networkConfig.network.ethernets.length > 0 &&
            networkConfig.network.ethernets
              .toString()
              .split(' ')
              .forEach(interf => {
                if (!Object.keys(testAgent.additional_data.interfaces).includes(interf.trim())) {
                  notExistInterfaces.push(interf)
                }
              })
        }
        if (notExistInterfaces.length > 0) {
          _yamlAlert = (
            <>
              <p className="mb-0">
                Incorrect interface names: <b>{notExistInterfaces.join()}</b>.
              </p>
              <p className="mb-0">
                Network interfaces available on this test agent:{' '}
                <b>{Object.keys(testAgent.additional_data.interfaces).join()}</b>.
              </p>
            </>
          )
        }
      }
      setYamlAlert(_yamlAlert)
    }
  }, [testAgent && testAgent.additional_data && testAgent.additional_data.network_config])

  const hiddenFileInput = React.useRef(null)

  const handleClick = () => {
    hiddenFileInput.current.click()
  }

  const handleChange = event => {
    const fileUploaded = event.target.files[0]
    const reader = new FileReader()
    reader.onload = async e => {
      const text = e.target.result
      setTestAgent({ ...testAgent, additional_data: { ...testAgent.additional_data, network_config: text } })
    }
    reader.readAsText(fileUploaded)
  }

  function updateTestAgent() {
    dispatch(
      actions.addDataDetailsFunction(
        token,
        'TEST_AGENTS',
        testAgent.id,
        'NETWORK_CONFIG',
        { config: testAgent.additional_data.network_config, reboot: reboot },
        true
      )
    )
    submitForm()
  }

  return (
    <>
      <CardBody>
        <p>
          To change the network configuration on this test agent, upload a file or use the editor below and click Update
          Network Configuration button. Configuration must be netplan compatible.
        </p>
        <FormGroup>
          <Button color="info" onClick={handleClick}>
            Upload a file
          </Button>
          <input
            type="file"
            id="networkInterfaces"
            ref={hiddenFileInput}
            onChange={handleChange}
            style={{ display: 'none' }}
          />
          <FormText color="muted">Select the file to upload or type the content into the editor below.</FormText>
        </FormGroup>
        {yamlAlert && <Alert color="warning">{yamlAlert}</Alert>}
        <AceEditor
          placeholder="Set File Content"
          mode="sass"
          theme="tomorrow"
          name="fileContent"
          maxLines={Infinity}
          width="100%"
          onChange={e =>
            setTestAgent({ ...testAgent, additional_data: { ...testAgent.additional_data, network_config: e } })
          }
          fontSize={14}
          showPrintMargin={false}
          showGutter={true}
          highlightActiveLine={true}
          wrapEnabled={true}
          value={testAgent && testAgent.additional_data && testAgent.additional_data.network_config}
          setOptions={{
            enableBasicAutocompletion: true,
            enableLiveAutocompletion: true,
            enableSnippets: true,
            showLineNumbers: true,
            tabSize: 2
          }}
        />
      </CardBody>
      <CardFooter>
        <CustomInput
          type="checkbox"
          id="rebootCheckbox"
          label="Reboot test agent after update"
          className="d-inline-block"
          checked={reboot}
          onChange={() => setReboot(prev => !prev)}
        />
        <Button
          color="info"
          className="float-right d-inline-block"
          disabled={
            !testAgent ||
            !testAgent.additional_data ||
            !testAgent.additional_data.network_config ||
            testAgent.additional_data.network_config.length === 0
          }
          onClick={updateTestAgent}
        >
          Update Network Configuration
        </Button>
      </CardFooter>
    </>
  )
}
