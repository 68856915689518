import tar from 'tar-stream'
import streamifier from 'streamifier'
import { unzipSync } from 'zlib'

const untar = (buffer, filenames) =>
  new Promise((resolve, reject) => {
    const textData = []
    const extract = tar.extract()
    // Extract method accepts each tarred file as entry, separating header and stream of contents:
    extract.on('entry', (header, stream, next) => {
      if (!filenames || (filenames && filenames.includes(header.name))) {
        const chunks = []
        stream.on('data', chunk => {
          chunks.push(chunk)
        })
        stream.on('error', err => {
          reject(err)
        })
        stream.on('end', () => {
          // We concatenate chunks of the stream into string and push it to array, which holds contents of each file in .tar.gz:
          const text = Buffer.concat(chunks).toString('utf8')
          textData.push(text)
          next()
        })
      } else {
        next()
      }
      stream.resume()
    })

    extract.on('finish', () => {
      // We return array of tarred files's contents:
      resolve(textData)
    })
    try {
      // We unzip buffer and convert it to Readable Stream and then pass to tar-stream's extract method:
      streamifier.createReadStream(unzipSync(buffer)).pipe(extract)
    } catch (e) {
      console.warn('Invalid file. Unable to unpack.')
    }
  })

export { untar }
