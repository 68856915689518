import Cookies from 'js-cookie'

export const passCredentials = (username, password) => {
  return {
    username: username,
    password: password
  }
}

const addXCSRFToken = headers => {
  const csrftoken = Cookies.get('csrftoken')
  if (!headers) {
    headers = {}
  }
  if (csrftoken) {
    headers['X-CSRFToken'] = csrftoken
  }
  return headers
}

export const setXCSRFToken = () => {
  return {
    headers: addXCSRFToken({})
  }
}

export const setParamsAndToken = (params, token) => {
  return {
    params: params,
    headers: addXCSRFToken({
      Authorization: 'Token ' + token
    })
  }
}

export const setToken = token => {
  return {
    headers: addXCSRFToken({
      Authorization: 'Token ' + token
    })
  }
}

export const toLowerCase = name => {
  return String(name).toLowerCase()
}

export const setTokenAndDownloadHeaders = (token, onProgress) => {
  return {
    headers: addXCSRFToken({
      Authorization: 'Token ' + token
    }),
    responseType: 'blob',
    onDownloadProgress: progressEvent => {
      let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
      onProgress(percentCompleted)
    }
  }
}
