import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, CardHeader, CardTitle, Nav, NavItem, NavLink } from 'reactstrap'
import classnames from 'classnames'
import { NetworkInterfaces, Reboot, Update } from '../'
import { commonURI } from '../../../api/endpoints'
import * as actions from '../../../store/actions'

export default function TestAgentRemoteConfiguration({ testAgent, submitForm }) {
  const dispatch = useDispatch()
  const token = useSelector(state => state.auth.token)
  const [activeTab, setActiveTab] = useState(1)
  const toggleTab = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  const tabs = [
    {
      id: 1,
      header: 'Network Interfaces',
      content: <NetworkInterfaces testAgentApi={testAgent} submitForm={submitForm} />
    },
    { id: 2, header: 'Reboot', content: <Reboot testAgentApi={testAgent} submitForm={submitForm} /> },
    { id: 3, header: 'Update', content: <Update testAgentApi={testAgent} submitForm={submitForm} /> }
  ]

  useEffect(() => {
    dispatch(actions.fetchData(token, 'UPDATES', commonURI))
  }, [])

  return (
    <Card className="top-line-info" style={{ height: '98%' }}>
      <CardHeader className="no-border">
        <CardTitle>Remote Configuration</CardTitle>
      </CardHeader>
      <Nav tabs>
        {tabs.map(tab => (
          <NavItem key={tab.id}>
            <NavLink
              className={classnames({ active: activeTab === tab.id })}
              onClick={() => {
                toggleTab(tab.id)
              }}
            >
              {tab.header}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      {tabs.some(tab => tab.id === activeTab) && tabs.find(tab => tab.id === activeTab).content}
    </Card>
  )
}
