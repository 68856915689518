import React, { useState } from 'react'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'

export default function ChartParamSelect({ chartParams, selectedParam, setSelectedParam }) {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const toggle = () => setDropdownOpen(prevState => !prevState)

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle} className="d-block float-left">
      <DropdownToggle caret color="info">
        {selectedParam && selectedParam.display_name}
      </DropdownToggle>
      <DropdownMenu>
        {chartParams.map(value => (
          <DropdownItem key={value.param} onClick={() => setSelectedParam(value)}>
            {value.display_name}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  )
}
