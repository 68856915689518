import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useHistory } from 'react-router-dom'
import { Card, CardBody, CardHeader, CardTitle, Col, Row, Button } from 'reactstrap'
import { ContainerFluid, Content, ContentHeader, CardTools } from '../../components'
import UpdateList from './List'
import * as actions from '../../store/actions'
import { adminURI } from '../../api/endpoints'

export default function Users({ location }) {
  const dispatch = useDispatch()
  const history = useHistory()

  const token = useSelector(state => state.auth.token)
  const updates = useSelector(state => state.updates.data)
  const loading = useSelector(state => state.updates.loading)

  useEffect(() => {
    document.title = 'vUTP | Updates'
    if (!(location && location.state && location.state.noFetch)) {
      dispatch(actions.fetchData(token, 'UPDATES', adminURI))
    } else if (location && location.state){
      history.replace()
    }
  }, [])

  return (
    <>
      <ContentHeader title="Update Packages" />
      <Content>
        <ContainerFluid>
          <Row>
            <Col>
              <Card>
                <CardHeader className="no-border">
                  <CardTitle>Update Packages List</CardTitle>
                  <CardTools>
                    <NavLink to="/updates/add">
                      <Button color="info">Add Update Package</Button>
                    </NavLink>
                  </CardTools>
                </CardHeader>
                <CardBody className="p-0">
                  <UpdateList loading={loading} token={token} updates={updates} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ContainerFluid>
      </Content>
    </>
  )
}
