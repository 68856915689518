import React, { useRef } from 'react'
import { NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../store/actions'
import { Modal } from '../../components'
import { FaEdit, FaTrashAlt } from 'react-icons/fa'

export default function DeliveryConfigHeader({ nDC }) {
  const dispatch = useDispatch()
  const modalRef = useRef()
  const token = useSelector(state => state.auth.token)

  function deleteItem(nDC) {
    dispatch(actions.deleteData(token, 'NOTIFICATION_DELIVERY_CONFIGS', nDC.id))
  }

  return (
    <>
      {nDC.display_name}{' '}
      {nDC.display_name !== 'gui' && (
        <>
          <NavLink to={`/notifications/delivery_configs/edit/${nDC.id}`} className="text-dark">
            <FaEdit />
          </NavLink>
          <Modal
            ref={modalRef}
            title={`Delete delivery config "${nDC.display_name}"`}
            content={`Are you sure you want to delete "${nDC.display_name}" delivery config?`}
            onApply={() => deleteItem(nDC)}
          />
          <FaTrashAlt
            title="Delete Delivery Config"
            onClick={() => modalRef.current.openModal()}
            className="clickable"
          />
        </>
      )}
    </>
  )
}
