import React, { useRef } from 'react'
import { NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'reactstrap'
import { FaFileExport, FaTrashAlt } from 'react-icons/fa'
import * as actions from '../../store/actions'
import { Modal } from '../../components'
import { getTestBlocksString } from '../../utilities/testSuite'

export default function Preset({ preset }) {
  const dispatch = useDispatch()
  const modalRef = useRef()
  const token = useSelector(state => state.auth.token)
  const taskTypes = useSelector(state => state.task_types.data)

  function deletePreset(preset) {
    dispatch(actions.deleteData(token, `TEST_SUITE_PRESETS`, preset.id))
  }

  return (
    <tr>
      <td>{preset.display_name}</td>
      <td>{preset.description}</td>
      <td>{preset.config && getTestBlocksString(preset.config.test_blocks, taskTypes)}</td>
      <td>
        <NavLink
          to={{ pathname: '/test-suite', state: { rerunTest: preset.config, presetId: preset.id } }}
          className="pr-2"
        >
          <FaFileExport title="Export this Preset to Test Suite Configurator" />
        </NavLink>
        {preset.user && (
          <>
            <Modal
              ref={modalRef}
              title={`Delete test suite preset "${preset.display_name}"`}
              content={`Are you sure you want to delete "${preset.display_name}" test suite preset?`}
              onApply={() => deletePreset(preset)}
            />
            <Button color="link" onClick={() => modalRef.current.openModal()} className="p-0 mx-2 mb-1">
              <FaTrashAlt color="info" title="Delete Preset" className="align-middle" />
            </Button>
          </>
        )}
      </td>
    </tr>
  )
}
