import React, { createContext, useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import WSProvider from './WSProvider'

export const TestSuitesContext = createContext({})

export default function TestSuitesProvider({ children }) {
  const token = useSelector(state => state.auth.token)
  const [testSuites, setTestSuites] = useState([])
  const ws = useRef(null)
  const [wsActive, setWsActive] = useState(false)
  const ws_scheme = process.env.REACT_APP_HTTPS ? 'wss' : 'ws'

  useEffect(() => {
    ws.current = new WebSocket(`${ws_scheme}://${window.location.host}/ws/test_suites/?_token=${token}`)
  }, [])

  useEffect(() => {
    if (ws && ws.current) {
      setWsActive(true)
      ws.current.onmessage = evt => {
        // listen to data sent from the websocket server
        const message = JSON.parse(evt.data)
        setTestSuites(prev => {
          if (prev.some(testSuite => testSuite.id === message.id)) {
            return prev.map(testSuite => {
              return testSuite.id === message.id ? message : testSuite
            })
          } else {
            return [...prev, message]
          }
        })
      }
    }
  }, [ws && ws.current])

  return (
    ws &&
    wsActive &&
    ws.current && (
      <WSProvider ws={ws} contextName="test_suites" storeKeyName="test_suites">
        <TestSuitesContext.Provider
          value={{
            testSuitesWS: testSuites.sort(function(a, b) {
              return new Date(b.timestamp_created).getTime() - new Date(a.timestamp_created).getTime()
            })
          }}
        >
          {children}
        </TestSuitesContext.Provider>
      </WSProvider>
    )
  )
}
