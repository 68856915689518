import React from 'react'
import PropTypes from 'prop-types'
import { ClipLoader } from 'react-spinners'

const Spinner = ({ columns }) => {
  let spinner = (
    <div className='text-center my-5'>
      <ClipLoader
        color='#17a2b8'
        size={50} />
    </div>
  )
  if (columns) {
    spinner = (
      <tr>
        <td colSpan={columns}>
          {spinner}
        </td>
      </tr>
    )
  }
  return spinner
}

Spinner.displayName = 'Spinner'

Spinner.propTypes = {
  columns: PropTypes.number
}

Spinner.defaultProps = {
  columns: null
}

export default Spinner
