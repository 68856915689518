import React, { useRef } from 'react'
import { NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'reactstrap'
import { Modal } from '../../components'
import image from '../../assets/user.png'
import { FaTrashAlt, FaEdit } from 'react-icons/fa'
import * as actions from '../../store/actions'
import { adminURI } from '../../api/endpoints'

const UserRow = ({ user, currentUser }) => {
  const dispatch = useDispatch()
  const modalRef = useRef()
  const token = useSelector(state => state.auth.token)

  function deleteItem(user) {
    dispatch(actions.deleteData(token, 'USERS', user.id, false, adminURI))
  }
  return (
    <tr>
      <td>
        <img src={image} alt="User" className="img-circle img-size-32 mr-2" />
        {user.username}
      </td>
      <td>{user.first_name}</td>
      <td>{user.last_name}</td>
      <td>{user.email}</td>
      <td style={{ textAlign: 'center' }}>
        <NavLink className="p-3" to={`/users/edit/${user.id}`}>
          <FaEdit title="Edit user" />
        </NavLink>
        {currentUser && user.id !== currentUser.id && (
          <>
            <Modal
              ref={modalRef}
              title={`Delete user "${user.username}"`}
              content={`Are you sure you want to delete "${user.username}" user?`}
              onApply={() => deleteItem(user)}
            />
            <Button color="link" onClick={() => modalRef.current.openModal()}>
              <FaTrashAlt title="Delete user" />
            </Button>
          </>
        )}
      </td>
    </tr>
  )
}

export default UserRow
