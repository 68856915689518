import React, { useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  Collapse,
  CustomInput,
  Table
} from 'reactstrap'
import { CardTools, Editor, TableBody, Tooltip } from '../../components'
import { FaPlus, FaMinus } from 'react-icons/fa'
import { testSuiteLocalConfNotifTypesNames } from '../../utilities/testSuite'
import TestSuiteConfigurator from '../../context/TestSuiteConfiguratorContext'

export default function AdvancedSettings() {
  const notificationsDeliveryConfigs = useSelector(state => state.notifications_delivery_configs.data)
  const notificationsTypes = useSelector(state => state.notifications_types.data)
  const testSuiteNotificationsTypes =
    notificationsTypes && notificationsTypes.filter(nT => testSuiteLocalConfNotifTypesNames.includes(nT.name))
  const [cardOpened, setCardOpened] = useState(false)
  const { priorityLimit, setPriorityLimit, options, setOptions } = useContext(TestSuiteConfigurator)

  const params5G = [
    {
      name: 'mme_address',
      label: 'MME Address'
    },
    {
      name: 'imsi',
      label: 'IMSI'
    },
    {
      name: 'opc',
      label: 'OPC'
    },
    {
      name: 'k',
      label: 'Key'
    }
  ]

  return (
    <Card className="top-line-info">
      <CardHeader className="no-border">
        <CardTitle> Advanced Settings</CardTitle>
        <CardTools>
          {cardOpened ? (
            <FaMinus onClick={() => setCardOpened(false)} />
          ) : (
            <FaPlus onClick={() => setCardOpened(true)} />
          )}
        </CardTools>
      </CardHeader>
      <Collapse isOpen={cardOpened}>
        <CardBody>
          <Row>
            <Col sm="4" xs="12">
              {testSuiteNotificationsTypes && notificationsDeliveryConfigs && (
                <Table responsive bordered size="sm">
                  <thead>
                    <tr>
                      <th>Show notifications on:</th>
                      {notificationsDeliveryConfigs &&
                        notificationsDeliveryConfigs.map(nDC => <th key={nDC.id}>{nDC.display_name}</th>)}
                    </tr>
                  </thead>
                  <TableBody>
                    {testSuiteNotificationsTypes.map(notifType => (
                      <tr key={notifType.id}>
                        <th>{notifType.display_name}</th>
                        {notificationsDeliveryConfigs.map(nDC => (
                          <td key={nDC.id}>
                            <CustomInput
                              type="checkbox"
                              id={`${notifType.id}_${nDC.id}`}
                              checked={
                                options.notifications && options.notifications[notifType.id]
                                  ? options.notifications[notifType.id].includes(nDC.id)
                                  : false
                              }
                              onChange={e => {
                                setOptions(prev => ({
                                  ...prev,
                                  notifications: {
                                    ...prev.notifications,
                                    [notifType.id]: e.target.checked
                                      ? [...prev.notifications[notifType.id], nDC.id]
                                      : [...prev.notifications[notifType.id].filter(item => item !== nDC.id)]
                                  }
                                }))
                              }}
                            />
                          </td>
                        ))}
                      </tr>
                    ))}
                  </TableBody>
                </Table>
              )}
              <FormGroup>
                <Label for="priority_limit">Priority Limit:</Label>{' '}
                <Tooltip
                  name="priority_limit"
                  content="Priority limit is a threshold value, blocks with priority below which, do not trigger notifications when the threshold is exceeded."
                />
                <Input
                  type="number"
                  id="priority_limit"
                  value={Number.isInteger(priorityLimit) ? priorityLimit : ''}
                  name="priority_limit"
                  min={0}
                  onChange={e => {
                    setPriorityLimit(e.target.value ? parseInt(e.target.value) : '', e.target.name)
                  }}
                />
              </FormGroup>
            </Col>
            <Col sm="8" xs="12">
              <FormGroup>
                <CustomInput
                  type="checkbox"
                  id="test_suite_thresholds_enabled"
                  label="Enable Test Suite Thresholds"
                  checked={options.thresholds.enabled}
                  onChange={() =>
                    setOptions(prev => ({
                      ...prev,
                      thresholds: {
                        ...prev.thresholds,
                        enabled: !prev.thresholds.enabled
                      }
                    }))
                  }
                />
              </FormGroup>
              {options.thresholds.enabled && (
                <FormGroup>
                  <Label for="thresholdTestSuitePythonContent">Thresholds</Label>{' '}
                  <Tooltip
                    name="thresholdTestSuitePythonContent"
                    content={
                      <>
                        <div>The description of the thresholds configuration can be found in the </div>
                        <a
                          href="https://docs.vutp.netrsr.com/en/test_suite/create_test_suite.html#thresholds-config-scenario"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          vUTP documentation
                        </a>
                      </>
                    }
                  />
                  <Editor
                    placeholder="Set test suite thresholds by Python code"
                    mode="python"
                    name="thresholdTestSuitePythonContent"
                    onChange={e =>
                      setOptions(prev => ({
                        ...prev,
                        thresholds: {
                          ...prev.thresholds,
                          code: e
                        }
                      }))
                    }
                    value={options.thresholds.code || ''}
                  />
                </FormGroup>
              )}
              <FormGroup>
                <CustomInput
                  type="checkbox"
                  id="test_suite_5g_config"
                  label="5G Core Configuration"
                  checked={(options['5g_config'] && options['5g_config'].enabled) || false}
                  onChange={e =>
                    options['5g_config']
                      ? setOptions(prev => ({
                          ...prev,
                          '5g_config': {
                            ...prev['5g_config'],
                            enabled: e.target.checked
                          }
                        }))
                      : setOptions(prev => ({
                          ...prev,
                          '5g_config': { enabled: e.target.checked, imsi: '', k: '', opc: '', mme_address: '' }
                        }))
                  }
                />
              </FormGroup>
              {options['5g_config'] && options['5g_config'].enabled && (
                <>
                  {params5G.map(param5g => (
                    <FormGroup row key={param5g.name}>
                      <Label for={param5g.name} sm={2}>
                        {param5g.label}
                      </Label>{' '}
                      <Col sm={10}>
                        <Input
                          type="text"
                          id={param5g.name}
                          value={options['5g_config'][param5g.name]}
                          name={param5g.name}
                          onChange={e => {
                            setOptions(prev => ({
                              ...prev,
                              '5g_config': {
                                ...prev['5g_config'],
                                [param5g.name]: e.target.value
                              }
                            }))
                          }}
                        />
                      </Col>
                    </FormGroup>
                  ))}
                  <p style={{ color: 'orange', fontSize: '18px', textAlign: 'center' }}>
                    Advanced parameters in development...
                  </p>
                </>
              )}
            </Col>
          </Row>
        </CardBody>
      </Collapse>
    </Card>
  )
}
