import React from 'react'
import { Table, Progress } from 'reactstrap'
import { convertDigitalValueToLabel } from '../../../../utilities/stringFormatting'

export default function ResultHAR({ result }) {
  const timelineMin = Math.min(
    ...Object.keys(result.har.log.entries).map(key => new Date(result.har.log.entries[key].startedDateTime).getTime())
  )
  const timelineMax = Math.max(
    ...Object.keys(result.har.log.entries).map(
      key => new Date(result.har.log.entries[key].startedDateTime).getTime() + result.har.log.entries[key].time
    )
  )

  function renderProgressBar(entryId, attributeName, color) {
    return (
      result.har.log.entries[entryId].timings[attributeName] > 0 && (
        <div
          className={`progress-bar bg-${color}`}
          role="progressbar"
          title={`type: ${attributeName}\nconnection start: ${new Date(
            result.har.log.entries[entryId].startedDateTime
          )}\nduration: ${result.har.log.entries[entryId].timings[attributeName]} ms`}
          style={{
            minWidth: '1px',
            width: `${(result.har.log.entries[entryId].timings[attributeName] / (timelineMax - timelineMin)) * 100}%`
          }}
        />
      )
    )
  }

  return (
    <>
      <img
        alt="har_screenshot"
        src={`data:image/png;base64,${result.screenshot}`}
        height="300"
        className="mx-auto d-block"
      />
      <Table responsive size="sm" style={{ tableLayout: 'fixed' }}>
        <thead>
          <tr>
            <th style={{ width: '50%' }}>URL</th>
            <th>Status</th>
            <th>Size</th>
            <th style={{ width: '30%' }}>Timeline</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(result.har.log.entries).map(key => (
            <tr key={key}>
              <td style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                {result.har.log.entries[key].request.url}
              </td>
              <td>{result.har.log.entries[key].response.status}</td>
              <td>
                {convertDigitalValueToLabel(
                  Math.max(0, result.har.log.entries[key].response.headersSize) +
                    Math.max(0, result.har.log.entries[key].response.bodySize),
                  'B'
                )}
              </td>
              <td>
                <Progress multi>
                  <Progress
                    bar
                    value={new Date(result.har.log.entries[key].startedDateTime).getTime() - timelineMin}
                    max={timelineMax - timelineMin}
                    color="light"
                  />
                  {renderProgressBar(key, 'dns', 'primary')}
                  {renderProgressBar(key, 'ssl', 'danger')}
                  {renderProgressBar(key, 'send', 'warning')}
                  {renderProgressBar(key, 'wait', 'secondary')}
                  {renderProgressBar(key, 'connect', 'success')}
                  {renderProgressBar(key, 'receive', 'info')}
                  <Progress
                    bar
                    value={
                      timelineMax -
                      new Date(result.har.log.entries[key].startedDateTime).getTime() -
                      result.har.log.entries[key].time
                    }
                    max={timelineMax - timelineMin}
                    color="light"
                  />
                </Progress>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  )
}
