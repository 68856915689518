import React from 'react'
import { Table } from 'reactstrap'
import { Doughnut } from 'react-chartjs-2'

const Chart = require('react-chartjs-2').Chart

var originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw
Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
  draw: function() {
    originalDoughnutDraw.apply(this, arguments)

    var chart = this.chart
    var width = chart.chart.width,
      height = chart.chart.height,
      ctx = chart.chart.ctx

    //ctx.font = 0.8 + 'em sans-serif'
    ctx.textBaseline = 'middle'

    let sum = chart.config.data.datasets[0].data[0]

    var text = sum,
      textX = Math.round((width - ctx.measureText(text).width) / 2),
      textY = height - 8

    ctx.fillText(text, textX, textY)
  }
})

export default function ResultCPUResources({ result }) {
  function renderDoughnut(colors, values) {
    return (
      <Doughnut
        data={{
          datasets: [
            {
              data: values,
              backgroundColor: colors,
              hoverBackgroundColor: colors
            }
          ]
        }}
        options={{
          responsive: false,
          rotation: 1 * Math.PI,
          circumference: 1 * Math.PI,
          tooltips: {
            enabled: false
          }
        }}
        width={80}
        height={50}
      />
    )
  }

  const emptyDoughnut = renderDoughnut([], [0, 1])

  return (
    <div>
      <Table responsive size="sm">
        <thead>
          <tr>
            <th>Processor</th>
            <th>Model Name</th>
            <th>Vendor</th>
            <th>Current Clock [MHz]</th>
            <th>Min Clock [MHz]</th>
            <th>Max Clock [MHz]</th>
            <th>BogoMIPS</th>
            <th>Cache [KB]</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(result).map(key => (
            <tr key={key}>
              <td>{result[key].processor}</td>
              <td>{result[key].modelname}</td>
              <td>{result[key].vendorid}</td>
              <td>
                {result[key].max_freq
                  ? renderDoughnut(
                      [
                        result[key].current_freq >
                        (result[key].max_freq - result[key].min_freq) / 2 + result[key].min_freq
                          ? 'green'
                          : 'orange'
                      ],
                      [
                        Math.round(result[key].current_freq),
                        Math.round(result[key].max_freq) - Math.round(result[key].current_freq)
                      ]
                    )
                  : emptyDoughnut}
              </td>
              <td>
                {result[key].max_freq
                  ? renderDoughnut(
                      ['orange'],
                      [
                        Math.round(result[key].min_freq),
                        Math.round(result[key].max_freq) - Math.round(result[key].min_freq)
                      ]
                    )
                  : emptyDoughnut}
              </td>
              <td>
                {result[key].max_freq ? renderDoughnut(['green'], [Math.round(result[key].max_freq)]) : emptyDoughnut}
              </td>
              <td>{result[key].bogomips}</td>
              <td>{result[key].cachesize}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}
