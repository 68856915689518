import * as Api from '../../api'
import { commonURI } from '../../api/endpoints'

export const fetchCategories = token => dispatch => {
  dispatch({ type: `FETCH_CATEGORIES_START` })

  Api.fetchData(token, 'CATEGORIES', commonURI)
    .then(({ data }) => {
      dispatch({ type: `FETCH_CATEGORIES_SUCCESS`, payload: { data } })
      let tests = []
      dispatch({ type: `FETCH_CATEGORIES_DETAILS_START` })
      data.results.forEach(category => {
        category.subcategories.forEach(subcategory => {
          subcategory.task_types.forEach(task_type_name => {
            let test = {
              name: task_type_name,
              subcategory: {
                id: subcategory.id,
                name: subcategory.name,
                display_name: subcategory.display_name
              },
              category: {
                id: category.id,
                name: category.name,
                display_name: category.display_name
              }
            }
            tests.push(test)
          })
        })
      })
      dispatch({ type: `FETCH_CATEGORIES_DETAILS_SUCCESS`, payload: { data: tests } })
    })
    .catch(error => dispatch({ type: `FETCH_CATEGORIES_FAIL`, payload: { error } }))
}
