export const notificationMessage = (notification, notificationsTypes, workers, taskTypes) => {
  const notificationType =
    notification && notificationsTypes && notificationsTypes.find(notifType => notifType.id === notification.type)
  switch (notificationType && notificationType.name) {
    case 'TASK_STARTED':
      return `Task ${notification.additional_data &&
        (taskTypes && taskTypes.some(taskType => taskType.name === notification.additional_data.task_type)
          ? 'with test ' + taskTypes.find(taskType => taskType.name === notification.additional_data.task_type).display_name
          : notification.additional_data.task_type)} from test suite "${notification.additional_data &&
        notification.additional_data.test_suite_name}" has been started.`
    case 'TASK_SUCCEEDED':
      return `Task ${notification.additional_data &&
        (taskTypes && taskTypes.some(taskType => taskType.name === notification.additional_data.task_type)
          ? 'with test ' + taskTypes.find(taskType => taskType.name === notification.additional_data.task_type).display_name
          : notification.additional_data.task_type)} from test suite "${notification.additional_data &&
        notification.additional_data.test_suite_name}" has been finished.`
    case 'TASK_FAILED':
      return `Task ${notification.additional_data &&
        (taskTypes && taskTypes.some(taskType => taskType.name === notification.additional_data.task_type)
          ? 'with test ' + taskTypes.find(taskType => taskType.name === notification.additional_data.task_type).display_name
          : notification.additional_data.task_type)} from test suite "${notification.additional_data &&
        notification.additional_data.test_suite_name}" is failed.`
    case 'THRESHOLD_EXCEEDED':
      return `Threshold ${
        notification.additional_data && notification.additional_data.test_block_name
          ? `in test block "${notification.additional_data.test_block_name}"`
          : ''
      } in test suite "${notification.additional_data &&
        notification.additional_data.test_suite_name}" has been exceeded.`
    case 'TEST_SUITE_STARTED':
      return `Test suite "${notification.additional_data &&
        notification.additional_data.test_suite_name}" has been started.`
    case 'TEST_SUITE_FINISHED':
      return `Test suite "${notification.additional_data &&
        notification.additional_data.test_suite_name}" has been finished.`
    case 'TEST_AGENT_ONLINE':
      return `Test agent ${
        workers && workers.some(worker => worker.id === notification.additional_data.test_agent_id)
          ? workers.find(worker => worker.id === notification.additional_data.test_agent_id).name
          : notification.additional_data.test_agent_id
      } changed its status to online.`
    case 'TEST_AGENT_OFFLINE':
      return `Test agent ${
        workers && workers.some(worker => worker.id === notification.additional_data.test_agent_id)
          ? workers.find(worker => worker.id === notification.additional_data.test_agent_id).name
          : notification.additional_data.test_agent_id
      } changed its status to offline.`
    case 'BACKEND_ERROR':
      return `Backend error: ${notification.additional_data.text}`
    default:
      return 'Unknown notification.'
  }
}

export const notificationLink = (notification, notificationsTypes) => {
  const notificationType =
    notification && notificationsTypes && notificationsTypes.find(notifType => notifType.id === notification.type)
  switch (notificationType && notificationType.name) {
    case 'TASK_STARTED':
    case 'TASK_SUCCEEDED':
    case 'TASK_FAILED':
    case 'THRESHOLD_EXCEEDED':
    case 'TEST_SUITE_STARTED':
    case 'TEST_SUITE_FINISHED':
      return (
        notification &&
        notification.additional_data &&
        notification.additional_data.test_suite_id &&
        `/results/details/${notification.additional_data.test_suite_id}`
      )
    case 'TEST_AGENT_ONLINE':
    case 'TEST_AGENT_OFFLINE':
      return '/test-agents'
    default:
      return '/'
  }
}

export const notificationColor = (notification, notificationsTypes) => {
  const notificationType =
    notification && notificationsTypes && notificationsTypes.find(notifType => notifType.id === notification.type)
  switch (notificationType && notificationType.name) {
    case 'TASK_STARTED':
    case 'TEST_SUITE_STARTED':
    case 'TEST_SUITE_FINISHED':
      return 'info'
    case 'TASK_FAILED':
    case 'THRESHOLD_EXCEEDED':
    case 'TEST_AGENT_OFFLINE':
    case 'BACKEND_ERROR':
      return 'danger'
    case 'TASK_SUCCEEDED':
    case 'TEST_AGENT_ONLINE':
      return 'success'
    default:
      return ''
  }
}
