import React, { useEffect, useState } from 'react'
import { FormGroup, Input, Label } from 'reactstrap'
import { usePrevious } from '../../utilities/customHooks'
import { isJsonString } from '../../utilities/stringFormatting'
import QueryBuilder from './QueryBuilder'

export default function ThresholdsJsonBuilder({ query, setQuery, thresholdsParams }) {
  const [startQueryMode, setStartQueryMode] = useState(
    isJsonString(query) && JSON.parse(query).type === 'threshold' ? 'rule' : 'group'
  )
  const previousSQM = usePrevious(startQueryMode)
  useEffect(() => {
    if (previousSQM) {
      if (startQueryMode === 'rule') {
        setQuery({
          type: 'threshold',
          value: 0,
          variable: thresholdsParams[0].param,
          threshold_type: 'gte'
        })
      } else {
        setQuery({
          type: 'operator',
          operator_type: 'and',
          thresholds: []
        })
      }
    }
  }, [startQueryMode])

  return (
    <>
      <FormGroup>
        <div className="d-inline">
          <Label for="startQueryMode">Builder mode:</Label>
          <Input
            type="select"
            id="startQueryMode"
            value={startQueryMode}
            onChange={e => setStartQueryMode(e.target.value)}
          >
            <option value="rule">Single Threshold</option>
            <option value="group">Thresholds Tree</option>
          </Input>
        </div>
      </FormGroup>
      {isJsonString(query) && (
        <QueryBuilder query={JSON.parse(query)} setQuery={setQuery} thresholdsParams={thresholdsParams} />
      )}
    </>
  )
}
