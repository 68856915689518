import React, { useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import { Label, CustomInput, ListGroup, ListGroupItem, ListGroupItemText, Alert } from 'reactstrap'
import { SubHeader, Editor, Tooltip } from '../../components'
import { ThresholdsJsonBuilder } from '../../containers/'
import { getJsonError } from '../../utilities/stringFormatting'
import TestSuiteConfigurator from '../../context/TestSuiteConfiguratorContext'

export default function AdditionalConfiguration() {
  const { selectedBlock, handleThresholdsChange, handleAdditionalConfigurationChange } = useContext(
    TestSuiteConfigurator
  )
  const [jsonEditorMode, setJsonEditorMode] = useState(false)
  const testsDetails = useSelector(state => state.task_types.data)
  const testDetails = testsDetails && selectedBlock && testsDetails.find(test => test.id === selectedBlock.task_type)
  return (
    <>
      <SubHeader>Thresholds</SubHeader>
      <CustomInput
        type="checkbox"
        id="enabled"
        name="enabled"
        label="Enabled"
        checked={(selectedBlock && selectedBlock.additional_options.thresholds.enabled) || false}
        onChange={e => {
          handleAdditionalConfigurationChange(e.target.checked, e.target.name, 'thresholds')
        }}
      />
      {selectedBlock &&
        selectedBlock.additional_options.thresholds.enabled &&
        testDetails.options &&
        testDetails.options.additional_options &&
        testDetails.options.additional_options.thresholds &&
        testDetails.options.additional_options.thresholds.enabled && (
          <div>
            <CustomInput
              type="radio"
              id="thresholdModeOld"
              name="mode"
              label="Standard Configuration"
              checked={selectedBlock.additional_options.thresholds.mode === 'json'}
              onChange={e => {
                handleAdditionalConfigurationChange('json', e.target.name, 'thresholds')
              }}
            />
            <CustomInput
              type="radio"
              id="thresholdModePython"
              name="mode"
              label="Advanced Configuration"
              checked={selectedBlock.additional_options.thresholds.mode === 'python'}
              onChange={e => {
                handleAdditionalConfigurationChange('python', e.target.name, 'thresholds')
              }}
            />
          </div>
        )}
      {selectedBlock &&
        selectedBlock.additional_options.thresholds.enabled &&
        selectedBlock.additional_options.thresholds.mode === 'json' && (
          <>
            <CustomInput
              type="switch"
              id="jsonEditorSwitch"
              label="JSON Editor"
              value={jsonEditorMode}
              onChange={() => setJsonEditorMode(!jsonEditorMode)}
              className="float-right"
            />
            <div className="mt-4">
              {jsonEditorMode ? (
                <>
                  {testDetails.options.additional_options.thresholds &&
                    testDetails.options.additional_options.thresholds.params && (
                      <>
                        <Label>Possible threshold variables:</Label>
                        <ListGroup horizontal className="pb-2 w-100 overflow-auto">
                          {testDetails.options.additional_options.thresholds.params.map(thresholdParam => (
                            <ListGroupItem key={thresholdParam.param} className="py-0 text-center">
                              <ListGroupItemText className="my-0 font-weight-bold">
                                {thresholdParam.param}
                              </ListGroupItemText>
                              <ListGroupItemText className="my-0">{thresholdParam.name}</ListGroupItemText>
                            </ListGroupItem>
                          ))}
                        </ListGroup>
                      </>
                    )}
                  <Label>Thresholds configuration:</Label>
                  {selectedBlock.additional_options.thresholds.error && (
                    <Alert color="danger">{selectedBlock.additional_options.thresholds.error}</Alert>
                  )}
                  <Editor
                    placeholder="Set Thresholds Configuration in JSON"
                    mode="json"
                    name="thresholdsJSONContent"
                    onChange={e => handleThresholdsChange(e, e === '' ? null : getJsonError(e), 'configuration')}
                    value={selectedBlock.additional_options.thresholds.configuration}
                  />
                </>
              ) : (
                <ThresholdsJsonBuilder
                  query={selectedBlock.additional_options.thresholds.configuration}
                  setQuery={query => handleThresholdsChange(JSON.stringify(query, null, 2), null, 'configuration')}
                  thresholdsParams={testDetails.options.additional_options.thresholds.params}
                />
              )}
            </div>
          </>
        )}
      {selectedBlock &&
        selectedBlock.additional_options.thresholds.enabled &&
        selectedBlock.additional_options.thresholds.mode === 'python' && (
          <>
            <Label for="thresholdTestBlockPythonContent">Thresholds</Label>{' '}
            <Tooltip
              name="thresholdTestBlockPythonContent"
              content={
                <>
                  <div>The description of the thresholds configuration can be found in the </div>
                  <a
                    href="https://docs.vutp.netrsr.com/en/test_suite/create_test_suite.html#thresholds-config"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    vUTP documentation
                  </a>
                </>
              }
            />
            <Editor
              placeholder="Set Threshold Function in Python"
              mode="python"
              name="thresholdPythonContent"
              onChange={e => handleThresholdsChange(e, undefined, 'code')}
              value={selectedBlock.additional_options.thresholds.code || ''}
            />
          </>
        )}
    </>
  )
}
