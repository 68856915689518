import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Col, Row } from 'reactstrap'
import { ContainerFluid, Content, ContentHeader, Spinner } from '../../components'
import { TestAgentEdit, TestAgentRemoteConfiguration } from './'
import { usePrevious } from '../../utilities/customHooks'
import { TestAgentsContext } from '../../context/TestAgentsContext'

export default function TestAgentUpdate({ match }) {
  const history = useHistory()

  const loading = useSelector(
    state =>
      state.workers.loading ||
      state.functions_data.test_agents.network_config.loading ||
      state.functions_data.test_agents.reboot.loading ||
      state.functions_data.test_agents.update.loading
  )
  const testAgentsError = useSelector(
    state =>
      state.workers.error ||
      state.functions_data.test_agents.network_config.error ||
      state.functions_data.test_agents.reboot.error ||
      state.functions_data.test_agents.update.error
  )
  const prevTestAgentsError = usePrevious(testAgentsError)
  const { testAgents } = useContext(TestAgentsContext)
  const testAgent = testAgents && testAgents.find(worker => worker.id === parseInt(match.params.id))

  const [isAfterDispatch, setIsAfterDispatch] = useState(false)

  useEffect(() => {
    document.title = 'vUTP | Test Agents'
  }, [])

  useEffect(() => {
    if (isAfterDispatch && !prevTestAgentsError && !testAgentsError) {
      history.push('/test-agents')
    }
  }, [testAgents])

  return (
    <>
      <ContentHeader title="Test Agents" subtitle="Update" />
      <Content>
        <ContainerFluid>
          {!loading ? (
            <Row>
              <Col lg="6" md="12">
                <TestAgentEdit testAgent={testAgent} submitForm={() => setIsAfterDispatch(true)} />
              </Col>
              <Col lg="6" md="12">
                <TestAgentRemoteConfiguration testAgent={testAgent} submitForm={() => setIsAfterDispatch(true)} />
              </Col>
            </Row>
          ) : (
            <Spinner />
          )}
        </ContainerFluid>
      </Content>
    </>
  )
}
