import React from 'react'
import { Button, Form, Input } from 'reactstrap'
import { FaTrashAlt } from 'react-icons/fa'

export default function Rule({ query, setQuery, thresholdsParams }) {
  function onChange(e) {
    let updatedQuery = { ...query }
    updatedQuery[e.target.name] = e.target.type === 'number' ? parseFloat(e.target.value) : e.target.value
    setQuery(updatedQuery)
  }

  function deleteRule() {
    setQuery({})
  }

  return (
    <Form inline>
      <Input
        type="select"
        id="thresholdsParams"
        name="variable"
        value={query.variable}
        onChange={onChange}
        className="m-1"
      >
        {thresholdsParams.map(thresholdsParam => (
          <option key={thresholdsParam.param} value={thresholdsParam.param}>
            {thresholdsParam.name}
          </option>
        ))}
      </Input>
      <Input
        type="select"
        id="rule"
        name="threshold_type"
        value={query.threshold_type}
        onChange={onChange}
        className="m-1"
      >
        <option value="lt">LESS THAN</option>
        <option value="lte">LESS OR EQUAL THAN</option>
        <option value="gt">GREATER THAN</option>
        <option value="gte">GREATER OR EQUAL THAN</option>
        <option value="eq">EQUAL</option>
        <option value="nq">NOT EQUAL</option>
      </Input>
      <Input type="number" id="thresholdValue" name="value" value={query.value} onChange={onChange} className="m-1" />
      <Button onClick={deleteRule} className="m-1">
        <FaTrashAlt />
      </Button>
    </Form>
  )
}
