import React from 'react'
import { Table } from 'reactstrap'
import { convertDigitalValueToLabel } from '../../../../utilities/stringFormatting'

export default function ResultRFC6349({ result, parameters }) {
  if (parameters.mode === 'client') {
    return (
      <Table responsive size="sm">
        <thead>
          <tr>
            {result.succeeded ? (
              <>
                <th>Direction</th>
                <th>Maximum achievable TCP throughput</th>
                <th>TCP efficiency</th>
                <th>Transfer Time Ratio</th>
                <th>Buffer delay</th>
                <th>Succeeded</th>
              </>
            ) : (
              <>
                <th>Succeeded</th>
                <th>Error</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {result.succeeded ? (
            <>
              <tr>
                <td>{result.forward.summary.direction}</td>
                <td>{convertDigitalValueToLabel(result.forward.summary.mean_rate, 'bit/s')}</td>
                <td>{(result.forward.summary.tcp_efficiency * 100).toFixed(2)} %</td>
                <td>{result.forward.summary.transfer_ratio.toFixed(2)}</td>
                <td>{(result.forward.summary.buffer_delay * 100).toFixed(2)} %</td>
                <td style={{ color: result.succeeded ? 'green' : 'red' }}>{result.succeeded ? 'PASS' : 'FAILED'}</td>
              </tr>
              {!parameters.one_way && (
                <tr>
                  <td>{result.reverse.summary.direction}</td>
                  <td>{convertDigitalValueToLabel(result.reverse.summary.mean_rate, 'bit/s')}</td>
                  <td>{(result.reverse.summary.tcp_efficiency * 100).toFixed(2)} %</td>
                  <td>{result.reverse.summary.transfer_ratio.toFixed(2)}</td>
                  <td>{(result.reverse.summary.buffer_delay * 100).toFixed(2)} %</td>
                  <td style={{ color: result.succeeded ? 'green' : 'red' }}>{result.succeeded ? 'PASS' : 'FAILED'}</td>
                </tr>
              )}
            </>
          ) : (
            <>
              <tr>
                <td style={{ color: result.succeeded ? 'green' : 'red' }}>{result.succeeded ? 'PASS' : 'FAILED'}</td>
                <td>{result.error}</td>
              </tr>
            </>
          )}
        </tbody>
      </Table>
    )
  } else {
    return (
      <Table responsive size="sm">
        <thead>
          <tr>
            <th>Succeeded</th>
            {result.error && <th>Error</th>}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ color: result.succeeded ? 'green' : 'red' }}>{result.succeeded ? 'PASS' : 'FAILED'}</td>
            {result.error && <td>{result.error}</td>}
          </tr>
        </tbody>
      </Table>
    )
  }
}
