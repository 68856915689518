import React from 'react'
import { Table } from 'reactstrap'
import { FaCheck, FaTimes } from 'react-icons/fa'
import Moment from 'react-moment'

export default function ResultVoip({ result, parameters }) {
  return (
    <div>
      <Table responsive size="sm">
        <thead>
          <tr>
            <th>Account</th>
            <th>Destination</th>
            <th>Registered</th>
            <th>Answered</th>
            <th>Events</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{parameters.sip_user}</td>
            <td>{parameters.sip_destination}</td>
            <td>{result.data.registered ? <FaCheck /> : <FaTimes color="red" />}</td>
            <td>{result.data.answered ? <FaCheck /> : <FaTimes color="red" />}</td>
            <td>
              {result.data.events
                .slice(0)
                .reverse()
                .map((event, index) => (
                  <li key={index}>
                    <Moment format="DD-MM-YYYY HH:mm:ss">{event.timestamp * 1000}</Moment> - {event.name}{' '}
                    {event.detail !== '' && `(${event.detail})`}
                  </li>
                ))}
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  )
}
