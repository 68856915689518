import * as actionTypes from './actionTypes'
import * as Api from '../../api'

const localStorage = window.localStorage

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START
  }
}

export const authSuccess = (token, is_superuser) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    token: token,
    is_superuser: is_superuser
  }
}

export const authFail = error => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error
  }
}

export const logout = (error) => {
  localStorage.removeItem('token')
  localStorage.removeItem('expirationDate')
  localStorage.removeItem('is_superuser')
  return {
    type: actionTypes.AUTH_LOGOUT,
    session_error: error
  }
}

export const checkAuthTimeout = expirationTime => {
  return dispatch => {
    setTimeout(() => {
      dispatch(logout())
    }, expirationTime * 1000)
  }
}

export const auth = (username, password) => {
  const secondsToExpiration = 3600 * 24 * 365
  return dispatch => {
    dispatch(authStart())
    Api.acquireToken(username, password)
      .then(response => {
        const expirationDate = new Date(new Date().getTime() + secondsToExpiration * 1000)
        localStorage.setItem('token', response.data.token)
        localStorage.setItem('expirationDate', expirationDate)
        localStorage.setItem('is_superuser', response.data.is_superuser)
        dispatch(authSuccess(response.data.token, response.data.is_superuser))
        dispatch(checkAuthTimeout(secondsToExpiration))
      })
      .catch(error => {
        dispatch(authFail(error))
      })
  }
}

export const setAuthRedirectPath = path => {
  return {
    type: actionTypes.SET_AUTH_REDIRECT_PATH,
    path: path
  }
}

export const authCheckState = () => {
  return dispatch => {
    const token = localStorage.getItem('token')
    const is_superuser = localStorage.getItem('is_superuser')
    if (!token) {
      dispatch(logout())
    } else {
      const expirationDate = new Date(localStorage.getItem('expirationDate'))
      if (expirationDate <= new Date()) {
        dispatch(logout())
      } else {
        dispatch(authSuccess(token, is_superuser))
        dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime()) / 1000))
      }
    }
  }
}
