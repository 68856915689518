import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import * as actions from '../../store/actions'

export default function TypeFilter({ limit }) {
  const dispatch = useDispatch()
  const token = useSelector(state => state.auth.token)
  const notificationsTypes = useSelector(state => state.notifications_types.data)
  const notificationsParams = useSelector(state => state.notifications.params)
  const [notificationsTypesFilter, setNotificationsTypesFilter] = useState([])

  function onChangeSelect(e) {
    setNotificationsTypesFilter(e)
    getFilteredNotifications(e)
  }

  useEffect(() => {
    if (notificationsParams) {
      if (notificationsParams.type && Array.isArray(notificationsParams.type.split('|'))) {
        let notifFilters = []
        notificationsParams.type.split('|').forEach(notifTypeId => {
          let notifType = notificationsTypes.find(notifType => notifType.id === parseInt(notifTypeId))
          if (notifType) {
            notifFilters.push(notifType)
          }
        })
        setNotificationsTypesFilter(notifFilters)
      }
    }
  }, [notificationsParams])

  function getFilteredNotifications(typesInFilter) {
    let message = {
      limit: limit,
      ordering: '-timestamp'
    }
    if (typesInFilter && typesInFilter.length > 0) {
      message.type = typesInFilter.map(notifType => notifType.id).join('|')
    }
    message.severity = notificationsParams.severity
    dispatch(actions.fetchDataParams(token, 'NOTIFICATIONS', message))
  }

  return (
    <Select
      id="notificationsFilter"
      name="notificationsFilter"
      isMulti
      options={
        notificationsTypes &&
        notificationsTypes.map(notifType => {
          return { value: notifType.id, label: notifType.display_name }
        })
      }
      value={notificationsTypesFilter.map(notifType => {
        return { value: notifType.id, label: notifType.display_name }
      })}
      placeholder="Select a notifications types..."
      onChange={e =>
        onChangeSelect(
          e
            ? e.map(item => {
                return notificationsTypes.find(notifType => notifType.id === item.value)
              })
            : []
        )
      }
      styles={{
        menu: provided => ({ ...provided, zIndex: 9999, marginTop: 0 })
      }}
    />
  )
}
