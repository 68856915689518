import React from 'react'
import { ResultRun5GInfra, ResultTerminate5GInfra } from './ResultsEnvTasks'

export default function ResultEnvTask({ env_type, result, parameters }) {
  const renderDetails = (env_type, result, parameters) => {
    switch (env_type) {
      case '5g_infra':
        if (parameters.run) return <ResultRun5GInfra result={result} />
        else return <ResultTerminate5GInfra result={result} />
      default:
        return null
    }
  }
  return env_type && result ? renderDetails(env_type, result, parameters) : null
}
