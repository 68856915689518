import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../store/actions'
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from 'reactstrap'
import moment from 'moment-timezone'
import Moment from 'react-moment'
import { Spinner } from '../../components'
import user from '../../assets/user.png'

export default function UserProfile() {
  const dispatch = useDispatch()
  const token = useSelector(state => state.auth.token)
  const account = useSelector(state => state.account.data)
  const loading = useSelector(state => state.account.loading)
  const [dropdownOpen, setDropdownOpen] = useState(false)

  function toggle() {
    setDropdownOpen(!dropdownOpen)
  }

  function setTimezone(e) {
    dispatch(actions.updateData(token, 'ACCOUNT', account.id, { timezone: e.currentTarget.textContent }, true))
  }

  return (
    <Card className="top-line-info">
      <CardHeader>
        <CardTitle>User Profile</CardTitle>
      </CardHeader>
      <CardBody>
        {loading ? (
          <Spinner />
        ) : (
          account && (
            <>
              <img src={user} alt="User profile" className="d-block profile-user-img img-responsive img-circle" />
              <h3 className="profile-username text-center">{account.username}</h3>
              <div className="text-muted" style={{ textAlign: 'center' }}>
                <div>
                  Your subscription is currently <b>{account.subscription_active ? 'active' : 'not active'}</b>
                </div>
                {account.subscription_plan_name && (
                  <div>
                    Subscription plan: <b>{account.subscription_plan_name}</b>
                  </div>
                )}
                <div>
                  Service subscription is due to expire on:{' '}
                  <b>
                    <Moment format="DD-MM-YYYY HH:mm:ss">{account.subscription_valid_until}</Moment>
                  </b>
                </div>
                <div>
                  Account balance: <b>{account.credits} credits</b>
                </div>
              </div>
              <Table size="sm">
                <tbody>
                  <tr>
                    <th>Username</th>
                    <td style={{ textAlign: 'right' }}>{account.username}</td>
                  </tr>
                  <tr>
                    <th>First Name</th>
                    <td style={{ textAlign: 'right' }}>{account.first_name}</td>
                  </tr>
                  <tr>
                    <th>Last Name</th>
                    <td style={{ textAlign: 'right' }}>{account.last_name}</td>
                  </tr>
                  <tr>
                    <th>E-mail</th>
                    <td style={{ textAlign: 'right' }}>{account.email}</td>
                  </tr>
                  <tr>
                    <th>Joined</th>
                    <td style={{ textAlign: 'right' }}>
                      <Moment format="DD-MM-YYYY HH:mm:ss">{account.date_joined}</Moment>
                    </td>
                  </tr>
                  <tr>
                    <th>Timezone</th>
                    <td style={{ textAlign: 'right' }}>
                      <Dropdown
                        style={{ display: 'inline-block' }}
                        direction="up"
                        size="sm"
                        isOpen={dropdownOpen}
                        toggle={toggle}
                      >
                        <DropdownToggle>{account.timezone ? account.timezone : 'None'}</DropdownToggle>
                        <DropdownMenu
                          modifiers={{
                            setMaxHeight: {
                              enabled: true,
                              order: 890,
                              fn: data => {
                                return { ...data, styles: { ...data.styles, overflow: 'auto', maxHeight: 300 } }
                              }
                            }
                          }}
                        >
                          {moment.tz.names().map(timezone => (
                            <DropdownItem size="sm" onClick={setTimezone} key={timezone}>
                              {timezone}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </>
          )
        )}
      </CardBody>
    </Card>
  )
}
