import React, { useContext, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody } from 'reactstrap'
import ReactCardFlip from 'react-card-flip'
import OptionInput from '../../pages/Tests/OptionInput'
import { defaultTestForm, categoryIcon } from '../../utilities/testParameters'
import {
  validateLocations,
  validateMultiSelect,
  validateNumberInput,
  validateTextInput,
  validateUniqueParam
} from '../../utilities/formValidator'
import { useOutside } from '../../utilities/customHooks'
import classNames from 'classnames'
import TestSuiteConfigurator from '../../context/TestSuiteConfiguratorContext'

export default function TestTileCompact({ item, params, setStepsValid }) {
  const { steps, setSteps, globalLocations } = useContext(TestSuiteConfigurator)

  const categories = useSelector(state => state.test_categories.data)
  const category = categories && categories.find(category => category.id === item.subcategories[0].category)
  const categoryName = category && category.name
  const [showBack, setShowBack] = useState(false)

  const [isValid, setIsValid] = useState({})
  const [feedback, setFeedback] = useState({})
  const [validatorEnabled, setValidatorEnabled] = useState({})

  const tileSelected =
    steps[0].tests[0] &&
    steps[0].tests[0].task_type_name === item.name &&
    !Object.values(isValid).some(value => value === false)

  const backCardRef = useRef(null)
  useOutside(backCardRef, () => setShowBack(false))

  useEffect(() => {
    setStepsValid(!Object.values(isValid).some(value => value === false))
  }, [isValid])

  function handleClick() {
    if (!showBack && (steps[0].tests.length === 0 || steps[0].tests[0].task_type_name !== item.name)) {
      const testForm = defaultTestForm(item, steps, globalLocations)
      setSteps([{ tests: [testForm] }])
      params &&
        params.forEach(param => {
          validateInput(param.name, param.type, param.attributes && param.attributes.initial, param.label, {
            validators: param.validators,
            attributes: param.attributes,
            index: param.type === 'multipleinput' ? 0 : undefined
          })
        })
      setValidatorEnabled({})
    }
    if (params && params.length > 0) {
      setShowBack(!showBack)
    } else {
      setIsValid({})
    }
  }

  function handleBlur(paramName, index) {
    let _validatorEnabled = { ...validatorEnabled }
    if (isNaN(index)) {
      _validatorEnabled[paramName] = true
    } else {
      if (!(paramName in _validatorEnabled)) {
        _validatorEnabled[paramName] = []
      }
      _validatorEnabled[paramName][index] = true
    }
    setValidatorEnabled(_validatorEnabled)
  }

  function removeValidationParamsFromMultipleInput(paramName, index) {
    let _validatorEnabled = { ...validatorEnabled }
    _validatorEnabled[paramName].splice(index, 1)
    setValidatorEnabled(_validatorEnabled)
    let _feedback = { ...feedback }
    _feedback[paramName].splice(index, 1)
    setFeedback(_feedback)
    let _isValid = { ...isValid }
    _isValid[paramName].splice(index, 1)
    setIsValid(_isValid)
  }

  function validateInput(paramName, inputType, value, display_name, { validators, attributes, index, collection }) {
    let validateResult = { isValid: false, feedback: '' }
    switch (inputType) {
      case 'text':
        validateResult = validateTextInput(value, display_name, validators)
        break
      case 'text-unique':
        let validateText = validateTextInput(value, display_name, validators)
        let validateUnique = validateUniqueParam(value, paramName, display_name, collection)
        validateResult.isValid = validateText.isValid && validateUnique.isValid
        validateResult.feedback = validateText.feedback !== '' ? validateText.feedback : validateUnique.feedback
        break
      case 'number':
        validateResult = validateNumberInput(value, display_name, attributes)
        break
      case 'multiselect':
        validateResult = validateMultiSelect(value, display_name)
        break
      case 'multipleinput':
        validateResult = validateTextInput(value, display_name, validators)
        break
      case 'locations':
        validateResult = validateLocations(
          value,
          display_name,
          item && item.cloud_available,
          item && item.on_premises_available
        )
        break
      default:
        break
    }
    if (isNaN(index)) {
      setIsValid({ ...isValid, [paramName]: validateResult.isValid })
      setFeedback({ ...feedback, [paramName]: validateResult.feedback })
    } else {
      let isV = { ...isValid }
      let feedb = { ...feedback }
      if (!(paramName in isV)) {
        isV[paramName] = []
      }
      if (!(paramName in feedb)) {
        feedb[paramName] = []
      }
      isV[paramName][index] = validateResult.isValid
      feedb[paramName][index] = validateResult.feedback
      setIsValid(isV)
      setFeedback(feedb)
    }
  }

  return (
    <ReactCardFlip isFlipped={showBack} /*flipDirection="vertical"*/>
      <Card
        className={classNames(`m-0 tile-${categoryName}`, { active: tileSelected })}
        onClick={handleClick}
        style={{ minHeight: '48px' }}
      >
        <CardBody
          className={classNames(`p-1 d-flex align-items-center tile-${categoryName}`, { active: tileSelected })}
        >
          {categoryIcon(category.name, tileSelected)} <p className="m-0 d-inline">{item.display_name}</p>
        </CardBody>
      </Card>
      {params && params.length > 0 && (
        <div ref={backCardRef}>
          <Card
            className={classNames(`m-0 tile-${categoryName}`, { active: tileSelected })}
            onClick={handleClick}
            style={{ minHeight: '48px' }}
          >
            <CardBody className="p-1">
              {steps[0].tests[0] &&
                params &&
                params.map(param => (
                  <OptionInput
                    key={param.name}
                    option={param}
                    validator={{ isValid: isValid, enabled: validatorEnabled, feedback: feedback }}
                    removeValidationParamsFromMultipleInput={removeValidationParamsFromMultipleInput}
                    handleBlur={handleBlur}
                    validateInput={validateInput}
                    placeholder={param.name}
                  />
                ))}
            </CardBody>
          </Card>
        </div>
      )}
    </ReactCardFlip>
  )
}
