import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { GrafanaFrame } from '../../../../containers'
import { TimeChart } from '../../'
import { onlyUnique } from '../../../../utilities/mathOperations'
import TestSuiteInResults from '../../../../context/TestSuiteInResultsContext'

export default function RAMInfo() {
  const { selectedBlock } = useContext(TestSuiteInResults)
  const test_block_id = selectedBlock.id
  const testSuite = useSelector(state => state.test_suite.data)
  const monitoring = testSuite.repeat_interval
  const multi_agent =
    selectedBlock.on_premise_test_agents
      .concat(selectedBlock.cloud_locations)
      .concat(testSuite.on_premise_test_agents)
      .concat(testSuite.cloud_locations)
      .filter(onlyUnique).length > 1

  return monitoring ? (
    <TimeChart panel_id={56} />
  ) : multi_agent ? (
    <>
      <GrafanaFrame
        panel_id={54} // panelId from Grafana, defines the chart
        test_block_id={test_block_id}
        selectedTimerange={{ from: 'now-1h', to: 'now' }}
        refreshFlag={false}
        style={{ width: '95%', height: '380px' }}
        title={'ram-info-percent'}
      />
    </>
  ) : (
    <>
      <GrafanaFrame
        panel_id={10} // panelId from Grafana, defines the chart
        test_block_id={test_block_id}
        selectedTimerange={{ from: 'now-1h', to: 'now' }}
        refreshFlag={false}
        style={{ width: '55%', height: '350px' }}
        title={'ram-info-percent'}
      />

      <GrafanaFrame
        panel_id={16} // panelId from Grafana, defines the chart
        test_block_id={test_block_id}
        selectedTimerange={{ from: 'now-1h', to: 'now' }}
        refreshFlag={false}
        style={{ width: '40%', height: '300px' }}
        title={'ram-info-precise'}
      />
    </>
  )
}
