import React from 'react'
import {
  ResultCPUResources,
  ResultCPUUtilization,
  ResultRAMInfo,
  ResultStorageInfo,
  ResultSWAPInfo,
  ResultNICInfo,
  ResultRAMRead,
  ResultStorageReWrite,
  ResultPing,
  ResultTraceroute,
  ResultPathping,
  ResultTCP,
  ResultUDP,
  ResultFTPDownload,
  ResultHTTPSLatency,
  ResultHTTPSSecurity,
  ResultOTT,
  ResultHAR,
  ResultOTTStress,
  ResultVoip,
  ResultBashScript,
  ResultDHCP,
  ResultIPTV,
  ResultWWW,
  ResultScraper,
  ResultIperf,
  ResultDNS,
  ResultRFC6349,
  ResultUniversal,
  ResultTwamp,
  ResultFTPAvailability,
  ResultHTTPSThroughput
} from './ResultsPerTaskType'

export default function ResultPerTaskType({ task_type, result, parameters }) {
  const renderDetails = (task_type, result, parameters) => {
    switch (task_type.name) {
      case 'cpu_hash_test': // Node -> CPU Performance -> Hashing
      case 'cpu_aes_test': // Node -> CPU Performance -> AES Encryption
      case 'cpu_compression_test': // Node -> CPU Performance -> DEFLATE Compression
      case 'cpu_rsa_test': // Node -> CPU Performance -> RSA Encryption
      case 'cpu_fft_test': // Node -> CPU Performance -> Math (FFT) Operation
      case 'hdd_random_read_test': // Node -> Storage Performance -> HDD Random Read
      case 'hdd_write_test': // Node -> Storage Performance -> HDD Write
      case 'hdd_read_test': // Node -> Storage Performance -> HDD Read
      case 'hdd_latency_test': // Node -> Storage Performance -> HDD Latency
      case 'ram_latency_test': // Node -> RAM Performance -> RAM Latency
      case 'ram_write_test': // Node -> RAM Performance -> RAM Write
        return <ResultUniversal task_type={task_type} result={result} />
      case 'info_cpu': // Node -> OS Info -> CPU Resources
        return <ResultCPUResources result={result} parameters={parameters} />
      case 'info_cpu_times': // Node -> OS Info -> CPU Utilization
        return <ResultCPUUtilization result={result} parameters={parameters} />
      case 'info_memory': // Node -> OS Info -> RAM Info
        return <ResultRAMInfo result={result} parameters={parameters} />
      case 'info_disk_usage': // Node -> OS Info -> Storage Info
        return <ResultStorageInfo result={result} parameters={parameters} />
      case 'info_swap': // Node -> OS Info -> SWAP Info
        return <ResultSWAPInfo result={result} parameters={parameters} />
      case 'info_network_counters': // Node -> OS Info -> NIC Info
        return <ResultNICInfo result={result} parameters={parameters} />
      case 'ram_read_test': // Node -> RAM Performance -> RAM Read
        return <ResultRAMRead result={result} parameters={parameters} />
      case 'hdd_rewrite_test': // Node -> Storage Performance -> Storage Re-Write
        return <ResultStorageReWrite result={result} parameters={parameters} />
      case 'network_ping': // Network -> Connectivity -> Ping
        return <ResultPing result={result} parameters={parameters} />
      case 'traceroute_test': // Network -> Connectivity -> Traceroute
        return <ResultTraceroute result={result} parameters={parameters} />
      case 'pathping_test': // Network -> Connectivity -> Pathping
        return <ResultPathping result={result} parameters={parameters} />
      case 'udp_scan_test': // Network -> Connectivity -> UDP Scan
        return <ResultUDP result={result} parameters={parameters} />
      case 'tcp_syn_scan_test': // Network -> Connectivity -> TCP Initial Scan
      case 'tcp_connection_scan_test': // Network -> Connectivity -> TCP Connectivity Scan
        return <ResultTCP result={result} parameters={parameters} />
      case 'ftp_availability_test': // Network -> Connectivity -> FTP Availability
        return <ResultFTPAvailability result={result} parameters={parameters} />
      case 'ftp_file_download_test': // Network -> Performance -> FTP Download
        return <ResultFTPDownload result={result} parameters={parameters} />
      case 'http_ping_test': // Network -> Performance -> HTTP/S Latency
        return <ResultHTTPSLatency result={result} parameters={parameters} />
      case 'http_file_download_test': // Network -> Performance -> HTTP/S Throughput
        return <ResultHTTPSThroughput result={result} parameters={parameters} />
      case 'https_test': // Network -> Connectivity -> HTTPS Security
        return <ResultHTTPSSecurity result={result} parameters={parameters} />
      case 'hls_test': // Services -> Services -> OTT
        return <ResultOTT result={result} parameters={parameters} />
      case 'har_test': // Services -> Services -> HAR Test
        return <ResultHAR result={result} parameters={parameters} />
      case 'ott_stress_test': // Services -> Services -> OTT Stress Test
        return <ResultOTTStress result={result} parameters={parameters} />
      case 'voip_test': // Services -> Services -> VoIP
        return <ResultVoip result={result} parameters={parameters} />
      case 'bash_script': // Services -> Services -> Bash Script
        return <ResultBashScript result={result} parameters={parameters} />
      case 'dhcp_test': // Network -> Connectivity -> DHCP Test
        return <ResultDHCP result={result} />
      case 'iptv_test': // Services -> Services -> IPTV Test
        return <ResultIPTV result={result} />
      case 'www_test': // Network -> Connectivity -> WWW Test
        return <ResultWWW result={result} />
      case 'scraper_test': // Network -> Connectivity -> Scraper Test
        return <ResultScraper result={result} parameters={parameters} />
      case 'iperf_test': // Network -> Services -> Iperf Test
        return <ResultIperf result={result} />
      case 'dns_test': // Network -> Connectivity -> DNS Test
        return <ResultDNS result={result} parameters={parameters} />
      case 'rfc6349_test': // Network -> Performance -> RFC6349
        return <ResultRFC6349 result={result} parameters={parameters} />
      case 'twamp_test': // Network -> Performance -> TWAMP
        return <ResultTwamp result={result} parameters={parameters} />
      default:
        return null
    }
  }
  return task_type && result ? renderDetails(task_type, result, parameters) : null
}
