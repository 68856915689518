import { updateObject } from '../utilities'

const initialSingleFuncData = {
  data: null,
  loading: false,
  error: null
}

const functions = [
  { collectionName: 'test_agents', functionName: 'network_config' },
  { collectionName: 'test_agents', functionName: 'reboot' },
  { collectionName: 'test_agents', functionName: 'update' },
  { collectionName: 'test_suites', functionName: 'delete' },
  { collectionName: 'test_suites', functionName: 'set_as_preset' }
]

const reducer = () => {
  const initialState = {
    test_agents: {
      network_config: initialSingleFuncData,
      reboot: initialSingleFuncData,
      update: initialSingleFuncData
    },
    test_suites: {
      delete: initialSingleFuncData,
      set_as_preset: initialSingleFuncData
    }
  }

  return (state = initialState, { type, payload }) => {
    let functionTypeStart = functions.find(
      func => type === `ADD_${func.collectionName.toUpperCase()}_${func.functionName.toUpperCase()}_START`
    )
    if (functionTypeStart) {
      return updateObject(state, {
        [functionTypeStart.collectionName]: {
          ...state[functionTypeStart.collectionName],
          [functionTypeStart.functionName]: { data: null, loading: true, error: null }
        }
      })
    }
    let functionTypeSuccess = functions.find(
      func => type === `ADD_${func.collectionName.toUpperCase()}_${func.functionName.toUpperCase()}_SUCCESS`
    )
    if (functionTypeSuccess) {
      return updateObject(state, {
        [functionTypeSuccess.collectionName]: {
          ...state[functionTypeSuccess.collectionName],
          [functionTypeSuccess.functionName]: { data: payload.data, loading: false, error: null }
        }
      })
    }
    let functionTypeFail = functions.find(
      func => type === `ADD_${func.collectionName.toUpperCase()}_${func.functionName.toUpperCase()}_FAIL`
    )
    if (functionTypeFail) {
      return updateObject(state, {
        [functionTypeFail.collectionName]: {
          ...state[functionTypeFail.collectionName],
          [functionTypeFail.functionName]: { data: null, loading: false, error: payload.error }
        }
      })
    }
    return state
  }
}

export default reducer
