import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input } from 'reactstrap'
import { FaFilter } from 'react-icons/fa'
import Select from 'react-select'
import { testSuiteStatuses } from '../../utilities/statuses'

export default function TestSuitesFilters({ filters, viewPageWithFilters }) {
  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)

  const taskTypes = useSelector(state => state.task_types.data)
  const testAgents = useSelector(state => state.workers.data)

  return (
    <>
      <Button
        color={
          filters &&
          ((filters.testAgentFilter && filters.testAgentFilter.length > 0) ||
            filters.taskFilter ||
            (filters.statusFilter && filters.statusFilter.length > 0))
            ? 'danger'
            : 'secondary'
        }
        outline
        onClick={toggle}
      >
        <FaFilter /> Filters
      </Button>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Filters</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="testAgentFilter">Test agents</Label>
              <Select
                id="testAgentFilter"
                name="testAgentFilter"
                isMulti
                options={
                  testAgents &&
                  testAgents.map(testAgent => {
                    return { value: testAgent.id, label: testAgent.name }
                  })
                }
                value={
                  filters &&
                  filters.testAgentFilter &&
                  filters.testAgentFilter.map(testAgent => {
                    return { value: testAgent.id, label: testAgent.name }
                  })
                }
                placeholder="Select a test agents..."
                onChange={e => {
                  viewPageWithFilters({
                    testAgentFilter: e
                      ? e.map(item => {
                          return testAgents.find(testAgent => testAgent.id === item.value)
                        })
                      : []
                  })
                }}
                styles={{
                  menu: provided => ({ ...provided, zIndex: 9999 })
                }}
              />
            </FormGroup>
            <FormGroup>
              <Label for="taskFilter">Test blocks task type</Label>
              <Input
                type="select"
                name="taskFilter"
                id="taskFilter"
                value={filters ? filters.taskFilter : 0}
                onChange={e => {
                  viewPageWithFilters({ taskFilter: parseInt(e.target.value) })
                }}
              >
                <option value={0}>All task types</option>
                {taskTypes &&
                  taskTypes.map(taskType => (
                    <option key={taskType.id} value={taskType.id}>
                      {taskType.display_name}
                    </option>
                  ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="statusFilter">Status</Label>
              <Select
                id="statusFilter"
                name="statusFilter"
                isMulti
                options={
                  testSuiteStatuses &&
                  testSuiteStatuses.map(tSS => {
                    return { value: tSS.id, label: tSS.display_name }
                  })
                }
                value={
                  filters &&
                  filters.statusFilter &&
                  filters.statusFilter.map(tSS => {
                    return { value: tSS.id, label: tSS.display_name }
                  })
                }
                placeholder="Select a status..."
                onChange={e => {
                  viewPageWithFilters({
                    statusFilter: e
                      ? e.map(item => {
                          return testSuiteStatuses.find(tSS => tSS.id === item.value)
                        })
                      : []
                  })
                }}
                styles={{
                  menu: provided => ({ ...provided, zIndex: 9999 })
                }}
              />
            </FormGroup>
          </Form>
        </ModalBody>
      </Modal>
    </>
  )
}
