import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { PulseLoader } from 'react-spinners'
import { ResultsContext } from '../../../../context/ResultsContext'
import { taskStatuses } from '../../../../utilities/statuses'
import { TimeChart } from '../../'
import TestSuiteInResults from '../../../../context/TestSuiteInResultsContext'

export default function RFC6349() {
  const { resultsWS } = useContext(ResultsContext)
  const resultsApi = useSelector(state => state.results.data)
  const testSuite = useSelector(state => state.test_suite.data)
  const { selectedBlock } = useContext(TestSuiteInResults)

  const monitoring = testSuite.repeat_interval

  const statusFinishedId =
    taskStatuses &&
    taskStatuses.some(tS => tS.name === 'FINISHED') &&
    taskStatuses.find(tS => tS.name === 'FINISHED').id

  const resultFinished =
    resultsApi &&
    resultsWS
      .concat(resultsApi)
      .find(result => result.test_block === selectedBlock.id && result.status === statusFinishedId)

  const resultRFC =
    resultsApi &&
    resultsWS
      .concat(resultsApi)
      .find(
        result =>
          result.test_block === selectedBlock.id &&
          result.status === statusFinishedId &&
          result.result &&
          result.result.result &&
          result.result.result.forward
      )

  return !monitoring ? (
    resultFinished ? (
      resultRFC ? (
        <>
          <TimeChart panel_id={66} param="uplink" />
          <TimeChart panel_id={68} param="downlink" />
        </>
      ) : (
        'No results for chart'
      )
    ) : (
      <div>
        Waiting for results <PulseLoader size={5} color="black" />
      </div>
    )
  ) : (
    'No chart'
  )
}
