import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input } from 'reactstrap'
import { FaFilter } from 'react-icons/fa'
import DatePicker from 'react-datepicker'

export default function LogsFilters({ filters, viewPageWithFilters }) {
  const users = useSelector(state => state.users.data)

  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)

  const actions = [
    'create_test_suite',
    'delete_test_suite',
    'create_test_agent',
    'edit_test_agent',
    'configure_test_agent_networking',
    'reboot_test_agent',
    'update_test_agent',
    'delete_test_agent',
    'create_retrieve_token',
    'delete_token',
    'change_password',
    'account_update',
    'session_login'
  ]

  return (
    <>
      <Button
        color={
          filters &&
          (filters.user ||
            filters.startTime ||
            filters.endTime ||
            (filters.ip !== undefined && filters.ip !== '') ||
            (filters.action !== undefined && filters.action !== ''))
            ? 'danger'
            : 'secondary'
        }
        outline
        size="sm"
        onClick={toggle}
        className="mr-2"
      >
        <FaFilter /> Filters
      </Button>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Filters</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="userFilter">User</Label>
              <Input
                type="select"
                name="userFilter"
                id="userFilter"
                value={filters ? filters.user : 0}
                onChange={e => {
                  viewPageWithFilters({ user: parseInt(e.target.value) })
                }}
              >
                <option value={0}>All users</option>
                {users &&
                  users.map(user => (
                    <option key={user.id} value={user.id}>
                      {user.username}
                    </option>
                  ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="actionFilter">Action</Label>
              <Input
                type="select"
                name="actionFilter"
                id="actionFilter"
                value={filters ? filters.action || '' : ''}
                onChange={e => {
                  viewPageWithFilters({ action: e.target.value })
                }}
              >
                <option value="">All actions</option>
                {actions.map(action => (
                  <option key={action} value={action}>
                    {action}
                  </option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="startTimeFilter">Start Time</Label>
              <DatePicker
                name="startTimeFilter"
                selected={filters ? filters.startTime : null}
                onChange={e => {
                  viewPageWithFilters({ startTime: e })
                }}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                dateFormat="dd.MM.yyyy kk:mm"
                popperPlacement="bottom-end"
                onKeyDown={e => {
                  e.preventDefault()
                }}
                isClearable
                className={`form-control`}
              />
            </FormGroup>
            <FormGroup>
              <Label for="endTimeFilter">End Time</Label>
              <DatePicker
                name="endTimeFilter"
                selected={filters ? filters.endTime : null}
                onChange={e => {
                  viewPageWithFilters({ endTime: e })
                }}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                dateFormat="dd.MM.yyyy kk:mm"
                popperPlacement="bottom-end"
                onKeyDown={e => {
                  e.preventDefault()
                }}
                isClearable
                className={`form-control`}
              />
            </FormGroup>
            <FormGroup>
              <Label for="IPFilter">IP Address</Label>
              <Input
                name="IPFilter"
                id="IPFilter"
                value={filters ? filters.ip || '' : ''}
                onChange={e => {
                  viewPageWithFilters({ ip: e.target.value })
                }}
              />
            </FormGroup>
          </Form>
        </ModalBody>
      </Modal>
    </>
  )
}
