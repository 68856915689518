import React, { useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { NavLink } from 'react-router-dom'
import * as actions from '../../store/actions'
import { Toast, ToastHeader, ToastBody, Card } from 'reactstrap'
import BottomScrollListener from 'react-bottom-scroll-listener'
import Moment from 'react-moment'
import { notificationMessage, notificationLink, notificationColor } from '../../utilities/notifications'
import { Spinner } from '../../components'
import { NotificationsContext } from '../../context/NotificationsContext'
//import SeverityFilter from './SeverityFilter'
import TypeFilter from './TypeFilter'

export default function Notifications({ hideDropdown }) {
  const limit = 20
  const dispatch = useDispatch()
  const token = useSelector(state => state.auth.token)
  const loading = useSelector(state => state.notifications.loading)
  const workers = useSelector(state => state.workers.data)
  const taskTypes = useSelector(state => state.task_types.data)
  const notificationsTypes = useSelector(state => state.notifications_types.data)
  const { notifications } = useContext(NotificationsContext)
  const notificationsCount = useSelector(state => state.notifications.count)
  const notificationsParams = useSelector(state => state.notifications.params)

  function getFilteredNotifications() {
    let message = {
      limit: limit,
      ordering: '-timestamp'
    }
    message.offset = notifications.length
    if (notificationsParams && notificationsParams.type) {
      message.type = notificationsParams.type
    }
    message.severity = notificationsParams.severity
    dispatch(actions.fetchDataParams(token, 'NOTIFICATIONS', message))
  }

  function handleScroll() {
    if (!loading && notifications.length !== notificationsCount) {
      getFilteredNotifications()
    }
  }

  function seeNotifications(notification) {
    dispatch(actions.setData('SEEN_NOTIFICATIONS', [notification]))
    dispatch(actions.updateData(token, 'NOTIFICATIONS', notification.id, { seen: true }, false))
  }

  return (
    <Card className="h-100">
      {notificationsParams && <TypeFilter limit={limit} />}
      {/*notificationsParams && <SeverityFilter limit={limit} />*/}
      {loading && notificationsParams && notificationsParams.offset === undefined ? (
        <Spinner />
      ) : (
        <BottomScrollListener onBottom={handleScroll}>
          {scrollRef => (
            <div ref={scrollRef} className="overflow-auto" style={{ height: '95%' }}>
              {notifications && notifications.length > 0
                ? notifications.map(notification => (
                    <div
                      key={notification.id}
                      className={`p-0 ${
                        !notification.seen ? `bg-${notificationColor(notification, notificationsTypes)}` : ''
                      } my-1 rounded`}
                    >
                      <Toast className="mw-100" transition={{ appear: false, timeout: 150 }}>
                        <NavLink
                          to={notificationLink(notification, notificationsTypes) || '/'}
                          onClick={() => {
                            hideDropdown && hideDropdown()
                            seeNotifications(notification)
                          }}
                        >
                          <ToastHeader>
                            <small className="text-muted">
                              <Moment format="DD-MM-YYYY HH:mm:ss">{notification.timestamp}</Moment>
                            </small>
                          </ToastHeader>
                          <ToastBody className="text-dark">
                            {notificationMessage(notification, notificationsTypes, workers, taskTypes)}
                          </ToastBody>
                        </NavLink>
                      </Toast>
                    </div>
                  ))
                : 'No notifications'}
              {loading && <Spinner />}
            </div>
          )}
        </BottomScrollListener>
      )}
    </Card>
  )
}
