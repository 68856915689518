import React from 'react'
import { Table } from 'reactstrap'
import { Spinner, TableBody } from '../../components'
import UserRow from './Row'

const UserList = ({ loading, users, currentUser }) => {
  return loading || (users && users.length > 0) ? (
    <Table className="table-valign-middle" striped>
      <thead>
        <tr>
          <th>Username</th>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Email</th>
          <th style={{ textAlign: 'center' }}>Actions</th>
        </tr>
      </thead>
      <TableBody>
        {loading ? (
          <Spinner columns={5} />
        ) : (
          users && users.map(user => <UserRow key={user.id} user={user} currentUser={currentUser} />)
        )}
      </TableBody>
    </Table>
  ) : (
    <div className="p-3">No Users</div>
  )
}

export default UserList
