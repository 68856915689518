import { updateObject } from '../utilities'

const reducer = (name = '') => {
  const initialState = {
    data: null,
    loading: false,
    error: null
  }

  return (state = initialState, { type, payload }) => {
    switch (type) {
      case `ADD_${name}_START`:
        return updateObject(state, { loading: true, error: null })
      case `ADD_${name}_SUCCESS`:
        return updateObject(state, { loading: false })
      case `ADD_${name}_FAIL`:
        return updateObject(state, { loading: false, error: payload.error })
      case `DELETE_${name}_START`:
        return updateObject(state, { loading: true, error: null })
      case `DELETE_${name}_SUCCESS`:
        return updateObject(state, { loading: false })
      case `DELETE_${name}_FAIL`:
        return updateObject(state, { loading: false, error: payload.error })
      case `FETCH_${name}_START`:
        return updateObject(state, { loading: true, error: null, params: payload ? payload.params : null })
      case `FETCH_${name}_SUCCESS`:
        return updateObject(state, {
          loading: false,
          data: payload.data.results || payload.data,
          next: payload.data.next,
          previous: payload.data.previous,
          count: payload.data.count,
        })
      case `FETCH_${name}_FAIL`:
        return updateObject(state, { loading: false, data: null, error: payload.error })
      case `UPDATE_${name}_START`:
        return updateObject(state, { loading: true, error: null })
      case `UPDATE_${name}_SUCCESS`:
        return updateObject(state, { loading: false })
      case `UPDATE_${name}_FAIL`:
        return updateObject(state, { loading: false, error: payload.error })
      case `INCREMENT_${name}_COUNT`:
        return updateObject(state, { count: state.count + 1 })
      case `CLEAR_${name}`:
        return updateObject(state, { data: null })
      default:
        return state
    }
  }
}

export default reducer
