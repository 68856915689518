import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Collapse } from 'reactstrap'
import Moment from 'react-moment'
import { JsonView } from '../../../components'
import { ResultTaskStatus } from '../../../containers'
import { FaMicrochip, FaCloud, FaCircle, FaAngleUp, FaAngleDown } from 'react-icons/fa'
import { ResultPerTaskType, ResultEnvTask } from '../'

export default function ResultTask({ result }) {
  const testSuite = useSelector(state => state.test_suite.data)
  const taskTypes = useSelector(state => state.task_types.data)
  const testsCategory = useSelector(state => state.test_categories_details.data)
  const block = testSuite && testSuite.test_blocks.find(b => b.id === result.test_block)
  const testCategory = testsCategory && block && testsCategory.find(test => test.name === block.task_type_name)
  const locations = useSelector(state => state.locations.data)
  const workers = useSelector(state => state.workers.data)
  const testAgent = result.cloud_location
    ? locations && locations.find(loc => loc.id === result.cloud_location)
    : workers && workers.find(worker => worker.id === result.test_agent)
  const [showDetails, setShowDetails] = useState(false)
  const [showRawResults, setShowRawResults] = useState(false)

  return (
    <>
      <tr>
        <td>
          <Button color="link" className="nav-link p-0" onClick={() => setShowDetails(!showDetails)}>
            {result.result && result.result.env_type
              ? result.result.parameters.run
                ? 'Run 5G Infra'
                : 'Terminate 5G Infra'
              : taskTypes && block && taskTypes.find(taskType => taskType.id === block.task_type).display_name}
          </Button>
          <small>
            {testCategory && `[${testCategory.category.display_name}: ${testCategory.subcategory.display_name}]`}
          </small>
        </td>
        <td>
          {result.cloud_location ? <FaCloud /> : <FaMicrochip />} {testAgent ? testAgent.name : '(Deleted test agent)'}
        </td>
        <td>
          <ResultTaskStatus status={result.status} />
        </td>
        <td className="text-center">
          <FaCircle
            className={`text-${
              result.threshold_exceeded ? 'danger' : result.threshold_exceeded === null ? 'secondary' : 'success'
            }`}
          />
        </td>
        <td>
          {result.timestamp_created ? <Moment format="DD-MM-YYYY HH:mm:ss">{result.timestamp_created}</Moment> : '-'}
        </td>
        <td>
          {result.timestamp_updated ? <Moment format="DD-MM-YYYY HH:mm:ss">{result.timestamp_updated}</Moment> : '-'}
        </td>
      </tr>
      {showDetails && (
        <tr style={{ backgroundColor: '#f2f2f2' }}>
          <td colSpan="6">
            {result &&
              result.result &&
              result.result.result &&
              (result.result.env_type ? (
                <ResultEnvTask
                  result={result.result.result}
                  env_type={result.result.env_type}
                  parameters={result.result.parameters}
                />
              ) : (
                <ResultPerTaskType
                  task_type={taskTypes && block && taskTypes.find(taskType => taskType.id === block.task_type)}
                  result={result.result.result}
                  parameters={result.result.parameters}
                />
              ))}
            <Button color="link" onClick={() => setShowRawResults(prev => !prev)}>
              {showRawResults ? 'Hide' : 'Show'} Raw Results {showRawResults ? <FaAngleUp /> : <FaAngleDown />}
            </Button>
            <Collapse isOpen={showRawResults}>
              <JsonView src={result.result} />
            </Collapse>
          </td>
        </tr>
      )}
    </>
  )
}
