import axios from 'axios'
import { userURI, commonURI } from './endpoints'
import {
  passCredentials,
  setToken,
  toLowerCase,
  setParamsAndToken,
  setXCSRFToken,
  setTokenAndDownloadHeaders
} from './utilities'

export const acquireToken = (username, password) => {
  return axios.post(commonURI + '/token/', passCredentials(username, password), setXCSRFToken())
}

export const getDataDetailsFunction = (token, name, id, funcName, onProgress, uri = userURI) => {
  return axios.get(
    `${uri}/${toLowerCase(name)}/${id}/${toLowerCase(funcName)}/`,
    setTokenAndDownloadHeaders(token, onProgress)
  )
}

/* Factory */

export const addData = (token, name, data, uri = userURI) => {
  return axios.post(`${uri}/${toLowerCase(name)}/`, data, setToken(token))
}

export const addDataFunction = (token, name, funcName, data, uri = userURI) => {
  return axios.post(`${uri}/${toLowerCase(name)}/${toLowerCase(funcName)}/`, data, setToken(token))
}

export const addDataDetailsFunction = (token, name, id, funcName, data, uri = userURI) => {
  return axios.post(`${uri}/${toLowerCase(name)}/${id}/${toLowerCase(funcName)}/`, data, setToken(token))
}

export const deleteData = (token, name, id, uri = userURI) => {
  return axios.delete(`${uri}/${toLowerCase(name)}/${id}/`, setToken(token))
}

export const fetchData = (token, name, uri = userURI) => {
  return axios.get(`${uri}/${toLowerCase(name)}/`, setToken(token))
}

export const fetchDataParams = (token, name, params, uri = userURI) => {
  return axios.get(`${uri}/${toLowerCase(name)}/`, setParamsAndToken(params,token))
}

export const fetchDataDetails = (token, name, id, uri = userURI) => {
  return axios.get(`${uri}/${toLowerCase(name)}/${id}/`, setToken(token))
}

export const updateData = (token, name, id, data, uri = userURI) => {
  return axios.patch(`${uri}/${toLowerCase(name)}/${id}/`, data, setToken(token))
}
