import React from 'react'
import { Table, CustomInput } from 'reactstrap'
import { SortDateIcon, SortNameIcon } from '../../utilities/statuses'

const ResultsList = ({
  children,
  testSuites,
  filters,
  viewPageWithFilters,
  checkAllRef,
  checkAllChange,
  sortDisabled,
  mini
}) => {
  const sort = (filters && filters.sortParam) || '-timestamp_created'

  function setSort(sortParam) {
    viewPageWithFilters({ sortParam })
  }

  return (
    <Table responsive size="sm">
      <thead>
        <tr>
          {checkAllChange && (
            <th>
              <CustomInput
                id="checkAllTestSuites"
                type="checkbox"
                innerRef={checkAllRef}
                onChange={e => checkAllChange(e.target.checked)}
              />
            </th>
          )}
          <th>
            Test Name
            {!sortDisabled && <SortNameIcon sort={sort} setSort={setSort} />}
          </th>
          {!mini && <th>Mode</th>}
          {!mini && <th>Test Blocks</th>}
          <th>Status</th>
          <th>
            Date Created
            {!sortDisabled && <SortDateIcon sort={sort} setSort={setSort} />}
          </th>
          {!mini && <th>Action</th>}
        </tr>
      </thead>
      {testSuites && testSuites.length > 0 ? (
        <tbody>{children}</tbody>
      ) : (
        <tbody>
          <tr>
            <td>No results</td>
          </tr>
        </tbody>
      )}
    </Table>
  )
}

export default ResultsList
