import React from 'react'
import { Form, Formik, ErrorMessage, Field } from 'formik'
import {
  FormGroup,
  Label,
  Input,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardFooter,
  Col,
  Row,
  Button,
  CustomInput
} from 'reactstrap'
import * as Yup from 'yup'
import DatePicker from 'react-datepicker'

export default function SubscriptionEditForm({ subscription, onDataUpdated }) {
  function onFormSubmit(values) {
    const updatedSubscription = {
      ...values
    }
    onDataUpdated(updatedSubscription)
  }

  const validationSchema = Yup.object().shape({
    subscr_id: Yup.string()
      .max(150, 'Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only')
      .required('Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only'),
    period: Yup.number()
      .min(0, 'Min value is 0')
      .required('Value cannot be empty and must be a number')
      .integer()
  })

  return subscription ? (
    <Formik
      initialValues={{
        subscr_id: subscription.subscr_id,
        period: subscription.period,
        valid_until: subscription.valid_until ? new Date(subscription.valid_until) : new Date(),
        active: subscription.active
      }}
      onSubmit={onFormSubmit}
      validationSchema={validationSchema}
      validateOnMount={true}
    >
      {({ errors, isValid, touched, values, setFieldValue }) => (
        <Card>
          <Form>
            <CardHeader>
              <CardTitle>Form</CardTitle>
            </CardHeader>
            <CardBody>
              <Row>
                <Col sm="12">
                  <FormGroup>
                    <Label for="subscr_id">Name</Label>
                    <Input
                      name="subscr_id"
                      placeholder="Select subscription name"
                      tag={Field}
                      invalid={touched.subscr_id && Boolean(errors.subscr_id)}
                    />
                    <ErrorMessage name="subscr_id" component="div" className="invalid-feedback" />
                  </FormGroup>
                  <FormGroup>
                    <Label for="period">Period</Label>
                    <Input
                      type="number"
                      name="period"
                      placeholder="Select period"
                      tag={Field}
                      invalid={touched.period && Boolean(errors.period)}
                    />
                    <ErrorMessage name="period" component="div" className="invalid-feedback" />
                  </FormGroup>
                  <FormGroup>
                    <CustomInput
                      type="checkbox"
                      id="active"
                      name="active"
                      label="Active"
                      onChange={e => setFieldValue('active', e.target.checked)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="valid_until">Valid Until</Label>
                    <DatePicker
                      name="valid_until"
                      selected={values.valid_until}
                      onChange={e => setFieldValue('valid_until', e)}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={60}
                      dateFormat="dd.MM.yyyy kk:mm"
                      minDate={new Date()}
                      className={`form-control`}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <Button color="info" className="float-right" type="submit" disabled={!isValid}>
                Apply
              </Button>
            </CardFooter>
          </Form>
        </Card>
      )}
    </Formik>
  ) : (
    <></>
  )
}
