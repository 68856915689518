import React, { useEffect } from 'react'
import { Col, Row, Card, CardBody, CardHeader, CardTitle } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../store/actions'
import { ContainerFluid, Content, ContentHeader, Spinner } from '../../components'
import { Preset, PresetsList } from '.'

export default function Presets() {
  const dispatch = useDispatch()
  const token = useSelector(state => state.auth.token)
  const presets = useSelector(state => state.test_suite_presets.data)
  const loading = useSelector(state => state.test_suite_presets.loading || state.task_types.loading)

  useEffect(() => {
    document.title = 'vUTP | Presets'
    dispatch(actions.fetchData(token, 'TEST_SUITE_PRESETS'))
  }, [])

  return (
    <>
      <ContentHeader title="Presets" />
      <Content>
        <ContainerFluid>
          <Row>
            <Col>
              <Card className="top-line-info">
                <CardHeader className="no-border">
                  <CardTitle>Test Suite Presets</CardTitle>
                </CardHeader>
                <CardBody>
                  {loading ? (
                    <Spinner />
                  ) : (
                    <PresetsList presets={presets}>
                      {presets && presets.map(preset => <Preset key={preset.id} preset={preset} />)}
                    </PresetsList>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ContainerFluid>
      </Content>
    </>
  )
}
