import React, { useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody, CardHeader, CardTitle, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap'
import { validateDateTimeRange, validateTextInput } from '../../utilities/formValidator'
import 'react-datepicker/dist/react-datepicker.css'
import Select from 'react-select'
import { locationOptions } from '../../utilities/locations'
import { DropdownButton } from '../../containers'
import { TestAgentsContext } from '../../context/TestAgentsContext'
import TestSuiteConfigurator from '../../context/TestSuiteConfiguratorContext'

export default function TestSchedule({ disabledButton }) {
  const [inputValid, setInputValid] = useState({ testname: false })
  const [validatorFeedback, setValidatorFeedback] = useState({ testname: '' })
  const [validatorEnabled, setValidatorEnabled] = useState({ testname: false })
  const { testAgents } = useContext(TestAgentsContext)
  const locations = useSelector(state => state.locations.data)
  const { testname, setTestname, schedule, submitTest, globalLocations, handleGlobalLocations } = useContext(
    TestSuiteConfigurator
  )

  function validateDateTimeInput(startDate, endDate) {
    let validateStartTime = validateDateTimeRange(startDate, 'Start Time', false, endDate)
    let validateEndTime = validateDateTimeRange(endDate, 'End Time', true, startDate)
    setInputValid({
      ...inputValid,
      startDateMonitoring: validateStartTime.isValid,
      endDateMonitoring: validateEndTime.isValid
    })
    setValidatorFeedback({
      ...validatorFeedback,
      startDateMonitoring: validateStartTime.feedback,
      endDateMonitoring: validateEndTime.feedback
    })
    return validateStartTime.isValid && validateEndTime.isValid
  }

  function validSchedule() {
    let validTestName = validateTextInput(testname, 'Test name')
    setInputValid({ ...inputValid, testname: validTestName.isValid })
    setValidatorFeedback({ ...validatorFeedback, testname: validTestName.feedback })
    let isValidDateTime = true
    if (schedule.enabled) {
      isValidDateTime = validateDateTimeInput(schedule.start_monitoring_date, schedule.end_monitoring_date)
    }

    setValidatorEnabled({ ...validatorEnabled, testname: true, startDateMonitoring: true, endDateMonitoring: true })
    return validTestName.isValid && isValidDateTime
  }

  function submitTestSchedule(e) {
    e.preventDefault()
    submitTest(validSchedule())
  }

  return (
    <Card className="top-line-info">
      <CardHeader className="no-border">
        <CardTitle>Settings</CardTitle>
      </CardHeader>
      <CardBody>
        <Form id="testform" onSubmit={submitTestSchedule}>
          {/* Label's row */}
          <Row>
            <Col xs="3">
              <Label for="testname">
                Name: <p className="text-danger d-inline">*</p>
              </Label>
            </Col>
            <Col xs="7">
              <Label for="locations">Select a location:</Label>
            </Col>
          </Row>
          {/* Field's row */}
          <Row className="d-flex align-items-top">
            <style>{`.react-datepicker { width: 328px}`}</style>
            <Col sm="3" xs="4">
              <Input
                id="testname"
                name="testname"
                placeholder="Test's name"
                value={testname}
                valid={validatorEnabled.testname && inputValid.testname === true}
                invalid={validatorEnabled.testname && inputValid.testname === false}
                onChange={e => {
                  setTestname(e.target.value)
                  let validateResult = validateTextInput(e.target.value, 'Test name')
                  setInputValid({ ...inputValid, [e.target.name]: validateResult.isValid })
                  setValidatorFeedback({
                    ...validatorFeedback,
                    [e.target.name]: validateResult.feedback
                  })
                }}
                onBlur={e => {
                  setValidatorEnabled({ ...validatorEnabled, [e.target.name]: true })
                  let validateResult = validateTextInput(e.target.value, 'Test name')
                  setInputValid({ ...inputValid, [e.target.name]: validateResult.isValid })
                  setValidatorFeedback({
                    ...validatorFeedback,
                    [e.target.name]: validateResult.feedback
                  })
                }}
              />
              {validatorEnabled.testname && <small className="text-danger">{validatorFeedback.testname}</small>}
            </Col>
            <Col sm="7" xs="8">
              <FormGroup>
                <Select
                  id="locations"
                  name="locations"
                  isMulti
                  options={locationOptions(testAgents, locations)}
                  value={globalLocations}
                  placeholder="Select an option from a list..."
                  onChange={e => {
                    handleGlobalLocations(e)
                  }}
                  styles={{
                    menu: provided => ({ ...provided, zIndex: 9999 })
                  }}
                />
              </FormGroup>
            </Col>
            <Col sm="2" xs="12">
              <DropdownButton
                validateDateTimeInput={validateDateTimeInput}
                inputValid={inputValid}
                disabledButton={disabledButton}
                validatorEnabled={validatorEnabled}
                setValidatorEnabled={setValidatorEnabled}
                validatorFeedback={validatorFeedback}
              />
              {schedule.enabled ? (
                <div className="monitoring-warning">
                  <small className="text-dark ">*monitoring mode is on</small>
                </div>
              ) : (
                false
              )}
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  )
}
