import { createContext } from 'react'

// set the defaults
const TestSuiteConfigurator = createContext({
  selectedBlock: {},
  setSelectedBlock: () => {},
  steps: [],
  setSteps: () => {},
  setSelTestIndexes: () => {},
  testname: '',
  setTestname: () => {},
  schedule: {},
  setSchedule: () => {},
  globalLocations: [],
  handleGlobalLocations: () => {},
  options: {},
  setOptions: () => {},
  defaultNotificationsOptions: {},
  priorityLimit: 0,
  setPriorityLimit: () => {},
  presetId: null,
  checkAllTestsValid: () => {},
  handleFormChange: () => {},
  handleMultipleInputChange: () => {},
  handleOverrideLocations: () => {},
  addInputToMultiple: () => {},
  removeInputFromMultiple: () => {},
  submitTest: () => {},
  handleThresholdsChange: () => {},
  handleAdditionalConfigurationChange: () => {}
})

export default TestSuiteConfigurator
