import React, { Component } from 'react'
import classnames from 'classnames'
import {
  Button, Card, CardBody, CardFooter, CardHeader, CardTitle,
  Nav, NavItem, NavLink, TabContent, TabPane
} from 'reactstrap'

class Menu extends Component {
  state = {
    activeTab: 0
  }

  toggle = (tab) => {
    const { activeTab } = this.state
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab
      })
    }
  }

  render () {
    const { children, tabs, title } = this.props
    const { activeTab } = this.state

    return (
      <Card>
        <CardHeader className='d-flex p-0'>
          <CardTitle className='p-3'>{title}</CardTitle>
          <Nav pills className='ml-auto p-2'>
            {tabs.map((tab, i) => (
              <NavItem key={i}>
                <NavLink
                  href='#'
                  className={classnames({ active: activeTab === i })}
                  onClick={() => this.toggle(i)}>
                  {tab}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
        </CardHeader>
        <CardBody>
          <TabContent activeTab={activeTab}>
            {children.map((child, i) => (
              <TabPane tabId={i} key={i}>
                {child}
              </TabPane>
            ))}
          </TabContent>
        </CardBody>
        <CardFooter>
          <Button color='info' className='float-right' type='submit'>Apply</Button>
        </CardFooter>
      </Card>
    )
  }
}

export default Menu
