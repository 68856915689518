import React, { useContext } from 'react'
import { CustomInput, FormFeedback } from 'reactstrap'
import TestSuiteConfigurator from '../../context/TestSuiteConfiguratorContext'

const SliderInput = ({ props }) => {
  const { option, validator, removeValidationParamsFromMultipleInput, handleBlur, validateInput, ...otherProps } = props
  const { selectedBlock, handleFormChange } = useContext(TestSuiteConfigurator)

  return (
    <>
      <div>
        <span>Value:</span>
        <strong>{selectedBlock.params && selectedBlock.params[option.name]}</strong>
      </div>
      <CustomInput
        type="range"
        id={option.name}
        step={option.attributes.step}
        min={option.attributes.min_value}
        max={option.attributes.max_value}
        value={(selectedBlock.params && selectedBlock.params[option.name]) || option.attributes.min_value}
        onChange={e => {
          handleFormChange(parseInt(e.target.value), option.name, 'params')
        }}
        onClick={e => {
          e.stopPropagation()
        }}
        {...otherProps}
      />
      <FormFeedback>{validator.feedback[option.name]}</FormFeedback>
    </>
  )
}

export default SliderInput
