import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as actions from '../../../store/actions'
import { Col, Row } from 'reactstrap'
import { ContainerFluid, Content, ContentHeader } from '../../../components'
import {
  TestSuiteCard,
  ResultDetailsCard,
  ResultScheduleCard,
  ResultParametersCard,
  ResultChartCard,
  ResultParametersEnvTypeCard
} from '../'
import ResultTasksCard from './ResultTasksCard'
import ResultsProvider from '../../../context/ResultsContext'
import TestSuiteProvider from '../../../context/TestSuiteContext'
import ResultThresholdHealthCard from './ResultThresholdHealthCard'
import TestSuiteInResults from '../../../context/TestSuiteInResultsContext'

export default function ResultDetails({ match }) {
  const dispatch = useDispatch()
  const token = useSelector(state => state.auth.token)
  const testSuite = useSelector(state => state.test_suite.data)
  const testSuiteError = useSelector(state => state.test_suite.error)

  const [selectedBlock, setSelectedBlock] = useState()
  const [selectedEnvBlock, setSelectedEnvBlock] = useState()

  useEffect(() => {
    document.title = 'vUTP | Result Details'
    return () => {
      dispatch(actions.clearData('RESULTS'))
      dispatch(actions.clearDataDetails('TEST_SUITES'))
    }
  }, [])

  useEffect(() => {
    dispatch(actions.clearData('CREATED_TEST'))
  }, [useSelector(state => state.created_test.data)])

  useEffect(() => {
    dispatch(actions.fetchDataDetails(token, 'TEST_SUITES', match.params.id))
  }, [match.params.id])

  useEffect(() => {
    if (
      testSuite &&
      testSuite.test_blocks &&
      Array.isArray(testSuite.test_blocks) &&
      testSuite.test_blocks.length > 0
    ) {
      setSelectedBlock(testSuite.test_blocks[0])
    }
  }, [testSuite])

  useEffect(() => {
    if (selectedBlock) {
      dispatch(
        actions.fetchResultsToChart(token, {
          test_block: selectedBlock.id,
          limit: 1,
          ordering: 'timestamp_created'
        }) // first result of test block
      )
      dispatch(
        actions.fetchResultsToChart(token, {
          test_block: selectedBlock.id,
          limit: 1,
          ordering: '-timestamp_updated'
        }) // last result of test block
      )
    }
    return () => {
      if (selectedBlock) {
        dispatch(actions.clearData('RESULTS_TO_CHARTS'))
      }
    }
  }, [selectedBlock])

  return !testSuiteError ? (
    <TestSuiteInResults.Provider value={{ selectedBlock, setSelectedBlock, selectedEnvBlock, setSelectedEnvBlock }}>
      <TestSuiteProvider testSuiteId={match.params.id}>
        <ResultsProvider testSuiteId={match.params.id}>
          {testSuite && (
            <>
              <ContentHeader title={testSuite && testSuite.name} breadcrumbs={['Results', 'Result Details']} />
              <Content>
                <ContainerFluid>
                  <Row>
                    <Col
                      xl={testSuite && testSuite.repeat_interval > 0 ? 8 : 12}
                      lg={testSuite && testSuite.repeat_interval > 0 ? 8 : 12}
                      md="12"
                      sm="12"
                      xs="12"
                    >
                      <TestSuiteCard />
                    </Col>
                    {testSuite && testSuite.repeat_interval > 0 && (
                      <Col xl="4" lg="4" md="12" sm="12" xs="12">
                        <ResultScheduleCard />
                      </Col>
                    )}
                  </Row>
                  {(selectedBlock || selectedEnvBlock) && (
                    <Row>
                      <Col xl="6" lg="6" md="12" sm="12" xs="12">
                        <ResultDetailsCard />
                      </Col>
                      {selectedBlock && (
                        <Col xl="6" lg="6" md="12" sm="12" xs="12">
                          <ResultParametersCard />
                        </Col>
                      )}
                      {selectedEnvBlock && (
                        <Col xl="6" lg="6" md="12" sm="12" xs="12">
                          <ResultParametersEnvTypeCard />
                        </Col>
                      )}
                    </Row>
                  )}
                  {selectedBlock && (testSuite.repeat_interval > 0 || selectedBlock['task_type']) && (
                    <Row>
                      <Col xl="12" lg="12" md="12" sm="12" xs="12">
                        <ResultChartCard />
                      </Col>
                    </Row>
                  )}
                  {selectedBlock &&
                    testSuite.repeat_interval > 0 &&
                    testSuite.options.thresholds &&
                    testSuite.options.thresholds.enabled && (
                      <Row>
                        <Col xl="12" lg="12" md="12" sm="12" xs="12">
                          <ResultThresholdHealthCard />
                        </Col>
                      </Row>
                    )}
                  <Row>
                    <Col xl="12" lg="12" md="12" sm="12" xs="12">
                      <ResultTasksCard />
                    </Col>
                  </Row>
                </ContainerFluid>
              </Content>
            </>
          )}
        </ResultsProvider>
      </TestSuiteProvider>
    </TestSuiteInResults.Provider>
  ) : (
    <h4>
      <p className="text-center p-2">Test suite (id: {match.params.id}) does not exists.</p>
    </h4>
  )
}
