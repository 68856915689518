import React, { useEffect } from 'react'
import { Col, Row } from 'reactstrap'
import { ContainerFluid, Content, ContentHeader } from '../../components'
import UserProfile from './UserProfile'

export default function Account() {
  useEffect(() => {
    document.title = 'vUTP | Account'
  }, [])

  return (
    <>
      <ContentHeader title="Account" />
      <Content>
        <ContainerFluid>
          <Row>
            <Col xl="6" md="9" sm="12">
              <UserProfile />
            </Col>
          </Row>
        </ContainerFluid>
      </Content>
    </>
  )
}
