import React from 'react'
import { useSelector } from 'react-redux'
import { Alert } from 'reactstrap'

const ConnectionAlert = () => {
  const wsTestAgentsConnStatus = useSelector(state => state.connectionStatus.ws_test_agents)
  const wsNotificationsConnStatus = useSelector(state => state.connectionStatus.ws_notifications)
  const wsResultsConnStatus = useSelector(state => state.connectionStatus.ws_results)
  const wsTestSuites = useSelector(state => state.connectionStatus.ws_test_suites)
  const wsTestSuite = useSelector(state => state.connectionStatus.ws_test_suite)

  const getWSDisconnectedNames = () => {
    let names = []
    if (wsTestAgentsConnStatus === false) {
      names.push('Test Agents')
    }
    if (wsNotificationsConnStatus === false) {
      names.push('Notifications')
    }
    if (wsResultsConnStatus === false) {
      names.push('Results')
    }
    if (wsTestSuites === false) {
      names.push('Test Suites')
    }
    if (wsTestSuite === false) {
      names.push('Test Suite')
    }
    return names
  }

  const wsDisconnectedNames = getWSDisconnectedNames()

  return (
    <div className="pt-1">
      {wsDisconnectedNames.length > 0 && (
        <Alert color="danger" className="m-2">
          {wsDisconnectedNames.map((item, index) => {
            return `${item}${index !== wsDisconnectedNames.length - 1 ? ', ' : ''}`
          })}{' '}
          WebSocket{wsDisconnectedNames.length > 1 && 's'} disconnected. Please refresh a page to reconnect.
        </Alert>
      )}
    </div>
  )
}

export default ConnectionAlert
