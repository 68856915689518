import React from 'react'

const ContentHeader = props => (
  <div className="content-header">
    <div className="container-fluid">
      <div className="row mb-2">
        <div className="col-sm-6">
          <h1>
            {props.title} <small className="text-muted">{props.subtitle}</small>
          </h1>
        </div>
        <div className="col-sm-6">
          <ol className="breadcrumb float-sm-right">
            <li className="breadcrumb-item active">
              <a href="/">{'Home'}</a>
            </li>
            {props.breadcrumbs && props.breadcrumbs.length > 0 ? (
              props.breadcrumbs.map((breadcrumb, index) => (
                <li key={index} className="breadcrumb-item">
                  {breadcrumb}
                </li>
              ))
            ) : (
              <li className="breadcrumb-item">{props.title}</li>
            )}
          </ol>
        </div>
      </div>
    </div>
  </div>
)

export default ContentHeader
