import React, { useRef } from 'react'
import { NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'reactstrap'
import { Modal } from '../../components'
import { FaTrashAlt, FaEdit } from 'react-icons/fa'
import * as actions from '../../store/actions'
import { adminURI } from '../../api/endpoints'

const GroupRow = ({ group, permissions }) => {
  const dispatch = useDispatch()
  const modalRef = useRef()
  const token = useSelector(state => state.auth.token)

  function deleteItem(group) {
    dispatch(actions.deleteData(token, 'GROUPS', group.id, false, adminURI))
  }

  return (
    <tr>
      <td>{group.name}</td>
      <td>{group.users && group.users.length}</td>
      <td>
        {group.permission_ids && group.permission_ids.length}/{permissions && permissions.length}
      </td>
      <td style={{ textAlign: 'center' }}>
        <NavLink className="p-3" to={`/groups/edit/${group.id}`}>
          <FaEdit title="Edit group" />
        </NavLink>
        <Modal
          ref={modalRef}
          title={`Delete group "${group.name}"`}
          content={`Are you sure you want to delete "${group.name}" group?`}
          onApply={() => deleteItem(group)}
        />
        <Button color="link" onClick={() => modalRef.current.openModal()}>
          <FaTrashAlt title="Delete group" />
        </Button>
      </td>
    </tr>
  )
}

export default GroupRow
