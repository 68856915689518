import React from 'react'
import { Table } from 'reactstrap'
import { BooleanIcon } from '../../../../components'

export default function ResultTerminate5GInfra({ result }) {
  return (
    <div>
      <Table responsive size="sm" style={{ textAlign: 'center' }}>
        <thead>
          <tr>
            <th>Succeeded</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{<BooleanIcon value={result.succeeded} />}</td>
          </tr>
        </tbody>
      </Table>
    </div>
  )
}
